import { useContext } from 'react';
import { AddEditJobContext } from './AddEditJobContext';
import AddEquipments from '../../components/components_for_add_edit_job_slinga/AddEquipments';

/**
 * Component that wraps 'AddEquipments' and passes in data state variables from 'AddEditJobContext'
 */
export default function AddEquipmentsToJob() {
  const { vehicle, vehicleEquipments, setVehicleEquipments } = useContext(AddEditJobContext);

  return (
    <AddEquipments
      vehicle={vehicle}
      vehicleEquipments={vehicleEquipments}
      setVehicleEquipments={setVehicleEquipments}
    />
  );
}
