import { useContext, useState } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';

import { PopupContext } from '../contexts/PopupContext';
import { EquipmentDoc } from '../utils/types';
import EquipmentForm from '../forms/EquipmentForm';
import { stopPropagation } from '../utils/uiHelpers';
import { addEquipment } from '../firebase/firestore_functions/equipments';

export default function AddEquipment() {
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);
  const [saving, setSaving] = useState<boolean>(false);

  async function handleSubmit(equipment: EquipmentDoc) {
    setSaving(true);

    const addResponse = await addEquipment(equipment);

    if (addResponse.code === 201) {
      notify('Fordonutrustning tillagt', NotificationStatus.SUCCESS);
      setSaving(false);
      close();
    } else {
      setSaving(false);
      notify('Hmm kunde inte spara', NotificationStatus.ERROR);
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Lägg till fordonsutrustning</h2>
      </header>
      <main>
        <EquipmentForm handleSubmit={handleSubmit} saving={saving} />
      </main>
    </section>
  );
}
