import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import Textarea from '../../components/Textarea';
import { DataContext } from '../../contexts/CommonDataContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import { saveSignature, updateJob } from '../../firebase/firestore_functions/job';
import { saveImageStorage } from '../../firebase/storageFunctions';
import { NotificationStatus } from '../../utils/constants';
import { sendSMS } from '../../utils/otherHelpers';
import { toRangeDateString } from '../../utils/time';
import { ClientData, JobData } from '../../utils/types';
import ReportSummary from '../report_job/ReportSummary';
import './Sign.css';

type SignProps = {
  job: JobData;
};

/**
 *
 * Component to get a signature from the clients
 * @returns JSX.Element
 */
export default function Sign({ job }: SignProps) {
  const { close } = useContext(PopupContext);
  const { notify } = useContext(NotificationContext);
  const [name, setName] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  // Get the list of clients
  const { clients } = useContext(DataContext);
  const [suggestions, setSuggestions] = useState<Array<ClientData>>([]);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const [prevX, setPrevX] = useState<number>();
  const [prevY, setPrevY] = useState<number>();

  useEffect(setupCanvas, []);

  function setupCanvas() {
    if (canvasRef.current) {
      const _ctx = canvasRef.current.getContext('2d');
      if (_ctx) {
        setCtx(_ctx);
      }

      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = 200;

      // Disable any scrolling actions
      canvasRef.current.addEventListener('touchstart', function (event) {
        event.preventDefault();
      });
      canvasRef.current.addEventListener('touchmove', function (event) {
        event.preventDefault();
      });
      canvasRef.current.addEventListener('touchend', function (event) {
        event.preventDefault();
      });
      canvasRef.current.addEventListener('touchcancel', function (event) {
        event.preventDefault();
      });
    }
  }

  function clearCanvas() {
    if (canvasRef.current && ctx) {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      setPrevX(undefined);
      setPrevY(undefined);
    }
  }

  function onChangeName(event: SyntheticEvent) {
    setName((event.target as HTMLInputElement).value);
  }

  /**
   * Draws the lines in the signature as the user touches the canvas.
   * @param event
   */
  function draw(event: SyntheticEvent) {
    if (canvasRef.current && ctx) {
      // calculate difference between where touch event relative to client
      // happens and the canvas position to get position in canvas

      const rect = (canvasRef.current as HTMLCanvasElement).getBoundingClientRect();

      const x = (event as never as TouchEvent).targetTouches[0].clientX - rect.x;
      const y = (event as never as TouchEvent).targetTouches[0].clientY - rect.y;

      if (prevX && prevY) {
        // draw a line between current and previous position
        ctx.fillStyle = 'rgb(0,0,0)';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(prevX, prevY);
        ctx.lineTo(x, y);
        ctx.stroke();
      }

      setPrevX(x);
      setPrevY(y);
    }
  }

  function onTouchStartCapture(event: SyntheticEvent) {
    // To prevent forcing cursive on the user we reset previous pen down on pen down
    setPrevX(undefined);
    setPrevY(undefined);
  }

  function save() {
    // sendEmail("lovejansson.94@gmail.com");
    if (prevX && canvasRef.current) {
      canvasRef.current.toBlob(async (blob) => {
        if (blob) {
          const response = await saveSignature(job.docId, {
            name,
            email,
            comment,
          });

          if (response.code === 200) {
            saveSignatureToStorage(blob);
          } else {
            notify('Kunde inte spara signaturen', NotificationStatus.ERROR);
          }
        }
      });
    }
  }

  async function saveSignatureToStorage(blob: Blob) {
    if (job) {
      let nameOfSignatureFile: string;

      if (job.client) {
        nameOfSignatureFile = job.client.name;
      } else if (job.contactClient) {
        nameOfSignatureFile = job.contactClient.name;
      } else if (job.contactClientTemp) {
        nameOfSignatureFile = job.contactClientTemp;
      } else {
        nameOfSignatureFile = 'unknown';
      }

      // save image in storage

      const storageResponse = await saveImageStorage(
        `/${job?.orderNum}/signs/${nameOfSignatureFile}`,
        blob,
      );

      // set the job as signed
      if (storageResponse.code === 201) {
        const firestoreResponse = await updateJob(job.docId, {
          signed: true,
        });

        if (firestoreResponse.code === 201) {
          close();
          notify('Jobbet har signerats', NotificationStatus.SUCCESS);
        } else {
          notify(
            `Kunde inte spara signaturen: ${firestoreResponse.error}`,
            NotificationStatus.ERROR,
          );
        }
      } else {
        notify(`Kunde inte spara signaturen: ${storageResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  // Stuff needed for the AutoComplete component
  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const copy = [...clients];

    return inputLength === 0
      ? []
      : copy.filter((client) => client.email.trim().toLowerCase().includes(inputValue));
  };

  const getSuggestionValue = (suggestion: ClientData) => suggestion.email;

  const renderSuggestion = (suggestion: ClientData) => {
    return (
      <div>
        <p>{`${suggestion.name}, ${suggestion.email}`}</p>
      </div>
    );
  };

  const onSuggestionsFetchReq = ({ value }: any) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearReq = () => {
    setSuggestions([]);
  };

  const onChange = (event: any, { newValue }: any) => {
    setEmail(newValue);
  };

  const copySignLink = (e: SyntheticEvent) => {
    const url = `${window.location.origin}/signera-${job.docId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        notify('Kopierade signeringslänk till urklipp', NotificationStatus.SUCCESS);
      })
      .catch(() => {
        notify('Kunde inte kopiera till urklipp', NotificationStatus.ERROR);
      });
  };

  const sendSignLink = (e: SyntheticEvent) => {
    if (job.client && job.client.phone !== '') {
      const phone = job.client.phone;
      sendSMS(
        `Hej ${job.client.name}!\n\nDu har ett nytt uppdrag att signera från Nolblad:\nhttps://nolblad.se/signera-${job.docId}`,
        phone,
      )
        .catch((e) => {
          console.log('ERROR: ', e);
        })
        .then((_) => {
          console.log('SMS sent to client with phone number ' + job.client?.phone);
        });
    } else {
      if (!job.client) notify('Saknar kund', NotificationStatus.ERROR);
      else notify(`Saknar kund namn ${job.client.phone}`, NotificationStatus.ERROR);
    }
  };

  return (
    <section id='sign'>
      <button className='fa fa-share button--main' style={{ margin: '8px' }} onClick={copySignLink}>
        Kopiera signeringslänk
      </button>
      <button
        className='fa fa-share button--main'
        style={{ margin: '8px', marginBottom: '32px' }}
        onClick={sendSignLink}
      >
        Skicka sms med länk till kund
      </button>
      <h2>
        {' '}
        Signera uppdrag {toRangeDateString(job.start, job.end)} {job.client && job.client.name}
      </h2>

      <ReportSummary job={job} showSensitiveData={false} />
      <section id='sign__info'>
        <input
          id='name'
          type='text'
          className='input-text'
          value={name}
          placeholder='Namn'
          onChange={onChangeName}
        />
        <Textarea id='comment' value={comment} setState={setComment} placeholder={'Kommentar'} />

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchReq}
          onSuggestionsClearRequested={onSuggestionsClearReq}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder: 'Email',
            value: email,
            onChange: onChange,
            className: 'input-text',
            id: 'email',
          }}
        />
      </section>

      <section id='sign__sign'>
        <h3>Underskrift</h3>

        <button onClick={clearCanvas} className='button--icon fa fa-eraser'>
          Radera
        </button>

        <canvas
          ref={canvasRef}
          id='sign__scetch-pad'
          onTouchMove={draw}
          onTouchStartCapture={onTouchStartCapture}
        ></canvas>
      </section>

      <button onClick={save} className='button--green' style={{ marginTop: '2.4rem' }}>
        Signera
      </button>
    </section>
  );
}
