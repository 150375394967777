import { useContext, useState } from 'react';
import { Client, ClientData } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import ClientForm from '../forms/ClientForm';
import { stopPropagation } from '../utils/uiHelpers';
import { PopupContext } from '../contexts/PopupContext';
import { updateClient } from '../firebase/firestore_functions/clients';

interface EditClientProps {
  client: ClientData;
}

export default function EditClient({ client }: EditClientProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  function getChanges(editedClient: Client) {
    const changes: any = {};

    if (client.name !== editedClient.name) {
      changes.name = editedClient.name;
    }

    if (client.phone !== editedClient.phone) {
      changes.phone = editedClient.phone;
    }

    if (client.email !== editedClient.email) {
      changes.email = editedClient.email;
    }

    return changes;
  }

  async function handleSubmit(editedClient: Client) {
    const changes = getChanges(editedClient);

    if (Object.keys(changes).length !== 0) {
      const updateResponse = await updateClient(changes, client.docId);

      setSaving(false);
      if (updateResponse.code === 201) {
        close();
        notify('Kund uppdaterad', NotificationStatus.SUCCESS);
        // Call save client cloud function
      } else {
        notify(`Hmm kunde inte uppdatera kund: ${updateResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Redigera kund</h2>
      </header>
      <main>
        <ClientForm
          handleSubmit={handleSubmit}
          saving={saving}
          name={client.name}
          phone={client.phone}
          email={client.email}
          orgNum={client.orgNum}
        />
      </main>
    </section>
  );
}
