import { FirebaseError } from 'firebase/app';
import {
  getFirestore,
  runTransaction,
  doc,
  collection,
  setDoc,
  DocumentReference,
  Transaction,
  updateDoc,
  getDoc,
} from 'firebase/firestore';

import { JobStatus, jobStatusLabel, NotificationStatus } from '../../utils/constants';
import { populateDocs } from './common';
import {
  AddedMaterialData,
  FirebaseResponse,
  JobData,
  JobDataPartial,
  JobDoc,
  JobPopulatedFields,
  JobReportData,
  MaterialData,
  ReportRowData,
  SlingaDoc,
  JobReportDoc,
  UpdateJobParams,
  UpdateJobParamsData,
  UpdateJobParamsDoc,
  Signature,
  UserPartial,
  User,
  ClientPartial,
  LittraPartial,
  LittraDoc,
  VehicleDoc,
  ClientDoc,
  ContactDoc,
  ContactPartial,
  VehiclePartial,
} from '../../utils/types';
import { v4 as uuidv4 } from 'uuid';
import { getImageUrl, saveImageStorage } from '../storageFunctions';
import { b64ToBlob } from '../../utils/otherHelpers';

const db = getFirestore();

/**
 * Gets single job
 * @param id doc id of job
 * @returns FirebaseResponse with JobDoc in case of success.
 */
export async function getJob(id: string): Promise<FirebaseResponse> {
  try {
    const jobRef = doc(db, 'jobs', id);
    const jobSnap = await getDoc(jobRef);

    if (jobSnap.data()) {
      const jobData = await toJobData(jobSnap.id, jobSnap.data() as JobDoc);

      return {
        code: 200,
        data: jobData,
      };
    } else {
      return {
        code: 404,
        error: 'not found',
      };
    }
  } catch (e) {
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Gets a list of jobs
 * @param ids doc ids of the jobs to get.
 * @returns FirebaseResponse with a list of JobData in case of success.
 */
export async function getJobs(ids: string[]): Promise<FirebaseResponse> {
  try {
    const jobsPromises: Promise<JobData>[] = [];

    for (const docId of ids) {
      jobsPromises.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve, reject) => {
          try {
            const job = await getJob(docId);

            if (job.data) {
              resolve(job.data);
            } else {
              reject(job.error);
            }
          } catch (e) {
            reject(e);
          }
        }),
      );
    }

    const jobs = await Promise.all(jobsPromises);

    return {
      code: 200,
      data: jobs,
    };
  } catch (e) {
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Adds a job to "jobs" collection. This procedure has other side effects as well:
 *
 * - The order number in the "constants" collection is incremented.
 * - Images added to the job is saved in firebase storage
 *
 * @param jobDoc the document to add to firestore
 * @param ongoingTransaction if this operation should be included in a transaction.
 * @returns FirebaseResponse with an object containing the newly added job's doc ID and order number.
 */
export async function addJob(
  jobDoc: JobDoc,
  ongoingTransaction?: Transaction,
): Promise<FirebaseResponse> {
  try {
    const response = await runTransaction(db, async (transaction) => {
      const transactionToUse = ongoingTransaction ? ongoingTransaction : transaction;

      // Get current orderNumber
      const orderNumberRef = doc(db, 'constants', 'orderNumber');
      const orderNumberDoc = await transactionToUse.get(orderNumberRef);

      if (orderNumberDoc.exists()) {
        const orderNumber = orderNumberDoc.data().orderNumber;

        // Create new job ref
        const newJobRef = doc(collection(db, 'jobs'));

        // Check if images should be added to firebase storage

        if (jobDoc.images && jobDoc.images.length > 0) {
          const images: string[] = [];
          const promises: Promise<void>[] = [];

          for (const img of jobDoc.images) {
            promises.push(
              // eslint-disable-next-line no-async-promise-executor
              new Promise<void>(async (resolve, reject) => {
                try {
                  let url = img;
                  const imageId = uuidv4();
                  await saveImageStorage(`/${orderNumber}/from_office/${imageId}`, b64ToBlob(img));

                  const urlResponse = await getImageUrl(`/${orderNumber}/from_office/${imageId}`);

                  if (urlResponse.data) {
                    url = urlResponse.data;
                  }

                  images.push(url);
                  resolve();
                } catch (e) {
                  reject(e);
                }
              }),
            );
          }

          await Promise.all(promises);

          jobDoc.images = images;
        }

        // Add the job

        transactionToUse.set(newJobRef, { ...jobDoc, orderNum: orderNumber });

        // Increment orderNumber
        transactionToUse.update(orderNumberRef, {
          orderNumber: orderNumber + 1,
        });

        return {
          code: 200,
          data: { orderNumber, docId: newJobRef.id },
        };
      } else {
        console.error('Error while getting orderNumber');
        return {
          code: 404,
          error: 'orderNumber',
        };
      }
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Adds a list of jobs to "jobs" collection, used when adding a slinga since a slinga contains a list of jobs.
 * @param jobs the list of job documents to add.
 * @param transaction the transaction that this operation should be included in. (called from addSlinga where a transaction is started)
 * @returns FirebaseResponse with
 */

export async function addJobs(jobs: JobDoc[], transaction: Transaction): Promise<FirebaseResponse> {
  try {
    const addedJobs: DocumentReference[] = [];

    // 1. fetch current orderNumber
    const orderNumberRef = doc(db, 'constants', 'orderNumber');
    const orderNumberDoc = await transaction.get(orderNumberRef);

    if (orderNumberDoc.exists()) {
      let orderNumber = orderNumberDoc.data().orderNumber;
      for (const job of jobs) {
        // 2. update each job with order number
        job.orderNum = orderNumber;

        if (job.images && job.images.length > 0) {
          const images: string[] = [];
          const promises: Promise<void>[] = [];

          for (const img of job.images) {
            promises.push(
              // eslint-disable-next-line no-async-promise-executor
              new Promise<void>(async (resolve, _) => {
                let url = img;
                const imageId = uuidv4();
                await saveImageStorage(`/${orderNumber}/from_office/${imageId}`, b64ToBlob(img));

                const urlResponse = await getImageUrl(`/${orderNumber}/from_office/${imageId}`);

                if (urlResponse.data) {
                  url = urlResponse.data;
                }

                images.push(url);
                resolve();
              }),
            );
          }

          await Promise.all(promises);

          job.images = images;
        }
        ++orderNumber;

        // 3. add the job to db
        const newJobRef = doc(collection(db, 'jobs'));
        transaction.set(newJobRef, job);

        // 4. add job ref to result array
        addedJobs.push(newJobRef);
      }

      // 5. update order number
      transaction.update(orderNumberRef, { orderNumber });
    } else {
      return { code: 404, error: 'orderNum' };
    }

    return { code: 201, data: { addedJobs } };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * overwrites a job doc with new data. Is used in 'AddEditJob'.
 * @param updatedJobDoc
 * @param job
 * @returns
 */
export async function setJob(updatedJobDoc: JobDoc, job: JobData) {
  try {
    await setDoc(doc(db, 'jobs', job.docId), {
      ...updatedJobDoc,
      orderNum: job.orderNum,
    });

    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Deletes a job and deletes its entry from the calendar collections "months", "weeks", "days"
 * @param job
 * @returns FirebaseResponse
 */
export async function deleteJob(job: JobData): Promise<FirebaseResponse> {
  try {
    const response = await runTransaction(db, async (transaction) => {
      // 1. remove the job ref from slinga's array of jobs
      if (job.slinga) {
        const slingaRef = doc(db, 'jobs', job.slinga.docId);
        const slingaDoc = await transaction.get(slingaRef);

        if (slingaDoc.exists()) {
          // filter away the deleted job
          const jobs = (slingaDoc.data() as SlingaDoc).jobs;
          const filteredJobs = jobs.filter((j) => j.id !== job.docId);

          // update the slinga with new jobs
          transaction.update(slingaRef, { jobs: filteredJobs });
        } else {
          return {
            code: 404,
            error: 'slinga',
          };
        }
      }

      // 4. delete job
      transaction.delete(doc(db, 'jobs', job.docId));

      return {
        code: 200,
      };
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Deletes an array of jobs. Used when a slinga is deleted
 * @param jobIds
 * @param transaction
 * @returns FirebaseResponse
 */
export function deleteJobs(jobIds: string[], transaction: Transaction): FirebaseResponse {
  try {
    for (const id of jobIds) {
      transaction.delete(doc(collection(db, 'jobs'), id));
    }

    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * @param docId
 * @param updates
 * @param oldStart if dates has changed
 * @param oldEnd if dates has changed
 * @returns FirebaseResponse
 */
export async function updateJob(
  docId: string,
  updates: UpdateJobParams,
  oldStart?: number,
  oldEnd?: number,
): Promise<FirebaseResponse> {
  try {
    const response = runTransaction(db, async (transaction) => {
      try {
        const updatesDoc = fromUpdateJobParamsDataToDoc(updates) as {
          [x: string]: any;
        };

        console.log('updatesDoc sent to firestore ', updatesDoc);

        // 3. update the job
        transaction.update(doc(db, 'jobs', docId), updatesDoc);
      } catch (e) {
        return {
          code: 500,
          error: (e as FirebaseError).code,
        };
      }

      return { code: 201 };
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Copies a job and ads it to the db. The copy has new order number and status is 'JobStatus.New'.
 * @param docId id of job to copy
 * @returns FirebaseResponse
 */
export async function copyJob(
  docId: string,
  slinga?: DocumentReference,
  transactionSlinga?: Transaction,
): Promise<FirebaseResponse> {
  try {
    const response = await runTransaction(db, async (transaction) => {
      const transactionToUse = transactionSlinga ? transactionSlinga : transaction;

      // 1. fetch orderNumber
      const orderNumberRef = doc(db, 'constants', 'orderNumber');
      const orderNumberDoc = await transactionToUse.get(orderNumberRef);

      if (!orderNumberDoc.exists()) {
        return { code: 404, error: 'orderNumber' };
      }
      const orderNum = orderNumberDoc.data().orderNumber;

      // 2. get the job to copy
      const jobDoc = await transactionToUse.get(doc(db, 'jobs', docId));

      if (!jobDoc.exists()) {
        return { code: 404, error: 'job to copy' };
      }
      const copy: JobDoc = { ...(jobDoc.data() as JobDoc), orderNum };

      // 3. delete reports and change status of original job and add slinga if belongs to slinga

      if (copy.reports) {
        delete copy.reports;
      }

      copy.status = JobStatus.NEW;

      if (slinga) {
        copy.slinga = slinga;
      }

      // if driver field contains data, then we need to check the type of that field.
      // if it is not a partial, then we fetch the document
      // TODO is it maybe worth it to break out this convertion into a function that is generic over the type?
      if (copy.driver && copy.driver instanceof DocumentReference) {
        const user = (await getDoc(copy.driver)).data() as User;
        const partial: UserPartial = {
          ref: copy.driver,
          firstName: user?.firstName ? user.firstName : '-',
        };
        copy.driver = partial;
      }

      // we do the same for the client
      if (copy.client && copy.client instanceof DocumentReference) {
        const client = (await getDoc(copy.client)).data() as ClientDoc;
        const partial: ClientPartial = {
          ref: copy.client,
          name: client?.name ? client.name : '-',
          phone: client?.phone ? client.phone : '-',
        };
        copy.client = partial;
      }

      // and for the littra
      if (copy.littra && copy.littra instanceof DocumentReference) {
        const littra = (await getDoc(copy.littra)).data() as LittraDoc;
        const partial: LittraPartial = {
          ref: copy.littra,
          name: littra?.projectNum ? littra.projectNum : '-',
        };
        copy.littra = partial;
      }

      // and for the vehicle
      if (copy.vehicle && copy.vehicle instanceof DocumentReference) {
        // if it is a reference we need to fetch the data as if it was a User
        const vehicle = (await getDoc(copy.vehicle)).data() as VehicleDoc;
        const partial: VehiclePartial = {
          ref: copy.vehicle,
          name: vehicle?.id ? vehicle.id : '-',
          vehicleType: vehicle.vehicleType,
        };
        copy.vehicle = partial;
      }

      // and for the client contact
      if (copy.contactClient && copy.contactClient instanceof DocumentReference) {
        // if it is a reference we need to fetch the data as if it was a User
        const contact = (await getDoc(copy.contactClient)).data() as ContactDoc;
        const partial: ContactPartial = {
          ref: copy.contactClient,
          name: contact?.name ? contact.name : '-',
          phone: contact?.phone ? contact.phone : '-',
        };
        copy.contactClient = partial;
      }

      const newJobRef = doc(collection(db, 'jobs'));

      // 6 update the copy doc

      transactionToUse.set(newJobRef, copy);

      // 5. increment orderNumber
      transactionToUse.update(orderNumberRef, {
        orderNumber: orderNum + 1,
      });

      return { code: 201, data: newJobRef.id };
    });

    return response;
  } catch (e) {
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Saves information about who has signed a job.
 * @param docId
 * @param signature
 * @returns FirebaseResponse with only status code 200 if successful.
 */
export async function saveSignature(docId: string, signature: Signature) {
  try {
    await updateDoc(doc(db, 'jobs', docId), { signedBy: signature });

    return {
      code: 200,
    };
  } catch (e) {
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

//////////////
// HELPERS //
////////////

/**
 *
 * Populates the specified fields of jobDoc and returns an object with the data. The possible field changes are the following:
 *
 * FROM THESE:
 * driver?: DocumentReference;
 * vehicle?: DocumentReference;
 * client?: DocumentReference;
 * contact?: DocumentReference;
 * littra?: DocumentReference;
 * materials?: Array<AddedMaterialDoc>;
 * vehicleEquipments?: Array<DocumentReference>;
 * reports?: Array<JobReportDoc>;
 *
 * TO THESE:
 * driver?: User;
 * vehicle?: VehicleData;
 * vehicleEquipments?: Array<Equipment>;
 * client?: ClientData;
 * littra?: LittraData;
 * contact?: ClientContactData;
 * materials?: Array<AddedMaterialData>;
 * reports?: Array<JobReportData>;
 *
 * @param jobDoc
 * @param fields
 * @returns JobPopulatedFields
 */

export async function populateFieldsInJobDoc(
  jobDoc: JobDoc,
  fields: string[],
): Promise<FirebaseResponse> {
  try {
    const promises = [];

    const populatedFields: JobPopulatedFields = {};

    for (const field of fields) {
      promises.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise<void>(async (resolve, _) => {
          // POPULATE THE MATERIALS
          if (field === 'materials') {
            if (jobDoc.materials) {
              // need to fetch the material doc within all addedmaterials

              const materials: AddedMaterialData[] = [];
              const materialsPromises = [];

              for (const addedMaterialDoc of jobDoc.materials) {
                materialsPromises.push(
                  // eslint-disable-next-line no-async-promise-executor
                  new Promise<void>(async (resolve, _) => {
                    const materialResponse = await populateDocs([addedMaterialDoc.material]);

                    if (materialResponse.code === 200 && materialResponse.data) {
                      materials.push({
                        ...addedMaterialDoc,
                        material: materialResponse.data[0] as MaterialData,
                      });
                    }

                    resolve();
                  }),
                );
              }

              await Promise.all(materialsPromises);

              populatedFields.materials = materials;
            }
            // POPULATE THE MATERIAL ADDED TO THE REPORTS
          } else if (field === 'reports' && jobDoc.reports) {
            if (jobDoc.reports) {
              // need to fetch the material doc within all reportrows

              const reports: JobReportData[] = [];
              const reportsPromises = [];

              for (const report of jobDoc.reports) {
                reportsPromises.push(
                  // eslint-disable-next-line no-async-promise-executor
                  new Promise<void>(async (resolve, reject) => {
                    const _report: JobReportData = { ...report, rows: [] };
                    const rows = [];

                    for (const reportRowDoc of report.rows) {
                      const row: ReportRowData = {
                        ...reportRowDoc,
                        materials: [],
                      };

                      const materials: AddedMaterialData[] = [];

                      for (const materialDoc of reportRowDoc.materials) {
                        const materialResponse = await populateDocs([materialDoc.material]);

                        if (materialResponse.code === 200 && materialResponse.data) {
                          materials.push({
                            ...materialDoc,
                            material: materialResponse.data[0] as MaterialData,
                          });
                        }
                      }

                      row.materials = materials;
                      rows.push(row);
                    }
                    _report.rows = rows;

                    reports.push(_report);
                    resolve();
                  }),
                );
              }

              await Promise.all(reportsPromises);
              populatedFields.reports = reports;
            }

            // POPULATE LITTRA AND THE CONTACT ON THE LITTRA
          } else if (field === 'littra') {
            if (jobDoc.littra) {
              const ref =
                jobDoc.littra instanceof DocumentReference ? jobDoc.littra : jobDoc.littra.ref;

              const populatedLittra = await populateDocs([ref]);

              if (populatedLittra.data[0]) {
                if (populatedLittra.data[0].contact) {
                  const populatedContact = await populateDocs([populatedLittra.data[0].contact]);
                  if (populatedContact.data[0]) {
                    populatedLittra.data[0].contact = populatedContact.data[0];
                  }
                }

                populatedFields.littra = populatedLittra.data[0];
              }
            }

            // POPULATE CLIENT + THE ARRAY OF CONTACTS FOR THE CLIENT
          } else if (field === 'client') {
            if (jobDoc.client) {
              const ref =
                jobDoc.client instanceof DocumentReference ? jobDoc.client : jobDoc.client.ref;

              const populatedClient = await populateDocs([ref]);

              if (populatedClient.data[0]) {
                if (populatedClient.data[0].contacts) {
                  const populatedContacts = await populateDocs(populatedClient.data[0].contacts);
                  if (populatedContacts.data) {
                    populatedClient.data[0].contacts = populatedContacts.data;
                  }
                }

                populatedFields.client = populatedClient.data[0];
              }
            }
            // POPULATE SUBCONTRACTOR + THE ARRAY OF CONTACTS FOR THE SUBCONTRACTOR
          } else if (field === 'subcontractor') {
            if (jobDoc.subcontractor) {
              const populatedSubcontractor = await populateDocs([jobDoc.subcontractor]);

              if (populatedSubcontractor.data[0]) {
                if (populatedSubcontractor.data[0].contacts) {
                  const populatedContacts = await populateDocs(
                    populatedSubcontractor.data[0].contacts,
                  );
                  if (populatedContacts.data) {
                    populatedSubcontractor.data[0].contacts = populatedContacts.data;
                  }
                }

                populatedFields.subcontractor = populatedSubcontractor.data[0];
              }
            }
          } else {
            // ALL OTHER FIELDS THAT NEEDS TO BE POPULATED ARE EITHER SIMPLE REFERENCES OR ONE DIMENSIONAL ARRAYS WITH NO INNER REFERENCES THAT NEEDS TO BE POPULATED
            const value = (jobDoc as any)[field];

            if (value) {
              // not an array, a simple firebase document reference that needs to be populated.
              if (value.length === undefined) {
                // the value above migth be a partial field
                const ref: DocumentReference =
                  value instanceof DocumentReference
                    ? value
                    : 'ref' in value
                    ? (value['ref'] as DocumentReference)
                    : value;

                const populated = await populateDocs([ref]);
                if (populated.code === 200 && populated.data) {
                  (populatedFields as any)[field] = populated.data[0];
                } else {
                  // remove the field value if the process of populating failed.
                  (populatedFields as any)[field] = undefined;
                }

                // an array with document references that needs to be populated.
              } else if (value.length > 0) {
                const populated = await populateDocs(value);
                if (populated.code === 200 && populated.data) {
                  (populatedFields as any)[field] = populated.data;
                } else {
                  // add empty array if the population process failed.
                  (populatedFields as any)[field] = [];
                }
              } else {
                // if the array is empty, add an empty array to the object of populated fields.
                (populatedFields as any)[field] = [];
              }
            }
          }

          resolve();
        }),
      );
    }

    await Promise.all(promises);

    return {
      code: 200,
      data: populatedFields,
    };
  } catch (e) {
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 *
 * a function that converts some fields in the data to document references for storage in firestore.
 *
 * @param updates
 * @returns UpdateJobParamsDoc
 */
export function fromUpdateJobParamsDataToDoc(updates: UpdateJobParamsData) {
  // Create doc refs and filter away undefined fields

  const updatesDoc: UpdateJobParamsDoc = { ...(updates as any) };

  if (updates.client) {
    updatesDoc.client = {
      ref: doc(db, 'clients', updates.client.docId),
      name: updates.client.name,
      phone: updates.client.phone,
    };
  }

  if (updates.contactClient) {
    updatesDoc.contactClient = {
      ref: doc(db, 'contacts', updates.contactClient.docId),
      name: updates.contactClient.name,
      phone: updates.contactClient.phone,
    };
  } else {
    delete updates.contactClient;
  }

  if (!updates.contactClientTemp || updates.contactClientTemp === '') {
    delete updates.contactClientTemp;
  }

  if (updates.subcontractor) {
    updatesDoc.subcontractor = doc(db, 'subcontractors', updates.subcontractor.docId);

    if (updates.contactSubcontractor) {
      updatesDoc.contactSubcontractor = doc(db, 'contacts', updates.contactSubcontractor.docId);
    } else {
      delete updates.contactSubcontractor;
    }
  }

  if (updates.vehicle) {
    updatesDoc.vehicle = {
      ref: doc(db, 'vehicles', updates.vehicle.docId),
      name: updates.vehicle.id,
      vehicleType: updates.vehicle.vehicleType,
    };
  }

  if (updates.vehicleEquipments && updates.vehicleEquipments.length > 0) {
    const _vehicleEquipments: Array<DocumentReference> = [];

    updates.vehicleEquipments.forEach((e) => {
      _vehicleEquipments.push(doc(db, 'equipments', e.docId));
    });

    updatesDoc.vehicleEquipments = _vehicleEquipments;
  }

  if (updates.driver) {
    updatesDoc.driver = {
      ref: doc(db, 'users', updates.driver.docId),
      firstName: updates.driver.firstName,
    };
  }

  if (updates.materials && updates.materials.length > 0) {
    updatesDoc.materials = updates.materials.map((m) => {
      return { ...m, material: doc(db, 'materials', m.material.docId) };
    });
  } else {
    updates.materials = undefined;
  }

  if (updates.littra) {
    updatesDoc.littra = {
      ref: doc(db, `clients/${updates.client?.docId}/littras/${updates.littra.docId}`),
      name: updates.littra.projectNum,
    };
  }

  // Delete undefined values

  for (const entry of Object.entries(updatesDoc)) {
    if (entry[1] === undefined) {
      delete updatesDoc[entry[0] as keyof UpdateJobParamsDoc];
    }
  }
  console.log('updatesDoc ', updatesDoc);
  return updatesDoc;
}

/**
 *
 * a function that converts some fields in the data to document references for storage in firestore.
 *
 * @param jobData
 * @returns JobDoc
 */
export function fromJobDataToJobDoc(jobData: JobData) {
  const jobDoc: JobDoc = {
    jobType: jobData.jobType,
    start: jobData.start,
    end: jobData.end,
    status: jobData.status,
    otherMaterials: jobData.otherMaterials,
    otherInformation: jobData.otherInformation,
    hasReceiptsForCompany: false,
    adminComments: jobData.adminComments,
    images: jobData.images,
    smsHasBeenSent: jobData.smsHasBeenSent,
  };

  // Create doc refs and filter away undefined fields

  if (jobData.client) {
    jobDoc.client = {
      ref: doc(db, 'clients', jobData.client.docId),
      name: jobData.client.name,
      phone: jobData.client.phone,
    };

    if (jobData.contactClient) {
      jobDoc.contactClient = {
        ref: doc(db, 'contacts', jobData.contactClient.docId),
        name: jobData.contactClient.name,
        phone: jobData.contactClient.phone,
      };
    } else if (jobData.contactClientTemp && jobData.contactClientTemp !== '') {
      jobDoc.contactClientTemp = jobData.contactClientTemp;
    }
  }

  if (jobData.estimatedDistance) {
    jobDoc.estimatedDistance = jobData.estimatedDistance;
  }
  if (jobData.estimatedTime) {
    jobDoc.estimatedTime = jobData.estimatedTime;
  }
  if (jobData.subcontractor) {
    jobDoc.subcontractor = doc(db, 'subcontractors', jobData.subcontractor.docId);

    if (jobData.contactSubcontractor) {
      jobDoc.contactSubcontractor = doc(db, 'contacts', jobData.contactSubcontractor.docId);
    }
  }

  if (jobData.littraTemp !== '') {
    jobDoc.littraTemp = jobData.littraTemp;
  }
  if (jobData.littraWorkplaceTemp !== '') {
    jobDoc.littraWorkplaceTemp = jobData.littraWorkplaceTemp;
  }

  if (jobData.from) {
    jobDoc.from = jobData.from;
  }

  if (jobData.to) {
    jobDoc.to = jobData.to;
  }

  if (jobData.vehicle) {
    jobDoc.vehicle = {
      ref: doc(db, 'vehicles', jobData.vehicle.docId),
      name: jobData.vehicle.id,
      vehicleType: jobData.vehicle.vehicleType,
    };

    if (jobData.vehicleEquipments && jobData.vehicleEquipments.length > 0) {
      const _vehicleEquipments: Array<DocumentReference> = [];

      jobData.vehicleEquipments.forEach((e) => {
        _vehicleEquipments.push(doc(db, 'equipments', e.docId));
      });

      jobDoc.vehicleEquipments = _vehicleEquipments;
    }
  }

  if (jobData.driver) {
    jobDoc.driver = {
      ref: doc(db, 'users', jobData.driver.docId),
      firstName: jobData.driver.firstName,
    };
  }

  if (jobData.materials && jobData.materials.length > 0) {
    jobDoc.materials = jobData.materials.map((m) => {
      return { ...m, material: doc(db, 'materials', m.material.docId) };
    });
  }

  if (jobData.littra) {
    jobDoc.littra = {
      ref: doc(db, `clients/${jobData.client?.docId}/littras/${jobData.littra.docId}`),
      name: jobData.littra.projectNum,
    };
  }

  return jobDoc;
}

/**
 * Converts format of report to be stored in firestore so that it contains appropriate doc references and image paths.
 * @param report JobReportData
 * @returns JobReportDoc
 */
export function toJobReportDoc(report: JobReportData): JobReportDoc {
  // converting "AddedMaterialData" to "AddedMaterialDoc" so it contains documentreference to the material.
  const rows = report.rows.map((row: ReportRowData) => {
    return {
      ...row,
      materials: row.materials.map((m) => {
        return {
          ...m,
          material: doc(db, 'materials', m.material.docId),
        };
      }),
    };
  });

  return { ...report, rows };
}

// returns object that can be used in the notify function, or other such user feedbacks
export async function updateJobStatus(
  details: JobData,
  desiredStatus: JobStatus,
): Promise<{ msg: string; status: NotificationStatus }> {
  const updateStatusResponse = await updateJob(details.docId, {
    status: desiredStatus,
  });

  if (updateStatusResponse.code === 201) {
    // notify("Jobbet har attesterats", NotificationStatus.SUCCESS);
    return {
      msg: `Jobbets status har uppdaterats till ${jobStatusLabel[desiredStatus]}`,
      status: NotificationStatus.SUCCESS,
    };
  } else {
    return {
      msg: `Jobbets status kunde inte uppdateras: ${updateStatusResponse.error}`,
      status: NotificationStatus.ERROR,
    };
  }
}

export async function toJobData(id: string, jobDoc: JobDoc): Promise<JobData> {
  const populatedFieldsResponse = await populateFieldsInJobDoc(jobDoc, [
    'littra',
    'driver',
    'vehicle',
    'client',
    'vehicleEquipments',
    'reports',
    'materials',
    'subcontractor',
    'contactSubcontractor',
    'contactClient',
    'slinga',
  ]);

  let populatedFields;
  if (populatedFieldsResponse.data) {
    populatedFields = populatedFieldsResponse.data;
  }

  const job: JobData = {
    jobType: jobDoc.jobType,
    slinga: jobDoc.slinga,
    start: jobDoc.start,
    end: jobDoc.end,
    contactClientTemp: jobDoc.contactClientTemp,
    littraTemp: jobDoc.littraTemp,
    littraWorkplaceTemp: jobDoc.littraWorkplaceTemp,
    status: jobDoc.status,
    to: jobDoc.to,
    from: jobDoc.from,
    estimatedDistance: jobDoc.estimatedDistance,
    estimatedTime: jobDoc.estimatedTime,
    otherMaterials: jobDoc.otherMaterials,
    otherInformation: jobDoc.otherInformation,
    hasReceiptsForCompany: jobDoc.hasReceiptsForCompany,
    adminComments: jobDoc.adminComments,
    orderNum: jobDoc.orderNum ? jobDoc.orderNum : 0,
    docId: id,
    signed: jobDoc.signed,
    images: jobDoc.images,
    signedBy: jobDoc.signedBy,
    smsHasBeenSent: jobDoc.smsHasBeenSent,
    ...populatedFields,
  };

  return job;
}

/**
 * Converts a job document to 'JobPartialData' which populates some but not all fields with document references
 * @param docId
 * @param data
 * @returns JobDataPartial
 */
export async function toJobDataPartial(docId: string, jobDoc: JobDoc): Promise<JobDataPartial> {
  const job: JobDataPartial = {
    docId: docId,
    ...jobDoc,
    driver: undefined,
    client: undefined,
    littra: undefined,
    vehicle: undefined,
    contactClient: undefined,
  };

  // if driver field contains data, then we need to check the type of that field.
  // if it is not a partial, then we fetch the document
  // TODO is it maybe worth it to break out this convertion into a function that is generic over the target type?
  // one could have something like convertToPartial<T>(ref: DocumentReference, converter: (ref: DocumentReference) => T): T
  if (jobDoc.driver) {
    // DocumentReference is a class and therefore we can check for its type at runtime
    if (jobDoc.driver instanceof DocumentReference) {
      const user = (await getDoc(jobDoc.driver)).data() as User;
      const partial: UserPartial = {
        ref: jobDoc.driver,
        firstName: user?.firstName ? user.firstName : '-',
      };
      console.log('Partial!!: ', partial);
      job.driver = partial;
    } else if ('ref' in jobDoc.driver) {
      // the type is our partial one
      job.driver = jobDoc.driver;
    }
  }

  // we do the same for the client
  if (jobDoc.client) {
    if (jobDoc.client instanceof DocumentReference) {
      const client = (await getDoc(jobDoc.client)).data() as ClientDoc;
      const partial: ClientPartial = {
        ref: jobDoc.client,
        name: client?.name ? client.name : '-',
        phone: client?.phone ? client.phone : '-',
      };
      job.client = partial;
    } else if ('ref' in jobDoc.client) {
      // the type is our partial one
      job.client = jobDoc.client;
    }
  }

  // and for the littra
  if (jobDoc.littra) {
    if (jobDoc.littra instanceof DocumentReference) {
      const littra = (await getDoc(jobDoc.littra)).data() as LittraDoc;
      const partial: LittraPartial = {
        ref: jobDoc.littra,
        name: littra?.projectNum ? littra.projectNum : '-',
      };
      job.littra = partial;
    } else if ('ref' in jobDoc.littra) {
      // the type is our partial one
      job.littra = jobDoc.littra;
    }
  }

  // and for the vehicle
  if (jobDoc.vehicle) {
    if (jobDoc.vehicle instanceof DocumentReference) {
      // if it is a reference we need to fetch the data as if it was a User
      const vehicle = (await getDoc(jobDoc.vehicle)).data() as VehicleDoc;
      const partial: VehiclePartial = {
        ref: jobDoc.vehicle,
        name: vehicle?.id ? vehicle.id : '-',
        vehicleType: vehicle?.vehicleType,
      };
      job.vehicle = partial;
    } else if ('ref' in jobDoc.vehicle) {
      // the type is our partial one
      job.vehicle = jobDoc.vehicle;
    }
  }

  // and for the client contact
  if (jobDoc.contactClient) {
    if (jobDoc.contactClient instanceof DocumentReference) {
      // if it is a reference we need to fetch the data as if it was a User
      const contact = (await getDoc(jobDoc.contactClient)).data() as ContactDoc;
      const partial: ContactPartial = {
        ref: jobDoc.contactClient,
        name: contact?.name ? contact.name : '-',
        phone: contact?.phone ? contact.phone : '-',
      };
      job.contactClient = partial;
    } else if ('ref' in jobDoc.contactClient) {
      // the type is our partial one
      job.contactClient = jobDoc.contactClient;
    }
  }

  return job;
}
