import { CalendarMode } from './constants';

export const months = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];
export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apri',
  'Maj',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
];

export const days = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
export const daysShort = ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'];
export const headerDays = ['M', 'T', 'O', 'T', 'F', 'L', 'S'];
export const headerDaysSwedish = ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'];

/**
 * returns the number of hours elapsed rounded to 2 decimal points.
 * @param start milliseconds
 * @param end milliseconds
 */
export function getHoursElapsed(start: number, end: number) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const hours =
    endDate.getHours() +
    endDate.getMinutes() / 60 -
    (startDate.getHours() + startDate.getMinutes() / 60);

  return hours;
}

/**
 * calculates number of days between two days
 *
 * @param start
 * @param end
 */
function getDaysElapsed(start: number, end: number) {
  let currentStartDate = getDateWithTimeZero(start);
  const endDate = getDateWithTimeZero(end);
  let days = 0;

  while (currentStartDate !== endDate) {
    days++;

    currentStartDate = incrementDay(currentStartDate);
  }

  return days;
}

/**
 *
 * @param day
 * @returns day number in the format where monday comes first.
 */

export function getEuDayNumber(day: number) {
  return day === 0 ? 6 : day - 1;
}

/**
 *
 * @param milliseconds
 * @returns day
 */
export function getDayString(milliseconds: number) {
  const date = new Date(milliseconds);
  if (date.getDay() === 0) {
    return days[6];
  } else {
    return days[date.getDay() - 1];
  }
}

/**
 *
 * @param year
 * @param month (0-11)
 * @returns the number of days in a given month
 */
export function getDaysInMonth(year: number, month: number) {
  ++month;

  if (month === 2) {
    return isLeapYear(year) ? 29 : 28;
  } else if (month < 8) {
    return month % 2 === 0 ? 30 : 31;
  } else {
    return month % 2 === 0 ? 31 : 30;
  }
}

/**
 *
 * @param calendarMode the mode that the calendar is displaying
 * @returns default start date in milliseconds based on view mode, i.e. today or beginning of this week/month
 */
export function getDefaultStartDate(calendarMode: CalendarMode) {
  const date = new Date();
  const weekday = date.getDay() - 1;
  const startOfWeek = date.getDate() - weekday;

  switch (calendarMode) {
    case CalendarMode.MONTH:
      date.setDate(1);
      break;
    case CalendarMode.WEEK:
      date.setDate(startOfWeek);

      break;
  }

  return getDateWithTimeZero(date.getTime());
}

export function getDefaultEndDate(calendarMode: CalendarMode) {
  const date = new Date();
  const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
  const weekday = date.getDay() - 1;
  const endOfWeek = date.getDate() - weekday + 6;

  switch (calendarMode) {
    case CalendarMode.MONTH:
      date.setDate(daysInMonth);
      break;
    case CalendarMode.WEEK:
      date.setDate(endOfWeek);
      break;
  }

  return getDateWithTimeZero(date.getTime());
}

/**
 *
 * @param milliseconds
 * @returns date with every time information set to 0
 */

export function getDateWithTimeZero(milliseconds: number) {
  const date = new Date(milliseconds);

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

/**
 *
 * @param milliseconds
 * @returns default time for "from" in application which is 07:00
 */

export function getDefaultTimeFromMillis(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setHours(7);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date.getTime();
}

/**
 *
 * @param milliseconds
 * @returns default time for "to" in application which is 16:00
 */

export function getDefaultTimeToMillis(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setHours(16);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date.getTime();
}

/**
 *
 * @param milliseconds
 *
 * @returns Date on format "month year"
 */
export function toMonthDateString(milliseconds: number) {
  const date = new Date(milliseconds);

  return `${months[date.getMonth()]} ${date.getFullYear()}`;
}

/**
 *
 * @param millisecondsFrom
 * @param millisecondsTo
 * @returns Date from - to
 */

export function toRangeDateString(millisecondsFrom: number, millisecondsTo: number) {
  const dateFrom = new Date(millisecondsFrom);
  const dateTo = new Date(millisecondsTo);

  if (isSameDate(millisecondsFrom, millisecondsTo)) {
    return `${dateFrom.getDate()}/${dateFrom.getMonth() + 1} ${dateFrom.getFullYear()}`;
  } else if (dateFrom.getMonth() === dateTo.getMonth()) {
    return `${dateFrom.getDate()}-${dateTo.getDate()}/${
      dateFrom.getMonth() + 1
    }  ${dateFrom.getFullYear()}`;
  } else if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
    return `${dateFrom.getDate()}/${dateFrom.getMonth() + 1}  ${dateFrom.getFullYear()}  - 
    ${dateTo.getDate()}/${dateTo.getMonth() + 1}  ${dateTo.getFullYear()}`;
  } else {
    return `${dateFrom.getDate()}/${dateFrom.getMonth() + 1} - 
    ${dateTo.getDate()}/${dateTo.getMonth() + 1}  ${dateTo.getFullYear()}`;
  }
}

export function isSameDate(millisecondsFrom: number, millisecondsTo: number) {
  const dateFrom = new Date(millisecondsFrom);
  const dateTo = new Date(millisecondsTo);

  return (
    dateFrom.getMonth() === dateTo.getMonth() &&
    dateFrom.getDate() === dateTo.getDate() &&
    dateFrom.getFullYear() &&
    dateTo.getFullYear()
  );
}

export function isToday(millis: number) {
  return isSameDate(millis, new Date().getTime());
}

/**
 *
 * @param milliseconds
 *
 * @returns Date on format "day date/month year"
 */
export function toDayDateString(milliseconds: number, showYear = true) {
  const date = new Date(milliseconds);

  const dayNum = date.getDay();

  const day = daysShort[dayNum === 0 ? 6 : dayNum - 1];

  return `${day} ${date.getDate()}/${date.getMonth() + 1}${
    showYear ? '/' + date.getFullYear() : ''
  }`;
}

/**
 *
 * @param milliseconds
 *
 * @returns Date on format "date/month year HH:MM"
 */
export function toTimeDateString(milliseconds: number) {
  const date = new Date(milliseconds);
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${date.getDate()}/${date.getMonth() + 1} ${date.getFullYear()} ${hours}:${minutes}`;
}

/**
 *
 * @param milliseconds
 *
 * @returns Date on format "YYYY-MM-DD, HH:MM"
 */
export function toTimeDateStringVariant(milliseconds: number, withoutTime = false): string {
  const date = new Date(milliseconds);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  if (withoutTime) {
    return `${year}-${month}-${day}`;
  }

  return `${year}-${month}-${day}, ${hours}:${minutes}`;
}

/**
 *
 * @returns millis of today at 7
 */

export function todayAt7() {
  const date = new Date();
  date.setHours(7);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

/**
 *
 * @returns millis of the date at 7
 */

export function dateAt7(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setHours(7);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

/**
 *
 * @returns millis of the date at 16
 */

export function todayAt16() {
  const date = new Date();
  date.setHours(16);
  date.setMinutes(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

/**
 *
 * @returns millis of the date at 16
 */

export function dateAt16(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setHours(16);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

export function getEndOfDay(millis: number) {
  const date = new Date(millis);
  date.setHours(16);
  return date.getTime();
}

/* Skottår är jämnt delbara med 4. Sekelår som inte är jämnt delbart med 400 är inte skottår även om det är jämnt delbart med 4.*/

/**
 *
 * Checks if year is leap year
 * @param year
 * @returns true or false
 *
 */

function isLeapYear(year: number) {
  return year % 4 === 0 && !(year % 100 === 0 && year % 400 !== 0);
}

/**
 *
 * @param milliseconds
 * @returns date in milliseconds of the first date of the week that '@param' milliseconds belongs to.
 */
export function getMondayOfWeek(milliseconds: number) {
  const date = new Date(milliseconds);

  // days sun: 0, mon: 1, tue: 2 and so on..
  const monday = date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1);
  date.setDate(monday);
  return getDateWithTimeZero(date.getTime());
}

/**
 *
 * @param milliseconds
 * @returns date in milliseconds of the first date of the month that '@param' milliseconds belongs to.
 */
export function getFirstDateInMonth(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setDate(1);
  return getDateWithTimeZero(date.getTime());
}

/**
 *
 * @param milliseconds
 * @returns date milliseconds of beginning of next month
 */

export function incrementMonth(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setMonth(date.getMonth() + 1);
  date.setDate(1);

  return getDateWithTimeZero(date.getTime());
}

/**
 *
 * @param milliseconds
 * @returns date milliseconds of beginning of previous month
 */
export function decrementMonth(milliseconds: number) {
  const date = new Date(milliseconds);
  date.setMonth(date.getMonth() - 1);
  date.setDate(1);

  return getDateWithTimeZero(date.getTime());
}

/**
 *
 * @param milliseconds
 * @returns date milliseconds of beginning of next week
 */

export function incrementWeek(milliseconds: number) {
  return changeWeek(milliseconds, 1);
}

/**
 *
 * @param milliseconds
 * @returns date milliseconds of beginning of previous week
 */
export function decrementWeek(milliseconds: number) {
  return changeWeek(milliseconds, -1);
}

//Helper-function for incrementWeek and decrementWeek
function changeWeek(milliseconds: number, change: number) {
  return changeDay(milliseconds, change * 7);
}

/**
 *
 * @param milliseconds
 * @returns date milliseconds of next day
 */
export function incrementDay(milliseconds: number) {
  return changeDay(milliseconds, 1);
}

/**
 *
 * @param milliseconds
 * @returns date milliseconds of prevoius day
 */
export function decrementDay(milliseconds: number) {
  return changeDay(milliseconds, -1);
}

/**
 *
 * @param milliseconds
 * @param change how many days to change the date by
 * @returns milliseconds of the new date
 */

export function changeDay(milliseconds: number, change: number) {
  const date = new Date(milliseconds);
  let diff = date.getDate() + change;

  // day in previous month
  if (diff < 0) {
    if (date.getMonth() === 0) {
      date.setFullYear(date.getFullYear() - 1);
      date.setMonth(11);
      diff = Math.abs(diff);
    } else {
      date.setMonth(date.getMonth() - 1);

      diff = Math.abs(diff);
      // diff 2
    }
    date.setDate(getDaysInMonth(date.getFullYear(), date.getMonth()) - diff);
  }

  // day in next month
  else if (diff > getDaysInMonth(date.getFullYear(), date.getMonth())) {
    diff = diff - getDaysInMonth(date.getFullYear(), date.getMonth());
    date.setDate(diff);

    if (date.getMonth() === 11) {
      date.setFullYear(date.getFullYear() + 1);
      date.setMonth(0);
    } else {
      date.setMonth(date.getMonth() + 1);
    }
  } else {
    date.setDate(date.getDate() + change);
  }

  return getDateWithTimeZero(date.getTime());
}

/**
 *
 * Checks if the job is within the date range.
 * It is in the dateRange if it has it's end or start date within the range or if it's start date is before the range and it's end date is after the range.
 * @param jobRange element 0 is start element 1 is end
 * @param dateRange element 0 is start element 1 is end
 * @returns Is date within the range
 */
export function isDateInRange(jobRange: number[], dateRange: number[]) {
  return (
    (jobRange[0] > dateRange[0] && jobRange[0] < dateRange[1]) ||
    (jobRange[1] > dateRange[0] && jobRange[1] < dateRange[1]) ||
    (jobRange[0] < dateRange[0] && jobRange[1] > dateRange[1])
  );
}

export function getEndDate(
  calendarMode: CalendarMode,
  startDate: number,
  do30Days: boolean,
  NUM_OF_DAYS_LOOK_AHEAD: number,
): Date {
  let newEndDate = startDate;

  switch (calendarMode) {
    case CalendarMode.MONTH: {
      // set end date to last day of month
      const _endDate = new Date(startDate);
      _endDate.setDate(getDaysInMonth(_endDate.getFullYear(), _endDate.getMonth()));
      newEndDate = _endDate.getTime();
      break;
    }
    case CalendarMode.WEEK:
      if (do30Days) {
        newEndDate = changeDay(newEndDate, NUM_OF_DAYS_LOOK_AHEAD);
      } else {
        newEndDate = decrementDay(incrementWeek(startDate));
      }
      break;
    case CalendarMode.DAY:
      newEndDate = startDate;
      break;
  }

  // adjust clock of end date
  const endDate = new Date(newEndDate);
  endDate.setHours(23);
  endDate.setMinutes(59);

  return endDate;
}
