import { FirebaseError } from 'firebase/app';

import {
  getFirestore,
  collection,
  DocumentReference,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';

import { Vehicle, VehicleDoc, UpdateVehicleParams } from '../../utils/types';

const db = getFirestore();

/**
 * @param updates UpdateVehicleParams
 * @param docId id of document to update
 * @returns Promise<FirebaseReponse>
 */
export async function updateVehicle(updates: UpdateVehicleParams, docId: string) {
  try {
    await updateDoc(doc(db, 'vehicles', docId), updates as { [x: string]: any });
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Adds a vehicle to the db
 * @param vehicleDoc
 * @returns Promise<FirebaseResponse>
 */
export async function addVehicle(vehicleDoc: VehicleDoc) {
  try {
    await addDoc(collection(db, 'vehicles'), vehicleDoc);
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 * @param docId id of document to delete
 * @returns Promise<FirebaseReponse>
 */
export async function deleteVehicle(docId: string) {
  try {
    await deleteDoc(doc(db, `/vehicles/${docId}`));
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * converts vehicle data to a vehicle document
 * @param vehicle
 * @returns VehicleDoc
 */
export function toVehicleDoc(vehicle: Vehicle) {
  const equipmentRefs: Array<DocumentReference> = [];

  vehicle.equipments.forEach((e) => {
    equipmentRefs.push(doc(db, 'vehicleEquipments', e));
  });

  const vehicleDoc: VehicleDoc = {
    id: vehicle.id,
    vehicleType: vehicle.vehicleType,
    equipments: equipmentRefs,
    service: vehicle.service,
    serviceId: vehicle.serviceId,
  };

  if (vehicle.driver && vehicle.driver !== '') {
    vehicleDoc.driver = doc(db, 'users', vehicle.driver);
  }

  return vehicleDoc;
}
