import { collection, getFirestore, onSnapshot } from '@firebase/firestore';
import { query } from 'firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { User } from '../../utils/types';

import AddUser from '../../popups/AddUser';
import UserDetails from '../../popups/UserDetails';
import { Unsubscribe } from '@firebase/util';
import ListPage from '../../components/list_page';
import { PopupContext } from '../../contexts/PopupContext';
import history from '../../utils/history';

const db = getFirestore();

export default function Users() {
  const [users, setUsers] = useState<Array<User>>([]);
  const [search, setSearch] = useState<string>('');
  const { showPopup } = useContext(PopupContext);

  useEffect(() => {
    return setHistoryListener();
  }, []);

  /**
   * when history is empty, history.block (used in PopupContext to intersect backpressed in browser and on phone) is not called so we add extra history when navigating to each page.
   * @returns unregisterCallback
   */
  function setHistoryListener() {
    history.push('/användare');
    return history.listen((location, action) => {
      if (action === 'POP' && location.pathname === '/användare') {
        history.goBack();
      }
    });
  }

  useEffect(() => {
    try {
      const q = query(collection(db, 'users'));

      const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
        const _users: Array<User> = [];

        for (const doc of querySnapshot.docs) {
          _users.push({ ...(doc.data() as User), docId: doc.id });
        }

        setUsers(_users);
        return unsubscribe;
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  function onChangeSearch(event: SyntheticEvent) {
    setSearch((event.target as HTMLInputElement).value);
  }

  function openAddUser(event: SyntheticEvent) {
    event.preventDefault();

    showPopup(<AddUser />, 'big');
  }

  function openUserDetails(user: User) {
    showPopup(<UserDetails user={user} />, 'big');
  }

  return (
    <ListPage
      heading='Personal'
      addButtonText='Ny personal'
      onClickAddButton={openAddUser}
      onChangeSearch={onChangeSearch}
    >
      <ul id='list-page__items'>
        {users.map((user: User) => {
          return (
            (user.firstName?.includes(search) ||
              user.lastName?.includes(search) ||
              user.email.includes(search)) && (
              <li
                tabIndex={0}
                className='list-page__items__item'
                id={`list-page__items__${user.docId}`}
                onClick={() => {
                  openUserDetails(user);
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    openUserDetails(user);
                    requestAnimationFrame(() => {
                      (document.querySelector('.button--close') as HTMLElement).focus();
                    });
                  }
                }}
                key={user.email}
              >
                <p>
                  {user.firstName} {user.lastName}
                </p>
              </li>
            )
          );
        })}
      </ul>
    </ListPage>
  );
}
