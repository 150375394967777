import Select from '../select/Select';
import Option from '../select/Option';
import { useContext } from 'react';
import { DataContext } from '../../contexts/CommonDataContext';
import { SubcontractorData } from '../../utils/types';

interface AddSubcontractorProps {
  subcontractor?: SubcontractorData;
  setSubcontractor: React.Dispatch<React.SetStateAction<SubcontractorData | undefined>>;
}

/**
 * Component that is used in 'AddEditSlinga' and 'AddEditJob* to add/select a subcontractor
 */
export default function AddSubcontractor({
  subcontractor,
  setSubcontractor,
}: AddSubcontractorProps) {
  const { subcontractors } = useContext(DataContext);

  function onChangeSubcontractor(value: string) {
    const _subcontractor = subcontractors.find((s) => s.docId === value);
    setSubcontractor(_subcontractor);
  }

  return (
    <li key='subcontractors' className='add-job__form__li'>
      <Select
        isMultiSelect={false}
        type='form'
        label='Underleverantör'
        searchBar={true}
        onChange={onChangeSubcontractor}
        iconRightClose='fa fa-angle-down'
        iconRightOpen='fa fa-angle-up'
        initialOption={
          subcontractor
            ? {
                value: subcontractor?.docId,
                label: subcontractor.name,
              }
            : undefined
        }
      >
        <>
          {subcontractors.map((s: SubcontractorData) => {
            return <Option key={s.docId} value={s.docId} label={s.name} />;
          })}
        </>
      </Select>
    </li>
  );
}
