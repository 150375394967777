import { SyntheticEvent, useContext, useState } from 'react';
import { ClientData, ContactData, Place, Littra, ContactDoc } from '../utils/types';
import PickAPlace from '../components/PickAPlace';
import Select from '../components/select/Select';
import Option from '../components/select/Option';
import Textarea from '../components/Textarea';
import { PopupContext } from '../contexts/PopupContext';
import AddContact from '../popups/AddContact';
import { addContact } from '../firebase/firestore_functions/contacts';
import * as R from 'ramda';

interface LittraFormProps {
  client: ClientData;
  handleSubmit: (littra: Littra) => Promise<void>;
  saving: boolean;
  littra?: Littra;
}

export default function LittraForm({ client, handleSubmit, saving, littra }: LittraFormProps) {
  const { showPopup } = useContext(PopupContext);
  const [newContacts, setNewContacts] = useState<ContactData[]>([]);

  ////////////////////////
  // Fields in  littra //
  //////////////////////

  const [projectNum, setProjectNum] = useState<string>(littra?.projectNum ? littra.projectNum : '');
  const [contact, setContact] = useState<string>(littra?.contact ? littra.contact : '');
  const [tempContact, setTempContact] = useState<string>(
    littra?.tempContact ? littra.tempContact : '',
  );
  const [from, setFrom] = useState<Place | undefined>(littra?.from ? littra?.from : undefined);
  const [to, setTo] = useState<Place | undefined>(littra?.to ? littra?.to : undefined);
  const [otherInformation, setOtherInformation] = useState<string>(
    littra?.otherInformation ? littra.otherInformation : '',
  );

  const [places, setPlaces] = useState<Place[]>(littra?.places ? littra.places : []);

  const [workplace, setWorkplace] = useState<string>(littra ? littra.workplace : '');

  /////////////////////////
  // on change handlers //
  ///////////////////////

  function onChangeFrom(place?: Place) {
    setFrom(place);
  }

  function onChangeTo(place?: Place) {
    setTo(place);
  }

  function onChangeProjectNum(event: SyntheticEvent) {
    setProjectNum((event.target as HTMLInputElement).value);
  }

  function onChangeContact(docId?: string) {
    if (docId) {
      setContact(docId);
    }
  }

  function onChangeTempContact(event: SyntheticEvent) {
    setTempContact((event.target as HTMLInputElement).value);
  }

  async function _handleSubmit() {
    handleSubmit({
      projectNum,
      to,
      from,
      contact,
      tempContact,
      otherInformation,
      places,
      workplace,
    });
  }

  async function onAddContact(contact: ContactDoc) {
    if (!client) {
      return { code: 500, error: 'no client' };
    }

    const response = await addContact(contact, client, 'clients');

    // Add to the local state
    if (response.data) {
      const data: ContactData = {
        docId: response.data,
        name: contact.name,
        phone: contact.phone,
      };

      client.contacts.push(data);
    }

    return response;
  }

  function onAddPlaceTo(place: Place) {
    onAddPlace(place);
    setTo(place);
  }

  function onAddPlaceFrom(place: Place) {
    onAddPlace(place);
    setFrom(place);
  }

  function onAddPlace(place: Place) {
    const _places = [...places];
    _places.push(place);
    setPlaces(_places);
  }

  const openAddClientContact = (event: SyntheticEvent) => {
    event.preventDefault();
    showPopup(<AddContact onAdd={onAddContact} />, 'medium');
  };

  const onChangeWorkplace = (event: SyntheticEvent) => {
    setWorkplace((event.target as HTMLInputElement).value);
  };

  return (
    <section className='form'>
      <ul className='form__ul'>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={projectNum}
            placeholder='Littra'
            onChange={onChangeProjectNum}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={workplace}
            placeholder='Arbetsplats'
            onChange={onChangeWorkplace}
            required
          />
        </li>

        <li className='form__li'>
          <Select
            isMultiSelect={false}
            type='form'
            label='Kontakt'
            onChange={onChangeContact}
            iconRightClose='fa fa-angle-down'
            iconRightOpen='fa fa-angle-up'
            initialOption={
              contact !== ''
                ? {
                    value: contact,
                    label:
                      client.contacts.find((c) => c.docId === contact)?.name ||
                      newContacts.find((c) => c.docId === contact)?.name ||
                      '',
                  }
                : undefined
            }
          >
            <>
              {client.contacts &&
                R.unionWith((c1, c2) => c1.docId === c2.docId, client.contacts, newContacts).map(
                  (c) => {
                    return <Option key={c.docId} value={c.docId} label={c.name} />;
                  },
                )}
            </>
          </Select>
        </li>
        <li className='form__li'>
          <input
            id='contact'
            type='text'
            className='input-text form__input'
            value={tempContact}
            placeholder='Temporär kontakt'
            autoComplete='off'
            onChange={onChangeTempContact}
          />
          <button
            id='button--add-contact'
            className='button--main--small'
            onClick={openAddClientContact}
          >
            <span className='fa fa-plus' />
            Lägg till kontakt
          </button>
        </li>

        <li key='place' className='form__li' id='add-job__form__li-place'>
          <p className='label'>Plats från</p>

          <PickAPlace
            place={from}
            onChange={onChangeFrom}
            onSaveToLittra={onAddPlaceFrom}
            places={places}
          />

          <p className='label'>Plats till</p>
          <PickAPlace
            place={to}
            onChange={onChangeTo}
            onSaveToLittra={onAddPlaceTo}
            places={places}
          />
        </li>

        <li className='form__li'>
          <Textarea
            id='other'
            setState={setOtherInformation}
            placeholder='Övrigt'
            value={otherInformation}
          />
        </li>
        <li className='form__li loading-parent'>
          {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
          <button onClick={_handleSubmit} className='button--green form__input'>
            Spara
          </button>
        </li>
      </ul>
    </section>
  );
}
