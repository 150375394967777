import { VehicleData } from './types';

export const companyAddress = 'Lefstad 330, 442 72 Kärna';
export const companyPhone = '0702-36 01 20';
export const companyMail = 'order@nolblads.se';

export enum CalendarMode {
  MONTH,
  WEEK,
  DAY,
}

export enum DatePickerType {
  DAY,
  WEEK,
  MONTH,
}

export const calenderModeLabels = ['Månad', 'Vecka', 'Dag'];

export enum AuthActionType {
  LOGIN,
  LOGOUT,
}

export enum JobType {
  TRANSPORT_SCHAKT, // transport schakt
  TRANSPORT_MACHINE, // transport machine
  EXCAVATOR_MAN, // Grävmaskin/anläggare
}

interface JobTypeLabel {
  [enumValue: number]: string;
}

export const jobTypeLabel: JobTypeLabel = [
  'Transport schakt',
  'Transport maskin',
  'Grävmaskin/anläggare',
];

export enum JobStatus {
  NEW, // jobbet är nyligen inlagt i kalendern
  RECEVIED, // chauffören har öppnat jobbet
  STARTED, // chauffören har tryckt på att starta jobbet
  STARTED_OVERDUE, // chauffören har inte rapporterat jobbet och sluttiden har passerat
  REPORTED, // chauffören har rapporterat jobbet
  VALIDATED, // attesterad
  INVOICED, // fakturerad. // andra version som gäller då: 1. material är uttaget på nolblads, 2. kunden är en central, 3. fordonet är lej.
  PAYED, // utbetalt till underleverantör alt. när kvittona för material som ska tas ut på nolblad har avprickats.
}

interface JobStatusLabel {
  [enumValue: number]: string;
}

export const jobStatusLabel: JobStatusLabel = {
  0: 'Ny',
  1: 'Mottagen av förare',
  2: 'Pågående',
  3: 'Förfallen',
  4: 'Rapporterad',
  5: 'Attesterad',
  6: 'Fakturerad', // Svarar man nej efter man tagit ut pdf
  // om det är underleverantör
  7: 'Utbetald', // svarar man ja efter man tagit ut pdf
};

export enum UserType {
  DRIVER,
  DRIVER_EXTENDED,
  ADMIN,
}

export enum NotificationStatus {
  INFO,
  SUCCESS,
  ERROR,
}

interface UserTypeLabel {
  [enumValue: number]: string;
}

export const userTypeLabel: UserTypeLabel = {
  0: 'Förare',
  1: 'Förare-utökad',
  2: 'Administratör',
};

export enum MaterialType {
  OTHER,
  CROSS,
  TIPP,
  SOIL,
}

export enum VehicleType {
  TRUCK,
  EXCAVATOR,
  MAN,
  TRUCK_LEJ,
  EXCAVATOR_LEJ,
  MAN_LEJ,
  TRUCK_NOT_ASSIGNED,
  EXCAVATOR_NOT_ASSIGNED,
  MAN_NOT_ASSIGNED,
}

export const vehicleNotAssigned: VehicleData = {
  id: 'Lastbil Ej tilldelat',
  vehicleType: VehicleType.TRUCK_NOT_ASSIGNED,
  docId: 'oMPqJkPIww6u5sSWqqhB',
  service: '',
  serviceId: '',
  equipments: [],
};

interface VehicleTypeLabel {
  [enumValue: number]: string;
}
export const vehicleTypeLabel: VehicleTypeLabel = [
  'Lastbil',
  'Grävmaskin',
  'Anläggare',
  'Lastbil Lej',
  'Grävmaskin Lej',
  'Anläggare Lej',
  'Lastbil Ej tilldelad',
  'Grävmaskin Ej tilldelad',
  'Anläggare Ej tilldelad',
];

export enum EquipmentType {
  TRAILER,
  FLATBED,
  EXCAVATOR,
  OTHER,
}
interface EquipmentTypeLabel {
  [enumValue: number]: string;
}
export const equipmentTypeLabel: EquipmentTypeLabel = {
  0: 'Flak',
  1: 'Släp',
  2: 'Skopa',
  3: 'Övrigt',
};

export const allowedEquipments: EquipmentType[][] = [
  [EquipmentType.TRAILER, EquipmentType.FLATBED, EquipmentType.OTHER],
  [EquipmentType.EXCAVATOR, EquipmentType.OTHER],
  [EquipmentType.OTHER],
  [EquipmentType.TRAILER, EquipmentType.FLATBED, EquipmentType.OTHER],
  [EquipmentType.EXCAVATOR, EquipmentType.OTHER],
  [EquipmentType.OTHER],
  [EquipmentType.TRAILER, EquipmentType.FLATBED, EquipmentType.OTHER],
  [EquipmentType.EXCAVATOR, EquipmentType.OTHER],
  [EquipmentType.OTHER],
];

interface fieldToLabelType {
  [key: string]: string;
}

export const fieldToLabel: fieldToLabelType = {
  orderNum: 'Ordernummer',
  vehicle: 'Fordon',
  driver: 'Förare',
  client: 'Kund',
  littra: 'Littra',
  contact: 'Kontakt',
  from: 'Från',
  to: 'Till',
  otherInformation: 'Kommentar till chaufför',
  otherMaterials: 'Godsslag',
};
