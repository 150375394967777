import { FirebaseError } from 'firebase/app';

import { DocumentReference, getDoc, getFirestore, Timestamp } from 'firebase/firestore';

import {
  getDateWithTimeZero,
  getFirstDateInMonth,
  getMondayOfWeek,
  incrementDay,
} from '../../utils/time';

import { FirebaseResponse, JobDataPartial, SlingaData, SlingaDataPartial } from '../../utils/types';

const db = getFirestore();

/**
 * Retrieves the documents in the array of references
 * @param docs
 * @returns FirebaseResponse
 */
export async function populateDocs(docs: DocumentReference[]): Promise<FirebaseResponse> {
  try {
    const promises = [];

    for (const doc of docs) {
      promises.push(await getDoc(doc));
    }

    const populatedDocs = await Promise.all(promises);

    return {
      code: 200,
      data: populatedDocs.map((doc) => {
        return { ...doc.data(), docId: doc.id };
      }),
    };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

// /**
//  * Creates arrays with the months, weeks and days that the job or slinga belongs to.
//  * @param start start date of job/slinga
//  * @param end end date of job/slinga
//  * @returns an object with days, weeks and months
//  */
// export function getDaysWeeksAndMonthsOfJobOrSlinga(start: number, end: number) {
//   let currentDay = getDateWithTimeZero(start);
//   let endDay = getDateWithTimeZero(end);

//   const days: Array<Timestamp> = [];
//   const weeksSet: Set<number> = new Set();
//   const monthsSet: Set<number> = new Set();

//   // iterate through the days of the job and update months, weeks and days

//   while (currentDay <= endDay) {
//     days.push(Timestamp.fromMillis(currentDay));
//     weeksSet.add(getMondayOfWeek(currentDay));
//     monthsSet.add(getFirstDateInMonth(currentDay));

//     currentDay = getDateWithTimeZero(incrementDay(currentDay));
//   }

//   // convert the milliseconds in weeks and montsh to Timestamp object

//   const weeks: Timestamp[] = [];
//   const months: Timestamp[] = [];

//   weeksSet.forEach((week) => {
//     weeks.push(Timestamp.fromMillis(week));
//   });

//   monthsSet.forEach((month) => {
//     months.push(Timestamp.fromMillis(month));
//   });

//   return { days, weeks, months };
// }

// /**
//  * Creates arrays with the months that the job or slinga belongs to.
//  * @param start start date of job/slinga
//  * @param end end date of job/slinga
//  * @returns array of months start dates i.e. 1/month at time 00:00
//  */
//  export function getMonthsOfJobOrSlinga(start: number, end: number) {
//   let currentDay = getDateWithTimeZero(start);
//   let endDay = getDateWithTimeZero(end);

//   const monthsSet: Set<number> = new Set();

//   // iterate through the days of the job and update weeks

//   while (currentDay <= endDay) {
//     monthsSet.add(getFirstDateInMonth(currentDay));
//     currentDay = getDateWithTimeZero(incrementDay(currentDay));
//   }

//   return Array.from(monthsSet);
// }

// /**
//  * Creates arrays with the weeks that the job or slinga belongs to.
//  * @param start start date of job/slinga
//  * @param end end date of job/slinga
//  * @returns array of weeks start dates i.e. monday at time 00:00
//  */
//  export function getWeeksOfJobOrSlinga(start: number, end: number) {
//   let currentDay = getDateWithTimeZero(start);
//   let endDay = getDateWithTimeZero(end);

//   const weeksSet: Set<number> = new Set();

//   // iterate through the days of the job and update weeks

//   while (currentDay <= endDay) {
//     weeksSet.add(getMondayOfWeek(currentDay));
//     currentDay = getDateWithTimeZero(incrementDay(currentDay));
//   }

//   return Array.from(weeksSet);
// }

// /**
//  * Creates arrays with the days that the job or slinga belongs to.
//  * @param start start date of job/slinga
//  * @param end end date of job/slinga
//  * @returns array of days
//  */
//  export function getDaysOfJobOrSlinga(start: number, end: number) {
//   let currentDay = getDateWithTimeZero(start);
//   let endDay = getDateWithTimeZero(end);

//   const days: Array<number> = [];

//   // iterate through the days of the job and update days

//   while (currentDay <= endDay) {
//     days.push(currentDay);
//     currentDay = getDateWithTimeZero(incrementDay(currentDay));
//   }

//   return days;
// }

/**
 * function that creates an array of which days to sort a job into. It is based on which days the job is spanning and
 * is limited by the "startLimit" and "endLimit" arguments.
 * @param jobOrSlinga
 * @param startLimit
 * @param endLimit
 * @returns array of days (milliseconds)
 */
export function getCalendarDaysOfJobOrSlinga(
  jobOrSlinga: SlingaDataPartial | JobDataPartial | (SlingaDataPartial & JobDataPartial),
  startLimit: number,
  endLimit: number,
) {
  let currentDay = getDateWithTimeZero(
    jobOrSlinga.start < startLimit ? startLimit : jobOrSlinga.start,
  );
  const endDay = getDateWithTimeZero(jobOrSlinga.end > endLimit ? endLimit : jobOrSlinga.end);

  const days: Array<number> = [];

  // iterate through the days of the job and update months, weeks and days

  while (currentDay <= endDay) {
    days.push(currentDay);

    currentDay = getDateWithTimeZero(incrementDay(currentDay));
  }

  return days;
}
