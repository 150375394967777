import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import Datepicker from '../../components/datepicker/Datepicker';
import Timepicker from '../../components/timepicker/Timepicker';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import {
  JobStatus,
  jobStatusLabel,
  NotificationStatus,
  UserType,
  VehicleType,
} from '../../utils/constants';
import { addSlinga, deleteSlinga, updateSlinga } from '../../firebase/firestore_functions/slinga';
import { toDayDateString, toRangeDateString } from '../../utils/time';
import { JobData, SlingaData, SlingaDoc, User, VehicleData } from '../../utils/types';
import './AddEditSlinga.css';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import Table from '../../components/table/Table';

import { collection, doc, DocumentReference, getFirestore } from 'firebase/firestore';
import Textarea from '../../components/Textarea';
import { stopPropagation } from '../../utils/uiHelpers';
import { DataContext } from '../../contexts/CommonDataContext';
import { AddEditSlingaContext } from './AddEditSlingaContext';
import AddEquipmentsToSlinga from './AddEquipmentsToSlinga';
import AddSubcontractorToSlinga from './AddSubcontractorToSlinga';
import AddContactSubcontractorToSlinga from './AddContactSubcontractorToSlinga';
import AddEditJobSlingaMain from '../add_job/AddEditJobSlingaMain';
import ConfirmAction from '../../components/confirm_action';
import { AuthContext } from '../../contexts/AuthContext';

const db = getFirestore();

interface AddEditSlingaProps {
  slinga?: SlingaData;
  onDelete?: () => void;
}

export default function AddEditSlinga({ slinga, onDelete }: AddEditSlingaProps) {
  const { vehicles, drivers } = useContext(DataContext);

  const [initDone, setInitDone] = useState(false);

  const [jobInEditIdx, setJobInEditIdx] = useState<number>(0);
  const { notify } = useContext(NotificationContext);
  const { showPopup, close } = useContext(PopupContext);

  const [saving, setSaving] = useState(false);

  const { user } = useContext(AuthContext);

  // data states for the slinga
  const {
    name,
    setName,
    start,
    setStart,
    end,
    setEnd,
    vehicle,
    setVehicle,
    vehicleEquipments,
    driver,
    setDriver,
    subcontractor,
    contactSubcontractor,
    comments,
    setComments,
    updateStateEditMode,
    addedJobs,
    setAddedJobs,
  } = useContext(AddEditSlingaContext);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    if (slinga) {
      updateStateEditMode(slinga);
    }

    setInitDone(true);
  }

  /**
   * Used as callback when opening "AddEditJob" to add the newly created job.
   * @param addedJob
   */
  function addJobToSlinga(addedJob: JobData) {
    setAddedJobs([...addedJobs, addedJob]);
  }

  /**
   * Used as callback when opening "AddEditJob" to remove the job currently in edit.
   */
  function removeJobFromSlinga() {
    const _addedJobs = [...addedJobs];
    _addedJobs.splice(jobInEditIdx, 1);
    setAddedJobs(_addedJobs);
  }

  function openAddJob() {
    showPopup(
      <AddEditJobSlingaMain
        addJobToSlinga={addJobToSlinga}
        slingaDriver={driver}
        slingaVehicle={vehicle}
        slingaVehicleEquipments={vehicleEquipments}
        slingaStart={start}
        slingaEnd={end}
      />,
      'big',
    );
  }

  function openEditJob(idx: number) {
    showPopup(
      <AddEditJobSlingaMain
        job={addedJobs[idx]}
        removeJobFromSlinga={removeJobFromSlinga}
        slingaStart={start}
        slingaEnd={end}
      />,
      'big',
    );
    setJobInEditIdx(idx);
  }

  function onChangeHoursFrom(hours: number) {
    const _date = new Date(start);
    _date.setHours(hours);
    setStart(_date.getTime());
  }

  function onChangeMinutesFrom(minutes: number) {
    const _date = new Date(start);
    _date.setMinutes(minutes);
    setStart(_date.getTime());
  }

  function onChangeHoursTo(hours: number) {
    const _date = new Date(end);
    _date.setHours(hours);
    setEnd(_date.getTime());
  }

  function onChangeMinutesTo(minutes: number) {
    const _date = new Date(end);
    _date.setMinutes(minutes);
    setEnd(_date.getTime());
  }

  function onChangeDateFrom(date: number) {
    const _date = new Date(date);
    _date.setHours(new Date(start).getHours());
    _date.setMinutes(new Date(start).getMinutes());
    setStart(_date.getTime());
    if (_date.getTime() > end) {
      //Update the To-date if From-date is later than it
      setEnd(_date.getTime());
    }
  }

  function onChangeVehicle(value: string) {
    // update vehicle
    const _vehicle = vehicles.find((v) => v.docId === value);
    _vehicle && setVehicle(_vehicle);

    // set default driver

    if (_vehicle && _vehicle.driver) {
      setDriver(_vehicle.driver);
    }
  }

  function onChangeDriver(value: string) {
    const _driver = drivers.find((d) => d.docId === value);
    _driver && setDriver(_driver);
  }

  function onChangeDateTo(date: number) {
    const _date = new Date(date);
    _date.setHours(new Date(end).getHours());
    _date.setMinutes(new Date(end).getMinutes());
    setEnd(_date.getTime());
    if (_date.getTime() < start) {
      //Update the To-date if From-date is later than it
      setStart(_date.getTime());
    }
  }

  function getRowData(job: JobData) {
    // table heads: "Datum", "Status", "Kund", "Från", "Till"

    return {
      data: [
        toRangeDateString(job.start, job.end),
        jobStatusLabel[job.status],
        job.client ? job.client.name : '',
        `${job.from?.address ? job.from.address : ''} ${job.from?.name ? job.from.name : ''}`,
        `${job.to?.address ? job.to.address : ''} ${job.to?.name ? job.to.name : ''}`,
      ],
    };
  }

  /*
   * Get initial driver option:
   * If the user is an extended driver, he is only able to
   * select his own name.
   */
  const getInitialDriverOption = () => {
    if (user?.userType == UserType.DRIVER_EXTENDED) {
      setDriver(user);
      return {
        value: user.docId,
        label: `${user.firstName} ${user.lastName}`,
      };
    } else if (driver) {
      return {
        value: driver.docId,
        label: `${driver.firstName} ${driver.lastName}`,
      };
    } else {
      return undefined;
    }
  };

  function getTableData() {
    return addedJobs.map((job) => getRowData(job));
  }

  async function save() {
    setSaving(true);

    const _slinga: SlingaDoc = {
      name: name,
      sNumber: 0, // temporary becuase SlingaDoc requires sNumber.
      start: start,
      end: end,
      jobs: slinga
        ? slinga.jobs.map((j) => doc(collection(db, 'jobs'), j.docId))
        : ([] as DocumentReference[]),
      status: JobStatus.NEW,
    };

    // prepare data for slinga with doc references

    if (comments) {
      _slinga.comments = comments;
    }

    if (vehicle) {
      _slinga.vehicle = {
        ref: doc(db, 'vehicles', vehicle.docId),
        name: vehicle.docId,
        vehicleType: vehicle.vehicleType,
      };

      if (vehicleEquipments && Array.from(vehicleEquipments).length > 0) {
        const _vehicleEquipments: Array<DocumentReference> = [];

        vehicleEquipments.forEach((e) => {
          _vehicleEquipments.push(doc(db, 'equipments', e));
        });

        _slinga.vehicleEquipments = _vehicleEquipments;
      }
    }

    if (driver) {
      _slinga.driver = {
        ref: doc(db, 'users', driver.docId),
        firstName: driver.firstName,
      };
    }

    if (subcontractor) _slinga.subcontractor = doc(db, 'subcontractors', subcontractor.docId);

    if (contactSubcontractor)
      _slinga.contactSubcontractor = doc(db, 'contacts', contactSubcontractor.docId);

    // only create jobs that are newly added if in edit mode but all if in create mode

    let slingaResponse;

    if (slinga) {
      const removedJobs: JobData[] = [];
      const newJobs: JobData[] = [];

      // check for added jobs
      for (const job of addedJobs) {
        if (!slinga.jobs.find((j) => j.docId === job.docId)) {
          newJobs.push(job);
        }
      }

      // check for removed jobs
      for (const job of slinga.jobs) {
        if (!addedJobs.find((j) => job.docId === j.docId)) {
          removedJobs.push(job as JobData);
        }
      }

      // check if dates changed

      let oldStart: number | undefined = undefined;
      let oldEnd: number | undefined = undefined;

      if (slinga.start !== start || slinga.end !== end) {
        oldStart = slinga.start;
        oldEnd = slinga.end;
      }

      _slinga.sNumber = slinga.sNumber;
      slingaResponse = await updateSlinga(
        slinga.docId,
        _slinga,
        newJobs,
        removedJobs,
        oldStart,
        oldEnd,
      );
    } else {
      slingaResponse = await addSlinga(_slinga, addedJobs);
    }

    if (slingaResponse.code === 201) {
      close();
      notify(`Slinga ${slinga ? 'uppdaterad' : 'skapad'}`, NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte ${slinga ? 'uppdatera' : 'skapa'} slingan`, NotificationStatus.ERROR);
    }
  }

  function confirmDelete() {
    showPopup(
      <ConfirmAction
        confirm={_deleteSlinga}
        heading='Är du säker på det här?'
        message='Om slingan tas bort från systemet kan det inte återställas senare.'
      />,
      'small',
    );
  }

  async function _deleteSlinga() {
    if (slinga) {
      const deleteResponse = await deleteSlinga(slinga);
      if (deleteResponse.code === 201) {
        notify(`Slinga raderad`, NotificationStatus.SUCCESS);
        close();
        // on delete callback so that the previous popup component can close itself if wished
        onDelete && onDelete();
      } else {
        notify(`Kunde inte radera slingan`, NotificationStatus.ERROR);
      }
    }
  }

  if (!initDone) {
    return <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />;
  } else {
    return (
      <section id='add-slinga' onClick={stopPropagation}>
        <header style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>{slinga ? `Redigera slinga ${slinga.sNumber}` : 'Skapa slinga'}</h2>

          {!!slinga && (
            <button
              onClick={confirmDelete}
              className='button--cancel'
              style={{ alignSelf: 'flex-end' }}
            >
              <span className='fa fa-trash' style={{ marginRight: '0.5rem' }} />
              Radera
            </button>
          )}
        </header>

        <section>
          <div className='name-slinga'>
            <h3>0. Namnge slingan</h3>
            <input
              className='name-slinga__input'
              id='slinga-name'
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>

          <h3>1. Välj tid, fordon och förare</h3>

          <ul>
            <li key='from' id='add-job__form__date' className='add-slinga__li'>
              <Datepicker
                mode='day'
                onChange={onChangeDateFrom}
                currentDateMillis={start}
                showQuickChangeButtons={true}
                buttonClassName='input-text add-job__form__input add-job__form__date-date'
                buttonContent={
                  <>
                    {' '}
                    <span className='fa fa-calendar' />
                    {`${toDayDateString(start)}`}
                  </>
                }
              />

              <Timepicker
                onChangeHours={onChangeHoursFrom}
                onChangeMinutes={onChangeMinutesFrom}
                hours={start ? new Date(start).getHours() : 7}
                minutes={start ? new Date(start).getMinutes() : 0}
              />
            </li>

            <li key='to' id='add-job__form__date' className='add-slinga__li'>
              <Datepicker
                mode='day'
                onChange={onChangeDateTo}
                currentDateMillis={end}
                showQuickChangeButtons={true}
                buttonClassName='input-text add-job__form__input add-job__form__date-date'
                buttonContent={
                  <>
                    <span className='fa fa-calendar' />
                    {`${toDayDateString(end)}`}
                  </>
                }
              />

              <Timepicker
                onChangeHours={onChangeHoursTo}
                onChangeMinutes={onChangeMinutesTo}
                hours={end ? new Date(end).getHours() : 16}
                minutes={end ? new Date(end).getMinutes() : 0}
              />
            </li>

            <li key='vehicle' className='add-slinga__li'>
              <Select
                isMultiSelect={false}
                type='form'
                label='Fordon'
                onChange={onChangeVehicle}
                iconRightClose='fa fa-angle-down'
                iconRightOpen='fa fa-angle-up'
                initialOption={{ value: vehicle.docId, label: vehicle.id }}
              >
                <>
                  {vehicles.map((v: VehicleData) => {
                    return <Option key={v.docId} value={v.docId} label={v.id} />;
                  })}
                </>
              </Select>
            </li>

            {[VehicleType.TRUCK, VehicleType.EXCAVATOR].includes(vehicle.vehicleType) && (
              <li key='driver' className='add-slinga__li'>
                <Select
                  isMultiSelect={false}
                  type='form'
                  label='Förare'
                  onChange={onChangeDriver}
                  iconRightClose='fa fa-angle-down'
                  iconRightOpen='fa fa-angle-up'
                  initialOption={getInitialDriverOption()}
                >
                  <>
                    {/* Admins are only allowed to pick drivers */}
                    {user?.userType == UserType.ADMIN &&
                      drivers.map((d: User) => {
                        return (
                          <Option
                            key={d.docId}
                            value={d.docId}
                            label={`${d.firstName} ${d.lastName}`}
                          />
                        );
                      })}
                  </>
                </Select>
              </li>
            )}

            <AddEquipmentsToSlinga />

            {[VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
              vehicle.vehicleType,
            ) && (
              <>
                <AddSubcontractorToSlinga />
                {!!subcontractor && <AddContactSubcontractorToSlinga />}
              </>
            )}
          </ul>

          <Textarea
            id='admin-comments'
            setState={setComments}
            placeholder='Kommentarer'
            value={comments}
          />
        </section>
        <section>
          <h3>2. Lägg till ordrar</h3>
          <button onClick={openAddJob} id='add-slinga__button--add' className='button--main'>
            <span className='fa fa-plus' />
            Ny
          </button>

          <Table
            heads={['Datum', 'Status', 'Kund', 'Från', 'Till']}
            data={getTableData()}
            onClickRow={openEditJob}
          />
        </section>
        <button className='button--green' onClick={save} style={{ width: '100%' }}>
          {saving && <span className='fa fa-circle-o-notch fa-spin' />} Spara
        </button>
      </section>
    );
  }
}
