import { useContext } from 'react';
import { MobileStateContext } from '../../contexts/MobileContext';
import { DaysWorkload, JobDataPartial, SlingaData } from '../../utils/types';
import WeekdayDesktop from './week_day/WeekdayDesktop';
import WeekDayMobile from './week_day/WeekdayMobile';
import './WeekView.css';
import '@fontsource/open-sans'; // Defaults to weight 400
import '@fontsource/open-sans/700.css'; // Specify weight
import hash from '../../images/hash.svg';
import grid from '../../images/grid.svg';
import userl from '../../images/user.svg';
import clock from '../../images/clock.svg';
import truck from '../../images/truck.svg';
import loader from '../../images/loader.svg';
import { JobsContext } from '../../contexts/JobsContext';
import CalendarFilters from '../../pages/home/CalendarFilters';
import { hasAdminPrivileges } from '../../utils/otherHelpers';
import { AuthContext } from '../../contexts/AuthContext';
import PlusButton from '../buttons/PlusButton';
import { CalendarMode } from '../../utils/constants';

import plus from '../../images/plus.svg';
import maximize from '../../images/maximize-2.svg';

interface WeekViewProps {
  daysWithJobs: DaysWorkload;
  onClickJob: (docId: string, clickedDate: number) => void;
  onClickSlinga: (slingaId: string) => void;
  calendarMode: CalendarMode;
  openPdfMenu: () => void;
  pdfJobs: Set<string>;
  openAddJob: () => void;
  openAddSlinga: () => void;
}

function WeekView({
  daysWithJobs,
  onClickJob,
  onClickSlinga,
  calendarMode,
  openPdfMenu,
  pdfJobs,
  openAddJob,
  openAddSlinga,
}: WeekViewProps) {
  const { isMobile } = useContext(MobileStateContext);
  const {
    jobs,
    setJobs,
    filteredJobs,
    setFilteredJobs,
    vehicleFilter,
    setVehicleFilter,
    littraFilter,
    setLittraFilter,
    driverFilter,
    setDriverFilter,
    clientFilter,
    setClientFilter,
    loading,
    showOnlyJobsToHandle,
    setShowOnlyJobsToHandle,
    showOnlySlingor,
    setShowOnlySlingor,
    setListenerOnJobsCollection,
    filterJobs,
  } = useContext(JobsContext);

  const { user } = useContext(AuthContext);
  let temp = 0;
  return (
    <div id='week-container'>
      {isMobile && (
        <ul id='week-mobile'>
          {Object.entries(daysWithJobs).map((day: [string, Array<JobDataPartial & SlingaData>]) => {
            return (
              <WeekDayMobile
                onClickJob={onClickJob}
                onClickSlinga={onClickSlinga}
                key={day[0]}
                dateMillis={parseInt(day[0])}
                jobs={day[1]}
                odd={temp++ % 2 === 0 ? true : false}
              />
            );
          })}
        </ul>
      )}

      {!isMobile && (
        <div id='week-box'>
          <table id='week' cellPadding='3'>
            <thead>
              <tr style={{ backgroundColor: 'white', border: 0, paddingBottom: '10px' }}>
                <td colSpan={7} style={{ width: '100%', padding: 0 }}>
                  {hasAdminPrivileges(user) && !isMobile && (
                    <CalendarFilters
                      driverFilter={driverFilter}
                      onChangeDriverFilter={setDriverFilter}
                      clientFilter={clientFilter}
                      onChangeClientFilter={setClientFilter}
                      vehicleFilter={vehicleFilter}
                      onChangeVehicleFilter={setVehicleFilter}
                      showOnlyJobsToHandle={showOnlyJobsToHandle}
                      onChangeShowOnlyJobsToHandle={setShowOnlyJobsToHandle}
                      showOnlySlingor={showOnlySlingor}
                      onChangeShowOnlySlingor={setShowOnlySlingor}
                      littraFilter={littraFilter}
                      onChangeLittraFilter={setLittraFilter}
                    />
                  )}
                  {hasAdminPrivileges(user) ? (
                    <>
                      <section id='calendar__controls__right__admin-settings'></section>
                      <section id='add-job-or-slinga-button-group'>
                        {!isMobile && (
                          <>
                            {calendarMode === CalendarMode.WEEK && (
                              <button
                                className='primary-button-pdf'
                                onClick={openPdfMenu}
                                disabled={pdfJobs.size === 0}
                              >
                                <img src={maximize} />
                                PDF
                              </button>
                            )}
                            <button className='primary-button-week' onClick={openAddJob}>
                              <img src={plus} />
                              Uppdrag
                            </button>
                            <button className='primary-button-week' onClick={openAddSlinga}>
                              <img src={plus} />
                              Slinga
                            </button>
                          </>
                        )}
                        {isMobile && (
                          <PlusButton openAddJob={openAddJob} openAddSlinga={openAddSlinga} />
                        )}
                      </section>
                    </>
                  ) : null}
                </td>
              </tr>
              <tr id='labels'>
                <th className='desk-week'>
                  <input
                    type='checkbox'
                    onChange={() => {
                      console.log('hej');
                    }}
                  />
                </th>
                <th className='desk-week'>
                  <img src={hash} className='logo' />
                  Order nr
                </th>
                <th className='desk-week'>
                  <img src={truck} className='logo' />
                  Resurs
                </th>
                <th className='desk-week'>
                  <img src={userl} className='logo' />
                  Förare
                </th>
                <th className='desk-week'>
                  <img src={userl} className='logo' />
                  Kund
                </th>
                <th className='desk-week'>
                  <img src={grid} className='logo' />
                  Littra
                </th>
                <th className='desk-week'>
                  <img src={clock} className='logo' />
                  Timmar
                </th>
                <th className='desk-week'>
                  <img src={loader} className='logo' />
                  Status
                </th>
                <th className='desk-week'>
                  <div className='label-container'>S</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(daysWithJobs).map(
                (day: [string, Array<JobDataPartial & SlingaData>]) => {
                  return (
                    <WeekdayDesktop
                      onClickJob={onClickJob}
                      onClickSlinga={onClickSlinga}
                      key={day[0]}
                      dateMillis={parseInt(day[0])}
                      jobs={day[1]}
                    />
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default WeekView;
