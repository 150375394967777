import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Equipment, Equipments, User } from '../utils/types';
import Option from '../components/select/Option';
import Select from '../components/select/Select';
import { allowedEquipments, VehicleType, vehicleTypeLabel } from '../utils/constants';

import { Vehicle, VehicleData } from '../utils/types';
import { getEquipments } from '../firebase/firestore_functions/equipments';
import { DataContext } from '../contexts/CommonDataContext';

interface VehicleFormProps {
  vehicle?: VehicleData;
  handleSubmit: (vehicle: Vehicle) => Promise<void>;
  saving: boolean;
}

export default function VehicleForm({ vehicle, handleSubmit, saving }: VehicleFormProps) {
  const [id, setId] = useState<string>(vehicle?.id ? vehicle.id : '');
  const [vehicleType, setVehicleType] = useState<VehicleType>(
    vehicle?.vehicleType ? vehicle.vehicleType : VehicleType.TRUCK,
  );
  const [defaultDriver, setDefaultDriver] = useState<string>(
    vehicle?.driver ? vehicle.driver.docId : '',
  ); // doc ref id of driver
  const [defaultEquipments, setDefaultEquipments] = useState<Set<string>>(
    vehicle?.equipments ? new Set(vehicle.equipments.map((e) => e.docId)) : new Set(),
  );
  const [service, setService] = useState<string>(vehicle?.service ? vehicle.service : '');
  const [serviceId, setServiceId] = useState<string>(vehicle?.serviceId ? vehicle.serviceId : '');

  const { drivers } = useContext(DataContext);

  const [equipments, setEquipments] = useState<Equipments>({});

  useEffect(() => {
    fetchEquipments();
  }, []);

  async function fetchEquipments() {
    const equipmentsResponse = await getEquipments();
    if (equipmentsResponse.code === 200 && equipmentsResponse.data) {
      setEquipments(equipmentsResponse.data);
    }
  }

  function onChangeId(event: SyntheticEvent) {
    setId((event.target as HTMLInputElement).value);
  }

  function onChangeService(event: SyntheticEvent) {
    setService((event.target as HTMLInputElement).value);
  }

  function onChangeServiceId(event: SyntheticEvent) {
    setServiceId((event.target as HTMLInputElement).value);
  }

  function onChangeDefaultDriver(value: string) {
    setDefaultDriver(value);
  }

  function onChangeVehicleType(value: string) {
    const newType = parseInt(value) as VehicleType;
    setVehicleType(newType);

    // check if some equipments should be removed

    setDefaultEquipments(
      new Set(
        Array.from(defaultEquipments).filter((e) => {
          return allowedEquipments[newType].includes(equipments[e].equipmentType);
        }),
      ),
    );
  }

  function onChangeEquipments(value?: string) {
    if (value) {
      const _equipments = new Set(defaultEquipments);
      _equipments.add(value);
      setDefaultEquipments(_equipments);
    }
  }

  function removeEquipment(value: string) {
    const _equipments = new Set(defaultEquipments);
    _equipments.delete(value);
    setDefaultEquipments(_equipments);
  }

  async function _handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    handleSubmit({
      id,
      vehicleType,
      driver: defaultDriver,
      equipments: defaultEquipments,
      service,
      serviceId,
    });
  }

  return (
    <section className='form' style={{ minHeight: '65vh' }}>
      <ul className='form__ul'>
        <li className='form__li' key='id'>
          <input
            className='form__input input-text'
            type='text'
            value={id}
            placeholder='Id'
            onChange={onChangeId}
            required
          />
        </li>
        <li className='form__li' key='service'>
          <input
            className='form__input input-text'
            type='text'
            value={service}
            placeholder='Tjänst'
            onChange={onChangeService}
            required
          />
        </li>
        <li className='form__li' key='serviceId'>
          <input
            className='form__input input-text'
            type='text'
            value={serviceId}
            placeholder='Artikelnummer'
            onChange={onChangeServiceId}
            required
          />
        </li>
        <li className='form__li' key='type'>
          <Select
            onChange={onChangeVehicleType}
            isMultiSelect={false}
            initialOption={{
              value: vehicleType.toString(),
              label: vehicleTypeLabel[vehicleType],
            }}
            type='form'
            iconRightClose='fa fa-angle-down'
            iconRightOpen='fa fa-angle-up'
          >
            <>
              {Object.entries(vehicleTypeLabel).map((entry: [string, string]) => {
                return <Option value={entry[0]} label={entry[1]} key={entry[0]} />;
              })}
            </>
          </Select>
        </li>

        {vehicleType !== undefined &&
          [VehicleType.TRUCK, VehicleType.EXCAVATOR].includes(vehicleType) && (
            <li className='form__li' key='driver'>
              <Select
                onChange={onChangeDefaultDriver}
                isMultiSelect={false}
                type='form'
                iconRightClose='fa fa-angle-down'
                iconRightOpen='fa fa-angle-up'
                searchBar={true}
                label='Default förare'
              >
                <>
                  {drivers.map((driver) => {
                    return (
                      <Option
                        value={driver.docId}
                        label={`${driver.firstName}  ${driver.lastName}`}
                        key={driver.docId}
                      />
                    );
                  })}
                </>
              </Select>
            </li>
          )}
        <li className='form__li' key='equipments' id='add-vehicle__form__equipments'>
          <Select
            isMultiSelect={false}
            type='form'
            label={'Default utrustning'}
            onChange={onChangeEquipments}
            showLabel={true}
            iconRightClose='fa fa-angle-down'
            iconRightOpen='fa fa-angle-up'
          >
            <>
              {Object.entries(equipments).map((equipment: [string, Equipment]) => {
                if (allowedEquipments[vehicleType].includes(equipment[1].equipmentType)) {
                  return <Option key={equipment[0]} value={equipment[0]} label={equipment[1].id} />;
                } else {
                  return null;
                }
              })}
            </>
          </Select>

          {defaultEquipments.size > 0 && (
            <ul className='form__selected-items'>
              {Array.from(defaultEquipments).map((e: string) => {
                return (
                  <li className='form__selected-items__item' key={e}>
                    {equipments[e] ? <p>{equipments[e].id}</p> : <p></p>}
                    <button
                      onClick={() => {
                        removeEquipment(e);
                      }}
                      className='button--icon fa fa-trash'
                    ></button>
                  </li>
                );
              })}
            </ul>
          )}
        </li>

        <li className='form__li loading-parent'>
          {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
          <button onClick={_handleSubmit} className='button--green form__input'>
            Spara
          </button>
        </li>
      </ul>
    </section>
  );
}
