import { useContext } from 'react';
import { PopupContext } from '../../contexts/PopupContext';
import './ConfirmAction.css';

interface ConfirmActionProps {
  confirm: () => void;
  cancel?: () => void;
  heading: string;
  message: string;
}

export default function ConfirmDelete({ confirm, cancel, heading, message }: ConfirmActionProps) {
  const { close } = useContext(PopupContext);

  function _confirm() {
    close();
    confirm();
  }

  function _cancel() {
    close();
    cancel && cancel();
  }

  return (
    <section id='confirm-action'>
      <header id='confirm-action__header'>
        <h2>{heading}</h2>
      </header>
      <main>
        <p>{message}</p>
        <section id='confirm-action__buttons'>
          <button className='button--confirm' onClick={_confirm}>
            Ja
          </button>
          <button className='button--cancel' onClick={_cancel}>
            Nej
          </button>
        </section>
      </main>
    </section>
  );
}
