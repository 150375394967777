import { collection, deleteDoc, getDocs, getFirestore, query } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseApp } from '../firebase/firebaseConfig';
import { UserType } from './constants';
import { User } from './types';

const functions = getFunctions(firebaseApp, 'europe-west1');

const db = getFirestore();

// Why is user a nullable field????
export function hasAdminPrivileges(user?: User) {
  return !!user && [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType);
}

export function b64ToBlob(base64String: string) {
  const byteString = atob(base64String.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export async function sendSMS(message: string, phone: string) {
  const sendSMSFunction = httpsCallable(functions, 'sendSMS');

  let phoneWithPlus46 = phone;
  if (phone.substring(0, 3) !== '+46') {
    phoneWithPlus46 = `+46${phone.slice(1)}`;
  }

  try {
    const response = await sendSMSFunction({ message, phone: phoneWithPlus46 });
    return response;
  } catch (error) {
    console.dir(error);
    return error;
  }
}

export async function sendEmail(email: string) {
  const sendEmailFunction = httpsCallable(functions, 'sendEmail');
  try {
    const response = await sendEmailFunction({ email });
  } catch (error) {
    console.dir(error);
  }
}

export async function deleteAllJobs() {
  const q = query(collection(db, 'jobs'));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    console.log(doc.id);
    deleteDoc(doc.ref);
  });
}
