import { useContext, useEffect, useState } from 'react';
import { JobData, JobDoc, Place, User, VehicleData } from '../../utils/types';
import './AddEditJob.css';
import {
  jobStatusLabel,
  JobType,
  jobTypeLabel,
  NotificationStatus,
  VehicleType,
} from '../../utils/constants';
import Select from '../../components/select/Select';
import Option from '../../components/select/Option';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import PickAPlace from '../../components/PickAPlace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import AddMaterials from './AddMaterials';
import AddEquipmentsToJob from './AddEquipmentsToJob';
import Textarea from '../../components/Textarea';
import { stopPropagation } from '../../utils/uiHelpers';
import { b64ToBlob } from '../../utils/otherHelpers';
import { fromJobDataToJobDoc } from '../../firebase/firestore_functions/job';
import { AddEditJobContext } from './AddEditJobContext';

import { setJob } from '../../firebase/firestore_functions/job';

import ConfirmAction from '../../components/confirm_action';
import AddClient from './AddClientToJob';
import AddContactClient from './AddContactClientJob';
import AddLittra from './AddLittra';
import AlertPopup from '../../components/alert_popup/AlertPopup';
import Timepicker from '../../components/timepicker/Timepicker';
import Datepicker from '../../components/datepicker/Datepicker';
import { toDayDateString } from '../../utils/time';
import { updateLittra } from '../../firebase/firestore_functions/clients';
import { deleteImageStorage, getImageUrl, saveImageStorage } from '../../firebase/storageFunctions';
import { v4 as uuidv4 } from 'uuid';
import AddImages from '../../components/components_for_add_edit_job_slinga/AddImages';

interface AddEditJobSlingaProps {
  job?: JobData;

  /**
   * Fields in the job needs to be synked in different ways with what is ADDED to the slinga.
   * slingaVehicle: CANNOT change for the job, is only used to display what vehicle is ADDED to the slinga.
   * slingaVehicleEquipments: CAN change for the job, what is already chosen for the slinga is displayed at first when a job is added.
   * slingaStart: start for job CANNOT be less than start for slinga.
   * slingaEnd: end for job CANNOT be greater than end for slinga.
   */
  slingaVehicle?: VehicleData;
  slingaDriver?: User;
  slingaVehicleEquipments?: Set<string>;
  slingaStart: number;
  slingaEnd: number;

  // callbacks from
  addJobToSlinga?: (jobData: JobData) => void;
  removeJobFromSlinga?: () => void;
}

export default function AddEditJobSlinga({
  job,
  slingaDriver,
  slingaVehicle,
  slingaVehicleEquipments,
  slingaStart,
  slingaEnd,
  addJobToSlinga,
  removeJobFromSlinga,
}: AddEditJobSlingaProps) {
  const { notify } = useContext(NotificationContext);
  const { showPopup, close } = useContext(PopupContext);

  const [initDone, setInitDone] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);

  const distanceService = new google.maps.DistanceMatrixService();

  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  // data states for the job
  const {
    jobType,
    setJobType,
    status,
    setStatus,
    from,
    setFrom,
    to,
    setTo,
    estimatedDistance,
    estimatedTime,
    setEstimatedDistance,
    setEstimatedTime,
    start,
    setStart,
    end,
    setEnd,
    vehicle,
    vehicleEquipments,
    setVehicleEquipments,
    setVehicle,
    driver,
    setDriver,
    client,
    contactClient,
    contactClientTemp,
    littra,
    littraTemp,
    littraWorkplaceTemp,
    subcontractor,
    contactSubcontractor,
    materials,
    images,
    setImages,
    otherInformation,
    setOtherInformation,
    otherMaterials,
    setOtherMaterials,
    adminComments,
    setAdminComments,
    updateStateEditMode,
  } = useContext(AddEditJobContext);

  const [saving, setSaving] = useState<boolean>(false);

  useEffect(init, []);
  useEffect(getDistance, [from, to]);

  function init() {
    if (job) {
      updateStateEditMode(job);
      setEditMode(true);
    }

    // when a job is added in slinga we pass the slinga's chosen vehicle, driver and vehicle equipments here
    if (slingaVehicle) {
      setVehicle(slingaVehicle);
    }

    if (slingaVehicleEquipments) {
      setVehicleEquipments(slingaVehicleEquipments);
    }
    if (slingaDriver) {
      setDriver(slingaDriver);
    }

    if (slingaStart) {
      setStart(slingaStart);
    }

    if (slingaEnd) {
      setEnd(slingaEnd);
    }

    setInitDone(true);
  }

  ////////////////////////
  // ON CHANGE HANDLERS //
  ////////////////////////

  function onChangeJobType(value: string) {
    setJobType(parseInt(value));
  }
  function onChangeStatus(value: string) {
    setStatus(parseInt(value));
  }

  function onChangePlaceFrom(place?: Place) {
    setFrom(place);
  }

  function onChangePlaceTo(place?: Place) {
    setTo(place);
  }

  function onChangeDateFrom(date: number) {
    const _date = new Date(date);
    _date.setHours(new Date(start).getHours());
    _date.setMinutes(new Date(start).getMinutes());

    // only update if it is within the time intervall for the slinga
    if (_date.getTime() >= slingaStart) {
      setStart(_date.getTime());
      if (_date.getTime() > end) {
        //Update the To-date if From-date is later than it
        _date.setHours(new Date(end).getHours());
        setEnd(_date.getTime());
      }
    } else {
      showAlertDates();
    }
  }

  function onChangeDateTo(date: number) {
    const _date = new Date(date);
    _date.setHours(new Date(end).getHours());
    _date.setMinutes(new Date(end).getMinutes());

    // only update if it is within the time intervall for the slinga
    if (_date.getTime() <= slingaEnd) {
      setEnd(_date.getTime());
      if (_date.getTime() < start) {
        //Update the To-date if From-date is later than it
        _date.setHours(new Date(start).getHours());
        setStart(_date.getTime());
      }
    } else {
      showAlertDates();
    }
  }

  function onChangeHoursFrom(hours: number) {
    const _date = new Date(start);
    _date.setHours(hours);

    // only update if it is within the time intervall for the slinga
    if (_date.getTime() >= slingaStart) {
      setStart(_date.getTime());
    } else {
      showAlertDates();
    }
  }

  function onChangeMinutesFrom(minutes: number) {
    const _date = new Date(start);
    _date.setMinutes(minutes);

    // only update if it is within the time intervall for the slinga
    if (_date.getTime() >= slingaStart) {
      setStart(_date.getTime());
    } else {
      showAlertDates();
    }
  }

  function onChangeHoursTo(hours: number) {
    const _date = new Date(end);
    _date.setHours(hours);

    // only update if it is within the time intervall for the slinga
    if (_date.getTime() <= slingaEnd) {
      setEnd(_date.getTime());
    } else {
      showAlertDates();
    }
  }

  function onChangeMinutesTo(minutes: number) {
    const _date = new Date(end);
    _date.setMinutes(minutes);

    // only update if it is within the time intervall for the slinga
    if (_date.getTime() <= slingaEnd) {
      setEnd(_date.getTime());
    } else {
      showAlertDates();
    }
  }

  function swapPlaces() {
    if (to && from) {
      setFrom(to);
      setTo(from);
    }
  }

  ///////////////////
  // FORM HANDLERS //
  //////////////////

  async function updateImagesInStorage() {
    const updatedImages: string[] = [];

    if (job) {
      const promises: Promise<any>[] = [];

      for (const img of images) {
        // if added image
        if (!job.images || !job.images.includes(img)) {
          promises.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise<void>(async (resolve, _) => {
              // save image
              const imageId = uuidv4();
              await saveImageStorage(`/${job.orderNum}/from_office/${imageId}`, b64ToBlob(img));

              // get the new url
              const urlResponse = await getImageUrl(`/${job.orderNum}/from_office/${imageId}`);

              if (urlResponse.data) {
                updatedImages.push(urlResponse.data);
              }

              resolve();
            }),
          );
        } else {
          updatedImages.push(img);
        }
      }

      for (const img of deletedImages) {
        promises.push(deleteImageStorage(img));
      }

      await Promise.all(promises);
    }
    return updatedImages;
  }

  async function save() {
    // client and place is required
    if (!client) {
      showAlertNoClient();
      return;
    }

    const newJobData: JobData = {
      jobType,
      orderNum: 1,
      docId: '1', // fake for now since required in jobData,
      start: start,
      end: end,
      status,
      otherMaterials,
      hasReceiptsForCompany: false,
      smsHasBeenSent: false,
      adminComments,
      littraTemp,
      littraWorkplaceTemp,
      from,
      to,
      driver: driver,
      vehicle: vehicle,
      vehicleEquipments: Array.from(vehicleEquipments).map((e) => {
        return {
          id: e,
          docId: e,
          equipmentType: 0,
          service: '',
          serviceId: '',
        };
      }),
      materials: Object.values(materials),
      littra,
      otherInformation,
      contactClientTemp,
    };

    newJobData.images = job ? await updateImagesInStorage() : images;
    // client is required
    if (client) newJobData.client = client;

    if (contactClient) newJobData.contactClient = contactClient;
    if (estimatedTime) newJobData.estimatedTime = estimatedTime;
    if (estimatedDistance) newJobData.estimatedDistance = estimatedDistance;

    if (job) {
      setSaving(true);
      saveUpdatedJob(fromJobDataToJobDoc(newJobData));
    } else {
      // "AddEditSlinga" will handle addition of new job
      addJobToSlinga && addJobToSlinga(newJobData);
      close();
    }
  }

  async function saveUpdatedJob(jobDoc: JobDoc) {
    console.log('UPDATES: ', jobDoc);
    if (job) {
      const updateResponse = await setJob(jobDoc, job);

      console.log('updateResponse', updateResponse);

      setSaving(false);

      if (updateResponse.code === 201) {
        // send sms to driver
        let link;
        let phone;
        if (
          [VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
            vehicle.vehicleType,
          )
        ) {
          if (subcontractor) {
            if (contactSubcontractor) {
              link = `Hej ${contactSubcontractor.name}!\n\nDu har ett updaterat uppdrag från Nolblad:\nhttps://nolblad.se/job-${job.docId}`;
              phone = contactSubcontractor.phone;
            }
          }
        } else if (job.driver) {
          phone = job.driver.phone;
          link = `Hej ${job.driver.firstName}!\n\nDu har ett uppdaterat uppdrag från Nolblad:\nhttps://nolblad.se/`;
        }
        notify('Jobb uppdaterat', NotificationStatus.SUCCESS);
        close();
      } else {
        notify(`Kunde inte uppdatera jobbet, ${updateResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  function confirmDelete() {
    showPopup(
      <ConfirmAction
        confirm={_deleteJob}
        heading='Är du säker på det här?'
        message='Om jobbet tas bort från systemet kan det inte återställas senare.'
      />,
      'small',
    );
  }

  function showAlertNoClient() {
    showPopup(<AlertPopup message='Du har inte lagt till kund!' />, 'small');
  }

  function showAlertDates() {
    showPopup(
      <AlertPopup message='Datum och tid måste vara inom tidsintervallet i slingan!' />,
      'small',
    );
  }

  async function _deleteJob() {
    // console.log("_DELETE ")
    // if the job belongs to a slinga we don't want to delete it from the db,
    // instead we call the callback from "AddEditSlinga" which handles deletion.
    // jobs are deleted from db afterwards if the user decides to save the updates
    if (removeJobFromSlinga) {
      close();
      removeJobFromSlinga();
    }
  }

  function showAlertNoLittra() {
    showPopup(
      <AlertPopup message='Du måste lägga till littra och kund för att kunna spara platsen på littrat!' />,
      'small',
    );
  }

  async function onSavePlaceToLittra(place: Place) {
    if (littra && client) {
      const updateResponse = await updateLittra(
        { places: [...littra.places, place] },
        littra?.docId,
        client.docId,
      );

      if (updateResponse.code === 201) {
        notify('Sparat plats på littra', NotificationStatus.SUCCESS);
      } else {
        notify('Kunde inte spara plats på littra', NotificationStatus.ERROR);
      }
    } else {
      showAlertNoLittra();
    }
  }

  function getDistance() {
    if (from && to && from.position && to.position) {
      distanceService.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(from.position.lat, from.position.lng)],
          destinations: [new google.maps.LatLng(to.position.lat, to.position.lng)],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        distanceResultCb,
      );
    }
  }
  function distanceResultCb(response: any, status: any) {
    if (status === 'OK') {
      if (response.rows[0].elements[0].status === 'OK') {
        setEstimatedDistance({
          text: response.rows[0].elements[0].distance.text,
          meters: response.rows[0].elements[0].distance.value,
        });
        setEstimatedTime({
          text: response.rows[0].elements[0].duration.text,
          seconds: response.rows[0].elements[0].duration.value,
        });
      } else {
        setEstimatedDistance(undefined);
        setEstimatedTime(undefined);
      }
    }
  }

  if (!initDone) {
    return <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />;
  } else {
    return (
      <section id='add-job' onClick={stopPropagation}>
        <header id='add-job__header'>
          <h2>{editMode ? 'Redigera uppdrag' : 'Lägg till nytt uppdrag'}</h2>
          {editMode && (
            <button
              onClick={confirmDelete}
              className='button--cancel'
              style={{ alignSelf: 'flex-end' }}
            >
              <span className='fa fa-trash' style={{ marginRight: '0.5rem' }} />
              Radera
            </button>
          )}
        </header>
        <main>
          <section id='add-job__form'>
            <ul id='add-job__form__ul'>
              {editMode && (
                <li key={`status-${status}`} className='add-job__form__li'>
                  <Select
                    isMultiSelect={false}
                    iconRightClose='fa fa-angle-down'
                    iconRightOpen='fa fa-angle-up'
                    onChange={onChangeStatus}
                    label='Status'
                    type='form'
                    initialOption={{
                      value: status.toString(),
                      label: jobStatusLabel[status],
                    }}
                  >
                    <>
                      {Object.entries(jobStatusLabel).map((s: [string, string]) => {
                        return <Option key={s[0]} value={s[0]} label={s[1]} />;
                      })}
                    </>
                  </Select>
                </li>
              )}

              <li key={`job-type`} className='add-job__form__li'>
                <Select
                  isMultiSelect={false}
                  iconRightClose='fa fa-angle-down'
                  iconRightOpen='fa fa-angle-up'
                  onChange={onChangeJobType}
                  label='Kategori'
                  type='form'
                  initialOption={{
                    value: jobType.toString(),
                    label: jobTypeLabel[jobType],
                  }}
                >
                  <>
                    {Object.entries(jobTypeLabel).map((s: [string, string]) => {
                      return <Option key={s[0]} value={s[0]} label={s[1]} />;
                    })}
                  </>
                </Select>
              </li>

              <AddClient />

              {!!client && (
                <>
                  <AddLittra />
                  <AddContactClient />{' '}
                </>
              )}

              <li key='place' className='add-job__form__li' id='add-job__form__li-place'>
                <p className='label'>Plats från</p>
                <PickAPlace
                  place={from}
                  onChange={onChangePlaceFrom}
                  onSaveToLittra={onSavePlaceToLittra}
                />

                <section id='add-job__form__swap-place'>
                  <button className='button--main--small' onClick={swapPlaces}>
                    Byt platser <span className='fa fa-angle-up' />
                    <span className='fa fa-angle-down' />
                  </button>
                </section>

                <p className='label'>Plats till</p>

                <PickAPlace
                  place={to}
                  onChange={onChangePlaceTo}
                  onSaveToLittra={onSavePlaceToLittra}
                />
              </li>

              <li key='from' id='add-job__form__date' className='add-job__form__li'>
                <Datepicker
                  mode='day'
                  onChange={onChangeDateFrom}
                  currentDateMillis={start}
                  showQuickChangeButtons={true}
                  buttonClassName='input-text add-job__form__input add-job__form__date-date'
                  buttonContent={
                    <>
                      {' '}
                      <span className='fa fa-calendar' />
                      {`${toDayDateString(start)}`}
                    </>
                  }
                />

                <Timepicker
                  onChangeHours={onChangeHoursFrom}
                  onChangeMinutes={onChangeMinutesFrom}
                  hours={start ? new Date(start).getHours() : 7}
                  minutes={start ? new Date(start).getMinutes() : 0}
                />
              </li>

              <li key='to' id='add-job__form__date' className='add-job__form__li'>
                <Datepicker
                  mode='day'
                  onChange={onChangeDateTo}
                  currentDateMillis={end}
                  showQuickChangeButtons={true}
                  buttonClassName='input-text add-job__form__input add-job__form__date-date'
                  buttonContent={
                    <>
                      <span className='fa fa-calendar' />
                      {`${toDayDateString(end)}`}
                    </>
                  }
                />

                <Timepicker
                  onChangeHours={onChangeHoursTo}
                  onChangeMinutes={onChangeMinutesTo}
                  hours={end ? new Date(end).getHours() : 16}
                  minutes={end ? new Date(end).getMinutes() : 0}
                />
              </li>

              {!!slingaVehicle && (
                <>
                  <li className='add-job__form__li'>
                    <p>
                      Fordon för slingan är <span className='label'>{slingaVehicle.id}</span>
                    </p>
                  </li>

                  <AddEquipmentsToJob />
                </>
              )}
              {jobType === JobType.TRANSPORT_SCHAKT && <AddMaterials />}

              {(jobType === JobType.TRANSPORT_SCHAKT || jobType === JobType.TRANSPORT_MACHINE) && (
                <li key='other-material' className='add-job__form__li'>
                  <Textarea
                    id='other-material'
                    setState={setOtherMaterials}
                    placeholder='Godsslag'
                    value={otherMaterials}
                  />
                </li>
              )}
              <AddImages
                images={images}
                setImages={setImages}
                deletedImages={deletedImages}
                setDeletedImages={setDeletedImages}
              />

              <li key='other' className='add-job__form__li'>
                <Textarea
                  id='other'
                  setState={setOtherInformation}
                  placeholder='Kommentar till chaufför'
                  value={otherInformation}
                />
              </li>

              <li key='admin-comments' className='add-job__form__li'>
                <Textarea
                  id='admin-comments'
                  setState={setAdminComments}
                  placeholder='Kommentar till kontoret'
                  value={adminComments}
                />
              </li>

              <li key='save' className='add-job__form__li'>
                {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
                <input className='button--green' type='submit' value='Spara' onClick={save} />
              </li>
            </ul>
          </section>
        </main>
      </section>
    );
  }
}
