import { useContext } from 'react';
import { AddEditJobContext } from './AddEditJobContext';
import AddMaterials from '../../components/components_for_add_edit_job_slinga/AddMaterials';

/**
 * Component that wraps 'AddMaterials' and passas data state variables from 'AddEditJobContext'
 */
export default function AddMaterialsToJob() {
  const { materials, setMaterials } = useContext(AddEditJobContext);
  return <AddMaterials materials={materials} setMaterials={setMaterials} />;
}
