import { useContext, useState } from 'react';
import { ClientData, ContactData, ContactDoc, FirebaseResponse } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import ContactForm from '../forms/ContactForm';
import { stopPropagation } from '../utils/uiHelpers';

interface AddContactProps {
  onAdd: (contact: ContactDoc) => Promise<FirebaseResponse>;
}

export default function AddContact({ onAdd }: AddContactProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  async function handleSubmit(contact: ContactDoc) {
    setSaving(true);

    const response = await onAdd(contact);

    if (response.code === 201 && response.data) {
      setSaving(false);
      notify('Kontakt tillagd', NotificationStatus.SUCCESS);
      close();
    } else {
      setSaving(false);
      notify(`Kunde inte spara kontakt: ${response.error}`, NotificationStatus.ERROR);
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Lägg till en ny kontakt</h2>
      </header>
      <main>
        <ContactForm handleSubmit={handleSubmit} saving={saving} />
      </main>
    </section>
  );
}
