import { useContext } from 'react';
import { ReportRowData, ChosenMaterials } from '../../utils/types';
import { NotificationContext } from '../../contexts/NotificationContext';
import { JobType, NotificationStatus } from '../../utils/constants';
import { PopupContext } from '../../contexts/PopupContext';
import { stopPropagation } from '../../utils/uiHelpers';
import './AddRow.css';
import RowForm from '../../forms/RowForm';

interface EditRowProps {
  row: ReportRowData;
  rowIdx: number;
  jobType: JobType;
  onEdit: (row: ReportRowData, rowIdx: number) => void;
}
export default function AddRow({ onEdit, row, rowIdx, jobType }: EditRowProps) {
  const { close } = useContext(PopupContext);
  const { notify } = useContext(NotificationContext);

  function getMaterialsInRow() {
    const _chosenMaterials: ChosenMaterials = {};
    if (row.materials) {
      for (const m of row.materials) {
        _chosenMaterials[m.material.docId] = m;
      }
    }
    return _chosenMaterials;
  }

  async function handleSubmit(row: ReportRowData) {
    if ((row.from && row.to) || (row.fromLocation && row.toLocation)) {
      onEdit(row, rowIdx);
      close();
    } else {
      notify('Lägg till platsinformation', NotificationStatus.INFO);
    }
  }

  return (
    <section id='add-row' onClick={stopPropagation}>
      <header id='add-row__header'>
        <h2>Redigera rapportrad</h2>
      </header>
      <main>
        <RowForm
          chosenMaterials={getMaterialsInRow()}
          from={row.from ? row.from.name : row.fromLocation}
          to={row.to ? row.to.name : row.toLocation}
          jobType={jobType}
          otherMaterials={row.otherMaterials ? row.otherMaterials : ''}
          handleSubmit={handleSubmit}
        />
      </main>
    </section>
  );
}
