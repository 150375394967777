import { initializeApp } from 'firebase/app';
import {
  FIREBASE_APIKEY,
  FIREBASE_APPID,
  FIREBASE_AUTHDOMAIN,
  FIREBASE_MESSAGINGSENDERID,
  FIREBASE_PROJECTID,
  FIREBASE_STORAGEBUCKET,
} from '../utils/keys';

/**
 * We have two firebase projects, one for production and one for
 * development. Here we initialize the firebase project according to
 * if the enivornment variable "NODE_ENV" is "development" or "production".
 *
 * NODE_ENV has the value "development" when using npm run start or npm run test and
 * "production" when using npm run build.
 */
const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  projectId: FIREBASE_PROJECTID,
  storageBucket: FIREBASE_STORAGEBUCKET,
  messagingSenderId: FIREBASE_MESSAGINGSENDERID,
  appId: FIREBASE_APPID,
};

export const firebaseAppAdmin = initializeApp(firebaseConfig, 'admin');

export const firebaseApp = initializeApp(firebaseConfig);
