import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import './Timepicker.css';
import { Select, SelectProps, ConfigProvider } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';

interface TimepickerProps {
  onChangeHours: (hours: number) => void;
  onChangeMinutes: (minutes: number) => void;
  hours?: number;
  minutes?: number;
}

export default function Timepicker({
  onChangeHours,
  onChangeMinutes,
  hours,
  minutes,
}: TimepickerProps) {
  const [size] = useState<SizeType>('middle');
  const [selectBGColor, setSelectBGColor] = useState<string>('');
  const [selectBGColor2, setSelectBGColor2] = useState<string>('');

  // optionHours in the for {value: "00", label: "00"} with prefilled values
  const optionHours = Array.from({ length: 24 }, (_, i) => ({
    value: i,
    label: i.toString().padStart(2, '0')
  }));
  
  const optionMinutes = Array.from({ length: 60 }, (_, i) => ({
    value: i,
    label: i.toString().padStart(2, '0')
  }));

  // Fixes the issue where the select options are not shown when the input is 0
  const filterOption = (inputValue: string, option: any) => {
    if (inputValue.startsWith('0')) {
      return option.label.startsWith(inputValue) || option.value.toString().padStart(2, '0').startsWith(inputValue);
    } else {
      return option.label.includes(inputValue) || option.value.toString().includes(inputValue);
    }
  };
  

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: "0.5rem" }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: 'rgba(229, 9, 127, 1)',
            colorBgBase: selectBGColor,
            colorText: selectBGColor !== '' ? 'rgba(229, 9, 127, 1)' : '',
          },
        }}
      >
        <Select
          showSearch
          size={size}
          style={{ width: '60px' }}
          defaultValue={hours}
          value={hours}
          filterOption={filterOption}
          options={optionHours}
          onFocus={() => setSelectBGColor('rgba(255, 243, 250, 1)')}
          onBlur={() => setSelectBGColor('')}
          onChange={(value) => onChangeHours(value)}
        />
      </ConfigProvider>

      <p>:</p>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: 'rgba(229, 9, 127, 1)',
            colorBgBase: selectBGColor2,
            colorText: selectBGColor2 !== '' ? 'rgba(229, 9, 127, 1)' : '',
          },
        }}
      >
        <Select
          showSearch
          size={size}
          style={{ width: '60px' }}
          filterOption={filterOption}
          options={optionMinutes}
          defaultValue={minutes}
          value={minutes}
          onFocus={() => setSelectBGColor2('rgba(255, 243, 250, 1)')}
          onBlur={() => setSelectBGColor2('')}
          onChange={(value) => onChangeMinutes(value)}
        />
      </ConfigProvider>
    </div>
  );
}
