import { SyntheticEvent, useContext, useState } from 'react';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { NotificationStatus } from '../../../utils/constants';
import { copyJob } from '../../../firebase/firestore_functions/job';
import { copySlinga } from '../../../firebase/firestore_functions/slinga';
import { isToday, toDayDateString } from '../../../utils/time';
import { JobDataPartial, SlingaData } from '../../../utils/types';
import { stopPropagation } from '../../../utils/uiHelpers';

import './WeekdayMobile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { LayoutGrid, Truck, UserSquare, User, Clock, Copy } from 'lucide-react';
import { AuthContext } from '../../../contexts/AuthContext';
import { hasAdminPrivileges } from '../../../utils/otherHelpers';
import { getJobHours, getLittraText, getLittraWorkText } from '../../../utils/jobUtils';
import {
  getPartialJobBackgroundColor,
  getJobStatusLabel,
  setCorrectJobStatusTextColor,
} from '../../../utils/styling';

interface WeekDayProps {
  dateMillis: number;
  jobs: Array<JobDataPartial & SlingaData>;
  onClickJob: (docId: string, clickedDate: number) => void;
  onClickSlinga: (docId: string) => void;
  odd?: boolean;
}

function WeekDayMobile({ dateMillis, jobs, onClickJob, onClickSlinga, odd = false }: WeekDayProps) {
  const { notify } = useContext(NotificationContext);
  const { user } = useContext(AuthContext);

  const [loadingCopy, setLoadingCopy] = useState('');

  async function copy(job: JobDataPartial & SlingaData) {
    setLoadingCopy(job.docId);

    const response =
      job.sNumber !== undefined ? await copySlinga(job.docId) : await copyJob(job.docId);

    switch (response.code) {
      case 201:
        notify('Jobb kopierat', NotificationStatus.SUCCESS);
        break;
      case 404:
        notify('Jobbet som skulle kopieras kunde inte hittas', NotificationStatus.ERROR);
        break;
      case 500:
        notify('Jobbet kunde inte kopieras', NotificationStatus.ERROR);
        break;
    }

    setLoadingCopy('');
  }

  return (
    <>
      <li className='weekday'>
        <div className='weekday-date-column'>
          <p className={isToday(dateMillis) ? 'today-day-name' : 'not-today-day-name'}>
            {toDayDateString(dateMillis, false).split(' ')[0].toUpperCase()}
          </p>
          {isToday(dateMillis) ? (
            <div className='today-date-circle'>
              <p>{toDayDateString(dateMillis, false).split(' ')[1]}</p>
            </div>
          ) : (
            <p className='not-today-day-number'>
              {toDayDateString(dateMillis, false).split(' ')[1]}
            </p>
          )}
        </div>

        <div className='weekday-job-column'>
          <ul>
            {jobs.length === 0 ? (
              <div style={{ marginBottom: '2px' }} />
            ) : (
              <p style={{ fontSize: '1rem', marginBottom: '2px' }}>
                <span style={{ fontWeight: 'bold' }}>{jobs.length}</span> jobb
              </p>
            )}

            {Object.values(jobs).length === 0 && (
              <li
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Inga jobb
              </li>
            )}

            {jobs.map((job) => {
              return (
                <li
                  onClick={() => {
                    if (job.sNumber !== undefined) {
                      onClickSlinga(job.docId);
                    } else {
                      onClickJob(job.docId, dateMillis);
                    }
                  }}
                  className={`job--mobile`}
                  style={{ backgroundColor: getPartialJobBackgroundColor(job, user, true) }}
                  key={job.docId}
                >
                  <section className='job--mobile-row-align-center'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <p
                        style={{
                          backgroundColor: getPartialJobBackgroundColor(job, user, false),
                          color: setCorrectJobStatusTextColor(job),
                          padding: '0.5rem',
                          borderRadius: 5,
                        }}
                      >
                        {getJobStatusLabel(job)}
                      </p>
                      <p>
                        {(job.orderNum
                          ? 'Order ' + job.orderNum
                          : job.sNumber
                          ? 'Slinga ' + job.sNumber
                          : '') + ' '}
                      </p>
                    </div>
                    <div style={{ display: 'flex', gap: '0.4rem' }}>
                      {job.smsHasBeenSent ? (
                        <FontAwesomeIcon icon={faBell} title={'SMS till förare har skickats'} />
                      ) : (
                        <FontAwesomeIcon
                          icon={faBell}
                          style={{ color: 'transparent' }}
                          className='unfilled-icon'
                        />
                      )}
                      {hasAdminPrivileges(user) && (
                        <button
                          onClick={(event: SyntheticEvent) => {
                            stopPropagation(event);

                            copy(job);
                          }}
                          className={
                            loadingCopy === job.docId
                              ? 'value fa fa-circle-o-notch fa-spin button--icon'
                              : undefined
                          }
                        >
                          {loadingCopy === job.docId ? null : <Copy size={16} />}
                        </button>
                      )}
                    </div>
                  </section>

                  <section className='job--mobile-row-align-start'>
                    <div className='info-column'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}
                      >
                        <LayoutGrid size={16} />
                        <p>{getLittraText(job)}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}
                      >
                        <Truck size={16} />
                        <p>{job.name ? job.name : job.vehicle ? job.vehicle.name : 'Ingen Bil'}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}
                      >
                        <UserSquare size={16} />
                        <p>{job.driver ? job.driver.firstName : 'Ingen Förare'}</p>
                      </div>
                    </div>
                    <div className='info-column'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'left',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}
                      >
                        <User size={16} />
                        {job.sNumber === undefined && (
                          <p style={{ fontWeight: 'bold', width: '100%' }}>
                            {job.client?.name ? job.client.name : 'Ingen kund tillagd'}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}
                      >
                        <Clock size={16} />
                        <p>{Math.round(getJobHours(job, dateMillis) * 100) / 100}h</p>
                      </div>
                    </div>
                  </section>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
    </>
  );
}

export default WeekDayMobile;
