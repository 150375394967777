import { SyntheticEvent, useState } from 'react';
import { ContactDoc } from '../utils/types';

interface ContactFormProps {
  name?: string;
  phone?: string;
  handleSubmit: (contact: ContactDoc) => Promise<void>;
  saving: boolean;
}
export default function ContactForm({ name, phone, handleSubmit, saving }: ContactFormProps) {
  const [_name, setName] = useState<string>(name ? name : '');
  const [_phone, setPhone] = useState<string>(phone ? phone : '');

  function onChangeName(event: SyntheticEvent) {
    setName((event.target as HTMLInputElement).value);
  }

  function onChangePhone(event: SyntheticEvent) {
    setPhone((event.target as HTMLInputElement).value);
  }

  function _handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    handleSubmit({ name: _name, phone: _phone });
  }

  return (
    <form onSubmit={_handleSubmit} className='form'>
      <ul className='form__ul'>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={_name}
            placeholder='Namn'
            onChange={onChangeName}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={_phone}
            placeholder='Tel'
            onChange={onChangePhone}
          />
        </li>
        <li className='form__li loading-parent'>
          {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
          <input className='button--green form__input' type='submit' value='Spara' />
        </li>
      </ul>
    </form>
  );
}
