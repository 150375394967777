import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import React, { SyntheticEvent, useContext } from 'react';
import ConfirmAction from '../components/confirm_action';
import { NotificationContext } from '../contexts/NotificationContext';
import { PopupContext } from '../contexts/PopupContext';
import { NotificationStatus } from '../utils/constants';
import { Place } from '../utils/types';

interface RemovePlaceProps {
  place: Place;
}

const db = getFirestore();

const RemovePlace: React.FC<RemovePlaceProps> = ({ place }) => {
  // Used to show popup for deletion confirmation
  const { showPopup, close } = useContext(PopupContext);
  const { notify } = useContext(NotificationContext);

  // Actually delete the place from firestore
  const deletePlace = async () => {
    try {
      await deleteDoc(doc(db, 'places', place.docId!));
      notify('Platsen readerad', NotificationStatus.SUCCESS);
      close();
    } catch (error) {
      notify('Kunde ej radera plats!', NotificationStatus.ERROR);
    }
  };

  // middle-man before deletion
  const confirmDelete = (event?: SyntheticEvent) => {
    event && event.stopPropagation();
    showPopup(
      <ConfirmAction
        confirm={deletePlace}
        heading='Är du säker på det här?'
        message='Platsen kommer readeras från systemet 
            och går inte att återställa'
      />,
      'small',
    );
  };

  return (
    <section className='details'>
      {/* TITLE */}
      <header className='details__header'>
        <h2>{place.docId ? place.docId : 'ID saknas kan inte ta bort'}</h2>
      </header>
      {/* TRASH BUTTON */}
      <section className='details__header_controls'>
        <button onClick={confirmDelete} className='fa fa-trash button--icon' />
      </section>
      {/* INFORMATION PART */}
      <main>
        <section className='details__info'>
          <div className='info'>
            <p className='label'>{place.name ? 'Namn' : 'Adress'}</p>
            <p>{place.name ? place.name : place.address!}</p>
          </div>
          <div className='info'>
            <p className='label'>Position (lat/lng)</p>
            <p>{place.position?.lat.toString() + ', ' + place.position?.lng.toString()}</p>
          </div>
        </section>
      </main>
    </section>
  );
};

export default RemovePlace;
