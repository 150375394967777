import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import ImagePopup from '../../components/ImagePopup';
import Textarea from '../../components/Textarea';
import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import { toJobData, updateJob } from '../../firebase/firestore_functions/job';
import {
  JobStatus,
  jobStatusLabel,
  JobType,
  NotificationStatus,
  UserType,
} from '../../utils/constants';
import {
  getContactClientTempName,
  getContactClientTempPhone,
  getLittraText,
  getLittraWorkText,
} from '../../utils/jobUtils';
import { toTimeDateString, toTimeDateStringVariant } from '../../utils/time';
import { JobData, JobDoc } from '../../utils/types';
import { stopPropagation } from '../../utils/uiHelpers';
import ReportJob from '../report_job';
import ReportJobFinal from '../report_job/ReportJobFinal';
import ReportSummary from '../report_job/ReportSummary';
import AdminControls from './AdminControls';
import DriverControls from './DriverControls';
import './JobDetails.css';
import { getJobStatusLabel, getPartialJobBackgroundColor } from '../../utils/styling';
import OrderNumber from '../OrderNumber';
import JobDetail from './JobDetail';
import {
  Briefcase,
  Calendar,
  Clock,
  Flag,
  Image,
  LayoutGrid,
  Loader,
  MapPin,
  Maximize2,
  MessageSquare,
  PenLine,
  Phone,
  Truck,
  User,
  UserSquare,
  Package,
} from 'lucide-react';
import JobDetailsDivider from './JobDetailsDivider';
import ShowPdfs from '../pdf/ShowPdfs';
import PdfButton from '../../components/pdf/PdfButton';

interface JobDetailsProps {
  jobDocId: string;
  calendarClickedDate?: number;
  belongsToSlinga?: boolean;
}

const db = getFirestore();

/**
 * Component that shows all information in a job.
 * @param jobDocId id of job in firestore
 * @returns JSX.Element
 */
const JobDetails: React.FC<JobDetailsProps> = ({
  jobDocId,
  belongsToSlinga,
  calendarClickedDate,
}) => {
  const { notify } = useContext(NotificationContext);
  const { user } = useContext(AuthContext);
  const { showPopup } = useContext(PopupContext);

  const [details, setDetails] = useState<JobData>();
  const [adminComments, setAdminComments] = useState(''); // to be able to update these without entering edit mode
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setListenerToJobDoc().then((unsubscribe) => {
      return unsubscribe;
    });
  }, []);

  /**
   * Sets listener to job doc to reflect changes made to the doc live
   */
  async function setListenerToJobDoc() {
    const jobDocRef = doc(db, 'jobs', jobDocId);
    const unsubscribe = onSnapshot(jobDocRef, async (querySnapshot) => {
      if (querySnapshot.exists()) {
        const jobDoc = querySnapshot.data() as JobDoc;
        const job = await toJobData(jobDocId, jobDoc);
        setDetails(job);
        setAdminComments(job.adminComments ? job.adminComments : '');
        checkShouldChangeStatusFromNewToReceived(job);
      } else {
        setNotFound(true);
      }
    });

    return unsubscribe;
  }

  async function checkShouldChangeStatusFromNewToReceived(job: JobData) {
    if (job.driver?.docId === user?.docId && job.status === JobStatus.NEW) {
      const updateStatusResponse = await updateJob(job.docId, {
        status: JobStatus.RECEVIED,
      });
      if (updateStatusResponse.code !== 201) {
        console.log('Kunde inte uppdatera status till mottagen av förare');
      }
    }
  }

  async function saveAdminComment() {
    if (adminComments && details) {
      const saveAdminCommentResponse = await updateJob(details.docId, {
        adminComments,
      });
      if (saveAdminCommentResponse.code === 201) {
        notify('Kommentarerna är sparade', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Kunde inte spara kommentarerna: ${saveAdminCommentResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  function showImagePopup(image: string) {
    showPopup(<ImagePopup image={image} />, 'medium');
  }

  function openReportJobFinal() {
    details && showPopup(<ReportJobFinal job={details} />, 'medium');
  }

  function openReportJob() {
    details &&
      showPopup(
        <ReportJob
          job={details}
          initialDate={calendarClickedDate !== undefined ? calendarClickedDate : details.start}
        />,
        'medium',
      );
  }

  const formatPhoneNumber = (phone: string | undefined) => {
    if (!phone) {
      return '';
    }

    //Remove spaces
    phone = phone.replace(/\s/g, '');
    //CHeck if country code exists
    if (phone.charAt(0) === '+') {
      return phone;
    }

    //Check if first number is 0
    if (phone.charAt(0) === '0') {
      phone = phone.slice(1);
    }

    if (phone.startsWith('46')) {
      phone = phone.slice(2);
    }

    //Add country code to phone number
    phone = '+46' + phone;
    return phone;
  };

  return (
    <>
      {notFound && (
        <section className='details' onClick={stopPropagation}>
          <h2>Uppdraget kunde inte hittas.</h2>
        </section>
      )}

      {!notFound && !details && (
        <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
      )}
      {details && (
        <div className='details' onClick={stopPropagation}>
          <section className='details-section' style={{ marginTop: '2.5rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '30px',
              }}
            >
              <OrderNumber orderNumber={details.orderNum} />

              {user && [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) && (
                <PdfButton jobs={[details]} includeLogo={true} includePictures={true}>
                  <p
                    style={{
                      color: '#fff',
                      fontWeight: '600',
                      fontSize: '14px',
                      marginRight: '8px',
                    }}
                  >
                    Visa
                  </p>
                  <Maximize2 size={14} strokeWidth={3} color='#fff' />
                </PdfButton>
              )}
            </div>

            <h1 className='h1-job-details'>{details.client?.name}</h1>

            <h2 className='h2-job-details'>{toTimeDateStringVariant(details.start)}</h2>

            {user && [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
              <>
                <DriverControls details={details} calendarClickedDate={calendarClickedDate} />
                <div style={{ marginBottom: '15px' }}></div>
                <AdminControls details={details} />
              </>
            ) : (
              <DriverControls details={details} calendarClickedDate={calendarClickedDate} />
            )}

            <div style={{ marginBottom: '15px' }}></div>

            <JobDetail icon={<Loader size={16} strokeWidth={3} />} text1='Status:'>
              <p
                className={`status`}
                style={{ backgroundColor: getPartialJobBackgroundColor(details, user) }}
              >
                {getJobStatusLabel(details)}
              </p>
            </JobDetail>
            <JobDetail
              icon={<Calendar size={16} strokeWidth={3} />}
              text1='Start:'
              text2={toTimeDateStringVariant(details.start)}
            />
            <JobDetail
              icon={<Calendar size={16} strokeWidth={3} />}
              text1='Slut:'
              text2={toTimeDateStringVariant(details.end)}
            />
          </section>

          <JobDetailsDivider />

          <section className='details-section'>
            <JobDetail
              icon={<User size={16} strokeWidth={3} />}
              text1='Kund:'
              text2={details.client?.name}
            />
            {details.contactClient && !details.contactClientTemp && (
              <JobDetail icon={<Phone size={16} strokeWidth={3} />} text1='Kontakt:'>
                <div>
                  {details.contactClient.name}
                  {`, `}
                  <a href={`tel:${formatPhoneNumber(details.contactClient.phone)}`}>
                    {details.contactClient.phone}
                  </a>
                </div>
              </JobDetail>
            )}
            {!details.contactClient && details.contactClientTemp && (
              <JobDetail icon={<Phone size={16} strokeWidth={3} />} text1='Kontakt:'>
                <div>{details.contactClientTemp}</div>
              </JobDetail>
            )}
            {details.littra && !details.littraTemp && (
              <>
                <JobDetail
                  icon={<LayoutGrid size={16} strokeWidth={3} />}
                  text1='Littra:'
                  text2={getLittraText(details)}
                />
                <JobDetail
                  icon={<Briefcase size={16} strokeWidth={3} />}
                  text1='Arbetsplats:'
                  text2={getLittraWorkText(details)}
                />
              </>
            )}
            {details.littraTemp && !details.littra && (
              <>
                <JobDetail
                  icon={<LayoutGrid size={16} strokeWidth={3} />}
                  text1='Littra:'
                  text2={getLittraText(details)}
                />
                <JobDetail
                  icon={<Briefcase size={16} strokeWidth={3} />}
                  text1='Arbetsplats:'
                  text2={getLittraWorkText(details)}
                />
              </>
            )}
            {details.driver && (
              <JobDetail
                icon={<UserSquare size={16} strokeWidth={3} />}
                text1='Förare:'
                text2={details.driver.firstName + ' ' + details.driver.lastName}
              />
            )}
            {details.otherMaterials && (
              <JobDetail
                icon={<Package size={16} strokeWidth={3} />}
                text1='Godsslag:'
                text2={details.otherMaterials}
              />
            )}
          </section>

          <JobDetailsDivider />

          <section className='details-section'>
            {/* Vehicle */}
            {details.vehicle && (
              <JobDetail
                icon={<Truck size={16} strokeWidth={3} />}
                text1='Fordon:'
                text2={details.vehicle.id}
              />
            )}

            {/* Utrustning */}
            {details.vehicleEquipments && details.vehicleEquipments.length > 0 && (
              <JobDetail icon={<UserSquare size={16} strokeWidth={3} />} text1='Utrustning:'>
                <div className='yo'>
                  {details.vehicleEquipments.map((equipment) => (
                    <p key={equipment.id}>{equipment.id}</p>
                  ))}
                </div>
              </JobDetail>
            )}

            {/* Från */}
            {details.from && (
              <JobDetail icon={<MapPin size={16} strokeWidth={3} />} text1='Från:'>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${details.from.position?.lat},${details.from.position?.lng}`}
                >
                  {details.from.address || details.from.name}
                </a>
              </JobDetail>
            )}

            {/* Till */}
            {details.to && (
              <JobDetail icon={<MapPin size={16} strokeWidth={3} />} text1='Till:'>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${details.to.position?.lat},${details.to.position?.lng}`}
                >
                  {details.to.address || details.to.name}
                </a>
              </JobDetail>
            )}

            {/* Estimerad sträcka */}
            {details.estimatedDistance && (
              <JobDetail
                icon={<Flag size={16} strokeWidth={3} />}
                text1='Estimerad sträcka:'
                text2={`${details.estimatedDistance.text}`}
              />
            )}

            {/* Estimerad tid */}
            {details.estimatedTime && (
              <JobDetail
                icon={<Clock size={16} strokeWidth={3} />}
                text1='Estimerad tid:'
                text2={`${details.estimatedTime.text}`}
              />
            )}

            {/* Annan information */}
            {details.otherInformation && (
              <JobDetail
                icon={<MessageSquare size={16} strokeWidth={3} />}
                text1='Kommentar till chaufför:'
                childrenOnNewLine={true}
              >
                <p
                  style={{
                    backgroundColor: 'var(--secondary-background-color)',
                    padding: '10px',
                    border: '1px solid var(--light-border-color)',
                    borderRadius: '5px',
                    width: '100%',
                    fontSize: '14px',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {details.otherInformation}
                </p>
              </JobDetail>
            )}

            <div style={{ marginBottom: '7px' }}></div>

            {details.images && details.images.length > 0 && (
              <JobDetail
                text1='Bilder:'
                childrenOnNewLine={true}
                icon={<Image size={16} strokeWidth={3} />}
              >
                <ul id='images__ul'>
                  {details.images.map((image, idx) => {
                    return (
                      <li className='images__li' key={idx} onClick={() => showImagePopup(image)}>
                        <img className='img' src={image} alt='' />
                      </li>
                    );
                  })}
                </ul>
              </JobDetail>
            )}
          </section>
          {details.subcontractor && (
            <>
              <JobDetailsDivider doNotShowLine={true} />

              <section className='details-section'>
                <JobDetail
                  icon={<User size={16} strokeWidth={3} />}
                  text1='Underleverantör:'
                  text2={details.subcontractor.name}
                />
                {details.contactSubcontractor && (
                  <JobDetail icon={<Phone size={16} strokeWidth={3} />} text1='Kontakt:'>
                    <div>
                      {details.contactSubcontractor.name}
                      {`, `}
                      <a href={`tel:${formatPhoneNumber(details.contactSubcontractor.phone)}`}>
                        {details.contactSubcontractor.phone}
                      </a>
                    </div>
                  </JobDetail>
                )}
              </section>
            </>
          )}

          <JobDetailsDivider />

          <section className='details-section'>
            {/* Signerad av kund */}
            <JobDetail icon={<PenLine size={16} strokeWidth={3} />} text1='Signerad av kund:'>
              <p className={details.signed ? 'signed' : 'not-signed'}>
                {details.signed ? 'Ja' : 'Nej'}
              </p>
            </JobDetail>

            {/* Fordon Tjänst (artikel) */}
            {details.vehicle?.service && (
              <JobDetail text1='Fordon Tjänst (artikel):'>
                <p>{details.vehicle.service + ', (' + details.vehicle.serviceId + ')'}</p>
              </JobDetail>
            )}

            {/* Admin kommentarer */}
            {user?.userType === UserType.ADMIN && (
              <div className='details__info'>
                <Textarea
                  id='admin-comments'
                  setState={setAdminComments}
                  placeholder='Kommentar till kontoret'
                  value={adminComments}
                  label='Kommentar till kontoret'
                />
                <button
                  onClick={saveAdminComment}
                  className='button--green'
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  Spara
                </button>
              </div>
            )}
          </section>

          <JobDetailsDivider doNotShowLine={details.reports?.length ? false : true} />

          {details.reports && (
            <>
              <ReportSummary job={details} showSensitiveData={true} />
              <JobDetailsDivider doNotShowLine={true} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default JobDetails;
