import { useContext } from 'react';
import ConfirmAction from '../../components/confirm_action';
import Table, { TableRow } from '../../components/table/Table';
import { PopupContext } from '../../contexts/PopupContext';
import { JobType } from '../../utils/constants';
import { ReportRowData } from '../../utils/types';
import EditRow from './EditRow';

import './ReportRowsTable.css';
import { DragControls } from 'framer-motion';

// These are also used in the PDF renderer
export type FromTo = {
  from: string;
  to: string;
};

export type Godsslag = {
  godsslag: string;
};

type ReportRowsTableProps = {
  onChangeRows?: (rows: ReportRowData[]) => void;
  enableEdit?: boolean;
  rows: ReportRowData[];
  jobType: JobType;
};

export default function ReportRowsTable({
  onChangeRows = () => {
    console.log('function not implemented');
  },
  rows,
  enableEdit,
  jobType,
}: ReportRowsTableProps) {
  const { showPopup } = useContext(PopupContext);

  function openEditRow(idx: number) {
    showPopup(
      <EditRow onEdit={onEditRow} row={rows[idx]} rowIdx={idx} jobType={jobType} />,
      'medium',
    );
  }

  function deleteRow(idx: number) {
    const _rows = [...rows];
    _rows.splice(idx, 1);
    onChangeRows(_rows);
  }

  function onEditRow(row: ReportRowData, idx: number) {
    const _rows = [...rows];
    _rows[idx] = row;
    onChangeRows(_rows);
  }

  function copyRow(idx: number) {
    const _rows = [...rows];
    const copy = _rows[idx];
    _rows.push(copy);
    onChangeRows(_rows);
  }

  function confirmDelete(idx: number) {
    showPopup(
      <ConfirmAction
        confirm={() => {
          deleteRow(idx);
        }}
        heading='Är du säker på det här?'
        message='Raden går inte att återställa.'
      />,
      'small',
    );
  }

  /**
   * Creates the rows in the table
   * @param rows The rows data to create table rows from
   * @returns The table rows
   */
  function getReportRows(rows: ReportRowData[]): TableRow[] {
    const tableRows: TableRow[] = [];

    for (const row of rows) {
      tableRows.push(...getReportRow(row));
    }

    return tableRows;
  }

  /**
   * Creates report rows for ONE ReportRowData to display in the table with columns "Till" "Från" and "Godsslag".
   * If there are more than one added material and/or godsslag, one row for each material/godsslag is created with the same values for "Till" and "Från".
   * @param r
   * @returns a TableRow[] with values for the columns
   */
  function getReportRow(r: ReportRowData): TableRow[] {
    const tableRows: TableRow[] = [];
    let fromColumn = '';
    let toColumn = '';

    if (r.from) {
      fromColumn = r.from.name ? r.from.name : '';
      fromColumn += r.from.address ? ' ' + r.from.address : '';
    } else if (r.fromLocation) { // new addition
      fromColumn = r.fromLocation;
    } else {
      fromColumn = '-';
    }

    if (r.to) {
      toColumn = r.to.name ? r.to.name : '';
      toColumn += r.to.address ? ' ' + r.to.address : '';
    } else if (r.toLocation) { // new addition
      toColumn = r.toLocation;
    } else {
      toColumn = '-';
    }

    // const fromColumn = r.from
    //   ? (r.from.name ? r.from.name : '') + ' ' + (r.from.address ? r.from.address : '')
    //   : '-';
    // const toColumn = r.to
    //   ? (r.to.name ? r.to.name : '') + ' ' + (r.to.address ? r.to.address : '')
    //   : '-';

    // No materials added: only add information about "Till" and "Från"
    if (r.materials.length === 0 && !r.otherMaterials) {
      tableRows.push({
        data: ['', fromColumn, toColumn],
      });
      // Materials are added: add a row for each material/godsslag
    } else {
      r.materials.forEach((m) => {
        tableRows.push({
          data: [
            `${m.material.description} ${m.howMuch[0].qty} ${m.howMuch[0].unit}/${m.howMuch[1].qty} ${m.howMuch[1].unit}`,
            fromColumn,
            toColumn,
          ],
        });
      });

      if (r.otherMaterials) {
        tableRows.push({
          data: [`${r.otherMaterials}`, fromColumn, toColumn],
        });
      }
    }

    return tableRows;
  }

  return (
    <div className='report-rows'>
      <div className='report-rows-header'>
        <span>Godsslag</span>
        <span style={{paddingLeft: 10}}>Från</span>
        <span style={{paddingLeft: 10}}>Till</span>
      </div>

      <div style={{overflowX: 'auto', width: '100%'}}>
        {getReportRows(rows).map((row, idx) => (
          <div className='report-rows-col' key={idx}>
            <div className='report-rows-row' key={idx} >
              <div className='report-rows-content'>
                <span>{row.data[0]}</span>
                <span style={{paddingLeft: 10}}>{row.data[1]}</span>
                <span style={{paddingLeft: 10}}>{row.data[2]}</span>
              </div>
            </div>

            {enableEdit && (
              <div className='report-rows-controls'>
                  <button onClick={() => openEditRow(idx)}>
                    <i className='fa fa-edit'/>
                  </button>
                  <button onClick={() => confirmDelete(idx)}>
                    <i className='fa fa-trash'/>
                  </button>
                  <button onClick={() => copyRow(idx)}>
                    <i className='fa fa-copy'/>
                  </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
