import React, { useState } from 'react';
import { getDateWithTimeZero, monthsShort } from '../../../utils/time';
import './Monthpicker.css';

interface MonthpickerProps {
  currentDate: number; // first date of month
  onChange: (date: number) => void;
}

function Monthpicker({ currentDate, onChange }: MonthpickerProps) {
  const [shownYear, setShownYear] = useState<number>(new Date(currentDate).getFullYear());
  const [rerender, setRerender] = useState<number>(0);

  function selectMonth(month: number) {
    const newDate = new Date(currentDate);

    newDate.setFullYear(shownYear);
    newDate.setMonth(month);
    newDate.setDate(1);

    onChange(getDateWithTimeZero(newDate.getTime()));
  }

  function nextYear() {
    setShownYear(shownYear + 1);
    setRerender(rerender + 1);
  }

  function prevYear() {
    setShownYear(shownYear - 1);
    setRerender(rerender + 1);
  }

  return (
    <section id='monthpicker'>
      <header id='monthpicker__controls'>
        <p>{shownYear}</p>
        <button className='fa fa-angle-double-left button--icon' onClick={prevYear}></button>
        <button className='fa fa-angle-double-right button--icon' onClick={nextYear}></button>
      </header>
      <ul id='monthpicker__months' key={rerender} className={rerender !== 0 ? 'fade-in' : ''}>
        {monthsShort.map((month, idx) => {
          return (
            <li key={`${month}${idx}`}>
              <button
                className={
                  idx === new Date(currentDate).getMonth() ? 'monthpicker__months__selected' : ''
                }
                onClick={() => {
                  selectMonth(idx);
                }}
              >
                {month}
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default Monthpicker;
