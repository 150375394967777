import { SyntheticEvent, useContext, useState } from 'react';
import { NotificationContext } from '../../contexts/NotificationContext';
import { JobStatus, NotificationStatus, vehicleTypeLabel } from '../../utils/constants';
import { copyJob } from '../../firebase/firestore_functions/job';
import { copySlinga } from '../../firebase/firestore_functions/slinga';
import { getHoursElapsed } from '../../utils/time';
import { DaysWorkload, JobDataPartial, SlingaData, SlingaDataPartial } from '../../utils/types';
import { stopPropagation } from '../../utils/uiHelpers';

import './DayView.css';
import { hasAdminPrivileges } from '../../utils/otherHelpers';
import { AuthContext } from '../../contexts/AuthContext';
import { getJobHours, getLittraText } from '../../utils/jobUtils';
import { jobStatusToStyling } from '../../utils/styling';

interface DayViewProps {
  dayWithJobs: DaysWorkload;
  onClickJob: (docid: string, clickedDate: number) => void;
  onClickSlinga: (id: string) => void;
  loading: boolean;
}

function DayView({ onClickJob, onClickSlinga, loading, dayWithJobs }: DayViewProps) {
  const dateMillis = parseInt(Object.keys(dayWithJobs)[0]);
  const { notify } = useContext(NotificationContext);
  const { user } = useContext(AuthContext);

  const [loadingCopy, setLoadingCopy] = useState('');

  if (Object.values(dayWithJobs).length === 0 && !loading) {
    return <p id='no-jobs'>Inga jobb idag</p>;
  }

  async function copy(job: JobDataPartial & SlingaDataPartial) {
    setLoadingCopy(job.docId);

    const response = job.sNumber ? await copySlinga(job.docId) : await copyJob(job.docId);

    switch (response.code) {
      case 201:
        notify('Jobb kopierat', NotificationStatus.SUCCESS);
        break;
      case 404:
        notify('Jobbet som skulle kopieras kunde inte hittas', NotificationStatus.ERROR);
        break;
      case 500:
        notify('Jobbet kunde inte kopieras', NotificationStatus.ERROR);
        break;
    }

    setLoadingCopy('');
  }

  return (
    <ul id='jobs'>
      {Object.values(dayWithJobs)[0] &&
        Object.values(dayWithJobs)[0].map((job: JobDataPartial & SlingaDataPartial) => {
          return (
            <li
              onClick={() => {
                if (job.sNumber !== undefined) {
                  onClickSlinga(job.docId);
                } else if (job.slinga) {
                  onClickSlinga(job.slinga.id);
                } else {
                  onClickJob(job.docId, dateMillis);
                }
              }}
              className={`${jobStatusToStyling(job)} jobs__job job`}
              key={`${job.orderNum}`}
            >
              {hasAdminPrivileges(user) && (
                <div className='job-info'>
                  <button
                    onClick={(event: SyntheticEvent) => {
                      stopPropagation(event);
                      copy(job);
                    }}
                    className={
                      loadingCopy === job.docId
                        ? 'value fa fa-circle-o-notch fa-spin button--icon button--copy'
                        : 'value fa fa-copy button--icon button--copy'
                    }
                  />
                </div>
              )}

              <div className='job-info'>
                <p className='category'>Littra</p>
                <p className='value'>{job && getLittraText(job)}</p>
              </div>

              <div className='job-info'>
                <p className='category'>Kund</p>
                <p className='value'>{job.client?.name}</p>
              </div>
              <div className='job-info'>
                <p className='category'>Kontakt</p>
                <p className='value'>
                  {job.contactClient?.name} {job.contactClient?.phone}
                </p>
              </div>

              <div className='job-info'>
                <p className='category'>Fordon</p>
                <p className='value'>
                  {`${
                    job?.vehicle
                      ? vehicleTypeLabel[job.vehicle.vehicleType] + ' ' + job.vehicle.ref.id
                      : job.vehicle?.ref.id
                  } `}
                  {/* we want to keep one space after the template string */}
                </p>
              </div>

              <div className='job-info'>
                <p className='category'>Förare</p>
                <p className='value'>{job.driver?.firstName}</p>
              </div>
              <div className='job-info'>
                <p className='category'>Total tid (timmar)</p>
                <p className='value'>{getJobHours(job, dateMillis) || '-'}</p>
              </div>
            </li>
          );
        })}
    </ul>
  );
}

export default DayView;
