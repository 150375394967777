import { useContext, useState } from 'react';
import { Vehicle } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import VehicleForm from '../forms/VehicleForm';
import { stopPropagation } from '../utils/uiHelpers';
import { addVehicle, toVehicleDoc } from '../firebase/firestore_functions/vehicles';

export default function AddVehicle() {
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);
  const [saving, setSaving] = useState<boolean>(false);

  async function handleSubmit(vehicle: Vehicle) {
    // setSaving(true);
    const vehicleDoc = toVehicleDoc(vehicle);
    if (!vehicleDoc.driver) {
      notify('Var vänlig välj en förare', NotificationStatus.INFO);
    }
    const addResponse = await addVehicle(vehicleDoc);
    setSaving(false);
    if (addResponse.code === 201) {
      notify('Fordon tillagt', NotificationStatus.SUCCESS);
      close();
    } else {
      notify(`Kunde inte spara fordon: ${addResponse.error}`, NotificationStatus.ERROR);
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Lägg till en nytt fordon</h2>
      </header>
      <main>
        <VehicleForm handleSubmit={handleSubmit} saving={saving} />
      </main>
    </section>
  );
}
