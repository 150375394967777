import { FirebaseError } from 'firebase/app';
import { addDoc, collection, deleteDoc, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { SubcontractorDoc, UpdateSubcontractorParams } from '../../utils/types';

const db = getFirestore();

/**
 * @param subcontractor
 * @returns Promise<FirebaseResponse>
 */
export async function addSubcontractor(subcontractor: SubcontractorDoc) {
  try {
    await addDoc(collection(db, 'subcontractors'), subcontractor);
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 *
 * @param updates UpdateClientParams
 * @param docId id of document to update
 * @returns Promise<FirebaseReponse>
 */
export async function updateSubcontractor(updates: UpdateSubcontractorParams, docId: string) {
  try {
    await updateDoc(doc(db, `subcontractors/${docId}`), updates as { [x: string]: any });
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * @param docId id of document to delete
 * @returns Promise<FirebaseReponse>
 */
export async function deleteSubcontractor(docId: string) {
  try {
    await deleteDoc(doc(db, `subcontractors/${docId}`));
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}
