import { useContext, useEffect, useState } from 'react';
import { PdfJobsContext } from '../../contexts/PdfJobsContext';
import './ShowPdfs.css';
import PdfButton from '../../components/pdf/PdfButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { getJobs } from '../../firebase/firestore_functions/job';
import { JobData } from '../../utils/types';
import { NotificationStatus } from '../../utils/constants';
import { NotificationContext } from '../../contexts/NotificationContext';

/**
 * Component where user can choose to create a PDF document based on selected jobs in the calendar.
 * @returns
 */
function ShowPdfs() {
  const [loading, setLoading] = useState(true);
  const { jobs: pdfJobs } = useContext(PdfJobsContext);
  const { notify } = useContext(NotificationContext);

  const [includeLogo, setIncludeLogo] = useState(true);
  const [includePictures, setIncludePictures] = useState(false);
  const [jobs, setJobs] = useState<JobData[]>([]);

  useEffect(() => {
    async function initJobs() {
      const jobsResponse = await getJobs(Array.from(pdfJobs));

      if (jobsResponse.code === 200) {
        setJobs(jobsResponse.data);
      } else {
        notify('Kunde inte hämta uppdragen', NotificationStatus.ERROR);
      }
      setLoading(false);
    }
    initJobs();
  }, []);

  return (
    <section id='show-pdfs'>
      {loading ? (
        <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
      ) : (
        <>
          <header>
            <h2>{`Få ut ${jobs.length} PDF`}</h2>
          </header>
          <main>
            <section id='checkboxes'>
              {/* Include logga checkbox */}
              <div>
                <label>Inkludera logga?</label>
                <input
                  type='checkbox'
                  className='checkbox'
                  checked={includeLogo}
                  onChange={() => setIncludeLogo(!includeLogo)}
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                />
              </div>
              {/* Include pictures checkbox */}
              <div>
                <label>Inkludera bilder?</label>
                <input
                  type='checkbox'
                  className='checkbox'
                  checked={includePictures}
                  onChange={() => setIncludePictures(!includePictures)}
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                />
              </div>
            </section>
            <PdfButton jobs={jobs} includeLogo={includeLogo} includePictures={includePictures}>
              Öppna PDF
            </PdfButton>
          </main>
        </>
      )}
    </section>
  );
}

export default ShowPdfs;
