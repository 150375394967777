import React from 'react';

interface Props {
  icon?: React.ReactElement;
  text1: string;
  text2?: string;
  children?: any;
  childrenOnNewLine?: boolean;
}

export default function JobDetail(props: Props) {
  return (
    <>
    <div className='job-detail'>
      {props.icon && <div style={{ marginRight: '4px' }}>{props.icon}</div>}
      <p style={{ fontWeight: '600', fontSize: '14px', marginRight: '10px' }}>{props.text1}</p>
      {props.text2 && <p style={{ fontSize: '14px' }}>{props.text2}</p>}
      {!props.childrenOnNewLine && props.children}
    </div>
    {props.childrenOnNewLine && props.children}
    </>
  );
}
