import { useContext, useState } from 'react';
import { NotificationStatus } from '../utils/constants';
import { NotificationContext } from '../contexts/NotificationContext';
import { PopupContext } from '../contexts/PopupContext';
import { stopPropagation } from '../utils/uiHelpers';
import UserForm from '../forms/UserForm';
import { User, UserDoc } from '../utils/types';
import { updateUser } from '../firebase/firestore_functions/users';

interface EditUserProps {
  user: User;
}

export default function EditUser({ user }: EditUserProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  function getChanges(editedUser: UserDoc) {
    const changes: any = {};

    if (editedUser.lastName !== user.lastName) {
      changes.lastName = editedUser.lastName;
    }

    if (editedUser.firstName !== user.firstName) {
      changes.firstName = editedUser.firstName;
    }

    if (editedUser.userType !== user.userType) {
      changes.userType = editedUser.userType;
    }

    if (editedUser.phone !== user.phone) {
      changes.phone = editedUser.phone;
    }

    return changes;
  }

  async function handleSubmit(editedUser: UserDoc) {
    const changes = getChanges(editedUser);

    if (Object.keys(changes).length > 0) {
      setSaving(true);

      setSaving(true);
      const updateResponse = await updateUser(changes, user.docId);
      setSaving(false);
      if (updateResponse.code === 201) {
        notify('Användare uppdaterat', NotificationStatus.SUCCESS);
        close();
      } else {
        notify(
          `Kunde inte uppdatera användaren: ${updateResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  return (
    <section id='add-user' onClick={stopPropagation}>
      <header id='add-user__header'>
        <h2>Redigera person</h2>
      </header>
      <main>
        <UserForm handleSubmit={handleSubmit} saving={saving} user={user} />
      </main>
    </section>
  );
}
