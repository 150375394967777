import { useContext, useState } from 'react';
import { Subcontractor, SubcontractorData } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import ClientForm from '../forms/ClientForm';
import { stopPropagation } from '../utils/uiHelpers';
import { PopupContext } from '../contexts/PopupContext';
import { updateSubcontractor } from '../firebase/firestore_functions/subcontractors';

interface EditSubcontractorProps {
  subcontractor: SubcontractorData;
}

export default function EditClient({ subcontractor }: EditSubcontractorProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  function getChanges(edited: Subcontractor) {
    const changes: any = {};

    if (subcontractor.name !== edited.name) {
      changes.name = edited.name;
    }

    if (subcontractor.phone !== edited.phone) {
      changes.phone = edited.phone;
    }

    if (subcontractor.email !== edited.email) {
      changes.email = edited.email;
    }

    return changes;
  }

  async function handleSubmit(edited: Subcontractor) {
    const changes = getChanges(edited);

    if (Object.keys(changes).length !== 0) {
      const updateResponse = await updateSubcontractor(changes, subcontractor.docId);

      setSaving(false);
      if (updateResponse.code === 201) {
        close();
        notify('Underleverantör uppdaterad', NotificationStatus.SUCCESS);
      } else {
        notify(`Kunde inte uppdatera kund: ${updateResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Redigera underleverantör</h2>
      </header>
      <main>
        <ClientForm
          handleSubmit={handleSubmit}
          saving={saving}
          name={subcontractor.name}
          phone={subcontractor.phone}
          email={subcontractor.email}
          orgNum={subcontractor.orgNum}
        />
      </main>
    </section>
  );
}
