import React, { useContext, useState } from 'react';
import './CalenderModeButtonGroup.css';
import { MobileStateContext } from '../../contexts/MobileContext';
import { CalendarModeContext } from '../../contexts/CalendarModeContext';
import { CalendarMode, calenderModeLabels } from '../../utils/constants';

interface CalenderModeButtonGroupProps {
  closePicker: () => void;
}

export default function CalenderModeButtonGroup({ closePicker }: CalenderModeButtonGroupProps) {
  const { isMobile } = useContext(MobileStateContext);
  const {
    calendarMode,
    onChangeView,
  } = useContext(CalendarModeContext);
  const NUM_OF_DAYS_LOOK_AHEAD = 50;
  const [activeButton, setActiveButton] = useState<CalendarMode>(calendarMode);

  const handleButtonClick = (mode: CalendarMode) => {
    setActiveButton(mode);
    onChangeView(mode.toString(), NUM_OF_DAYS_LOOK_AHEAD);
    closePicker();
  }

  return (
    <div className='flex-container'>
      <div className='calender-mode-button-group'>
        <button
          style={{
            height: isMobile ? 30 : 50,
            backgroundColor: activeButton === CalendarMode.DAY ? 'var(--primary-color)' : 'white',
            color: activeButton === CalendarMode.DAY ? 'white' : 'var(--primary-color)',
          }}
          onClick={() => handleButtonClick(CalendarMode.DAY)}
        >
          {calenderModeLabels[CalendarMode.DAY]}
        </button>
        <button
          style={{
            height: isMobile ? 30 : 50,
            backgroundColor: activeButton === CalendarMode.WEEK ? 'var(--primary-color)' : 'white',
            color: activeButton === CalendarMode.WEEK ? 'white' : 'var(--primary-color)',
          }}
          onClick={() => handleButtonClick(CalendarMode.WEEK)}
        >
          {calenderModeLabels[CalendarMode.WEEK]}
        </button>
        {/*<button>Period</button> will be added later*/}
      </div>
    </div>
  );
}
