import React, { useEffect, useState } from 'react';
import './PlusButton.css';

interface PlusButtonProps {
  openAddJob: () => void;
  openAddSlinga: () => void;
}

export default function PlusButton({ openAddJob, openAddSlinga }: PlusButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <div className='overlay' onClick={() => setOpen(false)}>
          <div className='plus-button-container'>
            <button onClick={openAddJob}>Uppdrag</button>
            <button onClick={openAddSlinga}>Slinga</button>
          </div>
        </div>
      )}
      <button
        className={`button--plus ${open ? 'button--plus-focused' : ''}`}
        onClick={() => setOpen((prev) => !prev)}
      >
        +
      </button>
    </>
  );
}
