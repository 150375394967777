import { AddEditJobProvider } from './AddEditJobContext';
import AddEditJob from './AddEditJob';
import { JobData } from '../../utils/types';

type AddEditJobMainProps = {
  job?: JobData;

  onDeleteJob?: () => void;
};

/**
 * Main component for add or edit of job. The purpose is to wrap AddEditJob in AddEditJobProvider which contains state
 * for the udpated or new job.
 *
 */
export default function AddEditJobMain({ job, onDeleteJob }: AddEditJobMainProps) {
  return (
    <AddEditJobProvider>
      <AddEditJob job={job} onDeleteJob={onDeleteJob} />
    </AddEditJobProvider>
  );
}
