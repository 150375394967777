import { getFirestore, onSnapshot } from '@firebase/firestore';
import { deleteDoc, doc } from 'firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Equipment } from '../utils/types';
import ConfirmAction from '../components/confirm_action';

import { equipmentTypeLabel, NotificationStatus } from '../utils/constants';
import { NotificationContext } from '../contexts/NotificationContext';

import { PopupContext } from '../contexts/PopupContext';
import EditEquipment from './EditEquipment';

interface EquipmentDetailsProps {
  equipment: Equipment;
}

const db = getFirestore();

export default function EquipmentDetails({ equipment }: EquipmentDetailsProps) {
  const { notify } = useContext(NotificationContext);
  const { close, showPopup } = useContext(PopupContext);
  const [equipmentInfo, setEquipmentInfo] = useState<Equipment>(equipment);

  useEffect(setListenerEquipment, []);

  function setListenerEquipment() {
    try {
      const q = doc(db, 'equipments', equipment.docId);

      onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.exists()) {
          setEquipmentInfo({
            ...(querySnapshot.data() as Equipment),
            docId: querySnapshot.id,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteEquipment() {
    try {
      await deleteDoc(doc(db, 'equipments', equipment.docId));
      notify('Utrustning raderat', NotificationStatus.SUCCESS);
      close();
    } catch (error) {
      notify(
        'Hmm kunde inte radera utrustningen, förmodligen programmerarens fel.',
        NotificationStatus.ERROR,
      );
      console.log(error);
    }
  }

  function openEditEquipment() {
    showPopup(<EditEquipment equipment={equipmentInfo} />, 'medium');
  }

  function confirmDelete(event?: SyntheticEvent) {
    event && event.stopPropagation();

    showPopup(
      <ConfirmAction
        confirm={deleteEquipment}
        heading='Är du säker på det här?'
        message='Utrustningen kommer raderas från systemet
           och går inte att återställa.'
      />,
      'small',
    );
  }

  function stopPropagation(event: SyntheticEvent) {
    event.stopPropagation();
  }

  return (
    <section className='details' onClick={stopPropagation}>
      <header className='details__header'>
        <h2>{equipmentInfo.id}</h2>
        <section className='details__header__controls'>
          <button className='fa fa-edit button--icon' onClick={openEditEquipment}></button>
          <button onClick={confirmDelete} className='fa fa-trash button--icon'></button>
        </section>
      </header>
      <main>
        <section className='details__info'>
          <div className='info'>
            <p className='label'>Kategori</p>
            <p>{equipmentTypeLabel[equipmentInfo.equipmentType]}</p>
          </div>
          <div className='info'>
            <p className='label'>Id</p>
            <p>{equipmentInfo.id}</p>
          </div>
          <div className='info'>
            <p className='label'>Artikelnummer</p>
            <p>{equipment.serviceId}</p>
          </div>
          <div className='info'>
            <p className='label'>Tjänst</p>
            <p>{equipment.service}</p>
          </div>
        </section>
      </main>
    </section>
  );
}
