import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import JobDetails from '../../popups/job_details';
import SlingaDetails from '../../popups/slinga_details/SlingaDetails';

enum JobType {
  SLINGA,
  JOB,
}

/**
 * Page that is used for the links sent to subcontractors :)
 */
export default function ExternalDriverPage() {
  const { isAuthenticated, isAnonymous } = useContext(AuthContext);

  const [jobType, setJobType] = useState<JobType>();
  const [docId, setDocId] = useState<string>('');

  const [initDone, setInitDone] = useState(false);

  const location = useLocation();

  useEffect(signIn, []);
  useEffect(extractDocIdAndJobType, [isAuthenticated, isAnonymous, location]);

  function signIn() {
    signInAnonymously(getAuth());
  }

  function extractDocIdAndJobType() {
    if (isAuthenticated && isAnonymous) {
      if (location.pathname.startsWith('/slinga')) {
        setJobType(JobType.SLINGA);
      } else {
        setJobType(JobType.JOB);
      }

      setDocId(location.pathname.split('-')[1]);
      setInitDone(true);
    }
  }

  return (
    <section style={{ padding: '1.6rem', overflow: 'auto' }}>
      {!initDone ? (
        <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
      ) : jobType === JobType.JOB ? (
        <JobDetails jobDocId={docId} />
      ) : (
        <SlingaDetails slingaDocId={docId} />
      )}
    </section>
  );
}
