import { useContext, useState } from 'react';
import { Equipment, EquipmentDoc } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import { stopPropagation } from '../utils/uiHelpers';
import EquipmentForm from '../forms/EquipmentForm';
import { updateEquipment } from '../firebase/firestore_functions/equipments';

interface EditEquipmentProps {
  equipment: Equipment;
}

export default function EditVehicle({ equipment }: EditEquipmentProps) {
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);
  const [saving, setSaving] = useState<boolean>(false);

  function getChanges(editedEquipment: EquipmentDoc) {
    const changes: any = {};

    if (equipment.equipmentType !== editedEquipment.equipmentType) {
      changes.equipmentType = editedEquipment.equipmentType;
    }
    if (equipment.id !== editedEquipment.id) {
      changes.id = editedEquipment.id;
    }

    return changes;
  }

  async function handleSubmit(editedEquipment: EquipmentDoc) {
    const changes = getChanges(editedEquipment);

    if (Object.keys(changes).length > 0) {
      setSaving(true);
      const updateResponse = await updateEquipment(changes, equipment.docId);
      setSaving(false);
      if (updateResponse.code === 201) {
        notify('Utrustning uppdaterat', NotificationStatus.SUCCESS);
        close();
      } else {
        notify(
          `Kunde inte uppdatera utrustningen: ${updateResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Redigera fordonsutrustning</h2>
      </header>
      <main>
        <EquipmentForm handleSubmit={handleSubmit} saving={saving} equipment={equipment} />
      </main>
    </section>
  );
}
