import { FirebaseError } from 'firebase/app';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const storage = getStorage();

/**
 * Saves image to specified path in firebase storage
 * @param path
 */
export async function saveImageStorage(path: string, blob: Blob) {
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, blob);

    return { code: 201 };
  } catch (e) {
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 * Get the image url for specified path
 * @param path
 */
export async function getImageUrl(path: string) {
  try {
    const storageRef = ref(storage, path);

    const url = await getDownloadURL(storageRef);
    return { data: url, code: 200 };
  } catch (e) {
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 * Delete image from storage with specified url
 * @param url
 */
export async function deleteImageStorage(url: string) {
  try {
    await deleteObject(ref(storage, url));
    return {
      code: 200,
    };
  } catch (e) {
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}
