import React from 'react';

interface Props {
  doNotShowLine?: boolean;
}

export default function JobDetailsDivider(props: Props) {
  const style = props.doNotShowLine ? { opacity: 0 } : {};

  return <div className='job-details-divider' style={style}></div>;
}
