import { getHoursElapsed, toDayDateString, toTimeDateStringVariant } from '../../utils/time';
import './ReportSummary.css';
import { JobData } from '../../utils/types';
import ReportRowsTable from './ReportRowsTable';
import { JobType } from '../../utils/constants';

interface ReportSummaryProps {
  job: JobData;
  onlyShowDate?: any;
  showSensitiveData: boolean;
}

/**
 * Component that shows all the reports for every day in a job.
 * @param job JobData
 * @returns JSX.Element
 */
export default function ReportSummary({
  job,
  onlyShowDate,
  showSensitiveData,
}: ReportSummaryProps) {
  //Make a sorted copy of the reports array
  if (job.reports && job.reports.length > 0) {
    job.reports.sort((a, b) => {
      return a.start < b.start ? -1 : 1;
    });
  }

  return (
    <section className='details-section'>
      <h1 className='h1-job-details'>Klara dagsrapporter</h1>

      {job.reports && job.reports.length && (
        <>
          {job.reports.map((report, idx) => {
            const hoursElapsed = getHoursElapsed(report.start, report.end) - report.breaktime / 60;
            const hoursElapsedRounded = Math.round(hoursElapsed * 100) / 100;

            return (
              <div key={idx} className='daily-report-container'>
                <h2 className='h2-job-details' style={{ marginBottom: '20px', fontSize: '16px' }}>
                  {toDayDateString(report.start)}
                </h2>

                <p className='report-summary-text'>
                  Start: <span>{toTimeDateStringVariant(report.start, true)}</span>
                </p>
                <p className='report-summary-text'>
                  Slut: <span>{toTimeDateStringVariant(report.end, true)}</span>
                </p>
                <p className='report-summary-text'>
                  Timmar: <span>{hoursElapsedRounded}</span>
                </p>
                <p className='report-summary-text'>
                  Mil: <span>{report.miles}</span>
                </p>
                {report.other.length > 0 && (
                  <>
                    <p className='report-summary-text'>Kommentar från förare: </p>
                    <p className='report-summary-text' style={{ marginBottom: '20px' }}>
                      <span style={{ paddingLeft: '0' }}>{report.other}</span>
                    </p>
                  </>
                )}

                {showSensitiveData && report.commentToAdmin.length > 0 && (
                  <>
                    <p className='report-summary-text'>Kommentar till kontoret: </p>
                    <p className='report-summary-text' style={{ marginBottom: '20px' }}>
                      <span style={{ paddingLeft: '0' }}>{report.commentToAdmin}</span>
                    </p>
                  </>
                )}

                {job.jobType !== JobType.EXCAVATOR_MAN && (
                  <>
                    <p className='report-summary-text'>Rader:</p>
                    <ReportRowsTable rows={report.rows} jobType={job.jobType} enableEdit={false} />
                  </>
                )}

                <p className='report-summary-text' style={{ marginTop: '20px' }}>
                  Bifogade bilder & kvitton
                </p>
                {report.images.length > 0 ? (
                  <ul id='images__ul'>
                    {report.images.map((image, idx) => {
                      return (
                        <li className='images__li' key={idx}>
                          <img className='img' src={image} alt='' />
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p style={{ fontSize: 14 }}>Inga bilder tillagda</p>
                )}
              </div>
            );
          })}
        </>
      )}
    </section>
  );
}
