import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import Timepicker from '../../components/timepicker/Timepicker';
import { getDateWithTimeZero, getEndOfDay, incrementDay, toDayDateString } from '../../utils/time';
import { JobData, JobReportData, ReportRowData } from '../../utils/types';
import './ReportJob.css';
import { JobType, NotificationStatus } from '../../utils/constants';
import { NotificationContext } from '../../contexts/NotificationContext';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { PopupContext } from '../../contexts/PopupContext';
import AddRow from './AddRow';
import { deleteImageStorage, getImageUrl, saveImageStorage } from '../../firebase/storageFunctions';
import { b64ToBlob } from '../../utils/otherHelpers';
import { v4 as uuidv4 } from 'uuid';
import { stopPropagation } from '../../utils/uiHelpers';
import { toJobReportDoc } from '../../firebase/firestore_functions/job';
import OrderNumber from '../OrderNumber';
import JobDetail from '../job_details/JobDetail';
import {
  Calendar,
  Check,
  Clock,
  Coffee,
  GitCommit,
  MessageSquare,
  Plus,
  PlusCircle,
} from 'lucide-react';
import JobDetailsDivider from '../job_details/JobDetailsDivider';
import { Select, SelectProps, ConfigProvider, Input, InputNumber } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import PopupButton from '../PopupButton';
import ReportRowsTable from './ReportRowsTable';
import AddImages from '../../components/components_for_add_edit_job_slinga/AddImages';
import './ReportJob.css';

interface ReportJobProps {
  job: JobData;
  initialDate: number;
}
interface JobReports {
  [day: number]: JobReportData;
}
const breakTimeIntervals = [
  { value: 0, label: '0 min' },
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' },
  { value: 45, label: '45 min' },
  { value: 60, label: '60 min' },
  { value: 75, label: '75 min' },
  { value: 90, label: '90 min' },
];

const db = getFirestore();
export default function ReportJob({ job, initialDate }: ReportJobProps) {
  const { notify } = useContext(NotificationContext);
  const { showPopup } = useContext(PopupContext);

  //////////////////////////////////////////
  // Fields in report that can be updated //
  /////////////////////////////////////////

  const [dateFrom, setDateFrom] = useState<number>(new Date(job.start).getTime());
  const [dateTo, setDateTo] = useState<number>(new Date(job.end).getTime());
  const [breaktime, setBreaktime] = useState<number>(0); // minutes
  const [totalTime, setTotalTime] = useState<string>('0'); // hours;
  const [miles, setMiles] = useState<string>('0');
  const [other, setOther] = useState<string>('');
  const [commentToAdmin, setCommentToAdmin] = useState<string>('');
  const [rows, setRows] = useState<ReportRowData[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  const { TextArea } = Input;

  //////////////////////////
  // State to change day //
  ////////////////////////

  const [days, setDays] = useState<Array<number>>();
  const [currentDay, setCurrentDay] = useState<number>(getDateWithTimeZero(initialDate));
  const [optionDays, setOptionDays] = useState<SelectProps['options']>();
  const [totalTimeInputEmpty, setTotalTimeInputEmpty] = useState<boolean>(false);

  const [reports, setReports] = useState<JobReports>({});

  useEffect(init, []);

  function init() {
    const days = initDays();
    const reports = initReports();

    const _optionDays: SelectProps['options'] = [];

    for (const day of days) {
      _optionDays.push({
        value: day.toString(),
        label: toDayDateString(day),
      });
    }

    setOptionDays(_optionDays);

    const day = initialDate !== undefined ? initialDate : days[0];

    setCurrentDay(day);
    _onChangeDay(day.toString(), reports, true);
  }

  ////////////
  // inits //
  //////////

  function initReports() {
    if (job.reports) {
      const _reports: JobReports = {};
      for (const report of job.reports) {
        _reports[getDateWithTimeZero(report.start)] = report;
      }

      setReports(_reports);
      return _reports;
    } else {
      return {};
    }
  }

  /**
   * Creates array of all the days in the job so that driver can choose day to report
   */
  function initDays() {
    let currentDay = getDateWithTimeZero(job.start);
    const endDay = getDateWithTimeZero(job.end);

    const _days: Array<number> = [];

    while (currentDay <= endDay) {
      _days.push(currentDay);
      currentDay = getDateWithTimeZero(incrementDay(currentDay));
    }

    setDays(Array.from(_days));
    return _days;
  }

  /////////////////////////
  // on change handlers //
  ///////////////////////

  function onChangeHoursFrom(hours: number) {
    const _date = new Date(dateFrom);
    _date.setHours(hours);
    setDateFrom(_date.getTime());

    const _totalTime = dateTo - _date.getTime() - breaktime * 60000;
    setTotalTime((_totalTime / 3600000).toFixed(2));
  }

  function onChangeMinutesFrom(minutes: number) {
    const _date = new Date(dateFrom);
    _date.setMinutes(minutes);
    setDateFrom(_date.getTime());

    const _totalTime = dateTo - _date.getTime() - breaktime * 60000;
    setTotalTime((_totalTime / 3600000).toFixed(2));
  }

  function onChangeHoursTo(hours: number) {
    const _date = new Date(dateTo);
    _date.setHours(hours);
    setDateTo(_date.getTime());

    const _totalTime = _date.getTime() - dateFrom - breaktime * 60000;
    setTotalTime((_totalTime / 3600000).toFixed(2));
  }

  function onChangeMinutesTo(minutes: number) {
    const _date = new Date(dateTo);
    _date.setMinutes(minutes);
    setDateTo(_date.getTime());

    const _totalTime = _date.getTime() - dateFrom - breaktime * 60000;
    setTotalTime((_totalTime / 3600000).toFixed(2));
  }

  function onChangeBreaktime(value: string) {
    const _breaktime = parseInt(value);
    setBreaktime(_breaktime);

    const _totalTime = dateTo - dateFrom - _breaktime * 60000;
    setTotalTime((_totalTime / 3600000).toFixed(2));
  }

  function onChangeTotalTime(event: SyntheticEvent) {
    let newTotalTime = (event.target as HTMLInputElement).value;

    // Replace ',' with '.' to ensure a valid decimal separator
    newTotalTime = newTotalTime.replace(/,/g, '.');

    // Remove non-numeric and non-decimal characters
    newTotalTime = newTotalTime.replace(/[^0-9.]/g, '');

    // Ensure there's only one decimal point
    newTotalTime = newTotalTime.replace(/(\..*)\./g, '$1');

    if (newTotalTime === '') {
      setTotalTimeInputEmpty(true);
    } else {
      setTotalTimeInputEmpty(false);
    }

    setTotalTime(newTotalTime);

    // change dateTo so that it syncs with total time
    const _dateTo = dateFrom + breaktime * 60000 + parseFloat(newTotalTime) * 3600000;
    setDateTo(_dateTo);
  }

  function onChangeMiles(event: SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;
    setMiles(value);
  }

  function onAddRow(row: ReportRowData) {
    const _rows = [...rows];
    _rows.push(row);
    setRows(_rows);
  }

  function openAddRow() {
    if (currentDay) {
      showPopup(<AddRow onAdd={onAddRow} job={job} />, 'medium');
    }
  }

  function onChangeRows(rows: ReportRowData[]) {
    setRows(rows);
  }

  /**
   *
   * Everything is saved in db when user presses save button, before that it is only saved locally.
   */
  function saveCurrentDay() {
    if (currentDay) {
      const _reports = { ...reports };
      _reports[currentDay] = {
        start: dateFrom,
        end: dateTo,
        breaktime: breaktime,
        miles: parseInt(miles),
        rows,
        other,
        commentToAdmin,
        images,
      };

      setReports(_reports);
      return _reports;
    } else {
      return reports;
    }
  }

  function onChangeDay(day: string) {
    _onChangeDay(day, reports);
  }

  function _onChangeDay(day: string, reports: JobReports, init = false) {
    let _reports = { ...reports };
    const dayMillis = parseInt(day);

    // save report for currentday

    if (!init) {
      _reports = saveCurrentDay();
    }

    // create report if the chosen day has none yet

    if (!_reports[dayMillis]) {
      const start = new Date(dayMillis);
      start.setHours(7);
      start.setMinutes(0);
      _reports[dayMillis] = {
        start: start.getTime(),
        end: getEndOfDay(start.getTime()),
        breaktime: 0,
        rows: [],
        other: '',
        commentToAdmin: '',
        miles: 0,
        images: [],
      };
    }
    // set state variables according to new day
    const _totalTime =
      _reports[dayMillis].end - _reports[dayMillis].start - _reports[dayMillis].breaktime * 60000;

    setTotalTime((_totalTime / 3600000).toFixed(2));

    setDateFrom(_reports[dayMillis].start);
    setDateTo(_reports[dayMillis].end);

    setBreaktime(_reports[dayMillis].breaktime);
    setOther(_reports[dayMillis].other);
    setCommentToAdmin(_reports[dayMillis].commentToAdmin);
    setRows(_reports[dayMillis].rows);
    setCurrentDay(dayMillis);

    setMiles(_reports[dayMillis].miles.toString());
    setImages(_reports[dayMillis].images);
    setReports(_reports);
  }

  //////////////////////////////////
  // functions to submit changes //
  ////////////////////////////////

  async function removeDeletedImagesFromstorage() {
    const promises = [];
    for (const url of deletedImages) {
      promises.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise<void>(async (resolve, _) => {
          const deleteResponse = await deleteImageStorage(url);

          if (deleteResponse.code !== 200) {
            console.log('Error when deleteing image from storage');
            console.log(deleteResponse.code + ' ' + deleteResponse.error);
          }

          resolve();
        }),
      );
    }

    await Promise.all(promises);

    setDeletedImages([]);
  }

  /**
   * Saves any new images to storage and updates path to be the storage url
   * @returns array with updated urls
   */

  async function saveNewImages(images: string[]) {
    const promises = [];
    const _images: string[] = [];
    for (const img of images) {
      promises.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise<void>(async (resolve, _) => {
          let url = img;

          // if the images isn't already in storage (is storage url) it's saved there
          if (!img.startsWith('https')) {
            const imageId = uuidv4();
            await saveImageStorage(`/${job.orderNum}/receipts/${imageId}`, b64ToBlob(img));

            const urlResponse = await getImageUrl(`/${job.orderNum}/receipts/${imageId}`);

            if (urlResponse.data) {
              url = urlResponse.data;
            }
          }

          _images.push(url);

          resolve();
        }),
      );
    }

    await Promise.all(promises);

    return _images;
  }

  /**
   * Saves current reports state to firestore.
   * */
  async function handleSubmit() {
    setSaving(true);
    removeDeletedImagesFromstorage();

    const newImages = await saveNewImages(images);

    // update reports locally to reflect change in images.
    const _reports = { ...reports };
    _reports[currentDay] = {
      start: dateFrom,
      end: dateTo,
      breaktime: breaktime,
      miles: parseInt(miles),
      rows,
      other,
      commentToAdmin,
      images: newImages,
    };

    setReports(_reports);

    // iterate through the reports and save any newly added images to storage and convert them to document data + sort by time.

    const updatedReportsPromises = Object.values(_reports).map(async (report) => {
      const updatedImages = await saveNewImages(report.images);

      return toJobReportDoc({ ...report, images: updatedImages });
    });

    const updatedReports = await Promise.all(updatedReportsPromises);

    updatedReports.sort((reportA, reportB) => reportA.start - reportB.start);

    try {
      await updateDoc(doc(db, 'jobs', job.docId), {
        reports: updatedReports,
      });

      notify('Dagsrapport sparad', NotificationStatus.SUCCESS);
    } catch (error) {
      notify('Något gick fel, dagsrapporten kunde inte sparats', NotificationStatus.ERROR);
    } finally {
      setSaving(false);
    }
  }

  const [size] = useState<SizeType>('middle');
  const [selectBGColor, setSelectBGColor] = useState<string>('');
  const [selectBGColor2, setSelectBGColor2] = useState<string>('');
  const [selectBGColor3, setSelectBGColor3] = useState<string>('');
  const [selectBGColor4, setSelectBGColor4] = useState<string>('');

  return (
    <div className='details'>
      <section
        className='details-section'
        onClick={stopPropagation}
        style={{ marginTop: '2.5rem' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '30px',
          }}
        >
          <OrderNumber orderNumber={job.orderNum} />
        </div>

        <h1 className='h1-job-details'>Dagsrapportera</h1>

        <h2 className='h2-job-details'>{job.client?.name}</h2>

        <JobDetail text1='Dag *' icon={<Calendar size={16} strokeWidth={3} />} />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'rgba(229, 9, 127, 1)',
              colorBgBase: selectBGColor,
              colorText: selectBGColor !== '' ? 'rgba(229, 9, 127, 1)' : '',
            },
          }}
        >
          <Select
            size={size}
            defaultValue={{ value: currentDay.toString(), label: toDayDateString(currentDay) }}
            style={{ width: '100%' }}
            options={optionDays}
            onFocus={() => setSelectBGColor('rgba(255, 243, 250, 1)')}
            onBlur={() => setSelectBGColor('')}
            onChange={(value) => onChangeDay(value.toString())}
          />
        </ConfigProvider>

        <div style={{ marginBottom: '20px' }}></div>

        <JobDetail text1='Tid *' icon={<Clock size={16} strokeWidth={3} />} />
        <div className='report-job-time-row'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
            <p style={{ fontWeight: '600', fontSize: '12px' }}>Från</p>
            <Timepicker
              onChangeHours={onChangeHoursFrom}
              onChangeMinutes={onChangeMinutesFrom}
              hours={new Date(dateFrom).getHours()}
              minutes={new Date(dateFrom).getMinutes()}
            />
          </div>

          <p style={{ alignSelf: 'center', marginTop: '15px' }}>-</p>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
            <p style={{ fontWeight: '600', fontSize: '12px' }}>Till</p>
            <Timepicker
              onChangeHours={onChangeHoursTo}
              onChangeMinutes={onChangeMinutesTo}
              hours={new Date(dateTo).getHours()}
              minutes={new Date(dateTo).getMinutes()}
            />
          </div>
        </div>

        <JobDetail text1='Rast *' icon={<Coffee size={16} strokeWidth={3} />} />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'rgba(229, 9, 127, 1)',
              colorBgBase: selectBGColor2,
              colorText: selectBGColor2 !== '' ? 'rgba(229, 9, 127, 1)' : '',
            },
          }}
        >
          <Select
            size={size}
            defaultValue={{ value: 0, label: '0 min' }}
            value={{ value: breaktime, label: `${breaktime} min` }}
            style={{ width: '100%' }}
            options={breakTimeIntervals}
            onChange={(value) => onChangeBreaktime(value.toString())}
            onFocus={() => setSelectBGColor2('rgba(255, 243, 250, 1)')}
            onBlur={() => setSelectBGColor2('')}
          />
        </ConfigProvider>

        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}
        >
          <JobDetail text1='Total tid (timmar) *' icon={<Clock size={16} strokeWidth={3} />} />
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'rgba(229, 9, 127, 1)',
                colorBgBase: selectBGColor4,
              },
            }}
          >
            <Input
              defaultValue={totalTime}
              value={totalTime}
              onChange={(e) => onChangeTotalTime(e)}
              onFocus={() => setSelectBGColor4('rgba(255, 243, 250, 1)')}
              onBlur={() => setSelectBGColor4('')}
            />
            {totalTimeInputEmpty && (
              <p style={{ color: 'red', fontSize: 12 }}>Fältet får inte vara tomt</p>
            )}
          </ConfigProvider>
        </div>
      </section>

      <JobDetailsDivider />

      <section className='details-section'>
        <JobDetail text1='Antal mil *' icon={<GitCommit size={16} strokeWidth={3} />} />

        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'rgba(229, 9, 127, 1)',
              colorBgBase: selectBGColor3,
            },
          }}
        >
          <Input
            placeholder='Antal mil'
            value={miles}
            onChange={(e) => onChangeMiles(e)}
            onFocus={() => setSelectBGColor3('rgba(255, 243, 250, 1)')}
            onBlur={() => setSelectBGColor3('')}
          />
        </ConfigProvider>

        <div style={{ marginBottom: 20 }}></div>

        <JobDetail text1='Kommentar till kund' icon={<MessageSquare size={16} strokeWidth={3} />} />
        <TextArea
          placeholder='Övriga kommentarer...'
          rows={3}
          style={{ resize: 'none' }}
          value={other}
          onChange={(e) => setOther(e.target.value)}
        />

        <div style={{ marginBottom: 20 }}></div>

        <JobDetail
          text1='Kommentar till kontor'
          icon={<MessageSquare size={16} strokeWidth={3} />}
        />
        <TextArea
          placeholder='Övriga kommentarer...'
          rows={3}
          style={{ resize: 'none' }}
          value={commentToAdmin}
          onChange={(e) => setCommentToAdmin(e.target.value)}
        />
      </section>

      <JobDetailsDivider />

      {job.jobType === JobType.EXCAVATOR_MAN ? (
        <section className='details-section'>
          <AddImages
            images={images}
            setImages={setImages}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
          />
        </section>
      ) : (
        <section className='details-section'>
          <JobDetail text1='Lägg till körning' icon={<PlusCircle size={16} strokeWidth={3} />} />
          <PopupButton
            text='Ny rad'
            onClick={openAddRow}
            backgroundColor='var(--primary-color)'
            icon={<Plus size={16} strokeWidth={3} />}
          />

          <div style={{ marginBottom: 10 }}></div>

          <ReportRowsTable
            rows={rows}
            onChangeRows={onChangeRows}
            enableEdit={true}
            jobType={job.jobType}
          />

          <div style={{ marginBottom: 20 }}></div>

          <AddImages
            images={images}
            setImages={setImages}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
          />
        </section>
      )}

      <JobDetailsDivider />

      {/* Save button */}
      {!totalTimeInputEmpty && (
        <section className='details-section'>
          <PopupButton
            text='Spara'
            icon={<Check size={16} strokeWidth={3} />}
            onClick={handleSubmit}
            backgroundColor='var(--success-color)'
          />
        </section>
      )}

      <JobDetailsDivider doNotShowLine={true} />
    </div>
  );
}
