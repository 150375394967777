import React from 'react';

import { AuthProvider } from './contexts/AuthContext';
import { MobileStateProvider } from './contexts/MobileContext';
import Routes from './components/Routes';
import './App.css';
import { NotificationProvider } from './contexts/NotificationContext';
import { PopupProvider } from './contexts/PopupContext';
import { GoogleMapsProvider } from './contexts/GoogleMapsContext';
import { DataProvider } from './contexts/CommonDataContext';
import { PdfJobsProvider } from './contexts/PdfJobsContext';
import { JobsContextProvider } from './contexts/JobsContext';
import { NavigationContextProvider } from './contexts/NavigationContext';
import { CalendarModeContextProvider } from './contexts/CalendarModeContext';
import '@fontsource/open-sans'; // Defaults to weight 400
import '@fontsource/open-sans/400.css'; // Specify weight
import '@fontsource/open-sans/400-italic.css'; // Specify weight and style
import '@fontsource/open-sans/700.css'; // Specify weight
function App() {
  return (
    <AuthProvider>
      <CalendarModeContextProvider>
        <JobsContextProvider>
          <NavigationContextProvider>
            <MobileStateProvider>
              <NotificationProvider>
                <DataProvider>
                  <GoogleMapsProvider>
                    <PdfJobsProvider>
                      <PopupProvider>
                        <Routes />
                      </PopupProvider>
                    </PdfJobsProvider>
                  </GoogleMapsProvider>
                </DataProvider>
              </NotificationProvider>
            </MobileStateProvider>
          </NavigationContextProvider>
        </JobsContextProvider>
      </CalendarModeContextProvider>
    </AuthProvider>
  );
}

export default App;
