import { SyntheticEvent, useState } from 'react';
import { Client, Subcontractor } from '../utils/types';

interface ClientFormProps {
  orgNum?: string;
  name?: string;
  email?: string;
  phone?: string;
  saving: boolean;
  handleSubmit: (client: Client & Subcontractor) => Promise<void>;
}

/**
 * Component for filling in basic info about client in a form (name, phone, organisation number and email)
 * @returns JSX.Element
 */
export default function ClientForm({
  orgNum,
  name,
  email,
  phone,
  saving,
  handleSubmit,
}: ClientFormProps) {
  const [_orgNum, setOrgNum] = useState<string>(orgNum ? orgNum : '');
  const [_name, setName] = useState<string>(name ? name : '');
  const [_email, setEmail] = useState<string>(email ? email : '');
  const [_phone, setPhone] = useState<string>(phone ? phone : '');

  function onChangeOrgNum(event: SyntheticEvent) {
    setOrgNum((event.target as HTMLInputElement).value);
  }

  function onChangeName(event: SyntheticEvent) {
    setName((event.target as HTMLInputElement).value);
  }

  function onChangeEmail(event: SyntheticEvent) {
    setEmail((event.target as HTMLInputElement).value);
  }

  function onChangePhone(event: SyntheticEvent) {
    setPhone((event.target as HTMLInputElement).value);
  }

  async function _handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    handleSubmit({ email: _email, name: _name, orgNum: _orgNum, phone: _phone });
  }

  return (
    <form className='form' onSubmit={_handleSubmit}>
      <ul className='form__ul'>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={_orgNum}
            placeholder='Organisations-/personnummer'
            onChange={onChangeOrgNum}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={_name}
            placeholder='Namn'
            onChange={onChangeName}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={_email}
            placeholder='Email'
            onChange={onChangeEmail}
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={_phone}
            placeholder='Tel'
            onChange={onChangePhone}
          />
        </li>
        <li className='form__li loading-parent'>
          {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
          <input className='button--green form__input' type='submit' value='Spara' />
        </li>
      </ul>
    </form>
  );
}
