import { useState, createContext } from 'react';

/**
 * Context that stores information about which jobs and slingas are selected in the calendar to be included in a PDF.
 * The full data for these jobs are later fetched when the user chooses to generate the pdf document in the popup "ShowPdfs".
 */

interface PdfJobsContextProps {
  isIncludedInPdf: (id: string) => boolean;
  jobs: Set<string>;
  onPdfJobChange: (docId: string) => void;
}

export const PdfJobsContext = createContext<PdfJobsContextProps>({
  isIncludedInPdf: () => {
    return false;
  },
  jobs: new Set(),
  onPdfJobChange: (docId: string) => {
    console.log('onPdfJobChange not defined');
  },
});

export const PdfJobsProvider = ({ children }: { children: JSX.Element }) => {
  const [jobs, setJobs] = useState<Set<string>>(new Set());

  function onPdfJobChange(docId: string) {
    const updatedJobs = new Set(jobs);

    if (jobs.has(docId)) {
      updatedJobs.delete(docId);
    } else {
      updatedJobs.add(docId);
    }

    setJobs(updatedJobs);
  }

  function isIncludedInPdf(docId: string) {
    return jobs.has(docId);
  }

  return (
    <PdfJobsContext.Provider
      value={{
        onPdfJobChange,
        isIncludedInPdf,
        jobs,
      }}
    >
      {children}
    </PdfJobsContext.Provider>
  );
};
