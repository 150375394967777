import React, { useContext, useEffect, useState } from 'react';
import './SlingaDetails.css';
import { JobData, JobDataPartial, SlingaData, SlingaDoc } from '../../utils/types';
import { toRangeDateString, toTimeDateString } from '../../utils/time';
import { jobStatusLabel, NotificationStatus, UserType } from '../../utils/constants';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { PopupContext } from '../../contexts/PopupContext';
import { stopPropagation } from '../../utils/uiHelpers';
import Table from '../../components/table/Table';
import JobDetails from '../job_details';
import ReportSlinga from '../report_slinga/ReportSlinga';
import { Unsubscribe } from 'firebase/auth';
import {
  createSlingaData,
  updateAdminComment,
  updateDriverComment,
} from '../../firebase/firestore_functions/slinga';
import { AuthContext } from '../../contexts/AuthContext';
import { sendSMS } from '../../utils/otherHelpers';
import AddEditSlingaMain from '../add_slinga/AddEditSlingaMain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NotificationContext } from '../../contexts/NotificationContext';
import {
  getJobStatusLabel,
  getPartialJobBackgroundColor,
  getSlingaDataBackgroundColor,
} from '../../utils/styling';
import Textarea from '../../components/Textarea';

interface SlingaDetailsProps {
  slingaDocId: string;
}

const db = getFirestore();

export default function SlingaDetails({ slingaDocId }: SlingaDetailsProps) {
  const { showPopup, close } = useContext(PopupContext);
  const { user } = useContext(AuthContext);
  const { notify } = useContext(NotificationContext);

  const [details, setDetails] = useState<SlingaData>();
  const [jobs, setJobs] = useState<JobData[]>([]); // [
  const [notFound, setNotFound] = useState(false);

  const [adminComment, setAdminComment] = useState('');
  const [driverComment, setDriverComment] = useState('');

  useEffect(() => {
    console.log('slingaDocId: ', slingaDocId);
    const jobDocRef = doc(db, 'jobs', slingaDocId);

    const unsubscribe = onSnapshot(jobDocRef, async (querySnapshot) => {
      if (querySnapshot.exists()) {
        const slingaDoc = querySnapshot.data() as SlingaDoc;

        const slingaData = await createSlingaData(querySnapshot.id, slingaDoc);
        setDetails(slingaData);
        setAdminComment(slingaData.adminComments ? slingaData.adminComments : '');
        setDriverComment(slingaData.driverComment ? slingaData.driverComment : '');
      } else {
        setNotFound(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (details) {
      const jobs = details.jobs.map((job) => job.docId);

      if (jobs.length === 0) {
        setJobs([]);
      } else {
        const jobsDocRef = collection(db, 'jobs');

        // we get an error if we try to use the 'in' operator with an empty array
        const querry = query(jobsDocRef, where('__name__', 'in', jobs));

        const unsubscribe = onSnapshot(querry, async (querySnapshot) => {
          const fetchedJobs = querySnapshot.docs.map((doc) => doc.data() as JobData);
          fetchedJobs.sort((a, b) => a.start - b.start);

          setJobs(fetchedJobs);
        });

        return () => {
          unsubscribe();
        };
      }
    }
  }, [details]);

  function getRowData(job: JobDataPartial | JobData) {
    return {
      data: [
        toRangeDateString(job.start, job.end),
        getJobStatusLabel(job),
        job.client ? job.client.name : '',
        `${job.from?.address ? job.from.address : ''} ${job.from?.name ? job.from.name : ''}`,
        `${job.to?.address ? job.to.address : ''} ${job.to?.name ? job.to.name : ''}`,
      ],
      backgroundColor: getPartialJobBackgroundColor(job, user, true),
    };
  }

  function getTableData() {
    return jobs.map((job) => getRowData(job));
  }

  function onClickJobRow(idx: number) {
    details &&
      showPopup(<JobDetails jobDocId={details.jobs[idx].docId} belongsToSlinga={true} />, 'big');
  }

  function openReportSlinga() {
    details && showPopup(<ReportSlinga slinga={details} />, 'big');
  }

  function openEditSlinga() {
    details && showPopup(<AddEditSlingaMain slinga={details} onDelete={close} />, 'big');
  }

  function sendLinkToDriver() {
    // '!' is used after details because we are sure that it is not undefined. The button that has this function as an onClick handler is not rendered if details are undefined.

    // Subcontractors (underleverantörer) gets a link that leads them directly to a details view for the job. A inhouse driver gets a link to the apps main page since this
    // will show them their schedule where they can see the job listed.
    if (details!.subcontractor && details!.contactSubcontractor) {
      sendSMS(
        `Hej ${
          details!.contactSubcontractor.name
        }!\n\nDu har ett nytt uppdrag från Nolblad:\nhttps://nolblad.se/slinga-${details!.docId}`,
        details!.contactSubcontractor.phone,
      );
    } else if (details!.driver) {
      sendSMS(
        `Hej ${
          details!.driver.firstName
        }!\n\nDu har ett nytt uppdrag från Nolblad:\nhttps://nolblad.se/`,
        details!.driver.phone,
      );
    } else {
      notify(
        'Finns ingen information om telefonnummer till förare eller underleverantör',
        NotificationStatus.INFO,
      );
      return;
    }

    notify('SMS har skickats till förare', NotificationStatus.SUCCESS);
  }

  async function saveAdminComment() {
    if (details) {
      const saveAdminCommentResponse = await updateAdminComment(details.docId, adminComment);
      if (saveAdminCommentResponse.code === 201) {
        notify('Kommentarerna är sparade', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Kunde inte spara kommentarerna: ${saveAdminCommentResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  async function saveDriverComment() {
    if (details) {
      const saveDriverCommentResponse = await updateDriverComment(details.docId, driverComment);
      if (saveDriverCommentResponse.code === 201) {
        notify('Kommentarerna är sparade', NotificationStatus.SUCCESS);
      } else {
        notify(
          `Kunde inte spara kommentarerna: ${saveDriverCommentResponse.error}`,
          NotificationStatus.ERROR,
        );
      }
    }
  }

  return (
    <>
      {notFound && (
        <section className='details' onClick={stopPropagation}>
          <h2>Uppdraget kunde inte hittas.</h2>
        </section>
      )}
      {!notFound && !details && (
        <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
      )}
      {details && (
        <section className='details' onClick={stopPropagation}>
          <header className='details__header'>
            <h2>Slinga{details?.sNumber}</h2>
            <section
              className='details__header__controls'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {user && [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) && (
                <>
                  <button onClick={openEditSlinga} className='fa fa-edit button--icon'>
                    Redigera
                  </button>

                  <button className='fa fa-share button--icon' onClick={sendLinkToDriver}>
                    Skicka länk till förare
                  </button>
                </>
              )}

              {/* can only creat reports if the driver is urself */}

              {details.docId === user?.docId && (
                <button
                  onClick={openReportSlinga}
                  className='button--green'
                  style={{ width: 'auto' }}
                >
                  <span className='fa fa-check' /> Klarrapportera
                </button>
              )}
            </section>
          </header>

          <main>
            <ul className='details__info'>
              <li key='status' className='info'>
                <p className='label'>Status</p>
                <p style={{ backgroundColor: getSlingaDataBackgroundColor(details, user) }}>
                  {getJobStatusLabel(details)}
                </p>
              </li>

              <li key='start' className='info'>
                <p className='label'>Start</p>
                <p>{toTimeDateString(details.start)}</p>
              </li>
              <li key='end' className='info'>
                <p className='label'>Slut</p>
                <p>{toTimeDateString(details.end)}</p>
              </li>

              <li key='vehicle' className='info'>
                <p className='label'>Fordon</p>
                <p>{details.vehicle ? details.vehicle.id : '-'}</p>
              </li>

              {details.driver && (
                <li key='driver' className='info'>
                  <p className='label'>Förare</p>
                  <p>
                    {details.driver.firstName} {details.driver.lastName}
                  </p>
                </li>
              )}

              {details.subcontractor && (
                <>
                  {' '}
                  <li key='subconractor' className='info'>
                    <p className='label'>Underleverantör</p>
                    <p>{details.subcontractor ? details.subcontractor.name : '-'}</p>
                  </li>
                  <li key='contact' className='info'>
                    <p className='label'>Kontakt</p>
                    <p>
                      {details.contactSubcontractor &&
                        `${details.contactSubcontractor.name} ${details.contactSubcontractor.phone}`}

                      {!details.contactSubcontractor && '-'}
                    </p>
                  </li>
                </>
              )}
              {details.comments && (
                <li className='info'>
                  <p className='label'>Övrigt</p>
                  <p>{details.comments}</p>{' '}
                </li>
              )}

              {details.report && (
                <li
                  className='info'
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginTop: '2.4rem',
                  }}
                >
                  <p className='label' style={{ marginBottom: '1rem', fontStyle: 'italic' }}>
                    Rapport
                  </p>
                  <ul>
                    <li className='info'>
                      <p className='label'>Timmar</p>
                      <p>{details.report.hours}</p>
                    </li>
                    <li className='info'>
                      <p className='label'>Mil</p>
                      <p>{details.report.mil}</p>
                    </li>

                    {details.report.comments && (
                      <li className='info'>
                        <p className='label'>Kommentar</p>
                        <p>{details.report.comments}</p>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              <li id='li--table'>
                <Table
                  heads={['Datum', 'Status', 'Kund', 'Från', 'Till']}
                  data={getTableData() || []}
                  onClickRow={onClickJobRow}
                />
              </li>
              {/* Driver comment*/}
              {user && [UserType.DRIVER, UserType.DRIVER_EXTENDED].includes(user.userType) && (
                <div className='comment-container'>
                  <Textarea
                    id='driver-comment'
                    setState={setDriverComment}
                    placeholder='Kommentar till kontoret'
                    value={driverComment}
                    label='Chaufförskommentar'
                  />
                  <button
                    onClick={saveDriverComment}
                    className='button--green'
                    style={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    Spara
                  </button>
                </div>
              )}

              {/* Driver Comment display for ADMINS*/}
              {user?.userType === UserType.ADMIN && details.driverComment && (
                <div className='comment-container'>
                  <p className='label'>Chaufförskommentar</p>
                  <p className='comment__text'>{details.driverComment}</p>
                </div>
              )}

              {/* Admin comment */}
              {user?.userType === UserType.ADMIN && (
                <div className='comment-container'>
                  <Textarea
                    id='admin-comments'
                    setState={setAdminComment}
                    placeholder='Kommentar'
                    value={adminComment}
                    label='Adminkommentar'
                  />
                  <button
                    onClick={saveAdminComment}
                    className='button--green'
                    style={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    Spara
                  </button>
                </div>
              )}
            </ul>
          </main>
        </section>
      )}
    </>
  );
}
