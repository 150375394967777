import { useEffect, useState, useContext, SyntheticEvent } from 'react';
import { weekNumber } from 'weeknumber';
import { MobileStateContext } from '../../contexts/MobileContext';
import { getDaysInMonth, isToday, months } from '../../utils/time';

import { DaysWorkload, VehiclePartial } from '../../utils/types';
import { stopPropagation } from '../../utils/uiHelpers';

import * as R from 'ramda';

import './MonthView.css';
import { DocumentReference } from 'firebase/firestore';

interface MonthViewProps {
  daysWithJobs: DaysWorkload;
  month: number;
  year: number;
  onClickDay: (day: number) => void;
  onClickWeek: (weekStart: number) => void;
}

const headerDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];

const headerDaysShort = ['M', 'T', 'O', 'T', 'F', 'L', 'S'];

function MonthView({ daysWithJobs, month, year, onClickDay, onClickWeek }: MonthViewProps) {
  const [days, setDays] = useState<Array<number>>([]);

  useEffect(createDaysArray, [month, year]);

  const { isMobile } = useContext(MobileStateContext);

  function createDaysArray() {
    const _days: Array<number> = [];

    const _currentDate = new Date();

    _currentDate.setMonth(month);
    _currentDate.setFullYear(year);

    _currentDate.setDate(1);
    _currentDate.setMinutes(0);
    _currentDate.setSeconds(0);
    _currentDate.setHours(0);
    _currentDate.setMilliseconds(0);
    // js Date getDay() 0 - 6, sun - sat
    const startDay = _currentDate.getDay() === 0 ? 6 : _currentDate.getDay() - 1;

    let currentWeek = weekNumber(_currentDate);
    _days.push(currentWeek);
    // insert empty days in beginning of calendar
    for (let i = 0; i < startDay; ++i) {
      _days.push(0);
    }

    // add the dates in milliseconds for every day in the current month
    for (let i = 0; i < getDaysInMonth(year, month); ++i) {
      _days.push(_currentDate.getTime());
      _currentDate.setDate(_currentDate.getDate() + 1);

      if (weekNumber(_currentDate) !== currentWeek) {
        currentWeek = weekNumber(_currentDate);
        _days.push(currentWeek);
      }
    }

    // js Date getDay() 0 - 6, sun - sat
    const endDay = _currentDate.getDay() === 0 ? 6 : _currentDate.getDay() - 1;

    // insert empty days at the end of calendar
    for (let i = 0; i < 7 - endDay; ++i) {
      _days.push(0);
    }

    setDays(_days);
  }

  function navigateToWeek(week: number) {
    let firstDayOfWeek = 0;
    let daysFor = 0;
    while (firstDayOfWeek === 0) {
      firstDayOfWeek = days[days.indexOf(week) + ++daysFor];
      if (daysFor > 20) break;
    }

    onClickWeek(firstDayOfWeek);
  }

  return (
    <section id='month'>
      <h2>{months[month]}</h2>

      <ul id='month__days'>
        <li className='month__days__day' key='empty-day'></li>
        {!isMobile &&
          headerDays.map((day, idx) => {
            return (
              <li className='month__days__day' key={`${day}${idx}`}>
                {day}
              </li>
            );
          })}
        {isMobile &&
          headerDaysShort.map((day, idx) => {
            return (
              <li className='month__days__day' key={`${day}${idx}`}>
                {day}
              </li>
            );
          })}
        {days.map((day: number, idx: number) => {
          return (
            <li
              key={`${day}${idx}`}
              className={isToday(day) ? 'today month__days__date' : 'month__days__date'}
              onClick={(event: SyntheticEvent) => {
                stopPropagation(event);
                if (day.toString().length < 3 && day !== 0) {
                  navigateToWeek(day);
                } else {
                  if (day !== 0) {
                    onClickDay(day);
                  }
                }
              }}
            >
              {day.toString().length < 3 && day !== 0 ? (
                <p>{'V. ' + day}</p>
              ) : (
                <>
                  <p className='date'>{day === 0 ? '' : `${new Date(day).getDate()}`}</p>{' '}
                  {daysWithJobs[day] &&
                    (!isMobile ? (
                      R.uniqWith((a: string, b: string) => {
                        return a === b;
                      })([
                        ...daysWithJobs[day]
                          .filter((data) => data.vehicle != undefined)
                          .map((d) =>
                            d.vehicle instanceof DocumentReference
                              ? d.vehicle.id
                              : (d.vehicle as VehiclePartial).ref.id,
                          ),
                      ]).map((vehicle) => (
                        <p
                          key={vehicle}
                          // className={
                          // 	job.status ===
                          // 	JobStatus.INVOICED
                          // 		? (job.vehicle &&
                          // 				[
                          // 					VehicleType.TRUCK_LEJ,
                          // 					VehicleType.EXCAVATOR_LEJ,
                          // 					VehicleType.MAN_LEJ,
                          // 				].includes(
                          // 					job
                          // 						.vehicle
                          // 						?.vehicleType
                          // 				)) ||
                          // 		  (
                          // 				job as JobDataPartial &
                          // 					SlingaData
                          // 		  )
                          // 				.hasReceiptsForCompany
                          // 			? "status-6v2 job"
                          // 			: "status-6 job"
                          // 		: `status-${job.status} job`
                          // }
                        >
                          {vehicle && vehicle}
                        </p>
                      ))
                    ) : (
                      <p className='button--icon month__days__day__job-count'>
                        {daysWithJobs[day].length} jobb
                      </p>
                    ))}
                </>
              )}
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default MonthView;
