import { getFirestore } from '@firebase/firestore';
import { deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { User } from '../utils/types';
import ConfirmAction from '../components/confirm_action';
import { NotificationStatus, UserType, userTypeLabel } from '../utils/constants';
import { NotificationContext } from '../contexts/NotificationContext';
import { PopupContext } from '../contexts/PopupContext';
import { AuthContext } from '../contexts/AuthContext';
import { stopPropagation } from '../utils/uiHelpers';
import EditUser from './EditUser';
import { getAuth } from 'firebase/auth';

const auth = getAuth();

interface UserDetailsProps {
  user: User;
}

const db = getFirestore();

export default function UserDetails({ user }: UserDetailsProps) {
  const { notify } = useContext(NotificationContext);
  const { user: currentUser } = useContext(AuthContext);
  const { close, showPopup } = useContext(PopupContext);
  const [userInfo, setUserInfo] = useState<User>(user);

  useEffect(() => {
    setListenerUser();
  }, []);

  function setListenerUser() {
    try {
      const q = doc(db, 'users', user.docId);

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        if (querySnapshot.exists()) {
          setUserInfo(querySnapshot.data() as User);
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  function confirmDelete(event?: SyntheticEvent) {
    event && event.stopPropagation();

    showPopup(
      <ConfirmAction
        confirm={deleteUser}
        heading='Är du säker på det här?'
        message='Användaren kommer att raderas från systemet
         och går inte att återställa.'
      />,
      'small',
    );
  }

  async function deleteUser() {
    try {
      await deleteDoc(doc(db, 'users', user.docId));
      close();
      notify('Användare raderad', NotificationStatus.SUCCESS);
    } catch (error) {
      console.log(error);
      notify(
        'Hmm kunde inte radera användare, förmodligen programmerarens fel.',
        NotificationStatus.ERROR,
      );
    }
  }

  function openEditUser() {
    showPopup(<EditUser user={user} />, 'big');
  }

  return (
    <section className='details' onClick={stopPropagation}>
      <header className='details__header'>
        <h2>
          {user.firstName} {user.lastName && user.lastName[0] + '.'}
        </h2>
        {currentUser?.userType === UserType.ADMIN && (
          <section className='details__header__controls'>
            <button className='fa fa-edit button--icon' onClick={openEditUser}></button>
            <button onClick={confirmDelete} className='fa fa-trash button--icon'></button>
          </section>
        )}
      </header>
      <main>
        <section className='details__info'>
          <div className='info'>
            <p className='label'>Roll</p>
            <p>{userInfo.userType !== undefined ? userTypeLabel[userInfo.userType] : '-'}</p>
          </div>
          <div className='info'>
            <p className='label'>Förnamn</p>
            <p>{userInfo.firstName}</p>
          </div>
          <div className='info'>
            <p className='label'>Efternamn</p>
            <p>{userInfo.lastName}</p>
          </div>
          <div className='info'>
            <p className='label'>Email</p>
            <p>{userInfo.email}</p>
          </div>
          <div className='info'>
            <p className='label'>Tel</p>
            <p>{userInfo.phone ? userInfo.phone : '-'}</p>
          </div>
        </section>
      </main>
    </section>
  );
}
