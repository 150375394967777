import { FirebaseError, getApp } from 'firebase/app';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  Client,
  Littra,
  LittraDoc,
  UpdateClientParams,
  UpdateLittraParams,
} from '../../utils/types';

const db = getFirestore();
const functions = getFunctions(getApp(), 'europe-west1');

/**
 * @param client
 * @returns Promise<FirebaseResponse>
 */
export async function addClient(client: Client) {
  try {
    await addDoc(collection(db, 'clients'), client);
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 *
 * @param updates UpdateClientParams
 * @param docId id of document to update
 * @returns Promise<FirebaseReponse>
 */
export async function updateClient(updates: UpdateClientParams, docId: string) {
  try {
    await updateDoc(doc(db, `clients/${docId}`), updates as { [x: string]: any });
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * @param docId id of document to delete
 * @returns Promise<FirebaseReponse>
 */
export async function deleteClient(docId: string) {
  try {
    await deleteDoc(doc(db, `/clients/${docId}`));
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 *
 * @param littraDoc
 * @param clientDocId
 * @returns Promise<FirebaseResponse>
 */
export async function addLittra(littraDoc: LittraDoc, clientDocId: string) {
  try {
    const response = await addDoc(collection(db, `clients/${clientDocId}/littras`), littraDoc);

    return { code: 201, data: response.id };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 *
 * @param updates
 * @param littraDocId
 * @param clientDocId
 * @returns  Promise<FirebaseResponse>
 */
export async function updateLittra(
  updates: UpdateLittraParams,
  littraDocId: string,
  clientDocId: string,
) {
  try {
    await updateDoc(
      doc(db, `clients/${clientDocId}/littras/${littraDocId}`),
      updates as { [x: string]: any },
    );
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

export async function deleteLittra(clientDocId: string, littraDocId: string) {
  try {
    await deleteDoc(doc(db, `clients/${clientDocId}/littras/${littraDocId}`));
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Converts a littra document to littra data by populating all document references
 * @param littra
 * @param clientDocId
 * @returns Promise<FirebaseResponse>
 */
export function toLittraDoc(littra: Littra, clientDocId: string) {
  const littraDoc: LittraDoc = {
    projectNum: littra.projectNum,
    places: [],
    workplace: littra.workplace,
  };

  // Check which fields should be added to the littra

  if (littra.contact && littra.contact !== '') {
    littraDoc.contact = doc(db, 'contacts', littra.contact);
  }

  if (littra.tempContact !== '') {
    littraDoc.tempContact = littra.tempContact;
  }

  if (littra.from) {
    littraDoc.from = littra.from;
  }
  if (littra.to) {
    littraDoc.to = littra.to;
  }

  if (littra.otherInformation !== '') {
    littraDoc.otherInformation = littra.otherInformation;
  }

  if (littra.places) {
    littraDoc.places = littra.places;
  }

  return littraDoc;
}

export async function getLittrasForClient(clientDocId: string): Promise<Littra[]> {
  const ref = collection(db, 'clients', clientDocId, 'littras');
  const docSnap = await getDocs(ref);

  const littras: Littra[] = [];

  docSnap.docs.forEach((doc) => {
    const littraDoc = doc.data();
    const littra: Littra = {
      docId: doc.id,
      projectNum: littraDoc.projectNum,
      workplace: littraDoc.workplace,
      contact: littraDoc.contact,
      tempContact: littraDoc.tempContact,
      from: littraDoc.from,
      to: littraDoc.to,
      otherInformation: littraDoc.otherInformation,
      places: littraDoc.places,
    };
    littras.push(littra);
  });
  return littras;
}

/**
 * Calls the cloud function for creating a new client in fortnox
 */
export async function createClientInFortnox(
  Name: string,
  Email: string,
  OrganisationNumber: string,
  Phone1: string,
) {
  try {
    const createClientInFortnoxFunction = httpsCallable(functions, 'createClientInFortnox');
    await createClientInFortnoxFunction({
      Name,
      Email,
      OrganisationNumber,
      Phone1,
    });
    return 'Sparat nya klienten i fortnox';
  } catch (e) {
    return 'Kunde inte spara nya klienten i fortnox. Cloud funktionen gick sne';
  }
}
