import { useContext } from 'react';
import { AddEditSlingaContext } from './AddEditSlingaContext';
import AddSubcontractor from '../../components/components_for_add_edit_job_slinga/AddSubcontractor';

/**
 * Component that wraps 'AddSubcontractor' and passes it data state variables from 'AddEditSlingaContext'
 */
export default function AddSubcontractorToJob() {
  const { subcontractor, setSubcontractor } = useContext(AddEditSlingaContext);

  return <AddSubcontractor subcontractor={subcontractor} setSubcontractor={setSubcontractor} />;
}
