import { FirebaseError } from 'firebase/app';
import { addDoc, collection, deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { Place } from '../../utils/types';

const db = getFirestore();

export async function addPlace(place: Place) {
  try {
    await addDoc(collection(db, 'places'), place);
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

export async function removePlace(id: string) {
  try {
    await deleteDoc(doc(db, `places/${id}`));
    return { code: 200 };
  } catch (e) {
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}
