import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { SubcontractorData } from '../../utils/types';

import ListPage from '../../components/list_page';
import { PopupContext } from '../../contexts/PopupContext';
import history from '../../utils/history';
import SubcontractorDetails from '../../popups/SubcontractorDetails';
import AddSubcontractor from '../../popups/AddSubcontractor';
import { DataContext } from '../../contexts/CommonDataContext';

export default function Subcontractors() {
  const { showPopup } = useContext(PopupContext);
  const { subcontractors } = useContext(DataContext);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    return setHistoryListener();
  }, []);

  /**
   * when history is empty, history.block (used in PopupContext to intersect backpressed in browser and on phone) is not called so we add extra history when navigating to each page.
   * @returns unregisterCallback
   */
  function setHistoryListener() {
    history.push('/underleverantörer');
    return history.listen((location, action) => {
      if (action === 'POP' && location.pathname === '/underleverantörer') {
        history.goBack();
      }
    });
  }

  function onChangeSearch(event: SyntheticEvent) {
    setSearch((event.target as HTMLInputElement).value);
  }

  function openAddSubcontractor() {
    showPopup(<AddSubcontractor />, 'big');
  }

  function openSubcontractorDetails(subcontractorIdx: number) {
    showPopup(<SubcontractorDetails subcontractor={subcontractors[subcontractorIdx]} />, 'big');
  }

  return (
    <ListPage
      heading='Underleverantörer'
      addButtonText='Ny underleverantör'
      onChangeSearch={onChangeSearch}
      onClickAddButton={openAddSubcontractor}
    >
      <ul id='list-page__items'>
        {subcontractors.map((s: SubcontractorData, idx: number) => {
          return (
            (s?.name?.toLocaleLowerCase().includes(search.toLowerCase()) ||
              s.orgNum.toLocaleLowerCase().includes(search.toLocaleLowerCase())) && (
              <li
                tabIndex={0}
                className='list-page__items__item'
                id={`list-page__items__${s.docId}`}
                onClick={() => {
                  openSubcontractorDetails(idx);
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    openSubcontractorDetails(idx);
                    requestAnimationFrame(() => {
                      (document.querySelector('.button--close') as HTMLElement).focus();
                    });
                  }
                }}
                key={s.docId}
              >
                <p>{s.name}</p>
              </li>
            )
          );
        })}
      </ul>
    </ListPage>
  );
}
