import { View, Text } from '@react-pdf/renderer';
import { getHoursElapsed, toRangeDateString } from '../../utils/time';
import { JobData, JobReportData, ReportRowData } from '../../utils/types';
import styles from './PDFStyles';
import { JobType } from '../../utils/constants';

type Props = {
  report: JobReportData;
  job: JobData;
};

const PdfReport = ({ report, job }: Props) => {
  function getReportHours() {
    return getHoursElapsed(report.start, report.end) - report.breaktime / 60;
  }

  function getReportMiles() {
    return report.miles > 0 ? report.miles : '-';
  }

  function getRowFrom(row: ReportRowData): string {
    if (row.fromLocation) {
      return row.fromLocation;
    }

    return row.from?.name || '';
  }

  function getRowTo(row: ReportRowData): string {
    if (row.toLocation) {
      return row.toLocation;
    }

    return row.to?.name || '';
  }

  const getMaterialsFromRow = (row: ReportRowData, idx: number) => {
    console.log('row  ', idx, row);
    if (row.materials.length === 0 && row.otherMaterials === '') return null;
    if (row.materials.length > 0) {
      const rowMaterials = row.materials.map((material) => {
        console.log('material', material);
        return (
          <View style={{ ...styles.tableRow, width: '100%' }} key={idx + material.material.docId}>
            <View style={{ ...styles.tableCell, width: '10%' }}>
              <Text style={styles.tableBody}>
                {toRangeDateString(report.start, report.end).split(' ')[0]}
              </Text>
            </View>

            <View style={{ ...styles.tableCell, width: '15%' }}>
              <Text style={styles.tableBody}>
                {row.materials.length > 0 ? material.material.description : row.otherMaterials}
              </Text>
            </View>

            {job.jobType === JobType.EXCAVATOR_MAN ? null : (
              <View style={{ ...styles.tableCell, width: '43%' }}>
                <Text style={styles.tableBody}>
                  {getRowFrom(row)} - {getRowTo(row)}
                </Text>
              </View>
            )}

            <View style={{ ...styles.tableCell, width: '7%' }}>
              <Text style={styles.tableBody}>
                {idx === report.rows.length - 1 ? getReportHours() : ''}
              </Text>
            </View>

            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.tableBody} key={idx}>
                {row.materials.length > 0 && material.howMuch[1].qty > 0 && material.howMuch[1].qty}
                {/* ton */}
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '15%' }}>
              <Text style={styles.tableBody} key={idx}>
                {row.materials.length > 0 && material.howMuch[0].qty} {/* antal turer (lass)*/}
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: '5%' }}>
              <Text style={styles.tableBody}>{getReportMiles()}</Text>
            </View>
          </View>
        );
      });

      return rowMaterials;
    }

    return (
      <View style={{ ...styles.tableRow, width: '100%' }} key={idx + row.otherMaterials}>
        <View style={{ ...styles.tableCell, width: '10%' }}>
          <Text style={styles.tableBody}>
            {toRangeDateString(report.start, report.end).split(' ')[0]}
          </Text>
        </View>

        <View style={{ ...styles.tableCell, width: '15%' }}>
          <Text style={styles.tableBody}>{row.otherMaterials}</Text>
        </View>

        {job.jobType === JobType.EXCAVATOR_MAN ? null : (
          <View style={{ ...styles.tableCell, width: '43%' }}>
            <Text style={styles.tableBody}>
              {getRowFrom(row)} - {getRowTo(row)}
            </Text>
          </View>
        )}

        <View style={{ ...styles.tableCell, width: '7%' }}>
          <Text style={styles.tableBody}>
            {idx === report.rows.length - 1 ? getReportHours() : ''}
          </Text>
        </View>

        <View style={{ ...styles.tableCell, width: '5%' }}>
          <Text style={styles.tableBody} key={idx}>
            0
          </Text>
        </View>
        <View style={{ ...styles.tableCell, width: '15%' }}>
          <Text style={styles.tableBody} key={idx}>
            0
          </Text>
        </View>
        <View style={{ ...styles.tableCell, width: '5%' }}>
          <Text style={styles.tableBody}>{getReportMiles()}</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={{ ...styles.tableColumn, marginTop: 24 }} key={Math.random()} wrap={false}>
      {/* Table titles */}
      {!!job.reports && job.reports.length > 0 && (
        <View style={{ ...styles.tableRow, width: '100%' }}>
          <View style={{ ...styles.tableCell, width: '10%' }}>
            <Text style={styles.tableTitle}>Datum</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableTitle}>Godsslag</Text>
          </View>

          {job.jobType === JobType.EXCAVATOR_MAN ? null : (
            <View style={{ ...styles.tableCell, width: '43%' }}>
              <Text style={styles.tableTitle}>Från - Till</Text>
            </View>
          )}

          <View style={{ ...styles.tableCell, width: '7%' }}>
            <Text style={styles.tableTitle}>Tim.</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '5%' }}>
            <Text style={styles.tableTitle}>Ton</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableTitle}>Antal turer</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '5%' }}>
            <Text style={styles.tableTitle}>Mil</Text>
          </View>
        </View>
      )}

      {/* Table body if no report rows */}
      {report.rows.length === 0 && (
        <View style={{ ...styles.tableRow, width: '100%' }}>
          <View style={{ ...styles.tableCell, width: '10%' }}>
            <Text style={styles.tableBody}>
              {toRangeDateString(report.start, report.end).split(' ')[0]}
            </Text>
          </View>

          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableBody}></Text>
          </View>

          {job.jobType === JobType.EXCAVATOR_MAN ? null : (
            <View style={{ ...styles.tableCell, width: '43%' }}>
              <Text style={styles.tableBody}></Text>
            </View>
          )}

          <View style={{ ...styles.tableCell, width: '7%' }}>
            <Text style={styles.tableBody}>{getReportHours()}</Text>
          </View>

          <View style={{ ...styles.tableCell, width: '5%' }}>
            <Text style={styles.tableBody}></Text>
          </View>

          <View style={{ ...styles.tableCell, width: '15%' }}>
            <Text style={styles.tableBody}></Text>
          </View>

          <View style={{ ...styles.tableCell, width: '5%' }}>
            <Text style={styles.tableBody}>{getReportMiles()}</Text>
          </View>
        </View>
      )}

      {/* Table body if report rows */}

      {report.rows.length > 0 && report.rows.map((row, idx) => getMaterialsFromRow(row, idx))}

      <Text style={{ ...styles.tableBody, marginTop: 6 }}>Kommentar till kund: {report.other}</Text>
    </View>
  );
};

export default PdfReport;
