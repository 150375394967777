import React, { useState, useEffect, useRef } from 'react';

import './FilterSelect.css';

import chevronDown from '../../images/chevron-down.svg';
import { ClientData, Littra, User, VehicleData } from '../../utils/types';

interface props {
  drivers: User[];
  vehicles: VehicleData[];
  clients: ClientData[];
  clientsLoading: boolean;
  littraOptions: Littra[];
  onChangeDriverFilter: (value: string, action?: boolean) => void;
  onChangeClientFilter: (value: string, action?: boolean) => void;
  onChangeVehicleFilter: (value: string, action?: boolean) => void;
  onChangeLittraFilter: (value: string, action?: boolean) => void;
  clientFilter: Set<string>;
  driverFilter: Set<string>;
  vehicleFilter: Set<string>;
  littraFilter: Set<string>;
}

/**
 * Component used for admin and extended drivers to filter for client, vehicle or driver in the calendar
 * @returns JSX.Element
 */
export const FilterSelect = ({
  drivers,
  vehicles,
  clients,
  clientsLoading,
  littraOptions,
  onChangeDriverFilter,
  onChangeClientFilter,
  onChangeVehicleFilter,
  onChangeLittraFilter,
  clientFilter,
  driverFilter,
  vehicleFilter,
  littraFilter,
}: props) => {
  const filterOptions = ['Ingen', 'Förare', 'Bil', 'Kund', 'Littra'];

  const [search, setSearch] = useState<string>('');
  const [values, setValues] = useState<filterValue[]>([]);
  const [showValues, setShowValues] = useState<boolean>(false);
  const [option, setOption] = useState<string>('Ingen');

  const ref = useRef(null);
  const ref2 = useRef(null);

  type filterValue = { label: string; value: string; selected?: boolean };
  function getValues(option: string) {
    switch (option) {
      case 'Förare':
        return drivers.map((driver) => {
          return {
            label: driver.firstName + ' ' + driver.lastName,
            value: driver.docId,
            selected: driverFilter.has(driver.docId),
          };
        });
      case 'Bil':
        return vehicles.map((vehicle) => {
          return {
            label: vehicle.id,
            value: vehicle.docId,
            selected: vehicleFilter.has(vehicle.docId),
          };
        });
      case 'Kund':
        return clients.map((client) => {
          return {
            label: client.name,
            value: client.docId,
            selected: clientFilter.has(client.docId),
          };
        });
      case 'Littra':
        return littraOptions.map((littra) => {
          return {
            label: littra.projectNum,
            value: littra.docId,
            selected: littraFilter.has(littra.docId),
          };
        });
      default:
        return [];
    }
  }

  // NOTE (PS): If option is changed before the data is fetched, values will be empty
  useEffect(() => {
    setValues(getValues(option));
    setSearch('');
  }, [option]);

  useEffect(() => {
    const values = getValues(option).filter((value) =>
      value.label.toLowerCase().includes(search.toLowerCase()),
    );
    setValues(values);
  }, [search]);

  useEffect;

  /* NOTE (PS): Added client, drivers, vehicles and littraOptions as dependencies to
   * update values when they are fetched.
   */
  useEffect(() => {
    const values = getValues(option);
    setValues(values);
  }, [
    driverFilter,
    vehicleFilter,
    clientFilter,
    littraFilter,
    clients,
    drivers,
    vehicles,
    littraOptions,
  ]);

  const onClickOutside = () => {
    setShowValues(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        ref2.current &&
        !(ref.current as HTMLElement).contains(event.target) &&
        !(ref2.current as HTMLElement).contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  // Open list if search is changing,
  useEffect(() => {
    setShowValues(true);
  }, [search]);

  const selectValue = (value: string) => {
    const newValues = values.map((v) => {
      if (v.value === value) {
        return { ...v, selected: !v.selected };
      } else {
        return v;
      }
    });
    setValues(newValues);

    if (option === 'Förare') {
      driverFilter.has(value)
        ? onChangeDriverFilter(value, false)
        : onChangeDriverFilter(value, true);
    } else if (option === 'Bil') {
      vehicleFilter.has(value)
        ? onChangeVehicleFilter(value, false)
        : onChangeVehicleFilter(value, true);
    } else if (option === 'Kund') {
      clientFilter.has(value)
        ? onChangeClientFilter(value, false)
        : onChangeClientFilter(value, true);
    } else if (option === 'Littra') {
      littraFilter.has(value)
        ? onChangeLittraFilter(value, false)
        : onChangeLittraFilter(value, true);
    }
  };

  const getSearchPlaceholder = () => {
    switch (option) {
      case 'Förare':
        return 'Sök efter förare';
      case 'Bil':
        return 'Sök efter bil';
      case 'Kund':
        if (clientsLoading) {
          return 'Laddar kunder...';
        }
        return 'Sök efter kund';
      case 'Littra':
        if (clientFilter.size < 1) {
          return 'Välj en kund först';
        } else if (littraOptions.length < 1) {
          return 'Inga littras hittades för denna kund';
        }
        return 'Sök efter littra';
      default:
        return 'Välj ett filtreringsalternativ';
    }
  };

  const getSearchDisabledState = () => {
    switch (option) {
      case 'Förare':
        return false;
      case 'Bil':
        return false;
      case 'Kund':
        if (clientsLoading) {
          return true;
        }
        return false;
      case 'Littra':
        if (clientFilter.size < 1) {
          return true;
        } else if (littraOptions.length < 1) {
          return true;
        }
        return false;
      default:
        return true;
    }
  };

  return (
    <div id='filter-select-container'>
      <section className='filer-select-select-section'>
        <select
          className='filer-select-select'
          id='filter-select-option'
          onChange={(value) => {
            setOption(value.target.value);
          }}
        >
          {filterOptions.map((option) => {
            return (
              <option key={option} value={option} className='filter-option'>
                {option}
              </option>
            );
          })}
        </select>
      </section>
      <section className='filer-select-select' id='filter-select-filter' ref={ref}>
        <input
          type='text'
          placeholder={getSearchPlaceholder()}
          id='input-field'
          value={search}
          onFocus={() => setShowValues(true)}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          /* Disable search field on these conditions. */
          disabled={getSearchDisabledState()}
        />
        {!getSearchDisabledState() && (
          <img src={chevronDown} id='dropdown-arrow' onClick={() => setShowValues(!showValues)} />
        )}
        {showValues && (
          <div id='filter-select-values' ref={ref2}>
            {values.map((value) => {
              return (
                <p
                  key={value.value}
                  className='filter-select-value'
                  onClick={() => {
                    selectValue(value.value);
                  }}
                  style={{ backgroundColor: value.selected ? '#e6e6e6' : 'white' }}
                >
                  {value.label}
                </p>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};
