import { useContext } from 'react';
import { AddEditJobContext } from './AddEditJobContext';
import AddContactSubcontractor from '../../components/components_for_add_edit_job_slinga/AddContactSubcontractor';

/**
 * Component that wraps 'AddContactSubcontractor' and passes data state variables from 'AddEditJobContext' to it.
 *
 */
export default function AddContactSubcontractorToJob() {
  const { subcontractor, contactSubcontractor, setContactSubcontractor } =
    useContext(AddEditJobContext);

  return (
    <AddContactSubcontractor
      subcontractor={subcontractor}
      contactSubcontractor={contactSubcontractor}
      setContactSubcontractor={setContactSubcontractor}
    />
  );
}
