import { FirebaseError } from 'firebase/app';
import {
  getFirestore,
  runTransaction,
  doc,
  collection,
  DocumentReference,
  updateDoc,
  Transaction,
  getDocs,
  query,
  where,
  getDoc,
} from 'firebase/firestore';

import { JobStatus, VehicleType } from '../../utils/constants';
import { fromJobDataToJobDoc, addJobs, deleteJobs, copyJob, toJobData } from './job';
import { populateDocs } from './common';
import {
  ContactData,
  FirebaseResponse,
  JobData,
  JobDoc,
  SlingaData,
  SlingaDataPartial,
  SlingaDoc,
  SlingaReport,
  SubcontractorData,
  User,
  UserPartial,
  VehicleData,
  VehicleDoc,
  VehiclePartial,
} from '../../utils/types';

const db = getFirestore();

/**
 * Adds a new slinga to the "jobs" collection. This operation has these side effects:
 * - Updating "sNumber" in the "constants" collection
 * - Adds job objects in the slinga to the "jobs" collection as well
 *
 * @param slingaDoc the slinga document
 * @param jobs jobs included in the slinga
 * @returns FirebaseResponse with id of slinga if successful
 */
export async function addSlinga(slingaDoc: SlingaDoc, jobs: JobData[]): Promise<FirebaseResponse> {
  try {
    const response = await runTransaction(db, async (transaction) => {
      // 1. get the current sNumber
      const sNumberResponse = await getSNumber(transaction);
      if (sNumberResponse.code !== 200 && !sNumberResponse.data) {
        return sNumberResponse;
      }

      if (sNumberResponse.data?.sNumber !== undefined) {
        // const sNumber = sNumberResponse.data;

        // 2. generate doc ref for new slinga
        const newSlingaRef = doc(collection(db, 'jobs'));

        // 5 add jobs with the slinga ref as a field (to later be able to update jobs array if job is deleted).
        // OBS! is 'misplaced' here since it contains reads, all reads needs to come before writes.
        const addedJobResponse = await addJobs(
          jobs.map((job) => {
            return { ...fromJobDataToJobDoc(job), slinga: newSlingaRef };
          }),
          transaction,
        );

        if (addedJobResponse.code !== 201) {
          return addedJobResponse;
        }

        // 6. add slinga with the jobs
        transaction.set(newSlingaRef, {
          ...slingaDoc,
          jobs: addedJobResponse.data?.addedJobs,
          sNumber: sNumberResponse.data.sNumber,
        });

        // 7. increment sNumber
        transaction.update(doc(db, 'constants', 'sNumber'), {
          sNumber: sNumberResponse.data.sNumber + 1,
        });

        return {
          code: 201,
          data: { docId: newSlingaRef.id },
        };
      } else {
        return {
          code: 404,
          error: 'sNumber',
        };
      }
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * updates a slinga including adding or deleting jobs that has been removed or added to the slinga
 * and updating the calendar collections, "months", "weeks" & "days"
 * @param slingaDoc the new document
 * @param addedJobs newly added jobs that should be added to db
 * @param removedJobs newly removed jobs that should be removed from db
 * @param oldStart if date has been changed, needed to update calendar collections.
 * @param oldEnd if date has been changed, needed to update calendar collections.
 * @returns FirebaseResponse
 */
export async function updateSlinga(
  slingaDocId: string,
  slingaDoc: SlingaDoc,
  addedJobs: JobData[],
  removedJobs: JobData[],
  oldStart?: number,
  oldEnd?: number,
): Promise<FirebaseResponse> {
  try {
    const response = await runTransaction(db, async (transaction) => {
      // if date has changed we need to update calendar collections.
      if (oldStart && oldEnd) {
        // ** create new jobs in db if any was added **
        // OBS weird placement, reads needs to be done before all writes in the transaction and since the below
        // code block contains reads (see addJob in jobs.ts), it is placed here and in the else clause.

        if (addedJobs.length > 0) {
          const addJobsResponse = await addJobs(
            addedJobs.map((job) => {
              return {
                ...fromJobDataToJobDoc(job),
                slinga: doc(collection(db, 'jobs'), slingaDocId),
              };
            }),
            transaction,
          );

          if (addJobsResponse.code !== 201 || !addJobsResponse.data) {
            return addJobsResponse;
          } else {
            // 2. add the new jobs to the original jobs array
            slingaDoc.jobs = slingaDoc.jobs.concat(addJobsResponse.data.addedJobs);
          }
        }
      } else {
        // 1. create new jobs in db if any was added
        if (addedJobs.length > 0) {
          const addJobsResponse = await addJobs(
            addedJobs.map((job) => {
              return {
                ...fromJobDataToJobDoc(job),
                slinga: doc(collection(db, 'jobs'), slingaDocId),
              };
            }),
            transaction,
          );

          if (addJobsResponse.code !== 201 || !addJobsResponse.data) {
            return addJobsResponse;
          } else {
            // 2. add the new jobs to the original jobs array
            slingaDoc.jobs = slingaDoc.jobs.concat(addJobsResponse.data.addedJobs);
          }
        }
      }

      // delete jobs from db if any was deleted
      if (removedJobs.length > 0) {
        const removeJobsResponse = deleteJobs(
          removedJobs.map((job) => job.docId),
          transaction,
        );

        if (removeJobsResponse.code !== 201) {
          return removeJobsResponse;
        } else {
          // 4. remove the deleted jobs from the jobs array
          slingaDoc.jobs = slingaDoc.jobs.filter((job) =>
            removedJobs.find((j) => j.docId !== job.id),
          );
        }
      }

      // update slinga with updated information including jobs (slingaDoc arg already contians updated jobs array).

      transaction.set(doc(collection(db, 'jobs'), slingaDocId), slingaDoc);

      return {
        code: 201,
      };
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

export async function updateAdminComment(slingaDocId: string, comment: string) {
  try {
    await updateDoc(doc(collection(db, 'jobs'), slingaDocId), { adminComments: comment });
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

export async function updateDriverComment(slingaDocId: string, comment: string) {
  try {
    await updateDoc(doc(collection(db, 'jobs'), slingaDocId), { driverComment: comment });
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 * Copies slinga and the jobs in the slinga.
 * @param slinga
 * @returns FirebaseResponse
 */

/**
 * Copies slinga and the jobs in the slinga.
 * @param slinga
 * @returns FirebaseResponse
 */
export async function copySlinga(slingaId: string): Promise<FirebaseResponse> {
  // TODO change such that it utilizies id instead of populated slinga type
  try {
    const response = await runTransaction(db, async (transaction) => {
      // 1. fetch sNumber
      const sNumberRef = doc(db, 'constants', 'sNumber');
      const sNumberDoc = await transaction.get(sNumberRef);

      if (!sNumberDoc.exists()) {
        return { code: 404, data: 'sNumber' };
      }
      const sNumber = sNumberDoc.data().sNumber;

      // 2. generate doc ref for new slinga
      const newSlingaRef = doc(collection(db, 'jobs'));

      // 3. make the copy (except for jobs, they will be copied and added later)

      // we retrieve the slinga doc
      const slingaRef = doc(db, 'jobs', slingaId);
      const oldSlingaSnap = await getDoc(slingaRef);
      if (!oldSlingaSnap.exists()) return;
      const oldSlingaDoc = oldSlingaSnap.data() as SlingaDoc;

      const slingaCopy: SlingaDoc = {
        ...oldSlingaDoc,
        sNumber: sNumber,
        jobs: [],
        driver: undefined,
        vehicle: undefined,
        vehicleEquipments: undefined,
      };

      // transform to partial

      // if driver field contains data, then we need to check the type of that field.
      // we opportunisticly transform a non partial type to a partial type
      if (oldSlingaDoc.driver) {
        if (oldSlingaDoc.driver instanceof DocumentReference) {
          // is reference, we need to transform to partial
          const user = (await getDoc(oldSlingaDoc.driver)).data() as User;
          const partial: UserPartial = {
            ref: oldSlingaDoc.driver,
            firstName: user?.firstName ? user.firstName : '-',
          };
          slingaCopy.driver = partial;
        } else if ('ref' in oldSlingaDoc.driver) {
          slingaCopy.driver = oldSlingaDoc.driver;
        }
      }

      if (oldSlingaDoc.vehicle) {
        if (oldSlingaDoc.vehicle instanceof DocumentReference) {
          // is reference, we need to transform to partial
          const vehicle = (await getDoc(oldSlingaDoc.vehicle)).data();
          const partial: VehiclePartial = {
            ref: oldSlingaDoc.vehicle,
            name: vehicle?.id ? vehicle.id : '-',
            vehicleType: vehicle?.vehicleType ? vehicle.vehicleType : VehicleType.TRUCK,
          };
          slingaCopy.vehicle = partial;
        } else if ('ref' in oldSlingaDoc.vehicle) {
          slingaCopy.vehicle = oldSlingaDoc.vehicle;
        }
      }

      // these fields do not need to be partially populated
      if (oldSlingaDoc.vehicleEquipments) {
        slingaCopy.vehicleEquipments = oldSlingaDoc.vehicleEquipments;
      }
      if (oldSlingaDoc.comments) {
        slingaCopy.comments = oldSlingaDoc.comments;
      }

      // 5. copy all the jobs

      const jobsPromises = [];

      for (const job of oldSlingaDoc.jobs) {
        jobsPromises.push(
          // eslint-disable-next-line no-async-promise-executor
          new Promise<DocumentReference>(async (resolve, reject) => {
            const copyJobResponse = await copyJob(job.id, newSlingaRef);

            if (copyJobResponse.code === 201 && copyJobResponse.data) {
              resolve(doc(collection(db, 'jobs'), copyJobResponse.data));
            } else {
              reject(null);
            }
          }),
        );
      }

      let jobs = await Promise.all(jobsPromises);

      jobs = jobs.filter((job) => job !== null);
      slingaCopy.jobs = jobs;

      // 7. add new slinga

      transaction.set(newSlingaRef, slingaCopy);

      // 8. increment sNumber

      transaction.update(sNumberRef, {
        sNumber: sNumber + 1,
      });

      return { code: 201, data: newSlingaRef.id };
    });

    return (
      response || {
        code: 500,
        error: 'copy did not return new slinga',
      }
    );
  } catch (error) {
    return {
      code: parseInt((error as FirebaseError).code),
      error: (error as FirebaseError).message,
    };
  }
}
/**
 * Adds report to a slinga
 * @param docId
 * @param report
 * @returns FirebaseResponse
 */
export async function reportSlinga(docId: string, report: SlingaReport) {
  try {
    await updateDoc(doc(collection(db, 'jobs'), docId), { report });

    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 * deletes slinga document, delete job documents in slinga and delete the entries in the collections "months", "weeks" and "days" for the slinga.
 * @param slinga
 */
export async function deleteSlinga(slinga: SlingaData) {
  try {
    const response = await runTransaction(db, async (transaction) => {
      const slingaDocRef = doc(db, 'jobs', slinga.docId);

      // 1. get the jobs to delete

      const jobs = await getDocs(
        query(collection(db, 'jobs'), where('slinga', '==', slingaDocRef)),
      );

      // 1. delete the jobs
      for (const job of jobs.docs) {
        transaction.delete(job.ref);
      }

      // 4. delete the slinga
      transaction.delete(slingaDocRef);

      return {
        code: 201,
      };
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Gets the "sNumber" (slinga nummer)
 *
 */

export async function getSNumber(transaction: Transaction) {
  try {
    const sNumberDoc = await transaction.get(doc(collection(db, 'constants'), 'sNumber'));

    return {
      code: 200,
      data: sNumberDoc.data() as { sNumber: number },
    };
  } catch (e) {
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 * Finds out the "least finished" status among the jobs
 * @param slinga
 * @returns
 */
export function getSlingaStatus(slinga: SlingaData) {
  if (slinga.jobs.length === 0) {
    return JobStatus.NEW;
  }

  let status = JobStatus.PAYED; // last status

  for (const job of slinga.jobs) {
    if (job.status < status) {
      status = job.status;
    }
  }
  return status;
}

//////////////
// HELPERS //
////////////

/**
 * converts a slinga document to 'SlingaData' and thereby populates all necessary fields.
 * @param docId
 * @param slingaDoc
 * @returns FirebaseResponse
 */
export async function createSlingaDataPartial(
  docId: string,
  slingaDoc: SlingaDoc,
): Promise<SlingaDataPartial> {
  const slinga: SlingaDataPartial = {
    docId,
    ...slingaDoc,
    driver: undefined,
    vehicle: undefined,
  };

  // if driver field contains data, then we need to check the type of that field.
  // if it is not a partial, then we fetch the document
  if (slingaDoc.driver) {
    // DocumentReference is a class and therefore we can check for its type at runtime
    if (slingaDoc.driver instanceof DocumentReference) {
      // if it is a reference we need to fetch the data as if it was a User
      const user = (await getDoc(slingaDoc.driver)).data() as User;
      const partial: UserPartial = {
        ref: slingaDoc.driver,
        firstName: user?.firstName ? user.firstName : 'No name',
      };
      slinga.driver = partial;
    } else if ('ref' in slingaDoc.driver) {
      // the type is our partial one
      slinga.driver = slingaDoc.driver;
    }
  }

  // if driver field contains data, then we need to check the type of that field.
  // if it is not a partial, then we fetch the document
  if (slingaDoc.vehicle) {
    // DocumentReference is a class and therefore we can check for its type at runtime
    if (slingaDoc.vehicle instanceof DocumentReference) {
      // if it is a reference we need to fetch the data as if it was a User
      const vehicle = (await getDoc(slingaDoc.vehicle)).data() as VehicleDoc;
      const partial: VehiclePartial = {
        ref: slingaDoc.vehicle,
        name: vehicle?.id ? vehicle.id : '-',
        vehicleType: vehicle?.vehicleType,
      };
      slinga.vehicle = partial;
    } else if ('ref' in slingaDoc.vehicle) {
      // the type is our partial one
      slinga.vehicle = slingaDoc.vehicle;
    }
  }

  return slinga;
}

/**
 * converts a slinga document to 'SlingaData' and thereby populates all necessary fields.
 * @param docId
 * @param data
 * @returns FirebaseResponse
 */
export async function createSlingaData(docId: string, data: SlingaDoc) {
  const slinga: SlingaData = {
    name: data.name,
    sNumber: data.sNumber,
    docId,
    status: JobStatus.PAYED, // highest status, later adjusted according to lowest status of jobs
    start: data.start,
    end: data.end,
    jobs: [],

    // is populated below!

    // * driver?: User;
    // * vehicle?: VehicleData;
    // * subcontractor: SubcontractorData;
    // * SubcontractorContact: ContactData;
    // * jobs: JobDataPartial[]
    // * adminComments?: string;
  };

  // populate driver and vehicle

  if (data.comments) {
    slinga.comments = data.comments;
  }
  if (data.report) {
    slinga.report = data.report;
  }

  if (data.driver) {
    const reference = data.driver instanceof DocumentReference ? data.driver : data.driver.ref;
    const populateResponse = await populateDocs([reference]);

    if (populateResponse.code === 200 && populateResponse.data) {
      slinga.driver = populateResponse.data[0] as User;
    }
  }

  if (data.subcontractor) {
    const populateResponse = await populateDocs([data.subcontractor]);

    if (populateResponse.code === 200 && populateResponse.data) {
      slinga.subcontractor = populateResponse.data[0] as SubcontractorData;
    }

    if (data.contactSubcontractor) {
      const populateResponse = await populateDocs([data.contactSubcontractor]);

      if (populateResponse.code === 200 && populateResponse.data) {
        slinga.contactSubcontractor = populateResponse.data[0] as ContactData;
      }
    }
  }

  if (data.vehicle) {
    const reference = data.vehicle instanceof DocumentReference ? data.vehicle : data.vehicle.ref;
    const populateResponse = await populateDocs([reference]);

    if (populateResponse.code === 200 && populateResponse.data) {
      slinga.vehicle = populateResponse.data[0] as VehicleData;
    }
  }

  // populate jobs

  const populateResponse = await populateDocs(data.jobs);

  if (populateResponse.code === 200 && populateResponse.data) {
    const promises = [];

    // first create job doc and thereby populating all fields in the job
    for (const jobDoc of populateResponse.data) {
      promises.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise<void>(async (resolve, _) => {
          const job = await toJobData(jobDoc.docId, jobDoc as JobDoc);
          slinga.jobs.push(job);
          resolve();
        }),
      );
    }

    if (data.adminComments) slinga.adminComments = data.adminComments;

    if (data.driverComment) slinga.driverComment = data.driverComment;
    await Promise.all(promises);

    // second sort the jobes based on date from lowest to hightst

    slinga.jobs.sort((job1: JobData, job2: JobData) => {
      if (job1.start === job1.start) {
        return job1.end < job2.end ? -1 : 1;
      } else {
        return job1.start < job2.start ? -1 : 1;
      }
    });

    slinga.status = getSlingaStatus(slinga);
  }

  return slinga;
}
