import React, { ReactElement, SyntheticEvent, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { UserType } from '../../utils/constants';

import './ListPage.css';

interface ListPageProps {
  heading: string;
  addButtonText?: string;
  onClickAddButton?: (event: SyntheticEvent) => void;
  onChangeSearch: (event: SyntheticEvent) => void;
  children: ReactElement;
}

/**
 *
 * Wrapper around the pages "Kunder", "Fordon", "Personal" since they have same design
 * @returns JSX.Element
 */
export default function ListPage({
  heading,
  addButtonText,
  onClickAddButton,
  onChangeSearch,
  children,
}: ListPageProps) {
  const { user } = useContext(AuthContext);

  function _onClickAddButton(event: SyntheticEvent) {
    onClickAddButton && onClickAddButton(event);
    event.preventDefault();
    requestAnimationFrame(() => {
      (document.querySelector('.button--close') as HTMLElement).focus();
    });
  }

  return (
    <main id='list-page' className='page'>
      <header id='list-page__header'>
        <h2>{heading}</h2>

        {user?.userType &&
        [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) &&
        addButtonText ? (
          <section id='list-page__header__buttons'>
            <button
              className='button--main'
              onClick={onClickAddButton}
              onKeyPress={_onClickAddButton}
            >
              <span className='fa fa-plus' /> {addButtonText}
            </button>
          </section>
        ) : null}
      </header>

      <input
        id='list-page__search'
        className='input-text'
        type='text'
        placeholder='Sök'
        onChange={onChangeSearch}
      />

      {children}
    </main>
  );
}
