import { Loader } from '@googlemaps/js-api-loader';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationStatus } from '../utils/constants';
import { GOOGLEMAPSAPIKEY } from '../utils/keys';
import { GoogleMapsState } from '../utils/types';
import { NotificationContext } from './NotificationContext';

const initialState: GoogleMapsState = {
  apiLoaded: false,
};

export const GoogleMapsContext = React.createContext(initialState);

interface GoogleMapsProviderProps {
  children: React.ReactElement;
}

export function GoogleMapsProvider({ children }: GoogleMapsProviderProps) {
  const [apiLoaded, setApiLoaded] = useState<boolean>(true);
  const { notify } = useContext(NotificationContext);

  const loader = new Loader({
    apiKey: GOOGLEMAPSAPIKEY,
    version: 'weekly',
    language: 'sv',
    libraries: ['places'],
  });

  useEffect(loadApi, []);

  function loadApi() {
    loader
      .load()
      .then((_) => {
        setApiLoaded(true);
      })
      .catch((_) => {
        notify('Kunde inte ladda in Google Maps API', NotificationStatus.ERROR);
      });
  }

  return <GoogleMapsContext.Provider value={{ apiLoaded }}>{children}</GoogleMapsContext.Provider>;
}
