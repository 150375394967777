import React, { useState } from 'react';
import { EquipmentType, vehicleNotAssigned, VehicleType } from '../../utils/constants';
import { getDefaultTimeFromMillis, getDefaultTimeToMillis } from '../../utils/time';
import {
  AddEditSlingaState,
  ContactData,
  JobData,
  SlingaData,
  SubcontractorData,
  User,
  VehicleData,
} from '../../utils/types';

const initialState: AddEditSlingaState = {
  name: '',
  vehicle: vehicleNotAssigned,
  start: 0,
  end: 0,
  vehicleEquipments: new Set(),
  comments: '',
  addedJobs: [],

  updateStateEditMode: () => {
    console.log('not defined');
  },
  setName: () => {
    console.log('not defined');
  },
  setEnd: () => {
    console.log('not defined');
  },
  setStart: () => {
    console.log('not defined');
  },
  setVehicle: () => {
    console.log('not defined');
  },
  setVehicleEquipments: () => {
    console.log('not defined');
  },
  setDriver: () => {
    console.log('not defined');
  },
  setSubcontractor: () => {
    console.log('not defined');
  },
  setContactSubcontractor: () => {
    console.log('not defined');
  },
  setComments: () => {
    console.log('not defined');
  },
  setAddedJobs: () => {
    console.log('not defined');
  },
};

interface AddEditSlingaProviderProps {
  children: React.ReactElement;
}

export const AddEditSlingaContext = React.createContext(initialState);

export function AddEditSlingaProvider({ children }: AddEditSlingaProviderProps) {
  const [name, setName] = useState<string>('');
  const [start, setStart] = useState<number>(getDefaultTimeFromMillis(new Date().getTime()));
  const [end, setEnd] = useState<number>(getDefaultTimeToMillis(new Date().getTime()));

  const [vehicle, setVehicle] = useState<VehicleData>(vehicleNotAssigned);
  const [vehicleEquipments, setVehicleEquipments] = useState<Set<string>>(new Set()); // array of doc ref ids

  const [driver, setDriver] = useState<User>();

  // SUBCONTRACTOR
  const [subcontractor, setSubcontractor] = useState<SubcontractorData>();
  const [contactSubcontractor, setContactSubcontractor] = useState<ContactData>();

  const [comments, setComments] = useState<string>('');

  const [addedJobs, setAddedJobs] = useState<JobData[]>([]);

  function updateStateEditMode(slinga: SlingaData) {
    // SUBCONTRACTOR
    if (slinga.subcontractor) {
      setSubcontractor(slinga.subcontractor);
      if (slinga.contactSubcontractor) {
        setContactSubcontractor(slinga.contactSubcontractor);
      }
    }

    setName(slinga.name);
    setStart(slinga.start);
    setEnd(slinga.end);

    slinga.driver && setDriver(slinga.driver);
    slinga.vehicle && setVehicle(slinga.vehicle);

    if (slinga.vehicleEquipments) {
      setVehicleEquipments(new Set(slinga.vehicleEquipments.map((e) => e.docId)));
    }

    if (slinga.jobs) {
      setAddedJobs(slinga.jobs);
    }
  }

  return (
    <AddEditSlingaContext.Provider
      value={{
        name,
        setName,
        start,
        setStart,
        end,
        setEnd,
        vehicle,
        setVehicle,
        vehicleEquipments,
        setVehicleEquipments,
        driver,
        setDriver,
        subcontractor,
        setSubcontractor,
        contactSubcontractor,
        setContactSubcontractor,
        updateStateEditMode,
        comments,
        setComments,
        addedJobs,
        setAddedJobs,
      }}
    >
      {children}
    </AddEditSlingaContext.Provider>
  );
}
