import { useContext, useState } from 'react';
import { Subcontractor, SubcontractorDoc } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import ClientForm from '../forms/ClientForm';
import { stopPropagation } from '../utils/uiHelpers';
import { PopupContext } from '../contexts/PopupContext';
import { addSubcontractor } from '../firebase/firestore_functions/subcontractors';

export default function AddSubcontractor() {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  async function handleSubmit(subcontractor: Subcontractor) {
    setSaving(true);

    const addSubcontractorResponse = await addSubcontractor({
      ...(subcontractor as SubcontractorDoc),
      contacts: [],
    });

    if (addSubcontractorResponse.code === 201) {
      setSaving(false);
      notify('Underleverantör tillagd', NotificationStatus.SUCCESS);
      close();
    } else {
      setSaving(false);
      notify(
        `Kunde inte spara underleverantören: ${addSubcontractorResponse.error}`,
        NotificationStatus.ERROR,
      );
    }
  }

  return (
    <section id='add-client' onClick={stopPropagation}>
      <header id='add-client__header'>
        <h2>Lägg till en ny underleverantör</h2>
      </header>
      <main>
        <ClientForm handleSubmit={handleSubmit} saving={saving} />
      </main>
    </section>
  );
}
