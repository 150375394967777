import { collection, doc, getFirestore, query } from '@firebase/firestore';
import { getDoc, onSnapshot } from '@firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import {
  ClientData,
  ContactData,
  LittraData,
  LittraDoc,
  ContactDoc,
  ClientDoc,
  UpdateContactParams,
} from '../utils/types';
import ConfirmAction from '../components/confirm_action';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import EditClient from './EditClient';
import { stopPropagation } from '../utils/uiHelpers';
import AddContact from './AddContact';
import AddLittra from './AddLittra';
import EditContact from './EditContact';
import EditLittra from './EditLittra';
import { populateDocs } from '../firebase/firestore_functions/common';
import { deleteClient, deleteLittra } from '../firebase/firestore_functions/clients';
import { deleteContact, addContact } from '../firebase/firestore_functions/contacts';

interface ClientDetailsProps {
  client: ClientData;
}

const db = getFirestore();

export default function ClientDetails({ client }: ClientDetailsProps) {
  const [clientDetails, setClientInfo] = useState<ClientData>(client);
  const [littras, setLittras] = useState<LittraData[]>([]);

  const { notify } = useContext(NotificationContext);
  const { close, showPopup } = useContext(PopupContext);

  useEffect(() => {
    setListenerClient();
    setListenerLittras();
  }, []);

  function setListenerClient() {
    try {
      const q = doc(db, 'clients', client.docId);

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        if (querySnapshot.exists()) {
          const newClient = querySnapshot.data() as ClientData;
          newClient.docId = querySnapshot.id;

          // populate contacts
          if (newClient.contacts && newClient.contacts.length > 0) {
            const contactsResponse = await populateDocs(
              (querySnapshot.data() as ClientDoc).contacts,
            );

            if (contactsResponse.code === 200 && contactsResponse.data) {
              newClient.contacts = contactsResponse.data;
            }
          }

          setClientInfo(newClient);
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  async function setListenerLittras() {
    try {
      const q = query(collection(db, `clients/${client.docId}/littras`));

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const promises: Promise<void>[] = [];
        const _littras: LittraData[] = [];

        for (const doc of querySnapshot.docs) {
          promises.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise(async (resolve, reject) => {
              const littraDoc: LittraDoc = doc.data() as LittraDoc;

              let contactDoc;
              if (littraDoc.contact) {
                contactDoc = await getDoc(littraDoc.contact);
              }

              if (contactDoc && contactDoc.exists()) {
                const contact: ContactData = {
                  ...(contactDoc.data() as ContactDoc),
                  docId: contactDoc.id,
                };
                _littras.push({ ...littraDoc, contact, docId: doc.id });
              } else {
                _littras.push({
                  ...littraDoc,
                  contact: undefined,
                  docId: doc.id,
                });
              }
              resolve();
            }),
          );
        }

        await Promise.all(promises);

        setLittras(_littras);
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  function confirmDeleteClient() {
    showPopup(
      <ConfirmAction
        confirm={_deleteClient}
        heading='Är du säker på det här?'
        message='Kunden kommer raderas från systemet inklusive registret i fortnox!'
      />,
      'small',
    );
  }

  function confirmDeleteLittra(idx: number) {
    showPopup(
      <ConfirmAction
        confirm={() => _deleteLittra(idx)}
        heading='Är du säker på det här?'
        message='Littrat kommer raderas från systemet och kan inte återställas'
      />,
      'small',
    );
  }

  function confirmDeleteContact(idx: number) {
    showPopup(
      <ConfirmAction
        confirm={() => _deleteContact(idx)}
        heading='Är du säker på det här?'
        message='Kontakten kommer raderas från systemet och kan inte återställas'
      />,
      'small',
    );
  }

  async function _deleteClient() {
    const deleteClientResponse = await deleteClient(client.docId);
    if (deleteClientResponse.code === 201) {
      close();
      notify('Kund raderad', NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte radera kunden: ${deleteClientResponse.error}`, NotificationStatus.ERROR);
    }
  }

  async function _deleteContact(idx: number) {
    const deleteContactResponse = await deleteContact(
      clientDetails.contacts[idx].docId,
      clientDetails,
      'clients',
    );
    if (deleteContactResponse.code === 201) {
      notify('Kontakt raderad', NotificationStatus.SUCCESS);
    } else {
      notify(
        `Kunde inte radera kontakten: ${deleteContactResponse.error}`,
        NotificationStatus.ERROR,
      );
    }
  }

  async function _deleteLittra(idx: number) {
    const deleteLittraResponse = await deleteLittra(client.docId, littras[idx].docId);
    if (deleteLittraResponse.code === 201) {
      notify('Littra raderat', NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte radera littrat: ${deleteLittraResponse.error}`, NotificationStatus.ERROR);
    }
  }

  function openEditClient(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(
      <EditClient client={{ ...clientDetails, contacts: client.contacts, littras }} />,
      'big',
    );
  }

  async function onAddContact(contact: ContactDoc) {
    return await addContact(contact, clientDetails, 'clients');
  }

  function onEditContact(changes: UpdateContactParams, docId: string) {
    // to refect updates in contact list
    for (let i = 0; i < clientDetails.contacts.length; ++i) {
      if (clientDetails.contacts[i].docId === docId) {
        clientDetails.contacts[i] = { ...clientDetails.contacts[i], ...changes };
      }
    }

    setClientInfo({ ...clientDetails });
  }

  function openAddContact(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<AddContact onAdd={onAddContact} />, 'big');
  }

  function openAddLittra(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<AddLittra client={clientDetails} />, 'big');
  }

  function openEditContact(event: SyntheticEvent, contact: ContactData) {
    event.preventDefault();
    showPopup(<EditContact contact={contact} onEdit={onEditContact} />, 'big');
  }

  function openEditLittra(event: SyntheticEvent, littra: LittraData) {
    event.preventDefault();
    showPopup(<EditLittra client={clientDetails} littra={littra} />, 'big');
  }

  return (
    <section className='details' onClick={stopPropagation}>
      <header className='details__header'>
        <h2>{clientDetails.name}</h2>
        <section className='details__header__controls'>
          <button className='fa fa-edit button--icon' onClick={openEditClient}></button>

          <button onClick={confirmDeleteClient} className='fa fa-trash button--icon'></button>
        </section>
      </header>
      <main>
        <section className='details__info'>
          <div className='info'>
            <p className='label'>Organisationsnummer</p>
            <p>{clientDetails.orgNum}</p>
          </div>
          <div className='info'>
            <p className='label'>Namn</p>
            <p>{clientDetails.name}</p>
          </div>

          <div className='info'>
            <p className='label'>Email</p>
            <p>{clientDetails.email}</p>
          </div>

          <div className='info'>
            <p className='label'>Tel</p>
            <p>{clientDetails.phone}</p>
          </div>

          <ul className='form__ul form'>
            <li className='form__li' key='contacts'>
              <div className='form__header'>
                <p className='label'>Kontakter</p>
                <button className='button--main--small form__button' onClick={openAddContact}>
                  <span className='fa fa-plus' />
                  Lägg till
                </button>
              </div>
              <ul id='client-details__form__li__contacts'>
                {(!clientDetails.contacts || clientDetails.contacts.length === 0) && (
                  <li key='no-contacts'>Inga tillagda kontakter</li>
                )}
                {clientDetails.contacts &&
                  clientDetails.contacts.map((contact, idx) => {
                    return (
                      <li key={idx} className='form__list__item'>
                        <p>
                          {contact.name} {' - '} {contact.phone}
                        </p>

                        <div>
                          <button
                            onClick={(event: SyntheticEvent) => {
                              openEditContact(event, contact);
                            }}
                            className='button--icon fa fa-edit'
                          ></button>
                          <button
                            onClick={() => {
                              confirmDeleteContact(idx);
                            }}
                            className='fa fa-trash button--icon'
                          ></button>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </li>

            <li className='form__li' key='littras'>
              <div className='form__header'>
                <p className='label'>Littras</p>
                <button className='button--main--small' onClick={openAddLittra}>
                  <span className='fa fa-plus' />
                  Lägg till
                </button>
              </div>

              <ul>
                {littras.length === 0 && <li key='no-littras'>Inga littras</li>}
                {littras.map((littra, idx) => {
                  return (
                    <li key={idx} className='form__list__item'>
                      <p>
                        {littra.projectNum} {' - '}{' '}
                        {littra.contact
                          ? littra.contact.name
                          : littra.tempContact
                          ? littra.tempContact
                          : ''}
                      </p>

                      <div>
                        <button
                          onClick={(event: SyntheticEvent) => {
                            openEditLittra(event, littra);
                          }}
                          className='button--icon fa fa-edit'
                        ></button>

                        <button
                          onClick={() => {
                            confirmDeleteLittra(idx);
                          }}
                          className='fa fa-trash button--icon'
                        ></button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </section>
      </main>
    </section>
  );
}
