import React, { useEffect, useState } from 'react';
import { CalendarMode } from '../utils/constants';
import history from '../utils/history';
import { NavigationState } from '../utils/types';

interface NavigationContextProviderProps {
  children: React.ReactElement;
}

const initialState: NavigationState = {
  navigationStack: [],
  setNavigationStack: () => undefined,
  setBackPressedBlocker: () => undefined,
};

export const NavigationContext = React.createContext(initialState);

export function NavigationContextProvider({ children }: NavigationContextProviderProps) {
  const [navigationStack, setNavigationStack] = useState<Array<CalendarMode>>([]);

  useEffect(() => {
    return setHistoryListener();
  }, []);

  //////////////////////////
  // NAVIGATION handlers //
  ////////////////////////

  /**
   * when history is empty, history.block is not called so we add extra history when navigating to each page.
   * @returns unregisterCallback
   */
  function setHistoryListener() {
    //history.push("/");
    return history.listen((location, action) => {
      if (action === 'POP' && location.pathname === '/') {
        history.goBack();
      }
    });
  }

  /**
   * Blocks navigation backwards if the user has moved between calender modes i.e. navigationStack has entries.
   * @returns UnregisterCallback
   */
  function setBackPressedBlocker(onChangeView: (calendarMode: string, NUM_OF_DAYS_LOOK_AHEAD: number) => void) {
    return history.block((_, action) => {
      if (navigationStack.length > 0 && action === 'POP') {
        // pop the navigation stack
        const _navigationStack = [...navigationStack];
        const _calendarMode = _navigationStack.pop();
        setNavigationStack(_navigationStack);

        // change to new calendar mode
        if (_calendarMode !== undefined) {
          onChangeView(_calendarMode.toString(), 50);
        }

        return false;
      }
    });
  }

  return (
    <NavigationContext.Provider
      value={{
        navigationStack,
        setNavigationStack,
        setBackPressedBlocker,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}
