import { FirebaseError } from 'firebase/app';
import { addDoc, collection, doc, getDocs, getFirestore, updateDoc } from 'firebase/firestore';
import { Equipment, EquipmentDoc, Equipments, UpdateEquipmentParams } from '../../utils/types';

const db = getFirestore();

/**
 * Adds an equipment to db
 * @param contact EquipmentDoc
 * @returns Promise<FirebaseResponse>
 */
export async function addEquipment(equipment: EquipmentDoc) {
  try {
    await addDoc(collection(db, 'equipments'), equipment);
    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: parseInt((e as FirebaseError).code),
      error: (e as FirebaseError).message,
    };
  }
}

/**
 *
 * @param updates UpdateEquipmentParams
 * @param docId id of document to update
 * @returns Promise<FirebaseReponse>
 */
export async function updateEquipment(updates: UpdateEquipmentParams, docId: string) {
  try {
    await updateDoc(doc(db, 'equipments', docId), updates as { [x: string]: any });
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Gets a list of all equipments
 * @returns Promise<FirebaseResponse>
 */
export async function getEquipments() {
  try {
    const response = await getDocs(collection(db, 'equipments'));
    const equipments: Equipments = {};

    for (const doc of response.docs) {
      equipments[doc.id] = doc.data() as Equipment;
    }

    return {
      code: 200,
      data: equipments,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}
