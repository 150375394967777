import { View, Text, Image } from '@react-pdf/renderer';
import { JobData } from '../../utils/types';
import PdfReport from './PdfReport';
import styles from './PDFStyles';
import { toDayDateString } from '../../utils/time';
import { getJobSignature, getTotalHours } from '../../utils/jobUtils';

export type Props = {
  job: JobData;
};

const OrderRow = ({ job }: Props) => {
  // Sort reports by start time
  if (job.reports && job.reports.length > 0) {
    job.reports.sort((a, b) => {
      return a.start < b.start ? -1 : 1;
    });
  }

  const totalHours = job.reports ? getTotalHours(job.reports) : null;
  const totalMiles = getTotalMiles();

  /**
   * Gets the number of miles driven in the job in total. If no miles is reportet it returnes null.
   */
  function getTotalMiles() {
    if (job.reports) {
      const totalMiles = job.reports.reduce((currentSum, report) => {
        return currentSum + report.miles;
      }, 0);

      return totalMiles > 0 ? totalMiles : null;
    }

    return null;
  }

  /**
   * Constructs the service literal displayed under "Tjänst"
   *
   * */
  function getServiceLiteral() {
    let literal = '';
    // if (job.vehicle) literal += job.vehicle.service;
    // job.vehicleEquipments?.forEach((eq) => {
    //   literal += `, ${eq.service}`;
    // });
    if (!job) return '';
    if (!job.vehicle && !job.vehicleEquipments) return '';

    literal += job.vehicle?.id;

    literal += '(';

    literal += job.vehicle?.serviceId;

    literal += ')';

    literal += ' ';

    job.vehicleEquipments?.forEach((eq) => {
      literal += `${eq.service}(${eq.serviceId}), `;
    });

    // remove last comma
    literal = literal.substring(0, literal.length - 2);

    return literal;
  }

  return (
    <View style={{ ...styles.section, paddingTop: 12 }} key={Math.random()}>
      <View style={styles.row}>
        <View style={{ ...styles.column, width: '30%' }}>
          <Text style={styles.title}>Ordernummer</Text>
          <Text style={styles.body}>{job.orderNum}</Text>
        </View>
        <View style={{ ...styles.column, width: '40%' }}>
          <Text style={styles.title}>Datum</Text>
          <Text style={styles.body}>{toDayDateString(job.start)}</Text>
          <Text style={styles.body}>{toDayDateString(job.end)}</Text>
        </View>
        <View style={{ ...styles.column, width: '30%' }}>
          <Text style={styles.title}>Utförare</Text>
          <Text style={styles.body}>{!!job.driver && job.driver.firstName}</Text>
        </View>
      </View>
      <View style={{ ...styles.row, marginBottom: 24 }}>
        <View style={{ ...styles.column, width: '30%' }}>
          <Text style={styles.title}>Tjänst</Text>
          <Text style={styles.body}>{getServiceLiteral()}</Text>
        </View>
        <View style={{ ...styles.column, width: '40%' }}>
          <Text style={styles.title}>Timmar</Text>
          <Text style={styles.body}>{totalHours ? totalHours.toString() : '-'}</Text>
        </View>
        {totalMiles && (
          <View style={{ ...styles.column, width: '30%' }}>
            <Text style={styles.title}>Mil</Text>
            <Text style={styles.body}>{totalMiles.toString()}</Text>
          </View>
        )}
      </View>

      {/* Map the reports in tables */}
      {!!job.reports &&
        job.reports.length > 0 &&
        job.reports.map((report) => <PdfReport key={report.start} report={report} job={job} />)}

      <View
        style={{ ...styles.row, paddingTop: 12, borderTop: '1px solid lightgrey', marginTop: 24 }}
        wrap={false}
      >
        <View style={{ ...styles.column, width: '30%' }}>
          <Text style={styles.title}>Signatur</Text>
          {job.signed && <Image src={getJobSignature(job)} />}
        </View>
        <View style={{ ...styles.column, width: '40%' }}>
          <Text style={styles.title}>Namnförtydligande</Text>
          <Text style={styles.body}>{job.signedBy && job.signedBy.name}</Text>
        </View>
        <View style={{ ...styles.column, width: '30%' }}>
          <Text style={styles.title}>Mail</Text>
          <Text style={styles.body}>{job.signedBy && job.signedBy.email}</Text>
        </View>
        <View style={{ ...styles.column, width: '30%' }}>
          <Text style={styles.title}>Kommentar</Text>
          {job.signed && <Text style={styles.body}>{job.signedBy?.comment}</Text>}
        </View>
      </View>
    </View>
  );
};

export default OrderRow;
