import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth, signInAnonymously } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { getJob } from '../../firebase/firestore_functions/job';
import Sign from '../../popups/sign_job/Sign';
import { NotificationStatus } from '../../utils/constants';
import { JobData } from '../../utils/types';

const RemoteSign: React.FC = () => {
  const { isAuthenticated, isAnonymous } = useContext(AuthContext);
  const { notify } = useContext(NotificationContext);
  const [docId, setDocId] = useState<string>('');
  const [initDone, setInitDone] = useState(false);
  const [job, setJob] = useState<JobData>();
  const location = useLocation();

  const signIn = () => {
    signInAnonymously(getAuth());
  };

  const extractDocId = () => {
    if (isAuthenticated && isAnonymous) {
      setDocId(location.pathname.split('-')[1]); // Extrat from url
    }
  };

  const getJobData = async () => {
    if (docId === '') return;
    const jobResponse = await getJob(docId);

    if (jobResponse.data) {
      setJob(jobResponse.data);
    } else if (jobResponse.code === 404) {
      notify('Uppdraget hittades inte', NotificationStatus.ERROR);
    } else {
      notify('Något gick fel vid hämtning av uppdraget. ', NotificationStatus.ERROR);
    }

    setInitDone(true);
  };

  // First anon sign in, then extract doc id from firestore
  // then finally grab the job data so we can render sign component
  useEffect(signIn, []);
  useEffect(extractDocId, [isAuthenticated, isAnonymous, location]);
  useEffect(() => {
    getJobData();
  }, [docId]);

  return (
    <section style={{ padding: '1.6rem', overflow: 'auto' }}>
      {!initDone ? (
        <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
      ) : (
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
            minWidth: '300px',
          }}
        >
          <Sign job={job!} />
        </div>
      )}
    </section>
  );
};

export default RemoteSign;
