import { useContext } from 'react';
import { AddEditJobContext } from './AddEditJobContext';
import AddSubcontractor from '../../components/components_for_add_edit_job_slinga/AddSubcontractor';

/**
 * Component that wraps 'AddSubcontractor' and passes it data state variables from 'AddEditJobContext'
 */
export default function AddSubcontractorToJob() {
  const { subcontractor, setSubcontractor } = useContext(AddEditJobContext);

  return <AddSubcontractor subcontractor={subcontractor} setSubcontractor={setSubcontractor} />;
}
