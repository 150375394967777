import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import './Settings.css';
import { firebaseApp } from '../../firebase/firebaseConfig';
import { getAuth, signOut } from 'firebase/auth';
import { NotificationContext } from '../../contexts/NotificationContext';
import { NotificationStatus } from '../../utils/constants';
import { PopupContext } from '../../contexts/PopupContext';
import ConfirmWithPassword from './ConfirmWithPassword';
import { updateUser } from '../../firebase/firestore_functions/users';

const auth = getAuth(firebaseApp);
export default function Settings() {
  const { user, fetchUser } = useContext(AuthContext);
  const { notify } = useContext(NotificationContext);
  const { showPopup } = useContext(PopupContext);

  const [email, setEmail] = useState(user?.email ? user.email : '');
  const [phone, setPhone] = useState(user?.phone ? user.phone : '');
  const [orgEmail, setOrgEmail] = useState(user?.email ? user.email : '');
  const [orgPhone, setOrgPhone] = useState(user?.phone ? user.phone : '');
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);

  useEffect(() => {
    setEmail(user?.email ? user.email : '');
    setOrgEmail(user?.email ? user.email : '');

    setPhone(user?.phone ? user.phone : '');
    setOrgPhone(user?.phone ? user?.phone : '');
  }, [user]);

  function onChangeEmail(event: SyntheticEvent) {
    setEmail((event.target as HTMLInputElement).value);
  }

  function onChangePhone(event: SyntheticEvent) {
    setPhone((event.target as HTMLInputElement).value);
  }

  function signOutFirebase() {
    signOut(auth);
  }

  function toggleEditEmail() {
    setEditEmail(!editEmail);
  }

  function toggleEditPhone() {
    setEditPhone(!editPhone);
  }

  function getUpdates() {
    const updates: any = {};

    if (email !== orgEmail) {
      updates.email = email;
    }

    if (phone !== orgPhone) {
      updates.phone = phone;
    }

    return updates;
  }

  async function save() {
    if (user && auth.currentUser) {
      const updates = getUpdates();

      const updateUserResponse = await updateUser(updates, user.docId);

      if (updateUserResponse.code === 201) {
        fetchUser(auth.currentUser);

        notify('Dina uppgifter är sparade', NotificationStatus.SUCCESS);

        setEditEmail(false);
        setEditPhone(false);
      }
    }
  }

  function checkIfReauthenticate() {
    if (email !== orgEmail) {
      showPopup(<ConfirmWithPassword save={save} />, 'small');
    } else {
      save();
    }
  }

  return (
    <main className='page'>
      <header id='settings__header'>
        <h2>Inställningar</h2>
        <button className='button--main' id='logout' onClick={signOutFirebase}>
          Logga ut
        </button>
      </header>

      <section id='settings__content'>
        <h3 style={{ marginBottom: '3.2rem' }}>
          {user?.firstName} {user?.lastName}
        </h3>
        <div className='info'>
          {editEmail ? (
            <>
              <label className='label' htmlFor='email'>
                Epost
              </label>
              <input
                id='email'
                type='text'
                className='input-text'
                value={email}
                onChange={onChangeEmail}
              />
              <button onClick={toggleEditEmail} className='button--icon fa fa-edit'></button>
            </>
          ) : (
            <>
              <p className='label'>Epost</p>
              <p>{email}</p>
              <button onClick={toggleEditEmail} className='button--icon fa fa-edit'></button>
            </>
          )}
        </div>

        <div className='info'>
          {editPhone ? (
            <>
              <label className='label' htmlFor='phone'>
                Tel
              </label>
              <input
                id='phone'
                type='text'
                className='input-text'
                value={phone}
                onChange={onChangePhone}
              />
              <button className='button--icon fa fa-edit' onClick={toggleEditPhone}></button>
            </>
          ) : (
            <>
              <p className='label'>Tel</p>
              <p>{phone ? phone : 'inte tillagt'}</p>
              <button className='button--icon fa fa-edit' onClick={toggleEditPhone}></button>
            </>
          )}
        </div>

        <button
          onClick={checkIfReauthenticate}
          id='save'
          className={
            email === orgEmail && phone === orgPhone ? 'button--green disabled' : 'button--green'
          }
          disabled={email === orgEmail && phone === orgPhone}
        >
          Spara
        </button>
      </section>
    </main>
  );
}
