import { FirebaseError } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  updateEmail,
} from 'firebase/auth';
import { getFirestore, collection, getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';

import { firebaseAppAdmin } from '../firebaseConfig';
import { User, UpdateUserParams, UserDoc } from '../../utils/types';

const db = getFirestore();
const auth = getAuth();

/**
 * Gets a user from db
 * @param docId id of user
 * @returns Promise<FirebaseResponse>
 */
export async function getUser(docId: string) {
  try {
    const userDoc = await getDoc(doc(db, 'users', docId));
    if (userDoc.exists()) {
      return {
        code: 200,
        data: { ...(userDoc.data() as User), docId: userDoc.id },
      };
    } else {
      return { code: 401, error: 'user' };
    }
  } catch (e) {
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 *
 * @param updates UpdateUserParams
 * @param docId id of document to update
 * @returns Promise<FirebaseReponse>
 */
export async function updateUser(updates: UpdateUserParams, docId: string) {
  try {
    // 1. update firestore doc
    await updateDoc(doc(collection(db, 'users'), docId), updates as { [x: string]: any });

    // 2. also update email in auth if changed
    if (updates.email && auth.currentUser) {
      if (auth.currentUser) {
        await updateEmail(auth.currentUser, updates.email);
      } else {
        return { code: 404, error: 'currentUser' };
      }
    }

    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * @param user UserDoc
 * @returns Promise<FirebaseResponse>
 */
export async function addUser(user: UserDoc) {
  const auth = getAuth(firebaseAppAdmin);
  const password = generatePassword();

  try {
    const response = await createUserWithEmailAndPassword(auth, user.email, password);

    // so user can choose their password
    await sendPasswordResetEmail(auth, user.email);

    await setDoc(doc(db, 'users', response.user.uid), user);

    return { code: 201 };
  } catch (e) {
    console.log(e);
    return {
      code: (e as FirebaseError).code === 'auth/email-already-in-use' ? 409 : 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 * Generates a random password that is temporarily used when a new user is created
 * @returns password
 */
function generatePassword() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!?';
  let password = '';

  for (let i = 0; i < 8; ++i) {
    password += chars[Math.ceil(Math.random() * chars.length - 1)];
  }

  return password;
}
