import { useContext, useEffect, useState } from 'react';

import {
  JobData,
  JobDoc,
  Place,
  UpdateJobParams,
  UpdateJobParamsData,
  User,
  VehicleData,
} from '../../utils/types';

import Option from '../../components/select/Option';
import Select from '../../components/select/Select';
import {
  JobStatus,
  jobStatusLabel,
  JobType,
  jobTypeLabel,
  NotificationStatus,
  UserType,
  vehicleNotAssigned,
  VehicleType,
  vehicleTypeLabel,
} from '../../utils/constants';
import './AddEditJob.css';

import Datepicker from '../../components/datepicker/Datepicker';
import Timepicker from '../../components/timepicker/Timepicker';
import { NotificationContext } from '../../contexts/NotificationContext';
import { toDayDateString } from '../../utils/time';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PickAPlace from '../../components/PickAPlace';
import Textarea from '../../components/Textarea';
import { PopupContext } from '../../contexts/PopupContext';
import { fromJobDataToJobDoc, updateJob } from '../../firebase/firestore_functions/job';
import AddEquipments from './AddEquipmentsToJob';
import AddMaterials from './AddMaterials';

import { addJob, deleteJob } from '../../firebase/firestore_functions/job';

import AlertPopup from '../../components/alert_popup/AlertPopup';
import AddImages from '../../components/components_for_add_edit_job_slinga/AddImages';
import ConfirmAction from '../../components/confirm_action';
import { DataContext } from '../../contexts/CommonDataContext';
import { updateLittra } from '../../firebase/firestore_functions/clients';
import { deleteImageStorage, getImageUrl, saveImageStorage } from '../../firebase/storageFunctions';
import AddClient from './AddClientToJob';
import AddContactClient from './AddContactClientJob';
import AddContactSubcontractor from './AddContactSubcontractorToJob';
import { AddEditJobContext } from './AddEditJobContext';
import AddLittra from './AddLittra';
import AddSubcontractor from './AddSubcontractorToJob';

import { v4 as uuidv4 } from 'uuid';
import { b64ToBlob } from '../../utils/otherHelpers';
import { AuthContext } from '../../contexts/AuthContext';

interface AddJobProps {
  job?: JobData; // for edit mode
  onDeleteJob?: () => void;
}

function AddJob({ job, onDeleteJob }: AddJobProps) {
  const { notify } = useContext(NotificationContext);
  const { showPopup, close } = useContext(PopupContext);

  const [initDone, setInitDone] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);

  const { drivers, vehicles } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  const distanceService = new google.maps.DistanceMatrixService();
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  // data states for the job
  const {
    jobType,
    setJobType,
    status,
    setStatus,
    from,
    setFrom,
    to,
    setTo,
    estimatedDistance,
    estimatedTime,
    setEstimatedDistance,
    setEstimatedTime,
    start,
    setStart,
    end,
    setEnd,
    vehicle,
    setVehicle,
    vehicleEquipments,
    driver,
    setDriver,
    client,
    contactClient,
    contactClientTemp,
    littra,
    littraTemp,
    littraWorkplaceTemp,
    subcontractor,
    contactSubcontractor,
    materials,
    images,
    setImages,
    otherInformation,
    setOtherInformation,
    otherMaterials,
    setOtherMaterials,
    adminComments,
    setAdminComments,
    updateStateEditMode,
  } = useContext(AddEditJobContext);

  const [saving, setSaving] = useState<boolean>(false);

  const isLej = [VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
    vehicle.vehicleType,
  );

  //////////////////
  // USE EFFECTS //
  /////////////////

  useEffect(init, []);

  useEffect(getDistance, [from, to]);

  function init() {
    if (job) {
      updateStateEditMode(job);
      setEditMode(true);
      setInitDone(true);
    } else {
      setInitDone(true);
    }
  }

  ////////////////////////
  // ON CHANGE HANDLERS //
  ////////////////////////

  function onChangeJobType(value: string) {
    setJobType(parseInt(value));
  }

  function onChangeStatus(value: string) {
    setStatus(parseInt(value));
  }

  function onChangeVehicle(value: string) {
    // update vehicle
    const _vehicle = vehicles.find((v) => v.docId === value);

    if (_vehicle) {
      setVehicle(_vehicle);

      // set default driver
      if (_vehicle.driver) {
        setDriver(_vehicle.driver);
      } else {
        // reset
        setDriver(undefined);
      }
    } else {
      // reset
      setVehicle(vehicleNotAssigned);
    }
  }

  function onChangeDriver(value: string) {
    const _driver = drivers.find((d) => d.docId === value);
    if (_driver) {
      setDriver(_driver);
    } else {
      setDriver(undefined);
    }
  }

  function onChangeDateFrom(date: number) {
    const _date = new Date(date);
    _date.setHours(new Date(start).getHours());
    _date.setMinutes(new Date(start).getMinutes());
    setStart(_date.getTime());

    if (_date.getTime() > end) {
      //Update the To-date if From-date is later than it
      _date.setHours(new Date(end).getHours());
      setEnd(_date.getTime());
    }
  }

  function onChangeDateTo(date: number) {
    const _date = new Date(date);
    _date.setHours(new Date(end).getHours());
    _date.setMinutes(new Date(end).getMinutes());
    setEnd(_date.getTime());

    if (_date.getTime() < start) {
      //Update the To-date if From-date is later than it
      _date.setHours(new Date(start).getHours());
      setStart(_date.getTime());
    }
  }

  function onChangeHoursFrom(hours: number) {
    const _date = new Date(start);
    _date.setHours(hours);
    setStart(_date.getTime());
  }

  function onChangeMinutesFrom(minutes: number) {
    const _date = new Date(start);
    _date.setMinutes(minutes);
    setStart(_date.getTime());
  }

  function onChangeHoursTo(hours: number) {
    const _date = new Date(end);
    _date.setHours(hours);
    setEnd(_date.getTime());
  }

  function onChangeMinutesTo(minutes: number) {
    const _date = new Date(end);
    _date.setMinutes(minutes);
    setEnd(_date.getTime());
  }

  function swapPlaces() {
    if (to && from) {
      setFrom(to);
      setTo(from);
    }
  }

  async function getUpdates() {
    const updates: UpdateJobParamsData = {
      vehicleEquipments: Array.from(vehicleEquipments).map((e) => {
        return {
          id: e,
          docId: e,
          equipmentType: 0,
          service: '',
          serviceId: '',
        };
      }),
    };
    if (job) {
      if (materials) {
        updates.materials = Object.values(materials);
      }

      if (start !== job.start || end !== job.end) {
        updates.start = start;
        updates.end = end;
      }

      if (status !== job.status) {
        updates.status = status;
      }
      if (to?.name !== job.to?.name) {
        updates.to = to;
      }

      if (from?.name !== job.from?.name) {
        updates.from = from;
      }
      if (otherMaterials !== job.otherMaterials) {
        updates.otherMaterials = otherMaterials;
      }
      if (otherInformation !== job.otherInformation) {
        updates.otherInformation = otherInformation;
      }
      if (adminComments !== job.adminComments) {
        updates.adminComments = adminComments;
      }
      if (driver?.docId !== job.driver?.docId) {
        updates.driver = driver;
      }
      if (vehicle.docId !== job.vehicle?.docId) {
        updates.vehicle = vehicle;
      }
      /*       if (client?.docId === job.client?.docId) { 
        updates.client = client;
        } */
      /*
       * NOTE (ps): Other fields may depend on the client so we always want to update it (e.g littras are stored in client).
       * May not be the most optimal solution, but it works for now.
       */
      updates.client = client;

      if (contactClient && contactClient?.docId !== job.contactClient?.docId) {
        updates.contactClient = contactClient;
      }
      if (contactClientTemp !== job.contactClientTemp) {
        updates.contactClientTemp = contactClientTemp;
      }
      if (subcontractor?.docId !== job.subcontractor?.docId) {
        updates.subcontractor = subcontractor;
      }
      if (contactSubcontractor?.docId !== contactSubcontractor) {
        updates.contactSubcontractor = contactSubcontractor;
      }
      if (littra && littra?.docId !== job.littra?.docId) {
        updates.littra = littra;
      }
      if (littraTemp !== job.littraTemp) {
        updates.littraTemp = littraTemp;
      }
      if (littraWorkplaceTemp !== job.littraWorkplaceTemp) {
        updates.littraWorkplaceTemp = littraTemp;
      }
      if (estimatedDistance?.meters !== job.estimatedDistance?.meters) {
        updates.estimatedDistance = estimatedDistance;
      }
      if (estimatedTime?.seconds !== job.estimatedTime?.seconds) {
        updates.estimatedTime = estimatedTime;
      }

      updates.images = await updateImagesInStorage();

      updates.estimatedDistance = undefined;
    }
    return updates;
  }

  async function updateImagesInStorage() {
    const updatedImages: string[] = [];

    if (job) {
      const promises: Promise<any>[] = [];

      for (const img of images) {
        // if added image
        if (!job.images || !job.images.includes(img)) {
          promises.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise<void>(async (resolve, _) => {
              // save image
              const imageId = uuidv4();
              await saveImageStorage(`/${job.orderNum}/from_office/${imageId}`, b64ToBlob(img));

              // get the new url
              const urlResponse = await getImageUrl(`/${job.orderNum}/from_office/${imageId}`);

              if (urlResponse.data) {
                updatedImages.push(urlResponse.data);
              }

              resolve();
            }),
          );
        } else {
          updatedImages.push(img);
        }
      }

      for (const img of deletedImages) {
        promises.push(deleteImageStorage(img));
      }

      await Promise.all(promises);
    }
    return updatedImages;
  }

  async function save() {
    // client and place is required
    if (!client) {
      showAlertNoClient();
      return;
    }

    if (job) {
      setSaving(true);
      const updates = await getUpdates();
      saveUpdatedJob(job.docId, updates);
    } else {
      setSaving(true);
      const newJobData: JobData = {
        jobType,
        orderNum: 1,
        docId: '1', // fake for now since required in jobData,
        start,
        end,
        status,
        otherMaterials,
        hasReceiptsForCompany: false,
        adminComments,
        littraTemp: littraTemp,
        littraWorkplaceTemp: littraWorkplaceTemp,
        from,
        to,
        vehicle,
        vehicleEquipments: Array.from(vehicleEquipments).map((e) => {
          return {
            id: e,
            docId: e,
            equipmentType: 0,
            service: '',
            serviceId: '',
          };
        }),
        materials: Object.values(materials),
        littra,
        otherInformation,
        contactClientTemp,
        images,
        smsHasBeenSent: false,
      };

      if (driver) newJobData.driver = driver;
      if (client) newJobData.client = client;
      if (subcontractor) newJobData.subcontractor = subcontractor;
      if (contactClient) newJobData.contactClient = contactClient;
      if (contactSubcontractor) newJobData.contactSubcontractor = contactSubcontractor;
      if (estimatedTime) newJobData.estimatedTime = estimatedTime;
      if (estimatedDistance) newJobData.estimatedDistance = estimatedDistance;
      addNewJob(fromJobDataToJobDoc(newJobData));
    }
  }

  function showAlertNoClient() {
    showPopup(<AlertPopup message='Du har inte lagt till kund!' />, 'small');
  }

  function showAlertNoLittra() {
    showPopup(
      <AlertPopup message='Du måste lägga till littra och kund för att kunna spara platsen på littrat!' />,
      'small',
    );
  }

  async function saveUpdatedJob(docId: string, updates: UpdateJobParams) {
    let updateResponse;

    if (updates.start || updates.end) {
      // Why do we have different update responses?
      updateResponse = await updateJob(docId, updates, job?.start, job?.end);
    } else {
      updateResponse = await updateJob(docId, updates);
    }

    setSaving(false);

    if (updateResponse.code === 201) {
      // send sms to driver
      let link;
      let phone;
      if (
        [VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
          vehicle.vehicleType,
        )
      ) {
        if (subcontractor) {
          if (contactSubcontractor) {
            link = `Hej ${contactSubcontractor.name}!\n\nDu har ett uppdaterat uppdrag från Nolblad:\nhttps://nolblad.se/job-${job?.docId}`;
            phone = contactSubcontractor.phone;
          }
        }
      } else if (driver) {
        phone = driver.phone;
        link = `Hej ${driver.firstName}!\n\nDu har ett uppdaterat uppdrag från Nolblad:\nhttps://nolblad.se/`;
      }

      close();
      notify('Jobb uppdaterat', NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte uppdatera jobbet, ${updateResponse.error}`, NotificationStatus.ERROR);
    }
  }

  async function addNewJob(jobDoc: JobDoc) {
    const addJobResponse = await addJob(jobDoc);

    if (addJobResponse.code === 200 && addJobResponse.data) {
      setSaving(false);
      close();
      notify(
        `Jobb med ordernummer ${addJobResponse.data.orderNumber} skapat`,
        NotificationStatus.SUCCESS,
      );
    } else {
      notify(`Kunde inte lägga till jobbet, ${addJobResponse.error} `, NotificationStatus.ERROR);
    }
  }

  function confirmDelete() {
    showPopup(
      <ConfirmAction
        confirm={_deleteJob}
        heading='Är du säker på det här?'
        message='Om jobbet tas bort från systemet kan det inte återställas senare.'
      />,
      'small',
    );
  }

  async function _deleteJob() {
    if (job) {
      const deleteResponse = await deleteJob(job);

      if (deleteResponse.code === 200) {
        notify('Jobb raderat', NotificationStatus.SUCCESS);
        close();

        // on delete callback so that the previous popup component can close itself if wished
        onDeleteJob && onDeleteJob();
      } else {
        notify('Jobbet kunde inte tas bort', NotificationStatus.ERROR);
      }
    }
  }

  async function onSavePlaceToLittra(place: Place) {
    if (littra && client) {
      const updateResponse = await updateLittra(
        littra.places ? { places: [...littra.places, place] } : { places: [place] },
        littra?.docId,
        client.docId,
      );

      if (updateResponse.code === 201) {
        notify('Sparat plats på littra', NotificationStatus.SUCCESS);
      } else {
        notify('Kunde inte spara plats på littra', NotificationStatus.ERROR);
      }
    } else {
      showAlertNoLittra();
    }
  }

  /*
   * Get initial driver option:
   * If the user is an extended driver, he is only able to
   * select his own name.
   */
  const getInitialDriverOption = () => {
    if (user?.userType == UserType.DRIVER_EXTENDED) {
      setDriver(user);
      return {
        value: user.docId,
        label: `${user.firstName} ${user.lastName}`,
      };
    } else if (driver) {
      return {
        value: driver.docId,
        label: `${driver.firstName} ${driver.lastName}`,
      };
    } else {
      return undefined;
    }
  };

  function getDistance() {
    if (from && to && from.position && to.position) {
      distanceService.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(from.position.lat, from.position.lng)],
          destinations: [new google.maps.LatLng(to.position.lat, to.position.lng)],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        distanceResultCb,
      );
    }
  }
  function distanceResultCb(response: any, status: any) {
    if (status === 'OK') {
      if (response.rows[0].elements[0].status === 'OK') {
        setEstimatedDistance({
          text: response.rows[0].elements[0].distance.text,
          meters: response.rows[0].elements[0].distance.value,
        });
        setEstimatedTime({
          text: response.rows[0].elements[0].duration.text,
          seconds: response.rows[0].elements[0].duration.value,
        });
      } else {
        setEstimatedDistance(undefined);
        setEstimatedTime(undefined);
      }
    }
  }

  if (!initDone) {
    return <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />;
  } else {
    return (
      <section id='add-job'>
        <header id='add-job__header'>
          <h2>{editMode ? 'Redigera uppdrag' : 'Lägg till nytt uppdrag'}</h2>
          {editMode && (
            <button
              onClick={confirmDelete}
              className='button--cancel'
              style={{ alignSelf: 'flex-end' }}
            >
              <span className='fa fa-trash' style={{ marginRight: '0.5rem' }} />
              Radera
            </button>
          )}
        </header>
        <main>
          <section id='add-job__form'>
            <ul id='add-job__form__ul'>
              {editMode && (
                <li key={`status-${status}`} className='add-job__form__li'>
                  <Select
                    isMultiSelect={false}
                    iconRightClose='fa fa-angle-down'
                    iconRightOpen='fa fa-angle-up'
                    onChange={onChangeStatus}
                    label='Status'
                    type='form'
                    initialOption={{
                      value: status.toString(),
                      label: jobStatusLabel[status],
                    }}
                  >
                    <>
                      {Object.entries(jobStatusLabel).map((s: [string, string]) => {
                        /**
                         * Should not show status payed when:
                         * 1. material has been taken out on Nolblad,
                         * 2. When client is central,
                         * 3. When vehicle is "lej"
                         */
                        if (
                          parseInt(s[0]) !== JobStatus.PAYED ||
                          !(vehicle.id === 'Lej' || job?.hasReceiptsForCompany)
                        ) {
                          return <Option key={s[0]} value={s[0]} label={s[1]} />;
                        } else {
                          return null;
                        }
                      })}
                    </>
                  </Select>
                </li>
              )}

              <li key={`job-type`} className='add-job__form__li'>
                <Select
                  isMultiSelect={false}
                  iconRightClose='fa fa-angle-down'
                  iconRightOpen='fa fa-angle-up'
                  onChange={onChangeJobType}
                  label='Kategori'
                  type='form'
                  initialOption={{
                    value: jobType.toString(),
                    label: jobTypeLabel[jobType],
                  }}
                >
                  <>
                    {Object.entries(jobTypeLabel).map((s: [string, string]) => {
                      return <Option key={s[0]} value={s[0]} label={s[1]} />;
                    })}
                  </>
                </Select>
              </li>
              <AddClient />
              {!!client && (
                <>
                  <AddLittra />
                  <AddContactClient />
                </>
              )}

              {jobType === JobType.EXCAVATOR_MAN ? (
                <li key='place' className='add-job__form__li' id='add-job__form__li-place'>
                  <p className='label'>Arbetsplats</p>
                  <PickAPlace
                    place={from}
                    onChange={setFrom}
                    onSaveToLittra={onSavePlaceToLittra}
                    getSavedPlaces={!isLej}
                  />
                </li>
              ) : (
                <li key='place' className='add-job__form__li' id='add-job__form__li-place'>
                  <p className='label'>Plats från</p>
                  <PickAPlace
                    place={from}
                    onChange={setFrom}
                    onSaveToLittra={onSavePlaceToLittra}
                    getSavedPlaces={!isLej}
                  />

                  <section id='add-job__form__swap-place'>
                    <button className='button--main--small' onClick={swapPlaces}>
                      Byt platser <span className='fa fa-angle-up' />
                      <span className='fa fa-angle-down' />
                    </button>
                  </section>

                  <p className='label'>Plats till</p>

                  <PickAPlace
                    place={to}
                    onChange={setTo}
                    onSaveToLittra={onSavePlaceToLittra}
                    getSavedPlaces={!isLej}
                  />

                  {!!estimatedTime && (
                    <div className='info'>
                      <p className='label'>Tidsuppskattning</p>
                      <p>{estimatedTime.text}</p>
                    </div>
                  )}

                  {!!estimatedDistance && (
                    <div className='info'>
                      <p className='label'>Avståndsuppskattning</p>
                      <p>{estimatedDistance.text}</p>
                    </div>
                  )}
                </li>
              )}

              <li key='from' id='add-job__form__date' className='add-job__form__li'>
                <Datepicker
                  mode='day'
                  onChange={onChangeDateFrom}
                  currentDateMillis={start}
                  showQuickChangeButtons={true}
                  buttonClassName='input-text add-job__form__input add-job__form__date-date'
                  buttonContent={
                    <>
                      {' '}
                      <span className='fa fa-calendar' />
                      {`${toDayDateString(start)}`}
                    </>
                  }
                />

                <Timepicker
                  onChangeHours={onChangeHoursFrom}
                  onChangeMinutes={onChangeMinutesFrom}
                  hours={start ? new Date(start).getHours() : 7}
                  minutes={start ? new Date(start).getMinutes() : 0}
                />
              </li>

              <li key='to' id='add-job__form__date' className='add-job__form__li'>
                <Datepicker
                  mode='day'
                  onChange={onChangeDateTo}
                  currentDateMillis={end}
                  showQuickChangeButtons={true}
                  buttonClassName='input-text add-job__form__input add-job__form__date-date'
                  buttonContent={
                    <>
                      <span className='fa fa-calendar' />
                      {`${toDayDateString(end)}`}
                    </>
                  }
                />

                <Timepicker
                  onChangeHours={onChangeHoursTo}
                  onChangeMinutes={onChangeMinutesTo}
                  hours={end ? new Date(end).getHours() : 16}
                  minutes={end ? new Date(end).getMinutes() : 0}
                />
              </li>

              <li key='vehicle' className='add-job__form__li'>
                <Select
                  isMultiSelect={false}
                  type='form'
                  label='Fordon'
                  onChange={onChangeVehicle}
                  iconRightClose='fa fa-angle-down'
                  iconRightOpen='fa fa-angle-up'
                  initialOption={{
                    value: vehicle.docId,
                    label: vehicle.id,
                  }}
                >
                  <>
                    {vehicles
                      .filter((v: VehicleData) => {
                        // filter away all trucks if jobtype is grävmaskin/anläggare.
                        return (
                          jobType !== JobType.EXCAVATOR_MAN ||
                          ![
                            VehicleType.TRUCK,
                            VehicleType.TRUCK_LEJ,
                            VehicleType.TRUCK_NOT_ASSIGNED,
                          ].includes(v.vehicleType)
                        );
                      })
                      .map((v: VehicleData) => {
                        return (
                          <Option
                            key={v.docId}
                            value={v.docId}
                            label={`${v.id} (${vehicleTypeLabel[v.vehicleType]})`}
                          />
                        );
                      })}
                  </>
                </Select>
              </li>

              {/* FÖRARE DROP-DOWN LIST*/}
              {[VehicleType.TRUCK, VehicleType.EXCAVATOR, VehicleType.MAN].includes(
                vehicle.vehicleType,
              ) && (
                <li key='driver' className='add-job__form__li'>
                  <Select
                    isMultiSelect={false}
                    type='form'
                    label='Förare'
                    onChange={onChangeDriver}
                    iconRightClose='fa fa-angle-down'
                    iconRightOpen='fa fa-angle-up'
                    initialOption={getInitialDriverOption()}
                  >
                    <>
                      {/* Admins are only allowed to pick drivers */}
                      {user?.userType == UserType.ADMIN &&
                        drivers.map((d: User) => {
                          return (
                            <Option
                              key={d.docId}
                              value={d.docId}
                              label={`${d.firstName} ${d.lastName}`}
                            />
                          );
                        })}
                    </>
                  </Select>
                </li>
              )}

              <AddEquipments />

              {[VehicleType.TRUCK_LEJ, VehicleType.EXCAVATOR_LEJ, VehicleType.MAN_LEJ].includes(
                vehicle.vehicleType,
              ) && (
                <>
                  <AddSubcontractor />
                  {!!subcontractor && <AddContactSubcontractor />}
                </>
              )}

              <AddImages
                images={images}
                setImages={setImages}
                deletedImages={deletedImages}
                setDeletedImages={setDeletedImages}
              />

              {jobType === JobType.TRANSPORT_SCHAKT && <AddMaterials />}

              {(jobType === JobType.TRANSPORT_SCHAKT || jobType === JobType.TRANSPORT_MACHINE) && (
                <li key='other-material' className='add-job__form__li'>
                  <Textarea
                    id='other-material'
                    setState={setOtherMaterials}
                    placeholder='Godsslag'
                    value={otherMaterials}
                  />
                </li>
              )}

              <li key='other' className='add-job__form__li'>
                <Textarea
                  id='other'
                  setState={setOtherInformation}
                  placeholder='Kommentar till chaufför'
                  value={otherInformation}
                />
              </li>

              <li key='admin-comments' className='add-job__form__li'>
                <Textarea
                  id='admin-comments'
                  setState={setAdminComments}
                  placeholder='Kommentar till kontoret'
                  value={adminComments}
                />
              </li>

              <li key='save' className='add-job__form__li'>
                {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
                <button className='button--green' onClick={save} style={{ width: '100%' }}>
                  Spara
                </button>
              </li>
            </ul>
          </section>
        </main>
      </section>
    );
  }
}

export default AddJob;
