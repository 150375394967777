import { useState, createContext } from 'react';
import { NotificationStatus } from '../utils/constants';
import './NotificationContext.css';

type NotificationState = {
  notify: (msg: string, status: NotificationStatus) => void;
};

const initialState: NotificationState = {
  notify: () => {
    console.log('notify not defined');
  },
};

export const NotificationContext = createContext(initialState);

export const NotificationProvider = ({ children }: { children: JSX.Element }) => {
  const [msg, setMsg] = useState('meddelande');
  const [status, setStatus] = useState(NotificationStatus.INFO);

  function showMsg() {
    const notification: HTMLElement = document.querySelector('#notification') as HTMLElement;

    notification.classList.remove('notification-hide');
    notification.classList.add('notification-show');

    setTimeout(() => {
      notification.classList.remove('notification-show');
      notification.classList.add('notification-hide');
    }, 5000);
  }

  function notify(msg: string, status: NotificationStatus) {
    switch (status) {
      case NotificationStatus.ERROR:
        setStatus(NotificationStatus.ERROR);
        break;
      case NotificationStatus.INFO:
        setStatus(NotificationStatus.INFO);
        break;
      case NotificationStatus.SUCCESS:
        setStatus(NotificationStatus.SUCCESS);
        break;
    }

    setMsg(msg);
    showMsg();
  }

  function getStatusName() {
    switch (status) {
      case NotificationStatus.ERROR:
        return 'error';
      case NotificationStatus.INFO:
        return 'info';
      case NotificationStatus.SUCCESS:
        return 'success';
    }
  }

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <div className={`notification-${getStatusName()} notification-hide`} id='notification'>
        <p id='notification__msg'>{msg}</p>
      </div>
    </NotificationContext.Provider>
  );
};
