import { FirebaseError } from 'firebase/app';
import { getFirestore, collection, doc, updateDoc, runTransaction } from 'firebase/firestore';
import { ContactDoc, UpdateContactParams, SubcontractorData, ClientData } from '../../utils/types';

const db = getFirestore();

/**
 * adds contact to contacts collection and to the array of the client or subcontractor in a transaction
 * @param contact
 * @param company
 * @param collectionName
 */
export async function addContact(
  contact: ContactDoc,
  company: ClientData | SubcontractorData,
  collectionName: 'clients' | 'subcontractors',
) {
  const response = await runTransaction(db, async (transaction) => {
    try {
      // 1. add contact

      const newContactRef = doc(collection(db, 'contacts'));

      transaction.set(newContactRef, contact);

      // 2. update client's list of contacts

      let contacts;
      if (company.contacts && company.contacts.length > 0) {
        contacts = company.contacts.map((c) => doc(db, `/contacts/${c.docId}`));
        contacts.push(doc(db, `/contacts/${newContactRef.id}`));
      } else {
        contacts = [doc(db, `/contacts/${newContactRef.id}`)];
      }

      transaction.update(doc(db, `/${collectionName}/${company.docId}`), {
        contacts,
      });

      return { code: 201, data: newContactRef.id };
    } catch (e) {
      console.log(e);
      return {
        code: parseInt((e as FirebaseError).code),
        error: (e as FirebaseError).message,
      };
    }
  });

  return response;
}

/**
 *
 * @param updates UpdateContactParams
 * @param docId id of document to update
 * @returns Promise<FirebaseReponse>
 */
export async function updateContact(updates: UpdateContactParams, docId: string) {
  try {
    await updateDoc(doc(db, `contacts/${docId}`), updates as { [x: string]: any });
    return {
      code: 201,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}

/**
 *
 * Deletes the contact document and removes its reference from the client/subcontractor list in a transaction
 *
 * @param docId id of document to delete
 * @company the client or subcuntractor to remove the contact from
 * @returns Promise<FirebaseReponse>
 */
export async function deleteContact(
  docId: string,
  company: ClientData | SubcontractorData,
  collectionName: 'clients' | 'subcontractors',
) {
  const response = await runTransaction(db, async (transaction) => {
    try {
      // 1. delete contact document

      transaction.delete(doc(db, `/contacts/${docId}`));

      // 2. delete the contact reference
      if (company.contacts && company.contacts.length > 0) {
        const contacts = [];
        for (const c of company.contacts) {
          if (c.docId !== docId) {
            contacts.push(doc(db, `/contacts/${c.docId}`));
          }
        }

        transaction.update(doc(db, `/${collectionName}/${company.docId}`), {
          contacts,
        });
      }

      return { code: 201, data: docId };
    } catch (e) {
      console.log(e);
      return {
        code: 500,
        error: (e as FirebaseError).code,
      };
    }
  });

  return response;
}
