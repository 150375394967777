import { collection, getFirestore, onSnapshot } from '@firebase/firestore';
import { query } from 'firebase/firestore';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import ListPage from '../../components/list_page';
import Option from '../../components/select/Option';
import Select from '../../components/select/Select';
import Table, { TableRow } from '../../components/table/Table';
import { DataContext } from '../../contexts/CommonDataContext';
import { PopupContext } from '../../contexts/PopupContext';
import AddEquipment from '../../popups/AddEquipment';
import AddVehicle from '../../popups/AddVehicle';
import EquipmentDetails from '../../popups/EquipmentDetails';
import VehicleDetails from '../../popups/VehicleDetails';
import {
  EquipmentType,
  equipmentTypeLabel,
  VehicleType,
  vehicleTypeLabel,
} from '../../utils/constants';
import history from '../../utils/history';
import { Equipment, VehicleData } from '../../utils/types';
import './Vehicles.css';

const db = getFirestore();

export default function Vehicles() {
  const { vehicles } = useContext(DataContext);

  const [equipments, setEquipments] = useState<Array<Equipment>>([]);

  const [search, setSearch] = useState<string>('');
  const { showPopup } = useContext(PopupContext);

  const [vehicleTypeFilter, setVehicleTypeFilter] = useState<Set<string>>(
    new Set([
      VehicleType.TRUCK.toString(),
      VehicleType.EXCAVATOR.toString(),
      VehicleType.MAN.toString(),
      VehicleType.TRUCK_LEJ.toString(),
      VehicleType.EXCAVATOR_LEJ.toString(),
      VehicleType.MAN_LEJ.toString(),
      VehicleType.TRUCK_NOT_ASSIGNED.toString(),
      VehicleType.EXCAVATOR_NOT_ASSIGNED.toString(),
      VehicleType.MAN_NOT_ASSIGNED.toString(),
    ]),
  );

  const [equipmentTypeFilter, setEquipmentTypeFilter] = useState<Set<string>>(
    new Set([
      EquipmentType.EXCAVATOR.toString(),
      EquipmentType.FLATBED.toString(),
      EquipmentType.OTHER.toString(),
      EquipmentType.TRAILER.toString(),
    ]),
  );

  useEffect(() => {
    return setHistoryListener();
  }, []);

  /**
   * when history is empty, history.block (used in PopupContext to intersect backpressed in browser and on phone)
   * is not called so we add extra history when navigating to each page.
   * @returns unregisterCallback
   */
  function setHistoryListener() {
    history.push('/fordon');
    return history.listen((location, action) => {
      if (action === 'POP' && location.pathname === '/fordon') {
        history.goBack();
      }
    });
  }

  useEffect(() => {
    return setListenerEquipments();
  }, []);

  function setListenerEquipments() {
    try {
      const q = query(collection(db, 'equipments'));

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const _equipments: Array<Equipment> = [];

        for (const doc of querySnapshot.docs) {
          _equipments.push({
            ...doc.data(),
            docId: doc.id,
          } as Equipment);
        }

        setEquipments(_equipments);
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeSearch(event: SyntheticEvent) {
    setSearch((event.target as HTMLInputElement).value);
  }

  function openAddVehicle() {
    showPopup(<AddVehicle />, 'big');
  }

  function openAddEquipment() {
    showPopup(<AddEquipment />, 'big');
  }

  function openEquipmentDetails(equipment: Equipment) {
    showPopup(<EquipmentDetails equipment={equipment} />, 'big');
  }

  function openVehicleDetails(vehicle: VehicleData) {
    showPopup(<VehicleDetails vehicle={vehicle} />, 'big');
  }

  function _onEnterAddButtonEquipments(event: SyntheticEvent) {
    openAddEquipment();
    event.preventDefault();
    requestAnimationFrame(() => {
      (document.querySelector('.button--close') as HTMLElement).focus();
    });
  }

  function _onEnterAddButtonVehicles(event: SyntheticEvent) {
    openAddVehicle();
    event.preventDefault();
    requestAnimationFrame(() => {
      (document.querySelector('.button--close') as HTMLElement).focus();
    });
  }

  function onChangeVehicleTypeFilter(value: string, state?: boolean) {
    const _vehicleTypeFilter = new Set(vehicleTypeFilter);

    if (state) {
      _vehicleTypeFilter.add(value);
    } else {
      _vehicleTypeFilter.delete(value);
    }

    setVehicleTypeFilter(_vehicleTypeFilter);
  }

  function onChangeEquipmentTypeFilter(event: SyntheticEvent) {
    const _equipmentTypeFilter = new Set(equipmentTypeFilter);
    const checked = (event.target as HTMLInputElement).checked;
    const value = (event.target as HTMLInputElement).value;
    checked ? _equipmentTypeFilter.add(value) : _equipmentTypeFilter.delete(value);

    setEquipmentTypeFilter(_equipmentTypeFilter);
  }

  function getTableDataVehicles() {
    const data: TableRow[] = [];
    for (const v of vehicles) {
      if (vehicleTypeFilter.has(v.vehicleType.toString())) {
        data.push({ data: [v.id, vehicleTypeLabel[v.vehicleType]] });
      }
    }
    return data;
  }

  function getTableDataEquipments() {
    const data: TableRow[] = [];
    for (const e of equipments) {
      if (equipmentTypeFilter.has(e.equipmentType.toString())) {
        data.push({
          data: [e.id, equipmentTypeLabel[e.equipmentType]],
        });
      }
    }
    return data;
  }

  function onClickVehicle(idx: number) {
    openVehicleDetails(vehicles[idx]);
  }

  function onClickEquipment(idx: number) {
    openEquipmentDetails(equipments[idx]);
  }
  function chooseEveryVehicleType() {
    setVehicleTypeFilter(
      new Set([
        VehicleType.TRUCK.toString(),
        VehicleType.EXCAVATOR.toString(),
        VehicleType.MAN.toString(),
        VehicleType.TRUCK_LEJ.toString(),
        VehicleType.EXCAVATOR_LEJ.toString(),
        VehicleType.MAN_LEJ.toString(),
        VehicleType.TRUCK_NOT_ASSIGNED.toString(),
        VehicleType.EXCAVATOR_NOT_ASSIGNED.toString(),
        VehicleType.MAN_NOT_ASSIGNED.toString(),
      ]),
    );
  }

  function removeVehicleTypeFilters() {
    setVehicleTypeFilter(new Set());
  }

  return (
    <ListPage heading='Resurser' onChangeSearch={onChangeSearch}>
      <section id='vehicle-page'>
        <section className='vehicle-page__section'>
          <header style={{ marginBottom: '1rem' }}>
            <h3>Fordon</h3>
            <button
              className='button--main'
              onClick={openAddVehicle}
              onKeyPress={_onEnterAddButtonVehicles}
            >
              <span className='fa fa-plus' /> Lägg till
            </button>
          </header>

          <section id='vehicle-page__filters'>
            <div>
              <Select
                isMultiSelect={true}
                type='button'
                className='button--main--small'
                label='Fordonskategori'
                showLabel={true}
                onChange={onChangeVehicleTypeFilter}
                iconRightClose='fa fa-angle-down'
                iconRightOpen='fa fa-angle-up'
                values={vehicleTypeFilter}
              >
                <>
                  {Object.entries(vehicleTypeLabel).map((entry: [string, string]) => {
                    return (
                      <Option key={entry[0]} value={entry[0]} label={entry[1]} checkbox={true} />
                    );
                  })}
                </>
              </Select>
            </div>

            <button className='button--green--small' onClick={chooseEveryVehicleType}>
              <span className='fa fa-check' /> Markera alla
            </button>
            <button className='button--cancel--small' onClick={removeVehicleTypeFilters}>
              {' '}
              <span className='fa fa-times' /> Avmarkera alla
            </button>
          </section>

          <Table
            heads={['Resurs', 'Kategori']}
            data={getTableDataVehicles()}
            onClickRow={onClickVehicle}
          />
        </section>
        <section className='vehicle-page__section'>
          <header>
            <h3>Tillbehör</h3>

            <button
              className='button--main'
              onClick={openAddEquipment}
              onKeyPress={_onEnterAddButtonEquipments}
            >
              <span className='fa fa-plus' /> Lägg till
            </button>
          </header>

          <section id='vehicle-page__filters'>
            <ul id='vehicles-page__controls__types'>
              {Object.entries(equipmentTypeLabel).map((et: [string, string]) => {
                return (
                  <li key={et[0]}>
                    <label htmlFor={et[0]}>{et[1]} </label>
                    <input
                      type='checkbox'
                      className='checkbox'
                      value={et[0]}
                      id={et[0]}
                      onChange={onChangeEquipmentTypeFilter}
                      checked={equipmentTypeFilter.has(et[0])}
                    />
                  </li>
                );
              })}
            </ul>
          </section>
          <Table
            heads={['Resurs', 'Kategori']}
            data={getTableDataEquipments()}
            onClickRow={onClickEquipment}
          />
        </section>
      </section>
    </ListPage>
  );
}
