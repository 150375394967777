import { collection, getFirestore, onSnapshot } from '@firebase/firestore';
import { query } from 'firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ContactData, ContactDoc, UpdateContactParams } from '../../utils/types';

import ListPage from '../../components/list_page';
import { PopupContext } from '../../contexts/PopupContext';
import Table, { TableRow } from '../../components/table/Table';
import EditContact from '../../popups/EditContact';

const db = getFirestore();

export default function Contacts() {
  const [search, setSearch] = useState<string>('');
  const { showPopup } = useContext(PopupContext);
  const [contacts, setContacts] = useState<ContactData[]>([]);

  useEffect(() => {
    try {
      const q = query(collection(db, 'contacts'));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const _contacts: Array<ContactData> = [];

        for (const doc of querySnapshot.docs) {
          _contacts.push({
            ...(doc.data() as ContactDoc),
            docId: doc.id,
          });
        }

        setContacts(_contacts);
      });

      return () => unsubscribe();
    } catch (error) {
      console.log('Contacts Resource View Error: ', error);
    }
  }, []);

  function onChangeSearch(event: SyntheticEvent) {
    setSearch((event.target as HTMLInputElement).value);
  }

  function openAddContact(event: SyntheticEvent) {
    event.preventDefault();

    // showPopup(<AddContact />, "big");
  }

  const onEditContact = (changes: UpdateContactParams, docId: string) => {
    console.log('not implemented');
  };

  const onContactClick = (idx: number) => {
    showPopup(<EditContact contact={contacts[idx]} onEdit={onEditContact} />, 'medium');
  };

  const getTableData = (): TableRow[] => {
    const rows: TableRow[] = [];
    for (const c of contacts) {
      rows.push({ data: [c.name, c.phone, c.docId] });
    }
    return rows;
  };

  return (
    <ListPage
      heading='Kontakter'
      addButtonText='Ny kontakt'
      onClickAddButton={openAddContact}
      onChangeSearch={onChangeSearch}
    >
      <section>
        <div>
          <Table
            heads={['Namn', 'Nummer', 'ID']}
            data={getTableData()}
            onClickRow={onContactClick}
          />
        </div>
      </section>
    </ListPage>
  );
}
