import React, { useContext, useEffect, useState } from 'react';
import './PickAPlace.css';

import Select from './select/Select';
import Option from './select/Option';
import { Place } from '../utils/types';
import { getFirestore, onSnapshot, Unsubscribe } from '@firebase/firestore';
import { collection, query } from 'firebase/firestore';
import { NotificationContext } from '../contexts/NotificationContext';
import MapView from './MapView';
import { PopupContext } from '../contexts/PopupContext';
import { NotificationStatus } from '../utils/constants';
import { addPlace } from '../firebase/firestore_functions/places';
import { preventDefault } from '../utils/uiHelpers';

interface PickAPlaceProps {
  onChange: (place?: Place) => void;
  onSaveToLittra?: (place: Place) => void;
  place?: Place;
  places?: Place[]; // places saved on littra
  getSavedPlaces?: boolean;
}
const db = getFirestore();

export default function PickAPlace({
  onChange,
  place,
  onSaveToLittra,
  places,
  getSavedPlaces = true,
}: PickAPlaceProps) {
  interface Places {
    [docRefId: string]: Place;
  }

  const { showPopup } = useContext(PopupContext);
  const { notify } = useContext(NotificationContext);

  const [savedPlaces, setSavedPlaces] = useState<Place[]>([]);
  const [_places, setPlaces] = useState<Places>({});

  useEffect(setListenerPlaces, [getSavedPlaces]);

  useEffect(() => {
    const newPlaces: Places = {};
    for (const p of savedPlaces) {
      newPlaces[`${p.position?.lat} + ${p.position?.lng}`] = p;
    }

    if (places) {
      for (const p of places) {
        newPlaces[`${p.position?.lat} + ${p.position?.lng}`] = p;
      }
    }

    setPlaces(newPlaces);
  }, [places, savedPlaces]);

  // This function will get places from the Places collection in firestore
  function setListenerPlaces() {
    if (!getSavedPlaces) {
      setSavedPlaces([]);
      return;
    }
    try {
      const q = query(collection(db, 'places'));

      const unsubscribe: Unsubscribe = onSnapshot(q, (querySnapshot) => {
        setSavedPlaces(querySnapshot.docs.map((d) => d.data() as Place));
        return unsubscribe;
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  function onChangePlace(place?: string) {
    if (place) {
      onChange(_places[place]);
    } else {
      onChange(undefined);
    }
  }

  async function onSaveToPlaces(place: Place) {
    const addResponse = await addPlace(place);

    if (addResponse.code === 201) {
      notify('Plats sparad i platser', NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte spara platsen: ${addResponse.error} `, NotificationStatus.ERROR);
    }

    onChange(place);
  }

  function openMapView() {
    showPopup(
      <MapView
        selectedPlace={place}
        onUse={onChange}
        onSaveToPlaces={onSaveToPlaces}
        onSaveToLittra={onSaveToLittra}
      />,
      'big',
    );
  }

  return (
    <section id='pick-a-place' onClick={preventDefault}>
      <Select
        isMultiSelect={false}
        type='form'
        onChange={onChangePlace}
        label='Välj sparad plats'
        iconRightOpen='fa fa-angle-up'
        searchBar={true}
        iconRightClose='fa fa-angle-down'
        initialOption={
          place && (place.name || place?.address) && place.position
            ? {
                value: `${place.position.lat}${place.position.lng}`,
                label: place.name ? place.name : place.address ? place.address : '',
              }
            : undefined
        }
      >
        <>
          {Object.entries(_places).map((place: [string, Place]) => {
            return (
              <Option
                key={place[0]}
                value={place[0]}
                label={place[1].name ? place[1].name : place[1].address ? place[1].address : ''}
              />
            );
          })}
        </>
      </Select>
      <button className='button--main--small' id='button--new-place' onClick={openMapView}>
        <span className='fa fa-globe ' /> Sök plats
      </button>
    </section>
  );
}
