import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { stopPropagation } from '../../utils/uiHelpers';
import { SelectContext } from './Select';
import './Option.css';

interface OptionProps {
  value: string;
  label: string;
  checkbox?: boolean;
}

export default function Option({ value, label, checkbox }: OptionProps) {
  const { updateState, search, values } = useContext(SelectContext);
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(updateSelected, [values, value]);

  function updateSelected() {
    if (values?.has(value)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }

  function onClick(event: SyntheticEvent) {
    stopPropagation(event);
    updateState(value, label, !selected);
    setSelected(!selected);
  }

  function onKeyPress(event: any) {
    if ((event as KeyboardEvent).key === 'Enter') {
      updateState(value, label, !selected);
      setSelected(!selected);
    }
  }

  if (search === '' || label?.toLowerCase().includes(search.toLowerCase())) {
    return (
      <li
        tabIndex={0}
        className={checkbox ? 'option-checkbox' : 'option'}
        onClick={onClick}
        onKeyPress={onKeyPress}
      >
        <p>{label}</p>

        {checkbox && <input type='checkbox' className='checkbox' checked={selected} readOnly />}
      </li>
    );
  } else {
    return null;
  }
}
