import { FirebaseError } from 'firebase/app';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { MaterialData } from '../../utils/types';

const db = getFirestore();

/**
 * Gets a list of all materials
 * @returns Promise<FirebaseResponse>
 */
export async function getMaterials() {
  try {
    const response = await getDocs(collection(db, 'materials'));
    const materials: MaterialData[] = [];
    for (const doc of response.docs) {
      materials.push({ ...(doc.data() as MaterialData), docId: doc.id });
    }
    return {
      code: 200,
      data: materials,
    };
  } catch (e) {
    console.log(e);
    return {
      code: 500,
      error: (e as FirebaseError).code,
    };
  }
}
