import './Table.css';

export interface TableControl {
  icon: string;
  func: (idx: number) => void;
}

export interface TableRow {
  className?: string;
  data: string[];
  backgroundColor?: string;
}

type TableProps = {
  heads: string[];
  data: TableRow[];
  onClickRow?: (row: number) => void;
  controls?: TableControl[];
};

export default function Table({ heads, data, onClickRow, controls }: TableProps) {
  return (
    <div id='table-wrapper'>
      <table id='table'>
        <thead>
          <tr>
            {heads.map((head, idx) => (
              <th key={idx}>{head}</th>
            ))}
            {controls?.length && <th></th>}
          </tr>
        </thead>

        <tbody>
          {data.length === 0 ? (
            <tr>
              <td className='empty-table__td'>Ingen information finns att visa.</td>
            </tr>
          ) : (
            data.map((row, idx) => (
              <tr
                key={idx}
                onClick={() => {
                  if (onClickRow) {
                    onClickRow(idx);
                  }
                }}
                className={
                  onClickRow
                    ? row.className
                      ? `tr-clickable ${row.className}`
                      : 'tr-clickable'
                    : row.className
                    ? row.className
                    : ''
                }
                style={{ backgroundColor: row.backgroundColor }}
              >
                {row.data.map((col, idx) => (
                  <td key={idx}>{col}</td>
                ))}

                {controls && (
                  <td>
                    {controls.map((c, i) => {
                      return (
                        <button key={i} onClick={() => c.func(idx)}>
                          <span className={`button--icon ${c.icon}`} />
                        </button>
                      );
                    })}
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
