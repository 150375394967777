import { getCalendarDaysOfJobOrSlinga } from '../firebase/firestore_functions/common';
import { JobType, VehicleType } from './constants';
import { decrementDay, getDateWithTimeZero, getHoursElapsed, incrementDay } from './time';
import {
  DaysWorkload,
  JobData,
  JobDataPartial,
  JobReport,
  JobReportData,
  SlingaDataPartial,
  User,
  VehicleData,
} from './types';
import { getImageUrl } from '../firebase/storageFunctions';

// Check if User can start job with JobData
function canStartJob(user?: User, jobDriver?: User, vehicle?: VehicleData): boolean {
  // First check if lej
  if (
    vehicle?.vehicleType &&
    [VehicleType.EXCAVATOR_LEJ, VehicleType.TRUCK_LEJ, VehicleType.MAN_LEJ].includes(
      vehicle.vehicleType,
    )
  ) {
    return true;
  }

  if (user && user.firstName && jobDriver && jobDriver.firstName) {
    // Check if user is the driver for this job
    if (
      user.docId === jobDriver.docId ||
      user.firstName.toLocaleLowerCase() === jobDriver.firstName.toLocaleLowerCase()
    ) {
      return true;
    }
  }

  // If all checks above failed this is not a valid driver
  return false;
}

/**
 * Get text with littra information for job. Can be from a saved littra or a temporary littra.
 * @param job
 * @returns string littra info
 */
function getLittraText(job: JobData | JobDataPartial): string {
  if (job.littra) return 'ref' in job.littra ? job.littra.name : job.littra.projectNum;
  if (job.littraTemp) return job.littraTemp;
  else return '';
}

/**
 * Get text with littra workplace information for job. Can be from a saved littra or a temporary littra.
 * @param job
 * @returns string littra info
 */
function getLittraWorkText(job: JobData | JobDataPartial): string {
  // TODO fix such that the worktext can be extracted from the partial
  if (job.littra) return 'ref' in job.littra ? '-' : job.littra.workplace;
  if (job.littraWorkplaceTemp) return job.littraWorkplaceTemp;
  else return '';
}

function getContactClientTempName(text: string) {
  const matches = /[a-zA-ZÅÄÖåäö]+/.exec(text);

  if (matches) {
    return matches[0];
  }
}

function getContactClientTempPhone(text: string) {
  // one or more digits followed by 0 or more spaces
  const matches = /[\d+ ? -?]+/.exec(text);

  if (matches) {
    return matches[0];
  }
}

/**
 * Gets the number of hours driven in the job in total. If no hours is reportet it returnes null.
 */
function getTotalHours(reports: JobReportData[]) {
  if (reports) {
    const totalHours = reports.reduce((currenTotalHours, report) => {
      const hoursElapsed = getHoursElapsed(report.start, report.end) - report.breaktime / 60;
      return currenTotalHours + hoursElapsed;
    }, 0);

    return totalHours;
  }

  return null;
}

/**
 * Gets the number of hours for the job and date. It will prioritize reported hours and otherwise return the diff between start and end fields of the job.
 *
 * @param job
 * @param dateMillis
 * @returns a number of hours or null if data is not present.
 */
function getJobHours(job: JobData | JobDataPartial, dateMillis: number) {
  const dateMillisTimeZero = getDateWithTimeZero(dateMillis);

  const report =
    job.reports &&
    (job.reports as JobReport[]).find((report: JobReport) => {
      return getDateWithTimeZero(report.start) == dateMillisTimeZero;
    });

  // If there is a report we use that, otherwise 0
  if (report !== undefined) {
    return getHoursElapsed(report.start, report.end) - report.breaktime / 60;
  } else {
    return 0;
  }
}

/**
 * sorts the job into the right keys in "jobs"
 * @param data can be job or slinga
 * @param jobs DaysWorkload object which is filled up with jobs in this function
 * @param startDate
 * @param endDate
 */
function addJobOrSlingaToCorrectDays(
  data: SlingaDataPartial | JobDataPartial | (SlingaDataPartial & JobDataPartial),
  jobs: DaysWorkload,
  startDate: number,
  endDate: number,
) {
  const days = getCalendarDaysOfJobOrSlinga(data, startDate, endDate);

  if (!jobs) {
    jobs = {};
  }

  for (const day of days) {
    if (!jobs[day]) {
      jobs[day] = [data];
    } else {
      jobs[day].push(data);
    }
  }
}

/**
 * sorts the job into the right keys in "jobs"
 * @param data can be job or slinga
 * @param jobs DaysWorkload object which is filled up with jobs in this function
 * @param startDate
 * @param endDate
 */
function modifyJobOrSlingaInJobs(
  data: SlingaDataPartial | JobDataPartial | (SlingaDataPartial & JobDataPartial),
  jobs: DaysWorkload,
  startDate: number,
  endDate: number,
) {
  const days = getCalendarDaysOfJobOrSlinga(data, startDate, endDate);

  let removeJobAtOldDates = days.length ? false : true;

  for (const day of days) {
    if (!jobs[day]) {
      jobs[day] = [data];
      removeJobAtOldDates = true;
    } else {
      const idx = jobs[day].findIndex((j) => j.docId === data.docId);

      if (idx === -1) {
        removeJobAtOldDates = true;
        jobs[day].push(data);
      } else {
        jobs[day][idx] = data;
      }
    }
  }

  // if dates have been modified we need to loop through every job to remove it from incorrect days.
  if (removeJobAtOldDates) {
    for (const day in jobs) {
      if (jobs[day].length && !days.includes(parseInt(day))) {
        jobs[day] = jobs[day].filter((j) => j.docId !== data.docId);
      }
    }
  }
}

/**
 * sorts the job into the right keys in "jobs"
 * @param data can be job or slinga
 * @param jobs DaysWorkload object which is filled up with jobs in this function
 * @param startDate
 * @param endDate
 */
function removeJobOrSlingaFromJobs(
  data: SlingaDataPartial | JobDataPartial | (SlingaDataPartial & JobDataPartial),
  jobs: DaysWorkload,
  startDate: number,
  endDate: number,
) {
  const days = getCalendarDaysOfJobOrSlinga(data, startDate, endDate);

  for (const day of days) {
    if (jobs[day]) {
      jobs[day] = jobs[day].filter((j) => j.docId !== data.docId);
    }
  }
}

/**
 *
 * @returns DaysWorkload with one entry per day in the week that leads to empty arrays
 */
function getJobsEmptyWeek(startDate: number, do30Days: boolean, NUM_OF_DAYS_LOOK_AHEAD: number) {
  const _jobs: DaysWorkload = {};
  let d = decrementDay(startDate);

  for (let i = 0; i < (!do30Days ? 7 : NUM_OF_DAYS_LOOK_AHEAD); ++i) {
    d = incrementDay(d);
    _jobs[d] = [];
  }

  return _jobs;
}

const getJobType = (job: JobData): string => {
  if (!job) return '';

  if (job.jobType === JobType.EXCAVATOR_MAN) return 'Grävmaskin/anläggare';

  if (job.jobType === JobType.TRANSPORT_MACHINE) return 'Transport maskin';

  if (job.jobType === JobType.TRANSPORT_SCHAKT) return 'Transport schakt';

  return '';
};

const getJobSignature = async (job: JobData) => {
  if (!job) return '';

  let nameOfSignatureFile: string;

  if (job.client) {
    nameOfSignatureFile = job.client.name;
  } else if (job.contactClient) {
    nameOfSignatureFile = job.contactClient.name;
  } else if (job.contactClientTemp) {
    nameOfSignatureFile = job.contactClientTemp;
  } else {
    nameOfSignatureFile = 'unknown';
  }

  const storageResponse = await getImageUrl(`/${job?.orderNum}/signs/${nameOfSignatureFile}`);

  if (storageResponse.code === 200) {
    return storageResponse.data;
  }

  return '';
};

export {
  canStartJob,
  getLittraText,
  getLittraWorkText,
  getContactClientTempPhone,
  getContactClientTempName,
  getTotalHours,
  getJobHours,
  addJobOrSlingaToCorrectDays,
  modifyJobOrSlingaInJobs,
  removeJobOrSlingaFromJobs,
  getJobsEmptyWeek,
  getJobType,
  getJobSignature,
};
