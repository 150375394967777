import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ClientData } from '../../utils/types';
import ClientDetails from '../../popups/ClientDetails';
import AddClient from '../../popups/AddClient';
import ListPage from '../../components/list_page';
import { PopupContext } from '../../contexts/PopupContext';
import history from '../../utils/history';
import { DataContext } from '../../contexts/CommonDataContext';

export default function Clients() {
  const { clients } = useContext(DataContext);
  const [search, setSearch] = useState<string>('');
  const { showPopup } = useContext(PopupContext);

  useEffect(() => {
    return setHistoryListener();
  }, []);

  /**
   * when history is empty, history.block (used in PopupContext to intersect backpressed in browser and on phone) is not called so we add extra history when navigating to each page.
   * @returns unregisterCallback
   */
  function setHistoryListener() {
    history.push('/kunder');
    return history.listen((location, action) => {
      if (action === 'POP' && location.pathname === '/kunder') {
        history.goBack();
      }
    });
  }

  function onChangeSearch(event: SyntheticEvent) {
    setSearch((event.target as HTMLInputElement).value);
  }

  function openAddClient() {
    showPopup(<AddClient />, 'big');
  }

  function openClientDetails(client: ClientData) {
    showPopup(<ClientDetails client={client} />, 'big');
  }

  return (
    <ListPage
      heading='Kunder'
      addButtonText='Ny kund'
      onChangeSearch={onChangeSearch}
      onClickAddButton={openAddClient}
    >
      <ul id='list-page__items'>
        {clients &&
          clients.map((client: ClientData) => {
            return (
              ((client &&
                client.name &&
                client.name.toLocaleLowerCase().includes(search.toLowerCase())) ||
                client?.email.toLowerCase().includes(search.toLowerCase())) && (
                <li
                  tabIndex={0}
                  className='list-page__items__item'
                  id={`list-page__items__${client.docId}`}
                  onClick={() => {
                    openClientDetails(client);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      openClientDetails(client);
                      requestAnimationFrame(() => {
                        (document.querySelector('.button--close') as HTMLElement).focus();
                      });
                    }
                  }}
                  key={client.docId}
                >
                  <p>{client.name}</p>
                </li>
              )
            );
          })}
      </ul>
    </ListPage>
  );
}
