import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Home from '../pages/home';
import Login from '../pages/login';
import history from '../utils/history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Users from '../pages/users';
import Navigation from '../components/navigation';
import Clients from '../pages/clients';
import Vehicles from '../pages/vehicles/Vehicles';
import { GoogleMapsContext } from '../contexts/GoogleMapsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Settings from '../pages/settings';
import ExternalDriverPage from '../pages/external_drivers/ExternalDriverPage';
import Subcontractors from '../pages/subcontractors';
import Resources from '../pages/resources/Resources';
import Places from '../pages/places/Places';
import RemoteSign from '../pages/remote-sign';
import Contacts from '../pages/contacts/Contacts';

interface RouteProps {
  component: React.ReactElement;
  path: string;
  exact: boolean;
}

function AuthRoute({ component, ...rest }: RouteProps) {
  const authState = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.isAuthenticated && !authState.isAnonymous ? (
          component
        ) : (
          <Redirect
            to={{
              pathname: '/logga-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function UnAuthRoute({ component, ...rest }: RouteProps) {
  const authState = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !authState.isAuthenticated || (authState.isAuthenticated && authState.isAnonymous) ? (
          component
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function Routes() {
  const authState = useContext(AuthContext);
  const { apiLoaded } = useContext(GoogleMapsContext);

  return authState.isAuthenticated === undefined || !apiLoaded ? (
    <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />
  ) : (
    <Router history={history}>
      <Navigation />
      <Switch>
        <AuthRoute path='/' exact component={<Home />} />
        <AuthRoute path='/användare' exact component={<Users />} />
        <AuthRoute path='/kunder' exact component={<Clients />} />
        <AuthRoute path='/underleverantörer' exact component={<Subcontractors />} />
        <AuthRoute path='/resurser' exact component={<Resources />} />
        <AuthRoute path='/fordon' exact component={<Vehicles />} />
        <AuthRoute path='/platser' exact component={<Places />} />
        <AuthRoute path='/inställningar' exact component={<Settings />} />
        <AuthRoute path='/kontakter' exact component={<Contacts />} />
        <Route path='/slinga-*' render={() => <ExternalDriverPage />} />
        <Route path='/job-*' render={() => <ExternalDriverPage />} />
        <Route path='/signera-*' render={() => <RemoteSign />} />
        <UnAuthRoute path='/logga-in' exact component={<Login />} />
      </Switch>
    </Router>
  );
}

export default Routes;
