import Select from '../select/Select';
import Option from '../select/Option';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ClientData, ContactData, ContactDoc } from '../../utils/types';
import { PopupContext } from '../../contexts/PopupContext';
import AddContact from '../../popups/AddContact';
import { addContact } from '../../firebase/firestore_functions/contacts';

interface AddContactClientProps {
  client?: ClientData;
  contactClient?: ContactData;
  contactClientTemp?: string;
  setContactClient: React.Dispatch<React.SetStateAction<ContactData | undefined>>;
  setContactClientTemp: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Component that is used in 'AddEditJob* to add/select a contact to the chosen client
 */
export default function AddContactClient({
  client,
  contactClient,
  contactClientTemp,
  setContactClient,
  setContactClientTemp,
}: AddContactClientProps) {
  const { showPopup } = useContext(PopupContext);

  const [contactClients, setClientContacts] = useState<ContactData[]>([]);

  useEffect(updateClientContacts, [client]);

  function updateClientContacts() {
    setClientContacts(client && client.contacts ? client.contacts : []);
  }

  async function onAddContact(contact: ContactDoc) {
    if (client) {
      const response = await addContact(contact, client, 'clients');

      if (response.data) {
        const contactData = { ...contact, docId: response.data };
        const _contacts = [...contactClients, contactData];
        setClientContacts(_contacts);
        setContactClient(contactData);
      }

      return response;
    } else {
      return { code: 500, error: 'no client' };
    }
  }

  function onChangeContactClient(value: string) {
    const _contact = contactClients.find((c) => c.docId === value);
    if (_contact) {
      setContactClientTemp('');
      setContactClient(_contact);
    }
  }

  function onChangeContactClientTemp(event: SyntheticEvent) {
    setContactClient(undefined);
    setContactClientTemp((event.target as HTMLInputElement).value);
  }

  function openAddClientContact(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<AddContact onAdd={onAddContact} />, 'medium');
  }

  return (
    <li key='contact' className='add-job__form__li' id='add-job__form__li-contact'>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <input
          id='contact'
          type='text'
          className='input-text add-job__form__input'
          value={contactClientTemp}
          placeholder='Tillfällig kontakt'
          autoComplete='off'
          onChange={onChangeContactClientTemp}
        />
        {contactClientTemp && contactClientTemp !== '' && (
          <button
            onClick={() => setContactClientTemp('')}
            style={{ position: 'absolute', right: '2rem' }}
            className='fa fa-times'
          ></button>
        )}
      </div>

      <Select
        isMultiSelect={false}
        type='form'
        label='Kontakt'
        onChange={onChangeContactClient}
        iconRightClose='fa fa-angle-down'
        iconRightOpen='fa fa-angle-up'
        initialOption={
          contactClient
            ? {
                value: contactClient.docId,
                label: contactClient.name,
              }
            : undefined
        }
      >
        <>
          {contactClients.map((c: ContactData, idx: number) => {
            return <Option key={idx} value={c.docId} label={c.name} />;
          })}
        </>
      </Select>
      <button
        id='button--add-contact'
        className='button--main--small'
        onClick={openAddClientContact}
      >
        <span className='fa fa-plus' />
        Lägg till kontakt
      </button>
    </li>
  );
}
