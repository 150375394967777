import { BlobProvider, Document, Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { companyAddress, companyMail, companyPhone } from '../../utils/constants';
import { getLittraText, getLittraWorkText } from '../../utils/jobUtils';
import { JobData } from '../../utils/types';
import OrderRow from './OrderRow';
import styles from './PDFStyles';
import { toRangeDateString } from '../../utils/time';

export default function PdfButton(props: {
  jobs: JobData[];
  includeLogo: boolean;
  includePictures?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <BlobProvider
        document={
          <Pdf
            jobs={props.jobs}
            includeLogo={props.includeLogo}
            includePictures={props.includePictures}
          />
        }
        key={props.jobs.length}
      >
        {({ url }) => {
          return (
            <a
              href={url || ''}
              style={{
                color: 'white',
                backgroundColor: 'var(--success-color)',
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                borderRadius: '5px',
                textDecoration: 'none',
              }}
              target='_blank'
              rel='noreferrer'
            >
              {props.children}
            </a>
          );
        }}
      </BlobProvider>
    </div>
  );
}

function Pdf(props: { jobs: JobData[]; includeLogo: boolean; includePictures?: boolean }) {
  const jobOrderNumbers = () => {
    let str = '';
    props.jobs.forEach((job) => {
      str += `${job.orderNum}, `;
    });

    // remove last comma
    str = str.substring(0, str.length - 2);
    return str;
  };

  return (
    <Document>
      {props.jobs.map((job, idx) => {
        return (
          <Page size='A4' key={job.docId} style={styles.page} wrap>
            {idx === 0 && props.includeLogo && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 70,
                }}
              >
                <View style={{ ...styles.top, flex: 1 }}>
                  <Image src='/images/logga.png' style={{ width: 165 }} />
                  <Text style={styles.tableBody}>{companyAddress}</Text>
                  <Text style={styles.tableBody}>{companyPhone}</Text>
                  <Text style={styles.tableBody}>{companyMail}</Text>
                </View>
                <View
                  style={{
                    ...styles.top,
                    flex: 1,
                    alignItems: 'flex-end',
                  }}
                >
                  <Text style={styles.body}>Transportsedel</Text>
                  <Text style={styles.body}>{jobOrderNumbers()}</Text>
                </View>
              </View>
            )}

            <Text
              style={{
                ...styles.body,
                borderBottom: '2pt solid black',
                paddingBottom: '12px',
                marginTop: '30pt',
              }}
            >
              {job.client && `Kund: ${job.client.name}`}
              {'              '}
              {`Littra: ${getLittraText(job) || '-'}`}
              {'              '}
              {`Arbetsplats: ${getLittraWorkText(job) || '-'}`}
            </Text>

            <OrderRow job={job} />
          </Page>
        );
      })}

      {/* Images */}
      {props.jobs.map((job) => {
        return (
          props.includePictures &&
          job.reports &&
          job.reports.map((report) => {
            // Check if the report has images
            if (report.images && report.images.length > 0) {
              return (
                <Page size='A4' key={`${job.docId}-${report.start}`} style={styles.page} wrap>
                  <Text
                    style={{
                      ...styles.body,
                      borderBottom: '1px solid lightgrey',
                      padding: '6 0',
                    }}
                  >
                    {toRangeDateString(report.start, report.end).split(' ')[0]}({job.orderNum})
                  </Text>
                  {report.images.map((img, idx) => {
                    return <Image key={`${img}-${idx}`} style={{ marginTop: 6 }} src={img} />;
                  })}
                </Page>
              );
            }
            // If no images on the report
            return null;
          })
        );
      })}
    </Document>
  );
}
