import React, { useContext } from 'react';

// components
import Datepicker from '../datepicker/Datepicker';
import Select from '../select/Select';
import Option from '../select/Option';

// utils
import { CalendarMode, calenderModeLabels } from '../../utils/constants';
import {
  changeDay,
  decrementDay,
  decrementMonth,
  decrementWeek,
  getDateWithTimeZero,
  getDaysInMonth,
  getEuDayNumber,
  incrementDay,
  incrementMonth,
  incrementWeek,
  toDayDateString,
  toMonthDateString,
  getEndDate,
} from '../../utils/time';
import { weekNumber } from 'weeknumber';

// contexts
import { CalendarModeContext } from '../../contexts/CalendarModeContext';
import { JobsContext } from '../../contexts/JobsContext';
import { NavigationContext } from '../../contexts/NavigationContext';
import { MobileStateContext } from '../../contexts/MobileContext';

// constants
const NUM_OF_DAYS_LOOK_AHEAD = 50;

function CalendarControls() {
  const { isMobile } = useContext(MobileStateContext);
  const {
    calendarMode,
    setCalendarMode,
    startDate,
    setStartDate,
    setEndDate,
    fetch,
    setFetch,
    do30Days,
    setDo30Days,
  } = useContext(CalendarModeContext);
  const { setJobs, setFilteredJobs, loading } = useContext(JobsContext);
  const { navigationStack, setNavigationStack } = useContext(NavigationContext);

  // Uncheck 30 days checkbox if it is checked
  function uncheck30Days() {
    if (do30Days) {
      setDo30Days(false);
    }
  }

  /**
   *
   * @param milliseconds date in milliseconds with all time variables set to 0, first day of week when calendarmode is week and
   * first date of month when calendarmode is month
   */
  function onChangeDate(milliseconds: number) {
    uncheck30Days();

    setStartDate(milliseconds);
    // set end date according to view mode
    const newEndDate = getEndDate(calendarMode, milliseconds, do30Days, NUM_OF_DAYS_LOOK_AHEAD);
    setEndDate(newEndDate.getTime());

    setJobs({});
    setFilteredJobs({});
    setFetch(!fetch);
  }

  function onClickLeftArrow() {
    uncheck30Days();

    let newStartDate: number;
    let newEndDate: number;

    switch (calendarMode) {
      case CalendarMode.MONTH: {
        newStartDate = decrementMonth(startDate);
        const _endDate = new Date(newStartDate);

        // set end date to last day of month
        _endDate.setDate(getDaysInMonth(_endDate.getFullYear(), _endDate.getMonth()));
        newEndDate = _endDate.getTime();

        break;
      }
      case CalendarMode.WEEK:
        newStartDate = decrementWeek(startDate);
        newEndDate = decrementDay(startDate);
        break;
      case CalendarMode.DAY:
        newStartDate = decrementDay(startDate);
        newEndDate = newStartDate;
        break;
    }

    // adjust clock of end date
    const _endDate = new Date(newEndDate);
    _endDate.setHours(23);
    _endDate.setMinutes(59);

    setStartDate(newStartDate);
    setEndDate(_endDate.getTime());
    setJobs({});
    setFilteredJobs({});
    setFetch(!fetch);
  }

  function onClickRightArrow() {
    uncheck30Days();

    let newStartDate: number;
    let newEndDate: number;

    switch (calendarMode) {
      case CalendarMode.MONTH: {
        newStartDate = incrementMonth(startDate);
        // set end date to last day of month
        const _endDate = new Date(newStartDate);
        _endDate.setDate(getDaysInMonth(_endDate.getFullYear(), _endDate.getMonth()));
        newEndDate = _endDate.getTime();
        break;
      }
      case CalendarMode.WEEK:
        newStartDate = incrementWeek(startDate);
        newEndDate = decrementDay(incrementWeek(newStartDate));
        break;
      case CalendarMode.DAY:
        newEndDate = incrementDay(startDate);
        newStartDate = newEndDate;
        break;
    }

    // adjust clock of end date
    const _endDate = new Date(newEndDate);
    _endDate.setHours(23);
    _endDate.setMinutes(59);

    setStartDate(newStartDate);
    setEndDate(_endDate.getTime());
    setJobs({});
    setFetch(!fetch);
  }

  // Used right now for the desktop view when changing calender mode, will be moved to context later
  function onChangeViewHome(_calendarMode: string) {
    uncheck30Days();

    setCalendarMode(parseInt(_calendarMode));

    if (calendarMode !== parseInt(_calendarMode)) {
      const _navigationStack = [...navigationStack];
      _navigationStack.push(calendarMode);
      setNavigationStack(_navigationStack);
    }

    // adjust startDate

    let newStartDate = startDate;

    switch (parseInt(_calendarMode)) {
      case CalendarMode.MONTH: {
        // start att current month 1
        const dateM = new Date(startDate);
        dateM.setDate(1);
        newStartDate = getDateWithTimeZero(dateM.getTime());
        break;
      }
      case CalendarMode.WEEK: {
        // first day of the week of the previously shown day
        let dateW = new Date(startDate);

        const euDay = getEuDayNumber(dateW.getDay());
        const daysDiff = 7 - (7 - euDay);
        dateW = new Date(changeDay(dateW.getTime(), -daysDiff));
        newStartDate = getDateWithTimeZero(dateW.getTime());
        break;
      }
      case CalendarMode.DAY:
        // just dont adjust the startDate, when we come from month mode, the day will be the first day of the month. When we come from week
        // mode, the day will be the first day of that week.
        newStartDate = startDate;
        break;
    }

    const newEndDate = getEndDate(
      parseInt(_calendarMode),
      newStartDate,
      do30Days,
      NUM_OF_DAYS_LOOK_AHEAD,
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate.getTime());
    setFilteredJobs({});
    setJobs({});
    setFetch(!fetch);
  }

  function on30DaysChange(e: React.ChangeEvent<HTMLInputElement>) {
    // Fixes bug where jobs would be duplicated when checking and unchecking the checkbox
    setJobs({});
    setFilteredJobs({});

    setDo30Days(e.target.checked);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'flex-start',
        width: '80%',
      }}
    >
      {calendarMode === CalendarMode.DAY && (
        <Datepicker
          disabled={loading}
          mode='day'
          onChange={onChangeDate}
          currentDateMillis={startDate}
          buttonContent={
            <>
              {toDayDateString(startDate)}
              <span className='fa fa-angle-down' />
            </>
          }
          buttonClassName={'button--icon'}
          onClickLeftArrow={onClickLeftArrow}
          onClickRightArrow={onClickRightArrow}
        />
      )}

      {calendarMode === CalendarMode.WEEK && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <Datepicker
            disabled={loading}
            mode='week'
            onChange={onChangeDate}
            currentDateMillis={startDate}
            buttonContent={
              <>
                {'v. ' + weekNumber(new Date(startDate)) + ' '}
                <span className='fa fa-angle-down' />
              </>
            }
            buttonClassName={'button--icon'}
            onClickLeftArrow={onClickLeftArrow}
            onClickRightArrow={onClickRightArrow}
          />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label> {NUM_OF_DAYS_LOOK_AHEAD} dagar</label>
            <input
              type='checkbox'
              className='checkbox'
              checked={do30Days}
              onChange={on30DaysChange}
              disabled={loading}
            />
          </div>
        </div>
      )}

      {calendarMode === CalendarMode.MONTH && (
        <Datepicker
          disabled={loading}
          mode='month'
          onChange={onChangeDate}
          currentDateMillis={startDate}
          buttonContent={
            <>
              {toMonthDateString(startDate)}
              <span className='fa fa-angle-down' />
            </>
          }
          buttonClassName={'button--icon'}
          onClickLeftArrow={onClickLeftArrow}
          onClickRightArrow={onClickRightArrow}
        />
      )}

      {!isMobile && (
        <div style={{ maxWidth: 200 }}>
          <Select
            disabled={loading}
            onChange={onChangeViewHome}
            isMultiSelect={false}
            type='button'
            iconLeftOpen='fa fa-calendar'
            iconLeftClose='fa fa-calendar'
            label='Vecka'
            initialOption={{
              value: calendarMode.toString(),
              label: calenderModeLabels[calendarMode],
            }}
          >
            <>
              <Option value='0' label='Månad' />
              <Option value='1' label='Vecka' />
              {isMobile && <Option value='2' label='Dag' />}
            </>
          </Select>
        </div>
      )}
    </div>
  );
}

export default CalendarControls;
