interface ImagePopupProps {
  image: string;
}

export default function ImagePopup({ image }: ImagePopupProps) {
  return (
    <section style={{ height: '100%' }}>
      <img
        className='img'
        src={image}
        alt=''
        style={{ width: '100%', objectFit: 'cover', height: 'auto', marginTop: '2.4rem' }}
      />
    </section>
  );
}
