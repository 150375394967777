import { AddEditJobProvider } from './AddEditJobContext';
import { JobData, User, VehicleData } from '../../utils/types';
import AddEditJobSlinga from './AddEditJobSlinga';

export enum AddEditJobMode {
  SLINGA,
  JOB,
}

type AddEditJobSlingaMainProps = {
  job?: JobData;

  /**
   * Fields in the job needs to be synked in different ways with what is added to the slinga.
   * slingaVehicle: CANNOT change for the job, is only used to display what vehicle is ADDED to the slinga.
   * slingaVehicleEquipments: CAN change for the job, what is already chosen for the slinga is displayed at first when a job is ADDED.
   * slingaStart: start for job CANNOT be less than start for slinga.
   * slingaEnd: end for job CANNOT be greater than end for slinga.
   */

  slingaDriver?: User;
  slingaVehicle?: VehicleData;
  slingaVehicleEquipments?: Set<string>;
  slingaStart: number;
  slingaEnd: number;

  addJobToSlinga?: (jobData: JobData) => void;
  removeJobFromSlinga?: () => void;
};

/**
 * Main component for add or edit of job. The purpose is to wrap AddEditSlinga in AddEditJobProvider which contains state
 * for the udpated or new job.
 *
 */
export default function AddEditJobSlingaMain({
  job,
  slingaVehicle,
  slingaDriver,
  slingaVehicleEquipments,
  slingaStart,
  slingaEnd,
  addJobToSlinga,
  removeJobFromSlinga,
}: AddEditJobSlingaMainProps) {
  return (
    <AddEditJobProvider>
      <AddEditJobSlinga
        job={job}
        slingaStart={slingaStart}
        slingaEnd={slingaEnd}
        slingaDriver={slingaDriver}
        slingaVehicle={slingaVehicle}
        slingaVehicleEquipments={slingaVehicleEquipments}
        addJobToSlinga={addJobToSlinga}
        removeJobFromSlinga={removeJobFromSlinga}
      />
    </AddEditJobProvider>
  );
}
