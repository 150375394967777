import { SyntheticEvent, useState } from 'react';
import Select from '../components/select/Select';
import { UserType, userTypeLabel } from '../utils/constants';
import Option from '../components/select/Option';
import { User, UserDoc } from '../utils/types';

interface UserFormProps {
  user?: User;
  handleSubmit: (user: UserDoc) => Promise<void>;
  saving: boolean;
}
export default function UserForm({ user, saving, handleSubmit }: UserFormProps) {
  const [firstName, setFirstName] = useState<string>(user?.firstName ? user.firstName : '');
  const [lastName, setLastName] = useState<string>(user?.lastName ? user.lastName : '');
  const [email, setEmail] = useState<string>(user?.email ? user.email : '');
  const [phone, setPhone] = useState<string>(user?.phone ? user.phone : '');
  const [userType, setUserType] = useState<UserType>(
    user?.userType ? user.userType : UserType.DRIVER,
  );

  function onChangeFirstName(event: SyntheticEvent) {
    setFirstName((event.target as HTMLInputElement).value);
  }

  function onChangeLastName(event: SyntheticEvent) {
    setLastName((event.target as HTMLInputElement).value);
  }

  function onChangeEmail(event: SyntheticEvent) {
    setEmail((event.target as HTMLInputElement).value);
  }

  function onChangePhone(event: SyntheticEvent) {
    setPhone((event.target as HTMLInputElement).value);
  }

  function onChangeUserType(value: string) {
    setUserType(parseInt(value));
  }

  async function _handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    handleSubmit({ firstName, lastName, email, phone, userType });
  }

  return (
    <form className='form' onSubmit={_handleSubmit}>
      <ul className='form__ul'>
        <li className='form__li'>
          <Select
            onChange={onChangeUserType}
            isMultiSelect={false}
            initialOption={{
              value: userType.toString(),
              label: userTypeLabel[userType],
            }}
            type='form'
            iconRightClose='fa fa-angle-down'
            iconRightOpen='fa fa-angle-up'
          >
            <>
              <Option value={UserType.DRIVER.toString()} label={userTypeLabel[UserType.DRIVER]} />
              <Option
                value={UserType.DRIVER_EXTENDED.toString()}
                label={userTypeLabel[UserType.DRIVER_EXTENDED]}
              />
              <Option value={UserType.ADMIN.toString()} label={userTypeLabel[UserType.ADMIN]} />
            </>
          </Select>
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={firstName}
            placeholder='Förnamn'
            onChange={onChangeFirstName}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={lastName}
            placeholder='Efternamn'
            onChange={onChangeLastName}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={email}
            placeholder='Email'
            onChange={onChangeEmail}
            required
          />
        </li>
        <li className='form__li'>
          <input
            className='input-text form__input'
            type='text'
            value={phone}
            placeholder='Tel'
            onChange={onChangePhone}
            required
          />
        </li>
        <li className='form__li loading-parent'>
          {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
          <input className='button--green form__input' type='submit' value='Spara' />
        </li>
      </ul>
    </form>
  );
}
