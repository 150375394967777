import React, { useEffect, useState } from 'react';
import { CalendarModeState } from '../utils/types';
import { CalendarMode } from '../utils/constants';
import { changeDay, getDateWithTimeZero, getDefaultEndDate, getDefaultStartDate, getEndDate, getEuDayNumber } from '../utils/time';
import { JobsContext } from './JobsContext'; // Import the JobsContext or other relevant contexts
import { NavigationContext } from './NavigationContext';

interface CalendarModeContextProviderProps {
    children: React.ReactElement;
  }

const initialState: CalendarModeState = {
    calendarMode: CalendarMode.WEEK,
    setCalendarMode: () => undefined,
    startDate: getDefaultStartDate(CalendarMode.WEEK),
    setStartDate: () => undefined,
    endDate: getDefaultEndDate(CalendarMode.WEEK),
    setEndDate: () => undefined,
    fetch: false,
    setFetch: () => undefined,
    do30Days: false,
    setDo30Days: () => undefined,
    onChangeView: () => undefined,
  
};

export const CalendarModeContext = React.createContext(initialState);

export function CalendarModeContextProvider({ children }: CalendarModeContextProviderProps) {
    const { setJobs, setFilteredJobs } = React.useContext(JobsContext); // Get the jobs and filteredJobs from the JobsContext
    const { navigationStack, setNavigationStack } = React.useContext(NavigationContext); // Get the navigationStack from the NavigationContext

    const [calendarMode, setCalendarMode] = useState<CalendarMode>(CalendarMode.WEEK);

    const [startDate, setStartDate] = useState<number>(getDefaultStartDate(CalendarMode.WEEK));
    const [endDate, setEndDate] = useState<number>(getDefaultEndDate(CalendarMode.WEEK));
  
    // used to trigger fetching of new jobs to make sure that state is updated when doing so.
    const [fetch, setFetch] = useState(false);

    const [do30Days, setDo30Days] = useState<boolean>(false);

    useEffect(() => {
        onChangeView('1', 50);
      }, [do30Days]);

    function onChangeView(_calendarMode: string, NUM_OF_DAYS_LOOK_AHEAD: number) {
        setCalendarMode(parseInt(_calendarMode));
    
        if (calendarMode !== parseInt(_calendarMode)) {
          const _navigationStack = [...navigationStack];
          _navigationStack.push(calendarMode);
          setNavigationStack(_navigationStack);
        }
    
        // adjust startDate
    
        let newStartDate = startDate;
    
        switch (parseInt(_calendarMode)) {
          case CalendarMode.MONTH: {
            // start att current month 1
            const dateM = new Date(startDate);
            dateM.setDate(1);
            newStartDate = getDateWithTimeZero(dateM.getTime());
            break;
          }
          case CalendarMode.WEEK: {
            // first day of the week of the previously shown day
            let dateW = new Date(startDate);
    
            const euDay = getEuDayNumber(dateW.getDay());
            const daysDiff = 7 - (7 - euDay);
            dateW = new Date(changeDay(dateW.getTime(), -daysDiff));
            newStartDate = getDateWithTimeZero(dateW.getTime());
            break;
          }
          case CalendarMode.DAY:
            // just dont adjust the startDate, when we come from month mode, the day will be the first day of the month. When we come from week
            // mode, the day will be the first day of that week.
            newStartDate = startDate;
            break;
        }
    
        const newEndDate = getEndDate(
          parseInt(_calendarMode),
          newStartDate,
          do30Days,
          NUM_OF_DAYS_LOOK_AHEAD,
        );
    
        setStartDate(newStartDate);
        setEndDate(newEndDate.getTime());
        setFilteredJobs({});
        setJobs({});
        setFetch(!fetch);
      }

  return (
    <CalendarModeContext.Provider
      value={{
        calendarMode,
        setCalendarMode,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        fetch,
        setFetch,
        do30Days,
        setDo30Days,
        onChangeView,
      }}
    >
      {children}
    </CalendarModeContext.Provider>
  );
}
