import React, { ReactElement, SyntheticEvent, useContext, useState } from 'react';
import { MobileStateContext } from '../../contexts/MobileContext';
import { PopupContext } from '../../contexts/PopupContext';
import { decrementDay, incrementDay } from '../../utils/time';

import './Datepicker.css';
import Daypicker from './daypicker/Daypicker';
import Monthpicker from './monthpicker/Monthpicker';
import Weekpicker from './weekpicker/Weekpicker';
import { CalendarMode } from '../../utils/constants';
import { on } from 'cluster';

interface DatepickerProps {
  disabled?: boolean;
  mode: 'day' | 'week' | 'month';
  onChange: (date: number) => void;
  currentDateMillis: number;
  buttonContent: ReactElement;
  buttonClassName: string;
  showQuickChangeButtons?: boolean;
  onClickLeftArrow?: () => void;
  onClickRightArrow?: () => void;
}

// datepicker where currentDateMillis in mode "week" == begining of week, month == first day of month, day == actual day

function Datepicker({
  disabled,
  mode,
  onChange,
  currentDateMillis,

  // if user wants to change button content and style
  buttonContent,
  buttonClassName,

  // if it should be possible to use arrows on the side of the button to fast pick date
  showQuickChangeButtons,

  // if user wants to use arrows to change date
  onClickLeftArrow,
  onClickRightArrow,
}: DatepickerProps) {
  const { close, showPopup } = useContext(PopupContext);
  const { isMobile } = useContext(MobileStateContext);
  const [showPicker, setShowPicker] = useState<boolean>(false);

  // // Represents first day of week in mode "week", first day of month in mode "month" and actual date in mode "day"
  // const [currentDateMillis, setSelectedDate] = useState<number>(currentDateMillis);

  function selectDate(date: number) {
    // setSelectedDate(date);
    onChange(date);
    setTimeout(closePicker, 100);
  }

  function selectPrevDate(event: SyntheticEvent) {
    // setSelectedDate(decrementDay(currentDateMillis));
    onChange(decrementDay(currentDateMillis));
  }

  function selectNextDate(event: SyntheticEvent) {
    // setSelectedDate(incrementDay(currentDateMillis));
    onChange(incrementDay(currentDateMillis));
  }

  function closePicker() {
    if (isMobile) {
      close();
    } else {
      setShowPicker(false);
    }
  }

  function openPicker(event: SyntheticEvent) {
    if (isMobile) {
      let picker;

      switch (mode) {
        case 'day':
          picker = <Daypicker selectDate={selectDate} currentDate={currentDateMillis} />;
          break;
        case 'month':
          picker = <Monthpicker currentDate={currentDateMillis} onChange={selectDate} />;
          break;
        case 'week':
          picker = <Weekpicker currentDate={currentDateMillis} onChange={selectDate} closePicker={closePicker} />;
          break;
        default:
          picker = <Daypicker selectDate={selectDate} currentDate={currentDateMillis} />;

          break;
      }
      showPopup(picker, 'min');
    } else {
      setShowPicker(true);
    }
  }



  return (
    <>
      {showPicker && <div className='overlay' onClick={closePicker} />}
      <section id='date-picker'>
        {onClickLeftArrow && (
          <button
            onClick={onClickLeftArrow}
            className='fa fa-angle-left button--icon'
            id='calendar__controls__left__button--left'
          />
        )}

        <section id='date-picker__buttons'>
          <button
            id='date-picker__button'
            className={buttonClassName}
            onClick={openPicker}
            disabled={disabled}
          >
            {buttonContent}
          </button>

          {showQuickChangeButtons && (
            <section>
              <button className='fa fa-angle-double-up button--icon' onClick={selectPrevDate} />
              <button className='fa fa-angle-double-down button--icon' onClick={selectNextDate} />
            </section>
          )}
        </section>

        {/* For desktop mode (in mobile mode a dialog is shown instead)*/}

        {showPicker && <div id='overlay' onClick={closePicker} />}

        {showPicker && mode === 'day' && (
          <Daypicker selectDate={selectDate} currentDate={currentDateMillis} />
        )}

        {showPicker && mode === 'month' && (
          <Monthpicker currentDate={currentDateMillis} onChange={selectDate} />
        )}

        {showPicker && mode === 'week' && (
          <Weekpicker currentDate={currentDateMillis} onChange={selectDate} closePicker={closePicker} />
        )}

        {onClickRightArrow && (
          <button
            onClick={onClickRightArrow}
            className='fa fa-angle-right button--icon'
            id='calendar__controls__left__button--right'
          />
        )}
      </section>
    </>
  );
}

export default Datepicker;
