import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { UserType } from '../../utils/constants';
import './Navigation.css';

import { MobileStateContext } from '../../contexts/MobileContext';

import CalendarControls from '../calendar_controls/CalendarControls';

export default function Navigation() {
  const [showMenu, setShowMenu] = useState<boolean>();
  const location = useLocation();

  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(MobileStateContext);

  const navigation = useHistory();

  function openMenu() {
    setShowMenu(true);
  }

  function closeMenu() {
    (document.querySelector('#navigation__button--open-menu') as HTMLInputElement).focus();
    setShowMenu(false);
  }

  function navigateToUsers() {
    navigation.push('/användare');
    setShowMenu(false);
  }
  function navigateToClients() {
    navigation.push('/kunder');
    setShowMenu(false);
  }
  function navigateToSubcontractors() {
    navigation.push('/underleverantörer');
    setShowMenu(false);
  }
  function navigateToCalendar() {
    navigation.push('/');
    setShowMenu(false);
  }
  function navigateToResources() {
    navigation.push('/resurser');
    setShowMenu(false);
  }

  function navigateToSettings() {
    navigation.push('/inställningar');
    setShowMenu(false);
  }

  // Desktop view (one row)
  function navbarDesktop() {
    return (
      <header id='navigation'>
        <h1 id='navigation--pointer'>
          <img
            src='/images/logga.png'
            width='1165'
            height='237'
            alt=''
            onClick={() => {
              navigateToCalendar();
            }}
          />
        </h1>

        {location.pathname !== '/logga-in' && <CalendarControls />}

        {location.pathname !== '/logga-in' &&
          !(location.pathname.startsWith('/slinga-') || location.pathname.startsWith('/job-')) && (
            <button id='navigation__button--open-menu' className='button--icon' onClick={openMenu}>
              {' '}
              <span className='fa fa-bars' /> Meny
            </button>
          )}

        {showMenu && <div id='overlay' onClick={closeMenu} />}

        <section
          className={
            showMenu === true
              ? 'navigation__menu--visible'
              : showMenu === false
              ? 'navigation__menu--hidden-anim'
              : 'navigation__menu--hidden'
          }
          id='navigation__menu'
        >
          <button
            id='navigation__menu__button--close'
            className='fa fa-close button--icon'
            onClick={closeMenu}
            tabIndex={showMenu ? 0 : -1}
          ></button>

          <ul>
            <li className='navigation__menu__item'>
              <button
                tabIndex={showMenu ? 0 : -1}
                onClick={navigateToCalendar}
                className={
                  navigation.location.pathname === '/'
                    ? 'navigation__menu__button navigation__menu__button--active'
                    : 'navigation__menu__button'
                }
              >
                Kalender
              </button>
            </li>

            <li className='navigation__menu__item'>
              <button
                tabIndex={showMenu ? 0 : -1}
                onClick={navigateToUsers}
                className={
                  navigation.location.pathname === '/användare'
                    ? 'navigation__menu__button navigation__menu__button--active'
                    : 'navigation__menu__button'
                }
              >
                Personal
              </button>
            </li>

            {user?.userType &&
            [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
              <li className='navigation__menu__item'>
                <button
                  tabIndex={showMenu ? 0 : -1}
                  onClick={navigateToClients}
                  className={
                    navigation.location.pathname === '/kunder'
                      ? 'navigation__menu__button navigation__menu__button--active'
                      : 'navigation__menu__button'
                  }
                >
                  Kunder
                </button>
              </li>
            ) : null}

            {user?.userType &&
            [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
              <li className='navigation__menu__item'>
                <button
                  tabIndex={showMenu ? 0 : -1}
                  onClick={navigateToSubcontractors}
                  className={
                    navigation.location.pathname === '/underleverantörer'
                      ? 'navigation__menu__button navigation__menu__button--active'
                      : 'navigation__menu__button'
                  }
                >
                  Underleverantörer
                </button>
              </li>
            ) : null}

            {user?.userType &&
            [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
              <li className='navigation__menu__item'>
                <button
                  tabIndex={showMenu ? 0 : -1}
                  onClick={navigateToResources}
                  className={
                    navigation.location.pathname === '/fordon'
                      ? 'navigation__menu__button navigation__menu__button--active'
                      : 'navigation__menu__button'
                  }
                >
                  Resurser
                </button>
              </li>
            ) : null}

            <li className='navigation__menu__item'>
              <button
                tabIndex={showMenu ? 0 : -1}
                onClick={navigateToSettings}
                className={
                  navigation.location.pathname === '/inställningar'
                    ? 'navigation__menu__button navigation__menu__button--active'
                    : 'navigation__menu__button'
                }
              >
                Inställningar
              </button>
            </li>
          </ul>
        </section>
      </header>
    );
  }

  // Mobile view (column with two rows: one for logo + menu button, one for calendar controls)
  function navbarMobile() {
    return (
      <header id='navigation-mobile'>
        <div id='navigation-row'>
          <h1 id='navigation--pointer'>
            <img
              src='/images/logga.png'
              width='1165'
              height='237'
              alt=''
              onClick={() => {
                navigateToCalendar();
              }}
            />
          </h1>

          {location.pathname !== '/logga-in' &&
            !(
              location.pathname.startsWith('/slinga-') || location.pathname.startsWith('/job-')
            ) && (
              <button
                id='navigation__button--open-menu'
                className='button--icon'
                onClick={openMenu}
              >
                {' '}
                <span className='fa fa-bars' /> Meny
              </button>
            )}

          {showMenu && <div id='overlay' onClick={closeMenu} />}

          <section
            className={
              showMenu === true
                ? 'navigation__menu--visible'
                : showMenu === false
                ? 'navigation__menu--hidden-anim'
                : 'navigation__menu--hidden'
            }
            id='navigation__menu'
          >
            <button
              id='navigation__menu__button--close'
              className='fa fa-close button--icon'
              onClick={closeMenu}
              tabIndex={showMenu ? 0 : -1}
            ></button>

            <ul>
              <li className='navigation__menu__item'>
                <button
                  tabIndex={showMenu ? 0 : -1}
                  onClick={navigateToCalendar}
                  className={
                    navigation.location.pathname === '/'
                      ? 'navigation__menu__button navigation__menu__button--active'
                      : 'navigation__menu__button'
                  }
                >
                  Kalender
                </button>
              </li>

              <li className='navigation__menu__item'>
                <button
                  tabIndex={showMenu ? 0 : -1}
                  onClick={navigateToUsers}
                  className={
                    navigation.location.pathname === '/användare'
                      ? 'navigation__menu__button navigation__menu__button--active'
                      : 'navigation__menu__button'
                  }
                >
                  Personal
                </button>
              </li>

              {user?.userType &&
              [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
                <li className='navigation__menu__item'>
                  <button
                    tabIndex={showMenu ? 0 : -1}
                    onClick={navigateToClients}
                    className={
                      navigation.location.pathname === '/kunder'
                        ? 'navigation__menu__button navigation__menu__button--active'
                        : 'navigation__menu__button'
                    }
                  >
                    Kunder
                  </button>
                </li>
              ) : null}

              {user?.userType &&
              [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
                <li className='navigation__menu__item'>
                  <button
                    tabIndex={showMenu ? 0 : -1}
                    onClick={navigateToSubcontractors}
                    className={
                      navigation.location.pathname === '/underleverantörer'
                        ? 'navigation__menu__button navigation__menu__button--active'
                        : 'navigation__menu__button'
                    }
                  >
                    Underleverantörer
                  </button>
                </li>
              ) : null}

              {user?.userType &&
              [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType) ? (
                <li className='navigation__menu__item'>
                  <button
                    tabIndex={showMenu ? 0 : -1}
                    onClick={navigateToResources}
                    className={
                      navigation.location.pathname === '/fordon'
                        ? 'navigation__menu__button navigation__menu__button--active'
                        : 'navigation__menu__button'
                    }
                  >
                    Resurser
                  </button>
                </li>
              ) : null}

              <li className='navigation__menu__item'>
                <button
                  tabIndex={showMenu ? 0 : -1}
                  onClick={navigateToSettings}
                  className={
                    navigation.location.pathname === '/inställningar'
                      ? 'navigation__menu__button navigation__menu__button--active'
                      : 'navigation__menu__button'
                  }
                >
                  Inställningar
                </button>
              </li>
            </ul>
          </section>
        </div>

        {location.pathname !== '/logga-in' && (
          <div id='navigation-row'>
            <CalendarControls />
          </div>
        )}
      </header>
    );
  }

  return <>{isMobile ? navbarMobile() : navbarDesktop()}</>;
}
