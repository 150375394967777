import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: '0 40 0 40',
  },
  viewer: {
    width: '100vw',
    height: '95vh',
  },
  header: {
    borderTop: '2pt solid black',
    borderBottom: '2pt solid black',
    marginTop: 10,
  },
  section: {
    borderBottom: '1pt solid lightgrey',
    paddingBottom: '20pt',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  column: {
    padding: 4,
  },
  title: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    padding: 0,
  },
  tableTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    padding: 0,
  },
  body: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    padding: 0,
  },
  tableBody: {
    fontSize: 10,
    fontFamily: 'Helvetica',
    padding: 0,
  },
  top: {
    margin: 30,
    marginBottom: 0,
  },
  tableCell: {
    margin: 0,
    border: '1px solid grey',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  report: {
    marginBottom: 24,
  },
  dayInfo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 4,
  },

  dayInfoItem: {
    marginRight: 16,
  },
});
export default styles;
