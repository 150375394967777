import React, { useEffect, useState } from 'react';

import {
  changeDay,
  getDateWithTimeZero,
  getEuDayNumber,
  headerDays,
  headerDaysSwedish,
  incrementDay,
  months,
} from '../../../utils/time';

import './Weekpicker.css';
import CalenderModeButtonGroup from '../../buttons/CalenderModeButtonGroup';

interface WeekpickerProps {
  currentDate: number;
  onChange: (date: number) => void;
  closePicker: () => void;
}

function Weekpicker({ currentDate, onChange, closePicker }: WeekpickerProps) {
  const [selectedYear, setSelectedYear] = useState<number>(new Date(currentDate).getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date(currentDate).getMonth());

  const [weeks, setWeeks] = useState<Array<Array<number>>>([]);

  // update ui as soon as there is new month
  useEffect(createWeeksArray, [selectedMonth, selectedYear]);

  function createWeeksArray() {
    const _weeks: Array<Array<number>> = [];

    // start with current date
    let currentDate = new Date(getDateWithTimeZero(new Date().getTime()));

    // adjust the month and year according to selection
    currentDate.setMonth(selectedMonth);
    currentDate.setFullYear(selectedYear);

    // set first date
    currentDate.setDate(1);

    // calculate the the date of closest monday
    currentDate = new Date(changeDay(currentDate.getTime(), -getEuDayNumber(currentDate.getDay())));

    // iterate over 6 weeks to create a week calendar
    for (let w = 0; w < 6; ++w) {
      const days: Array<number> = [];

      for (let d = 0; d < 7; ++d) {
        days.push(currentDate.getTime());

        currentDate = new Date(incrementDay(currentDate.getTime()));
      }
      _weeks.push(days);
    }
    setWeeks(_weeks);
  }

  function nextMonth() {
    const newMonth = selectedMonth + 1;
    if (newMonth === 12) {
      setSelectedMonth(0);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(newMonth);
    }
  }

  function prevMonth() {
    const newMonth = selectedMonth - 1;

    if (newMonth < 0) {
      setSelectedMonth(11);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(newMonth);
    }
  }

  return (
    <section id='weekpicker'>
      <section id='weekpicker__weeks'>
        <CalenderModeButtonGroup closePicker={closePicker} />
        <div id='weekpicker__controls'>
          <button className='fa fa-angle-double-left button--icon' onClick={prevMonth}></button>

          <p>
            {selectedYear} {months[selectedMonth]}
          </p>

          <button className='fa fa-angle-double-right button--icon' onClick={nextMonth}></button>
        </div>
        <ul id='weekpicker__weeks__header'>
          {headerDaysSwedish.map((day: string, idx: number) => {
            return (
              <li className='calendar-day' key={`${day}${idx}`}>
                {day}
              </li>
            );
          })}
        </ul>

        {weeks.map((week: Array<number>, idx: number) => {
          return (
            <ul
              onClick={() => {
                onChange(week[0]);
              }}
              key={`${idx * Math.random() * 100}`}
              className={week.includes(currentDate) ? 'weekpicker__weeks__selected' : ''}
            >
              {week.map((day: number, idx: number) => {
                return (
                  <li
                    key={`${day}${idx}`}
                    className={new Date(day).getMonth() !== selectedMonth ? 'prev-month-day' : ''}
                  >
                    {new Date(day).getDate()}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </section>
    </section>
  );
}

export default Weekpicker;
