import { useContext, useState } from 'react';
import { Client } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import ClientForm from '../forms/ClientForm';
import { stopPropagation } from '../utils/uiHelpers';
import { PopupContext } from '../contexts/PopupContext';
import { addClient, createClientInFortnox } from '../firebase/firestore_functions/clients';

export default function AddClient() {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  async function handleSubmit(client: Client) {
    setSaving(true);

    const addClientResponse = await addClient(client);

    if (addClientResponse.code === 201) {
      setSaving(false);
      close();
      notify('Kund tillagd', NotificationStatus.SUCCESS);
      createClientInFortnox(client.name, client.email, client.orgNum, client.phone).then((ret) => {
        notify(ret, NotificationStatus.INFO);
      });
    } else {
      setSaving(false);
      notify(`Kunde inte spara kund: ${addClientResponse.error}`, NotificationStatus.ERROR);
    }
  }

  return (
    <section id='add-client' onClick={stopPropagation}>
      <header id='add-client__header'>
        <h2>Lägg till en ny kund</h2>
      </header>
      <main>
        <ClientForm handleSubmit={handleSubmit} saving={saving} />
      </main>
    </section>
  );
}
