import React, { useState } from 'react';
import { signInWithEmailAndPassword, getAuth, sendPasswordResetEmail  } from 'firebase/auth';
import { firebaseApp } from '../../firebase/firebaseConfig';
import './Login.css';
import { FirebaseError } from '@firebase/util';

interface CredentialState {
  content: string;
  hasError: boolean;
  error: string;
}

function Login() {
  const [emailState, setEmailState] = useState<CredentialState>({
    content: '',
    hasError: false,
    error: '',
  });
  const [passwordState, setPasswordState] = useState<CredentialState>({
    content: '',
    hasError: false,
    error: '',
  });

  const [emailResetState, setEmailResetState] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);

  function handleChangePassword(event: React.SyntheticEvent) {
    setPasswordState({
      ...passwordState,
      content: (event.target as HTMLInputElement).value,
      hasError: false,
      error: '',
    });
    setEmailState({ ...emailState, hasError: false, error: '' });
  }

  function handleChangeEmail(event: React.SyntheticEvent) {
    setEmailState({
      ...emailState,
      content: (event.target as HTMLInputElement).value,
      hasError: false,
      error: '',
    });
    setPasswordState({ ...passwordState, hasError: false, error: '' });
  }

  function handleChangeShowPassword(event: React.SyntheticEvent) {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();


    if (emailResetState) {
      try {
        await sendPasswordResetEmail(
          getAuth(firebaseApp),
          emailState.content,
        );
        setEmailResetState(false);
      } catch (error) {
        const errorCode = (error as FirebaseError).code;
        if (errorCode === 'auth/user-not-found') {
          setEmailState({
            ...emailState,
            hasError: true,
            error: 'Användaren hittades inte',
          });
        } else {
          setEmailState({
            ...emailState,
            hasError: true,
            error: 'Något gick fel',
          });
        }
      }
      return;
    }

    try {
      await signInWithEmailAndPassword(
        getAuth(firebaseApp),
        emailState.content,
        passwordState.content,
      );
    } catch (error) {
      const errorCode = (error as FirebaseError).code;

      if (errorCode === 'auth/user-not-found') {
        setEmailState({
          ...emailState,
          hasError: true,
          error: 'Användaren hittades inte',
        });
      } else if (errorCode === 'auth/wrong-password') {
        setPasswordState({
          ...passwordState,
          hasError: true,
          error: 'Fel lösenord',
        });
      }
    }
  }

  return (
    <div className='wrapper'>
      <main id='login__main'>
        {emailResetState ? (<section id='section-login'>
          <h2>Återställ lösenord</h2>
          <form onSubmit={handleSubmit}>
          <input
              className='input-text'
              id='email'
              type='email'
              value={emailState.content}
              onChange={handleChangeEmail}
              required
              placeholder='Email'
            />
            <input className='button--main' type='submit' value='Återställ' />
            <button className='forgot-password' onClick={()=> setEmailResetState(false)}>Logga in</button>
          </form>

        </section>) : (<section id='section-login'>
          <h2>Logga in</h2>
          <form onSubmit={handleSubmit}>
            <input
              className='input-text'
              id='email'
              type='email'
              value={emailState.content}
              onChange={handleChangeEmail}
              required
              placeholder='Email'
            />
            {emailState.hasError && <p className='error'>{emailState.error}</p>}

            <div id='password-wrapper'>
              <input
                className='input-text'
                id='password'
                type={showPassword ? 'text' : 'password'}
                placeholder='Lösenord'
                value={passwordState.content}
                onChange={handleChangePassword}
                required
              ></input>
              <button
                id='password-visibility'
                onClick={handleChangeShowPassword}
                className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
              ></button>
            </div>
            {passwordState.hasError && <p className='error'>{passwordState.error}</p>}

            <input className='button--main' type='submit' value='Logga in' />
            <button className='forgot-password' onClick={()=> setEmailResetState(true)}>Glömt lösenord</button>
          </form>
        </section>)}
        
      </main>
    </div>
  );
}

export default Login;
