import React from 'react';
import { useHistory } from 'react-router-dom';
import './Resources.css';

const Resources: React.FC = () => {
  const navigation = useHistory();

  return (
    <div id='resources-view'>
      <button
        id='resources-button'
        className='button--main'
        onClick={() => {
          navigation.push('/fordon');
        }}
      >
        Gå till Fordon
      </button>
      <button
        id='resources-button'
        className='button--main'
        onClick={() => {
          navigation.push('/platser');
        }}
      >
        Gå till Platser
      </button>
      <button
        id='resources-button'
        className='button--main'
        onClick={() => {
          navigation.push('/kontakter');
        }}
      >
        Gå till kontakter
      </button>
    </div>
  );
};

export default Resources;
