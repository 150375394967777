import { SyntheticEvent, useEffect, useState } from 'react';
import { ChosenMaterials, MaterialData } from '../../utils/types';
import Select from '../select/Select';
import Option from '../select/Option';
import { getMaterials } from '../../firebase/firestore_functions/materials';

interface AddMaterialsProps {
  materials: ChosenMaterials;
  setMaterials: React.Dispatch<React.SetStateAction<ChosenMaterials>>;
}

/**
 * Component that is used in 'AddEditJob' and 'RowForm' to add/select materials that is used in a job/report
 */
export default function AddMaterials({ materials, setMaterials }: AddMaterialsProps) {
  const [allMaterials, setAllMaterials] = useState<MaterialData[]>([]);

  useEffect(init, []);

  function init() {
    fetchMaterials();
  }

  async function fetchMaterials() {
    const materialsResponse = await getMaterials();
    if (materialsResponse.code === 200 && materialsResponse.data) {
      setAllMaterials(materialsResponse.data);
    }
  }

  function onChangeQuantity(event: SyntheticEvent, docId: string, idx: number) {
    const value = (event.target as HTMLInputElement).value;
    let qty;

    if (value === '') {
      qty = 0;

      // match num with 2 decimals
    } else if (/\d*\.\d{1,2}/.exec(value) && materials[docId].howMuch[idx].unit === 'Ton') {
      qty = parseFloat(value);
    } else {
      qty = parseInt(value);
    }

    const _materials = { ...materials };
    _materials[docId].howMuch[idx].qty = qty;
    setMaterials(_materials);
  }

  function onChangeMaterials(value: string) {
    const _materials = { ...materials };
    const material = allMaterials.find((mat) => mat.docId === value);

    if (material) {
      _materials[value] = {
        material,
        howMuch: [
          { qty: 0, unit: 'Lass' },
          { qty: 0, unit: 'Ton' },
        ],
      };

      setMaterials(_materials);
    }
  }

  function removeMaterial(value: string) {
    const _materials = { ...materials };
    delete _materials[value];
    setMaterials(_materials);
  }

  return (
    <li key='material' className='add-job__form__li'>
      <Select
        isMultiSelect={false}
        type='form'
        label='Material'
        onChange={onChangeMaterials}
        iconRightClose='fa fa-angle-down'
        iconRightOpen='fa fa-angle-up'
        showLabel={true}
        searchBar={true}
      >
        <>
          {allMaterials.map((m: MaterialData) => {
            return <Option key={m.docId} value={m.docId} label={m.description} />;
          })}
        </>
      </Select>

      {materials && Object.keys(materials).length > 0 && (
        <ul className='form__selected-items'>
          {Object.keys(materials).map((m: string) => {
            return (
              <li style={{ overflowX: 'auto' }} className='form__selected-items__item' key={m}>
                <p>{allMaterials.find((mat) => mat.docId === m)?.description}</p>
                <ul style={{ display: 'flex' }}>
                  <li style={{ display: 'flex', alignItems: 'center', marginRight: '1.6rem' }}>
                    <input
                      style={{ width: '4rem', textAlign: 'center', marginRight: '0.5rem' }}
                      className='input-text '
                      type='number'
                      placeholder='0'
                      value={materials[m].howMuch[0].qty || ''}
                      onChange={(event: SyntheticEvent) => {
                        onChangeQuantity(event, m, 0);
                      }}
                      step={materials[m].howMuch[0].unit === 'Ton' ? '.01' : '1'}
                      min='0'
                    />

                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                      {materials[m].howMuch[0].unit}
                    </p>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ width: '4rem', textAlign: 'center' }}
                      className='input-text'
                      type='number'
                      placeholder='0'
                      value={materials[m].howMuch[1].qty || ''}
                      onChange={(event: SyntheticEvent) => {
                        onChangeQuantity(event, m, 1);
                      }}
                      step={materials[m].howMuch[1].unit === 'Ton' ? '.01' : '1'}
                      min='0'
                    />
                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                      {materials[m].howMuch[1].unit}
                    </p>
                  </li>
                </ul>

                <button
                  onClick={() => {
                    removeMaterial(m);
                  }}
                  className='button--icon fa fa-trash'
                ></button>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}
