import { useContext, useState } from 'react';
import { NotificationStatus } from '../utils/constants';

import { NotificationContext } from '../contexts/NotificationContext';
import { PopupContext } from '../contexts/PopupContext';
import { stopPropagation } from '../utils/uiHelpers';
import UserForm from '../forms/UserForm';
import { UserDoc } from '../utils/types';
import { addUser } from '../firebase/firestore_functions/users';

export default function AddUser() {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  async function handleSubmit(user: UserDoc) {
    setSaving(true);
    const addResponse = await addUser(user);
    setSaving(false);

    if (addResponse.code === 201) {
      notify('Användare skapad', NotificationStatus.SUCCESS);
      close();
    } else if (addResponse.code === 409) {
      notify('Användare med angiven email finns redan', NotificationStatus.ERROR);
    } else {
      notify(`Kunde inte skapa användare: ${addResponse.error}`, NotificationStatus.ERROR);
    }
  }

  return (
    <section id='add-user' onClick={stopPropagation}>
      <header id='add-user__header'>
        <h2>Lägg till ny personal</h2>
      </header>
      <main>
        <UserForm handleSubmit={handleSubmit} saving={saving} />
      </main>
    </section>
  );
}
