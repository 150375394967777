import { doc, getFirestore } from '@firebase/firestore';
import { useContext, useState } from 'react';
import { ClientData, LittraData, Littra } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';

import { stopPropagation } from '../utils/uiHelpers';
import LittraForm from '../forms/LittraForm';
import { updateLittra } from '../firebase/firestore_functions/clients';

interface EditLittraProps {
  client: ClientData;
  littra: LittraData;
}

const db = getFirestore();

export default function EditLittra({ client, littra }: EditLittraProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  function getChanges(editedLittra: Littra) {
    const changes: any = { places: editedLittra.places };

    if (editedLittra.projectNum !== littra.projectNum) {
      changes.projectNum = editedLittra.projectNum;
    }

    if (editedLittra.contact !== littra.contact && editedLittra.contact) {
      changes.contact = doc(db, 'contacts', editedLittra.contact);
    }

    if (editedLittra.otherInformation !== littra.otherInformation) {
      changes.otherInformation = editedLittra.otherInformation;
    }

    if (editedLittra.tempContact && editedLittra.tempContact !== littra.tempContact) {
      changes.tempContact = editedLittra.tempContact;
    }

    if (editedLittra.to !== littra.to) {
      changes.to = editedLittra.to;
    }

    if (editedLittra.from !== littra.from) {
      changes.from = editedLittra.from;
    }

    if (editedLittra.workplace !== littra.workplace) changes.workplace = editedLittra.workplace;

    return changes;
  }

  async function handleSubmit(editedLittra: Littra) {
    const changes = getChanges(editedLittra);
    if (Object.keys(changes).length > 0) {
      setSaving(true);
      const updateResponse = await updateLittra(changes, littra.docId, client.docId);
      setSaving(false);
      if (updateResponse.code === 201) {
        notify('Littra uppdaterat', NotificationStatus.SUCCESS);
        close();
      } else {
        notify(`Kunde inte spara littrat: ${updateResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Redigera littra</h2>
      </header>
      <main>
        <LittraForm
          handleSubmit={handleSubmit}
          saving={saving}
          client={client}
          littra={{ ...littra, contact: littra.contact?.docId }}
        />
      </main>
    </section>
  );
}
