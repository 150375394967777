import { getDoc, getFirestore } from '@firebase/firestore';
import { doc, onSnapshot } from 'firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Equipment, User, VehicleData, VehicleDoc } from '../utils/types';
import ConfirmAction from '../components/confirm_action';
import { NotificationStatus, vehicleTypeLabel } from '../utils/constants';
import { NotificationContext } from '../contexts/NotificationContext';
import { PopupContext } from '../contexts/PopupContext';
import { stopPropagation } from '../utils/uiHelpers';
import EditVehicle from './EditVehicle';
import { deleteVehicle } from '../firebase/firestore_functions/vehicles';

interface VehicleDetailsProps {
  vehicle: VehicleData;
}

const db = getFirestore();

export default function VehicleDetails({ vehicle }: VehicleDetailsProps) {
  const { notify } = useContext(NotificationContext);
  const { close, showPopup } = useContext(PopupContext);
  const [vehicleInfo, setVehicleInfo] = useState<VehicleData>(vehicle);

  useEffect(init, []);

  function init() {
    setListenerVehicle();
  }

  function setListenerVehicle() {
    try {
      const q = doc(db, 'vehicles', vehicle.docId);

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        if (querySnapshot.exists()) {
          const vehicleDoc = querySnapshot.data() as VehicleDoc;
          const vehicle = querySnapshot.data() as VehicleData;

          // populate driver
          if (vehicleDoc.driver) {
            const driver = await getDoc<User>(querySnapshot.data().driver);

            vehicle.driver = driver.data() as User;
          }

          vehicle.docId = querySnapshot.id;

          // populate equipments
          if (vehicleDoc.equipments) {
            const _equipments = [];
            for (const e of vehicleDoc.equipments) {
              const equipmentDoc = await getDoc(e);

              if (equipmentDoc.exists()) {
                _equipments.push({
                  ...(equipmentDoc.data() as Equipment),
                  docId: equipmentDoc.id,
                });
              }
            }

            vehicle.equipments = _equipments;
          } else {
            vehicle.equipments = [];
          }

          setVehicleInfo(vehicle);
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  async function _deleteVehicle() {
    const deleteResponse = await deleteVehicle(vehicle.docId);

    if (deleteResponse.code === 201) {
      close();
      notify('Fordon raderat', NotificationStatus.SUCCESS);
    } else {
      notify(`Kunde inte radera fordonet: ${deleteResponse.error}`, NotificationStatus.ERROR);
    }
  }

  ///////////////////////
  // UI STATE UPDATERS //
  ///////////////////////

  function confirmDelete(event?: SyntheticEvent) {
    event && event.stopPropagation();
    showPopup(
      <ConfirmAction
        confirm={_deleteVehicle}
        heading='Är du säker på det här?'
        message='Fordonet kommer raderas från systemet
         och går inte att återställa.'
      />,
      'small',
    );
  }

  function openEditVehicle() {
    showPopup(<EditVehicle vehicle={vehicleInfo} />, 'medium');
  }

  return (
    <section className='details' onClick={stopPropagation}>
      <header className='details__header'>
        <h2>{vehicle.id}</h2>
        <section className='details__header__controls'>
          <button className='fa fa-edit button--icon' onClick={openEditVehicle}></button>
          <button onClick={confirmDelete} className='fa fa-trash button--icon'></button>
        </section>
      </header>
      <main>
        <section className='details__info'>
          <div className='info'>
            <p className='label'>Kategori</p>
            <p>{vehicleTypeLabel[vehicleInfo.vehicleType]}</p>
          </div>
          <div className='info'>
            <p className='label'>Id</p>
            <p>{vehicleInfo.id}</p>
          </div>
          <div className='info'>
            <p className='label'>Tjänst</p>
            <p>{vehicleInfo.service}</p>
          </div>
          <div className='info'>
            <p className='label'>Tjänst ID</p>
            <p>{vehicleInfo.serviceId}</p>
          </div>

          {vehicleInfo.driver && (
            <div className='info'>
              <p className='label'>Förare</p>
              <p>
                {vehicleInfo.driver.firstName} {vehicleInfo.driver.lastName}
              </p>
            </div>
          )}

          {vehicleInfo.equipments && (
            <div className='info'>
              <p className='label'>Utrustning</p>
              {vehicleInfo.equipments.map((vehicleEquipment, idx) => {
                return (
                  <p className='equipment' key={vehicleEquipment.id}>
                    {vehicleEquipment.id}
                    {idx === vehicleInfo.equipments.length - 1 ? '' : ',  '}
                  </p>
                );
              })}
            </div>
          )}
        </section>
      </main>
    </section>
  );
}
