import React from 'react';

interface Props {
  orderNumber: number;
}

export default function OrderNumber(props: Props) {
  return (
    <div
      style={{
        backgroundColor: 'var(--secondary-background-color)',
        padding: '8px 12px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <p style={{ fontSize: '14px' }}>
        <span style={{ fontWeight: '600' }}>Ordernummer:</span> {props.orderNumber}
      </p>
    </div>
  );
}
