import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useContext } from 'react';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PopupContext } from '../../contexts/PopupContext';
import { JobStatus, NotificationStatus } from '../../utils/constants';
import { getDateWithTimeZero, incrementDay, toRangeDateString } from '../../utils/time';
import { JobData } from '../../utils/types';
import ReportSummary from './ReportSummary';
import './ReportJobFinal.css';
import ConfirmAction from '../../components/confirm_action';

interface ReportJobFinalProps {
  job: JobData;
}

/**
 * Component that handles final reporting (klarrapportering) of a job. From here its also possible to make the client sign the job.
 * @param job JobData
 * @returns JSX.Element
 */

export default function ReportJobFinal({ job }: ReportJobFinalProps) {
  const { notify } = useContext(NotificationContext);
  const { close, showPopup } = useContext(PopupContext);

  async function reportDone(hasReceiptsForCompany: boolean) {
    try {
      await updateDoc(doc(getFirestore(), 'jobs', job.docId), {
        status: JobStatus.REPORTED,
        hasReceiptsForCompany,
      });
      notify('Jobb klarrapporterat', NotificationStatus.SUCCESS);
      close();
    } catch (error) {
      console.log(error);
      notify('Något gick fel, kunde inte klarrapportera jobbet', NotificationStatus.ERROR);
    }
  }

  function checkIfAllDaysAreReported() {
    if (job.reports) {
      // iterate through all days in the job and check if there is a report for it.

      let currentDay = getDateWithTimeZero(job.start);
      const endDay = getDateWithTimeZero(job.end);

      while (currentDay <= endDay) {
        if (!job.reports.find((report) => getDateWithTimeZero(report.start) === currentDay)) {
          showPopup(
            <ConfirmAction
              heading='Saknar dagsrapporter'
              message='Alla dagar i jobbet är inte rapporterade, vill du klarmarkera ändå?'
              confirm={askIfAnythingIsChargedOnCompany}
            />,
            'small',
          );
          return;
        }
        currentDay = getDateWithTimeZero(incrementDay(currentDay));
      }

      askIfAnythingIsChargedOnCompany();
    } else {
      showPopup(
        <ConfirmAction
          heading='Saknar dagsrapporter'
          message='Alla dagar i jobbet är inte rapporterade, vill du klarmarkera ändå?'
          confirm={askIfAnythingIsChargedOnCompany}
        />,
        'small',
      );
    }
  }

  function askIfAnythingIsChargedOnCompany() {
    showPopup(
      <ConfirmAction
        heading='Finns det material i ordern som är uttaget på Nolblads?'
        message=''
        confirm={() => {
          reportDone(true);
        }}
        cancel={() => {
          reportDone(false);
        }}
      />,
      'small',
    );
  }

  return (
    <section id='report-job-final'>
      <header>
        <div>
          <h2>Klarrapportera</h2>
          <p>{`${job.client?.name} ${toRangeDateString(job.start, job.end)}`}</p>
        </div>
        <div></div>
      </header>
      <ReportSummary job={job} showSensitiveData={true} />
      <button onClick={checkIfAllDaysAreReported} className='button--green'>
        Klarrapportera <span className='fa fa-check' />
      </button>
    </section>
  );
}
