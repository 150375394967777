import { SyntheticEvent, useState, useEffect } from 'react';
import { Place, AddedMaterialData, ReportRowData } from '../utils/types';
import AddMaterials from '../components/components_for_add_edit_job_slinga/AddMaterials';
import PickAPlace from '../components/PickAPlace';
import Textarea from '../components/Textarea';
import { JobType } from '../utils/constants';

interface ChosenMaterials {
  [docId: string]: AddedMaterialData;
}

interface RowFormProps {
  handleSubmit: (row: ReportRowData) => void;
  // initial values
  chosenMaterials?: ChosenMaterials;
  otherMaterials?: string;
  to?: string;
  from?: string;
  jobType: JobType;
}

export default function RowForm({
  handleSubmit,
  chosenMaterials,
  from,
  to,
  otherMaterials,
  jobType,
}: RowFormProps) {

  ////////////////////////////////
  // all fields in a ReportRow //
  //////////////////////////////

  const [_chosenMaterials, setChosenMaterials] = useState<ChosenMaterials>(
    chosenMaterials ? chosenMaterials : {},
  );
  const [_otherMaterials, setOtherMaterials] = useState<string>(
    otherMaterials ? otherMaterials : '',
  );

  const [_to, setTo] = useState<string | undefined>(to);
  const [_from, setFrom] = useState<string | undefined>(from);

  useEffect(() => {
    setOtherMaterials('');
  }, [_chosenMaterials]);

  function _handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    handleSubmit({
      materials: Object.values(_chosenMaterials),
      toLocation: _to,
      fromLocation: _from,
      otherMaterials: _otherMaterials,
    });
  }

  return (
    <form className='form' onSubmit={_handleSubmit}>
      <ul className='form__ul'>
        {jobType === JobType.TRANSPORT_SCHAKT && (
          <AddMaterials materials={_chosenMaterials} setMaterials={setChosenMaterials} />
        )}

        <li className='form__li'>
          <Textarea
            id='other-materials'
            setState={setOtherMaterials}
            placeholder='Godsslag'
            value={_otherMaterials}
            disabled={_chosenMaterials && Object.keys(_chosenMaterials).length > 0}
          />
        </li>

        {jobType === JobType.EXCAVATOR_MAN ? (
          <li className='form__li'>
            <p className='label'>Arbetsplats</p>
          </li>
        ) : (
          <li className='form__li'>
            <p className='label'>Från</p>
            <input
              className='place-input'
              placeholder='Skriv in en plats'
              type='text'
              value={_from}
              onChange={(e) => setFrom(e.target.value)}
            />

            <p className='label'>Till</p>
            <input
              className='place-input'
              placeholder='Skriv in en plats'
              type='text'
              value={_to}
              onChange={(e) => setTo(e.target.value)}
            />
          </li>
        )}

        <li className='form__li'>
          <input type='submit' className='button--green form__input' value='Spara' />
        </li>
      </ul>
    </form>
  );
}
