import { useContext, useState } from 'react';

import { getAuth, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { FirebaseError } from '@firebase/app';
import { PopupContext } from '../../contexts/PopupContext';

interface CredentialState {
  content: string;
  hasError: boolean;
  error: string;
}

interface ConfirmWithPasswordProps {
  save: () => void;
}

const auth = getAuth();

export default function ConfirmWithPassword({ save }: ConfirmWithPasswordProps) {
  const { close } = useContext(PopupContext);

  const [emailState, setEmailState] = useState<CredentialState>({
    content: '',
    hasError: false,
    error: '',
  });
  const [passwordState, setPasswordState] = useState<CredentialState>({
    content: '',
    hasError: false,
    error: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  function handleChangePassword(event: React.SyntheticEvent) {
    setPasswordState({
      ...passwordState,
      content: (event.target as HTMLInputElement).value,
      hasError: false,
      error: '',
    });
    setEmailState({ ...emailState, hasError: false, error: '' });
  }

  function handleChangeEmail(event: React.SyntheticEvent) {
    setEmailState({
      ...emailState,
      content: (event.target as HTMLInputElement).value,
      hasError: false,
      error: '',
    });
    setPasswordState({ ...passwordState, hasError: false, error: '' });
  }

  function handleChangeShowPassword(event: React.SyntheticEvent) {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    try {
      if (auth.currentUser) {
        await reauthenticateWithCredential(
          auth.currentUser,
          EmailAuthProvider.credential(emailState.content, passwordState.content),
        );

        save();
        close();
      }
    } catch (error) {
      const errorCode = (error as FirebaseError).code;

      if (errorCode === 'auth/user-not-found') {
        setEmailState({
          ...emailState,
          hasError: true,
          error: 'Användaren hittades inte',
        });
      } else if (errorCode === 'auth/wrong-password') {
        setPasswordState({
          ...passwordState,
          hasError: true,
          error: 'Fel lösenord',
        });
      }
    }
  }

  return (
    <div>
      <h3>Bekräfta ändring av email</h3>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <input
          className='input-text'
          id='email'
          type='email'
          value={emailState.content}
          onChange={handleChangeEmail}
          required
          placeholder='Email'
        />

        <div style={{ position: 'relative' }}>
          <input
            className='input-text'
            id='password'
            type={showPassword ? 'text' : 'password'}
            placeholder='Lösenord'
            value={passwordState.content}
            onChange={handleChangePassword}
            required
          ></input>
          <button
            id='password-visibility'
            onClick={handleChangeShowPassword}
            className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}
          ></button>
        </div>
        {passwordState.hasError && <p className='error'>{passwordState.error}</p>}

        <input className='button--main' type='submit' value='Bekräfta' />
      </form>
    </div>
  );
}
