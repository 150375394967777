import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import Select from '../select/Select';
import Option from '../select/Option';
import { addContact } from '../../firebase/firestore_functions/contacts';
import { ContactData, ContactDoc, SubcontractorData } from '../../utils/types';
import { PopupContext } from '../../contexts/PopupContext';
import AddContactPopup from '../../popups/AddContact';

interface AddContactSubcontractorProps {
  subcontractor?: SubcontractorData;
  contactSubcontractor?: ContactData;
  setContactSubcontractor: React.Dispatch<React.SetStateAction<ContactData | undefined>>;
}

/**
 * Component that is used in 'AddEditSlinga' and 'AddEditJob' to add a contact to the chosen subcontractor.
 */
export default function AddContactSubcontractor({
  subcontractor,
  contactSubcontractor,
  setContactSubcontractor,
}: AddContactSubcontractorProps) {
  const { showPopup } = useContext(PopupContext);
  const [contacts, setContacts] = useState<ContactData[]>([]);

  useEffect(updateSubcontractorContacts, [subcontractor]);

  function updateSubcontractorContacts() {
    setContacts(subcontractor && subcontractor.contacts ? subcontractor.contacts : []);
    setContactSubcontractor(undefined);
  }

  async function onAddSubcontractorContact(contact: ContactDoc) {
    if (subcontractor) {
      const response = await addContact(contact, subcontractor, 'subcontractors');
      if (response.data) {
        const contactData = { ...contact, docId: response.data };
        const _contacts = [...contacts, contactData];
        setContacts(_contacts);
        setContactSubcontractor(contactData);
      }

      return response;
    } else {
      return { code: 500, error: 'no subcontractor' };
    }
  }

  function onChangeContactSubcontractor(value: string) {
    const _contact = contacts.find((c) => c.docId === value);
    setContactSubcontractor(_contact);
  }

  function openAddSubcontractorContact(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<AddContactPopup onAdd={onAddSubcontractorContact} />, 'medium');
  }

  return (
    <li key='subcontractor-contact' className='add-job__form__li' id='add-job__form__li-contact'>
      <Select
        isMultiSelect={false}
        type='form'
        label='Kontakt'
        onChange={onChangeContactSubcontractor}
        iconRightClose='fa fa-angle-down'
        iconRightOpen='fa fa-angle-up'
        initialOption={
          contactSubcontractor
            ? {
                value: contactSubcontractor.docId,
                label: contactSubcontractor.name,
              }
            : undefined
        }
      >
        <>
          {contacts.map((c: ContactData, idx: number) => {
            return <Option key={idx} value={c.docId} label={c.name} />;
          })}
        </>
      </Select>
      <button
        id='button--add-contact'
        className='button--main--small'
        onClick={openAddSubcontractorContact}
      >
        <span className='fa fa-plus' />
        Lägg till kontakt
      </button>
    </li>
  );
}
