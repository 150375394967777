import React, { useEffect, useState } from 'react';
import {
  changeDay,
  getDateWithTimeZero,
  getEuDayNumber,
  headerDays,
  incrementDay,
  isSameDate,
  months,
} from '../../../utils/time';

import './Daypicker.css';

interface DatepickerProps {
  currentDate: number;
  selectDate: (millis: number) => void;
}

function Datepicker({ selectDate, currentDate }: DatepickerProps) {
  const [selectedYear, setSelectedYear] = useState<number>(new Date(currentDate).getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date(currentDate).getMonth());

  const [days, setDays] = useState<Array<number>>([]);

  useEffect(createDaysArray, [selectedMonth, selectedYear]);

  function createDaysArray() {
    const _days: Array<number> = [];

    // start of by date today
    let currentDate = new Date(getDateWithTimeZero(new Date().getTime()));

    // adjust the month and year according to selection
    currentDate.setMonth(selectedMonth);
    currentDate.setFullYear(selectedYear);

    // set first date of month
    currentDate.setDate(1);

    // adjust date to closest monday
    currentDate = new Date(changeDay(currentDate.getTime(), -getEuDayNumber(currentDate.getDay())));

    // iterate over 6 weeks to create the days array
    for (let d = 0; d < 6 * 7; ++d) {
      _days.push(currentDate.getTime());

      currentDate = new Date(incrementDay(currentDate.getTime()));
    }

    setDays(_days);
  }

  function nextMonth() {
    const newMonth = selectedMonth + 1;
    if (newMonth === 12) {
      setSelectedMonth(0);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(newMonth);
    }
  }

  function prevMonth() {
    const newMonth = selectedMonth - 1;

    if (newMonth < 0) {
      setSelectedMonth(11);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(newMonth);
    }
  }

  return (
    <section id='daypicker'>
      <header id='daypicker__controls'>
        <p>{`${selectedYear} ${months[selectedMonth]}`}</p>

        <div>
          <button className='fa fa-angle-double-left button--icon' onClick={prevMonth}></button>

          <button className='fa fa-angle-double-right button--icon' onClick={nextMonth}></button>
        </div>
      </header>
      <ul id='daypicker__days'>
        {headerDays.map((day: string, idx: number) => {
          return (
            <li className='calendar-day' key={`${day}${idx}`}>
              {day}
            </li>
          );
        })}

        {days.map((day: number, idx: number) => {
          return (
            <li
              key={`${day}${idx}`}
              className={new Date(day).getMonth() !== selectedMonth ? 'prev-month-day' : ''}
            >
              {day !== 0 && (
                <button
                  onClick={() => {
                    selectDate(day);
                  }}
                  className={isSameDate(day, currentDate) ? 'daypicker__days__selected' : ''}
                >
                  {`${new Date(day).getDate()}`}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default Datepicker;
