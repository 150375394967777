import { useEffect, useState } from 'react';
import { allowedEquipments, EquipmentType, equipmentTypeLabel } from '../../utils/constants';
import { Equipment, Equipments, VehicleData } from '../../utils/types';
import Select from '../select/Select';
import Option from '../select/Option';
import './AddEquipments.css';
import { getEquipments } from '../../firebase/firestore_functions/equipments';

interface AddEquipmentsProps {
  vehicle: VehicleData;
  vehicleEquipments: Set<string>;
  setVehicleEquipments: React.Dispatch<React.SetStateAction<Set<string>>>;
}

/**
 * Component that is used in 'AddEditSlinga' and 'AddEditJob' to add equipments to the selected vehicle.
 */
export default function AddEquipments({
  vehicle,
  vehicleEquipments,
  setVehicleEquipments,
}: AddEquipmentsProps) {
  const [allEquipments, setEquipments] = useState<Equipments>({});

  // already added flatbed or not
  const [addedFlatbed, setAddedFlatbed] = useState<string>(''); // docRef id

  const [firstRender, setFirstRender] = useState(true);

  useEffect(init, []);
  useEffect(updateAddedFlatbed, [vehicleEquipments]);

  function init() {
    fetchEquipments();
  }

  useEffect(updateEquipments, [vehicle]);

  function updateEquipments() {
    // vehicleEquipments can have been set already if it is provided initially from the slinga so we dont want to overwrite it!
    if (firstRender) {
      if (vehicleEquipments.size === 0 && vehicle.equipments) {
        const _chosenEquipments: Set<string> = new Set();
        for (const e of vehicle.equipments) {
          _chosenEquipments.add(e.id);
        }
        setVehicleEquipments(_chosenEquipments);
      }

      setFirstRender(false);
    } else {
      if (vehicle.equipments) {
        const _chosenEquipments: Set<string> = new Set();
        for (const e of vehicle.equipments) {
          _chosenEquipments.add(e.id);
        }
        setVehicleEquipments(_chosenEquipments);
      } else {
        setVehicleEquipments(new Set());
      }
    }
  }

  function updateAddedFlatbed() {
    if (vehicleEquipments && vehicleEquipments.size > 0) {
      for (const e of Array.from(vehicleEquipments)) {
        if (allEquipments[e] && allEquipments[e].equipmentType === EquipmentType.FLATBED) {
          setAddedFlatbed(e);
          break;
        }
      }
    }
  }

  function onChangeEquipments(value?: string, state?: boolean) {
    if (value) {
      const _vehicleEquipments = new Set(vehicleEquipments);

      // check if value was removed or added
      if (state) {
        _vehicleEquipments.add(value);
      } else {
        _vehicleEquipments.delete(value);
      }

      // cannot add multiple flatbeds (släp)
      if (allEquipments[value].equipmentType === EquipmentType.FLATBED) {
        if (addedFlatbed !== '') {
          _vehicleEquipments.delete(addedFlatbed);
        }
        setAddedFlatbed(value);
      }
      // update
      setVehicleEquipments(_vehicleEquipments);
    }
  }

  async function fetchEquipments() {
    const equipmentsResponse = await getEquipments();
    if (equipmentsResponse.code === 200 && equipmentsResponse.data) {
      setEquipments(equipmentsResponse.data);
    }
  }

  function removeEquipment(value?: string) {
    if (value) {
      // remove equipment
      const _vehicleEquipments = new Set(vehicleEquipments);
      _vehicleEquipments.delete(value);

      // update
      setVehicleEquipments(_vehicleEquipments);

      // if we removed the flatbed
      if (value === addedFlatbed) {
        setAddedFlatbed('');
      }
    }
  }

  return (
    <li key='equipments' className='add-job__form__li'>
      {/* One select for every type of equipment that the vehicle can use */}
      <div id='add-equipments__selects'>
        {vehicle !== undefined &&
          vehicle.vehicleType !== undefined &&
          allowedEquipments[vehicle.vehicleType].map((e: EquipmentType) => {

            return (
              <Select
                key={e}
                isMultiSelect={e === EquipmentType.FLATBED ? false : true}
                type='button'
                label={equipmentTypeLabel[e]}
                values={vehicleEquipments}
                onChange={onChangeEquipments}
                iconRightClose='fa fa-angle-down'
                iconRightOpen='fa fa-angle-up'
              >
                <>
                  {Object.entries(allEquipments).map((equipment: [string, Equipment]) => {
                    return equipment[1].equipmentType === e ? (
                      <Option
                        key={equipment[0]}
                        value={equipment[0]}
                        label={equipment[1].id}
                        checkbox={e === EquipmentType.FLATBED ? false : true}
                      />
                    ) : null;
                  })}
                </>
              </Select>
            );
          })}
      </div>

      {!!vehicleEquipments && (
        <ul className='form__selected-items'>
          {Array.from(vehicleEquipments).map((e: string) => {
            return (
              <li className='form__selected-items__item' key={e}>
                <p>{allEquipments[e] ? allEquipments[e].id : ''}</p>
                <button
                  onClick={() => {
                    removeEquipment(e);
                  }}
                  className='button--icon fa fa-trash'
                ></button>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}
