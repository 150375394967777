import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { PopupState } from '../utils/types';
import history from '../utils/history';
import Popup from '../components/popup/Popup';
const initialState: PopupState = {
  showPopup: () => {
    console.log('showPopup not defined');
  },
  close: () => {
    console.log('close not defined');
  },
};

export const PopupContext = React.createContext(initialState);

interface PopupProviderProps {
  children: React.ReactElement;
}

/**
 * Component that shows popups/ dialogs on top of every page.
 * @param children React.ReactElement
 * @returns JSX.Element
 */

export function PopupProvider({ children }: PopupProviderProps) {
  const [show, setShow] = useState<boolean>(false);
  const [components, setComponents] = useState<
    Array<{ component: ReactElement; size: 'min' | 'small' | 'medium' | 'big' }>
  >([]);

  useEffect(() => {
    return setBackPressedBlocker();
  }, [show, components]);

  /**
   * Blocks back pressed if a popup is shown and closes that popup
   * @returns UnregisterCallback
   */
  function setBackPressedBlocker() {
    return history.block((_) => {
      if (show) {
        close();
        return false;
      }
    });
  }

  /**
   * Shows a popup and sets focus to the close button in popup
   * @param component content of popup to show
   */
  function showPopup(component: ReactElement, size: 'min' | 'small' | 'medium' | 'big') {
    setComponents((prevComponents) => {
      // add component to components
      const _components: Array<{
        component: ReactElement;
        size: 'min' | 'small' | 'medium' | 'big';
      }> = new Array(...prevComponents);
      _components.push({ component, size });

      // update state
      setShow(true);
      // if (_components[_components.length - 1].size === PopupType.PAGE) {
      // adjust current focus to close button of popup
      setFocusCloseButton();
      // }

      return _components;
    });
  }

  function setFocusCloseButton() {
    // requestAnimationFrame(() => {
    //   (document.querySelector(".popup .button--close") as HTMLElement).focus();
    // });
  }

  function close(event?: SyntheticEvent) {
    setComponents((prevComponents) => {
      // remove last added component
      const _components: Array<{
        component: ReactElement;
        size: 'min' | 'small' | 'medium' | 'big';
      }> = new Array(...prevComponents);
      _components.pop();

      if (_components.length === 0) {
        setShow(false);
      }
      return _components;
    });
  }
  return (
    <PopupContext.Provider
      value={{
        showPopup,
        close,
      }}
    >
      {children}
      {show &&
        components.map((component, idx) => {
          return (
            <Popup key={idx} size={component.size}>
              {component.component}
            </Popup>
          );
        })}
    </PopupContext.Provider>
  );
}
