import { SyntheticEvent, useContext, useState } from 'react';
import { ContactData, ContactDoc, UpdateContactParams } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import ContactForm from '../forms/ContactForm';
import { stopPropagation } from '../utils/uiHelpers';
import { updateContact } from '../firebase/firestore_functions/contacts';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

interface EditContactProps {
  contact: ContactData;
  onEdit: (changes: UpdateContactParams, docId: string) => void;
}

const db = getFirestore();

export default function EditContact({ contact, onEdit }: EditContactProps) {
  const [saving, setSaving] = useState<boolean>(false);
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  function getChanges(editedContact: ContactDoc) {
    const changes: any = {};

    if (contact.name !== editedContact.name) {
      changes.name = editedContact.name;
    }

    if (contact.phone !== editedContact.phone) {
      changes.phone = editedContact.phone;
    }

    return changes;
  }

  async function handleSubmit(editedContact: ContactDoc) {
    const changes = getChanges(editedContact);
    if (Object.keys(changes).length > 0) {
      setSaving(true);

      const updateResponse = await updateContact(changes, contact.docId);

      setSaving(false);
      if (updateResponse.code === 201) {
        onEdit(changes, contact.docId);
        close();
        notify('Kontakt uppdaterad', NotificationStatus.SUCCESS);
      } else {
        notify(`Kunde inte spara kontakt: ${updateResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  const confirmDelete = async (event?: SyntheticEvent) => {
    event && event.stopPropagation();
    // Delete contact with contact.docId
    try {
      await deleteDoc(doc(db, 'contacts', contact.docId));
      notify('Kontakt raderad', NotificationStatus.SUCCESS);
      close();
    } catch (error) {
      notify('Kunde ej radera kontakt', NotificationStatus.ERROR);
    }
  };

  return (
    <section id='edit-contact' onClick={stopPropagation}>
      <header id='edit-contact__header'>
        <h2>Redigera kundkontakt</h2>
        <button onClick={confirmDelete} className='button--main fa fa-trash button--icon'>
          Ta bort
        </button>
      </header>
      <main>
        <ContactForm
          name={contact.name}
          phone={contact.phone}
          handleSubmit={handleSubmit}
          saving={saving}
        />
      </main>
    </section>
  );
}
