import React, { SyntheticEvent, useContext, useState } from 'react';
import { useEffect } from 'react';
import ListPage from '../../components/list_page/ListPage';
import Table, { TableRow } from '../../components/table/Table';
import { DataContext } from '../../contexts/CommonDataContext';
import { PopupContext } from '../../contexts/PopupContext';
import RemovePlace from '../../popups/RemovePlace';
import { Place } from '../../utils/types';

const Places: React.FC = () => {
  // Some states
  const [search, setSearch] = useState<string>('');
  // const [places, setPlaces] = useState<Array<Place>>([]);
  const { places } = useContext(DataContext);
  const { showPopup } = useContext(PopupContext);
  const [filteredPlaces, setFilteredPlaces] = useState<Array<Place>>(places);

  // Callback sent to ListPage
  const onChangeSearch = (e: SyntheticEvent) => {
    setSearch((e.target as HTMLInputElement).value);
  };

  useEffect(() => {
    // Filter place with the new search word
    const filtered: Array<Place> = [];
    places.forEach((p) => {
      if (filter(p)) filtered.push(p);
    });
    setFilteredPlaces(filtered);
  }, [search, places]);

  const filter = (place: Place): boolean => {
    if (search === '') return true;

    const _search = search.toLocaleLowerCase();
    if (
      (place.address && place.address.toLocaleLowerCase().includes(_search)) ||
      (place.name && place.name.toLocaleLowerCase().includes(_search))
    ) {
      return true;
    }

    return false;
  };

  const getTableDataPlaces = () => {
    const data: TableRow[] = [];
    for (const p of filteredPlaces) {
      data.push({
        data: [
          p.name ? p.name! : p.address!,
          p.position?.lat.toString() + ', ' + p.position?.lng.toString(),
        ],
      });
    }
    return data;
  };

  const onPlaceClick = (idx: number) => {
    // Show popup
    showPopup(<RemovePlace place={filteredPlaces[idx]} />, 'medium');
  };

  return (
    <ListPage heading='Platser' onChangeSearch={onChangeSearch}>
      <section>
        <div>
          <Table
            heads={['Namn/Adress', 'Position (lat/lng)']}
            data={getTableDataPlaces()}
            onClickRow={onPlaceClick}
          />
        </div>
      </section>
    </ListPage>
  );
};

export default Places;
