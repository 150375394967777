import { useContext, useEffect, useState } from 'react';
import { weekNumber } from 'weeknumber';
import CalendarFilters from './CalendarFilters';

import Option from '../../components/select/Option';
import Select from '../../components/select/Select';
import WeekView from '../../components/week_view/WeekView';
import Datepicker from '../../components/datepicker/Datepicker';
import DayView from '../../components/day_view/DayView';
import MonthView from '../../components/month_view/MonthView';
import PlusButton from '../../components/buttons/PlusButton';

import { getFirestore } from 'firebase/firestore';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../contexts/AuthContext';
import { PdfJobsContext } from '../../contexts/PdfJobsContext';
import { PopupContext } from '../../contexts/PopupContext';
import { MobileStateContext } from '../../contexts/MobileContext';
import { JobsContext } from '../../contexts/JobsContext';
import { NavigationContext } from '../../contexts/NavigationContext';
import { CalendarModeContext } from '../../contexts/CalendarModeContext';

import AddEditJobMain from '../../popups/add_job/AddEditJobMain';
import AddEditSlingaMain from '../../popups/add_slinga/AddEditSlingaMain';
import JobDetails from '../../popups/job_details';
import SlingaDetails from '../../popups/slinga_details/SlingaDetails';
import ShowPdfs from '../../popups/pdf/ShowPdfs';

import { CalendarMode, calenderModeLabels } from '../../utils/constants';
import { hasAdminPrivileges } from '../../utils/otherHelpers';
import {
  changeDay,
  decrementDay,
  decrementMonth,
  decrementWeek,
  getDateWithTimeZero,
  getDaysInMonth,
  getDefaultEndDate,
  getDefaultStartDate,
  getEuDayNumber,
  incrementDay,
  incrementMonth,
  incrementWeek,
  toDayDateString,
  toMonthDateString,
  getEndDate,
} from '../../utils/time';

import './Home.css';

const db = getFirestore();

const NUM_OF_DAYS_LOOK_AHEAD = 50;

function Home() {
  ///////////////
  // contexts //
  /////////////

  const { showPopup } = useContext(PopupContext);
  const { isMobile } = useContext(MobileStateContext);
  const { user } = useContext(AuthContext);
  const {
    jobs,
    setJobs,
    filteredJobs,
    setFilteredJobs,
    vehicleFilter,
    setVehicleFilter,
    littraFilter,
    setLittraFilter,
    driverFilter,
    setDriverFilter,
    clientFilter,
    setClientFilter,
    loading,
    showOnlyJobsToHandle,
    setShowOnlyJobsToHandle,
    showOnlySlingor,
    setShowOnlySlingor,
    setListenerOnJobsCollection,
    filterJobs,
  } = useContext(JobsContext);
  const { navigationStack, setNavigationStack, setBackPressedBlocker } =
    useContext(NavigationContext);
  const { jobs: pdfJobs } = useContext(PdfJobsContext);
  const {
    calendarMode,
    setCalendarMode,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    fetch,
    setFetch,
    do30Days,
    setDo30Days,
    onChangeView,
  } = useContext(CalendarModeContext);

  //////////////////
  // ui togglers //
  ////////////////

  //////////////
  // FILTERS //
  ////////////

  ////////////////////
  // data handling //
  //////////////////

  /////////////////
  // useEffects //
  ///////////////

  // Fixes bug where jobs would be duplicated when navigating back to home
  useEffect(() => {
    setJobs({});
    setFilteredJobs({});
  }, []);

  useEffect(() => {
    return setBackPressedBlocker(onChangeView);
  }, [navigationStack]);

  useEffect(
    () => filterJobs(calendarMode, startDate, do30Days, NUM_OF_DAYS_LOOK_AHEAD, user),
    [
      clientFilter,
      driverFilter,
      vehicleFilter,
      showOnlyJobsToHandle,
      showOnlySlingor,
      littraFilter,
      do30Days,
      jobs,
    ],
  );

  useEffect(() => {
    const unsubscribe = setListenerOnJobsCollection(db, startDate, endDate, user);

    return unsubscribe;
  }, [fetch]);

  ////////////////////////////////////////////////
  // Functions for fetching and filtering jobs //
  //////////////////////////////////////////////

  function on30DaysChange(e: React.ChangeEvent<HTMLInputElement>) {
    // Fixes bug where jobs would be duplicated when checking and unchecking the checkbox
    setJobs({});
    setFilteredJobs({});

    setDo30Days(e.target.checked);
  }

  /////////////////////////
  // on change handlers //
  ///////////////////////

  // Used right now for the desktop view when changing calender mode, will be moved to context later
  function onChangeViewHome(_calendarMode: string) {
    setCalendarMode(parseInt(_calendarMode));

    if (calendarMode !== parseInt(_calendarMode)) {
      const _navigationStack = [...navigationStack];
      _navigationStack.push(calendarMode);
      setNavigationStack(_navigationStack);
    }

    // adjust startDate

    let newStartDate = startDate;

    switch (parseInt(_calendarMode)) {
      case CalendarMode.MONTH: {
        // start att current month 1
        const dateM = new Date(startDate);
        dateM.setDate(1);
        newStartDate = getDateWithTimeZero(dateM.getTime());
        break;
      }
      case CalendarMode.WEEK: {
        // first day of the week of the previously shown day
        let dateW = new Date(startDate);

        const euDay = getEuDayNumber(dateW.getDay());
        const daysDiff = 7 - (7 - euDay);
        dateW = new Date(changeDay(dateW.getTime(), -daysDiff));
        newStartDate = getDateWithTimeZero(dateW.getTime());
        break;
      }
      case CalendarMode.DAY:
        // just dont adjust the startDate, when we come from month mode, the day will be the first day of the month. When we come from week
        // mode, the day will be the first day of that week.
        newStartDate = startDate;
        break;
    }

    const newEndDate = getEndDate(
      parseInt(_calendarMode),
      newStartDate,
      do30Days,
      NUM_OF_DAYS_LOOK_AHEAD,
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate.getTime());
    setFilteredJobs({});
    setJobs({});
    setFetch(!fetch);
  }

  /**
   *
   * @param milliseconds date in milliseconds with all time variables set to 0, first day of week when calendarmode is week and
   * first date of month when calendarmode is month
   */
  function onChangeDate(milliseconds: number) {
    setStartDate(milliseconds);
    // set end date according to view mode
    const newEndDate = getEndDate(calendarMode, milliseconds, do30Days, NUM_OF_DAYS_LOOK_AHEAD);
    setEndDate(newEndDate.getTime());

    setJobs({});
    setFilteredJobs({});
    setFetch(!fetch);
  }

  ////////////////////////
  // other ui togglers //
  //////////////////////

  function openAddJob() {
    showPopup(<AddEditJobMain />, 'big');
  }

  function openPdfMenu() {
    showPopup(<ShowPdfs />, 'medium');
  }

  function openAddSlinga() {
    showPopup(<AddEditSlingaMain />, 'big');
  }

  function openJobDetails(docId: string, clickedDate: number) {
    showPopup(<JobDetails jobDocId={docId} calendarClickedDate={clickedDate} />, 'big');
  }

  function openSlingaDetails(slingaId: string) {
    showPopup(<SlingaDetails slingaDocId={slingaId} />, 'big');
  }

  function onClickDay(day: number) {
    setCalendarMode(CalendarMode.DAY);

    const _navigationStack = [...navigationStack];
    _navigationStack.push(calendarMode);
    setNavigationStack(_navigationStack);
    setStartDate(getDateWithTimeZero(day));
    const _endDate = new Date(day);
    _endDate.setHours(23);
    _endDate.setMinutes(59);
    setJobs({});
    setFilteredJobs({});
    setFetch(!fetch);
  }

  function onClickWeek(weekStart: number) {
    // in desktop mode this callback is called when the user clicks on any
    // day of the week so adjust the date to make sure it is the first day of week

    const dayDiff = new Date(weekStart).getDay() === 0 ? 6 : new Date(weekStart).getDay() - 1;

    if (dayDiff !== 0) {
      weekStart = changeDay(weekStart, -dayDiff);
    }

    setCalendarMode(CalendarMode.WEEK);

    const _navigationStack = [...navigationStack];
    _navigationStack.push(calendarMode);

    setNavigationStack(_navigationStack);

    setStartDate(getDateWithTimeZero(weekStart));
    const _endDate = new Date(decrementDay(incrementWeek(weekStart)));
    _endDate.setHours(23);
    _endDate.setMinutes(59);

    setEndDate(_endDate.getTime());

    setJobs({});
    setFilteredJobs({});
    setFetch(!fetch);
  }

  function onClickLeftArrow() {
    // if (!loading) {
    let newStartDate: number;
    let newEndDate: number;

    switch (calendarMode) {
      case CalendarMode.MONTH: {
        newStartDate = decrementMonth(startDate);
        const _endDate = new Date(newStartDate);

        // set end date to last day of month
        _endDate.setDate(getDaysInMonth(_endDate.getFullYear(), _endDate.getMonth()));
        newEndDate = _endDate.getTime();

        break;
      }
      case CalendarMode.WEEK:
        newStartDate = decrementWeek(startDate);
        newEndDate = decrementDay(startDate);
        break;
      case CalendarMode.DAY:
        newStartDate = decrementDay(startDate);
        newEndDate = newStartDate;
        break;
    }

    // adjust clock of end date
    const _endDate = new Date(newEndDate);
    _endDate.setHours(23);
    _endDate.setMinutes(59);

    setStartDate(newStartDate);
    setEndDate(_endDate.getTime());
    setJobs({});
    setFilteredJobs({});
    setFetch(!fetch);
    // }
  }

  function onClickRightArrow() {
    let newStartDate: number;
    let newEndDate: number;

    switch (calendarMode) {
      case CalendarMode.MONTH: {
        newStartDate = incrementMonth(startDate);
        // set end date to last day of month
        const _endDate = new Date(newStartDate);
        _endDate.setDate(getDaysInMonth(_endDate.getFullYear(), _endDate.getMonth()));
        newEndDate = _endDate.getTime();
        break;
      }
      case CalendarMode.WEEK:
        newStartDate = incrementWeek(startDate);
        newEndDate = decrementDay(incrementWeek(newStartDate));
        break;
      case CalendarMode.DAY:
        newEndDate = incrementDay(startDate);
        newStartDate = newEndDate;
        break;
    }

    // adjust clock of end date
    const _endDate = new Date(newEndDate);
    _endDate.setHours(23);
    _endDate.setMinutes(59);

    setStartDate(newStartDate);
    setEndDate(_endDate.getTime());
    setJobs({});
    setFetch(!fetch);
  }

  function openFilters() {
    showPopup(
      <CalendarFilters
        driverFilter={driverFilter}
        onChangeDriverFilter={setDriverFilter}
        clientFilter={clientFilter}
        onChangeClientFilter={setClientFilter}
        vehicleFilter={vehicleFilter}
        onChangeVehicleFilter={setVehicleFilter}
        showOnlyJobsToHandle={showOnlyJobsToHandle}
        onChangeShowOnlyJobsToHandle={setShowOnlyJobsToHandle}
        showOnlySlingor={showOnlySlingor}
        onChangeShowOnlySlingor={setShowOnlySlingor}
        littraFilter={littraFilter}
        onChangeLittraFilter={setLittraFilter}
      />,
      'big',
    );
  }

  // Inactivated for now
  // const swipeHandlers = useSwipeable({
  //   onSwipedRight: () => onClickLeftArrow(),
  //   onSwipedLeft: () => onClickRightArrow(),
  //   preventDefaultTouchmoveEvent: true,
  // });

  return (
    <main /*{...swipeHandlers}*/ style={{ overflow: 'hidden' }}>
      <section id='calendar'>
        {calendarMode === CalendarMode.MONTH && (
          <MonthView
            daysWithJobs={filteredJobs}
            year={new Date(startDate).getFullYear()}
            month={new Date(startDate).getMonth()}
            onClickDay={isMobile ? onClickDay : onClickWeek}
            onClickWeek={onClickWeek}
          />
        )}
        {calendarMode === CalendarMode.WEEK && (
          <WeekView
            daysWithJobs={filteredJobs}
            onClickJob={openJobDetails}
            onClickSlinga={openSlingaDetails}
            openAddJob={openAddJob}
            openAddSlinga={openAddSlinga}
            calendarMode={calendarMode}
            pdfJobs={pdfJobs}
            openPdfMenu={openPdfMenu}
          />
        )}
        {calendarMode === CalendarMode.DAY && (
          <DayView
            dayWithJobs={filteredJobs}
            loading={loading}
            onClickJob={openJobDetails}
            onClickSlinga={openSlingaDetails}
          />
        )}
        {loading && <FontAwesomeIcon icon={faCircleNotch} spin size='3x' id='loading-icon' />}

        {/* Plus button for adding jobs and slingor */}
        {isMobile && hasAdminPrivileges(user) && (
          <PlusButton openAddJob={openAddJob} openAddSlinga={openAddSlinga} />
        )}
      </section>
    </main>
  );
}

export default Home;
