import { doc, getFirestore } from '@firebase/firestore';
import { useContext, useState } from 'react';
import { Vehicle, VehicleData } from '../utils/types';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import VehicleForm from '../forms/VehicleForm';
import { stopPropagation } from '../utils/uiHelpers';
import { updateVehicle } from '../firebase/firestore_functions/vehicles';

const db = getFirestore();

interface EditVehicleProps {
  vehicle: VehicleData;
}

export default function EditVehicle({ vehicle }: EditVehicleProps) {
  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);
  const [saving, setSaving] = useState<boolean>(false);

  function getChanges(editedVehicle: Vehicle) {
    const changes: any = {};

    if (vehicle.vehicleType !== editedVehicle.vehicleType) {
      changes.vehicleType = editedVehicle.vehicleType;
    }
    if (vehicle.id !== editedVehicle.id) {
      changes.id = editedVehicle.id;
    }
    if (editedVehicle.driver && editedVehicle.driver !== vehicle.driver?.docId) {
      changes.driver = doc(db, 'users', editedVehicle.driver);
    }
    if (vehicle.service !== editedVehicle.service) {
      changes.service = editedVehicle.service;
    }
    if (vehicle.serviceId !== editedVehicle.serviceId) {
      changes.serviceId = editedVehicle.serviceId;
    }

    let changedEquipments = false;

    // check if has added equipment
    for (const equipmentId of Array.from(editedVehicle.equipments)) {
      if (!vehicle.equipments.find((e) => e.docId === equipmentId)) {
        changedEquipments = true;
        break;
      }
    }

    // check if has removed equipment
    if (!changedEquipments) {
      for (const equipmentRef of vehicle.equipments) {
        if (!editedVehicle.equipments.has(equipmentRef.id)) {
          changedEquipments = true;
          break;
        }
      }
    }

    if (changedEquipments) {
      const equipmentRefs: any[] = [];
      editedVehicle.equipments.forEach((e) => {
        equipmentRefs.push(doc(db, 'equipments', e));
      });

      changes.equipments = equipmentRefs;
    }

    return changes;
  }

  async function handleSubmit(editedVehicle: Vehicle) {
    const changes = getChanges(editedVehicle);

    if (Object.keys(changes).length > 0) {
      setSaving(true);
      const updateResponse = await updateVehicle(changes, vehicle.docId);
      setSaving(false);
      if (updateResponse.code === 201) {
        notify('Fordon uppdaterat', NotificationStatus.SUCCESS);
        close();
      } else {
        notify(`Kunde inte uppdatera fordon: ${updateResponse.error}`, NotificationStatus.ERROR);
      }
    }
  }

  return (
    <section onClick={stopPropagation}>
      <header>
        <h2>Redigera fordon</h2>
      </header>
      <main>
        <VehicleForm handleSubmit={handleSubmit} saving={saving} vehicle={vehicle} />
      </main>
    </section>
  );
}
