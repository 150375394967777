import './ReportSlinga.css';

import React, { SyntheticEvent, useContext, useState } from 'react';
import Textarea from '../../components/Textarea';
import { SlingaData } from '../../utils/types';
import { reportSlinga } from '../../firebase/firestore_functions/slinga';
import { NotificationContext } from '../../contexts/NotificationContext';
import { NotificationStatus } from '../../utils/constants';
import { PopupContext } from '../../contexts/PopupContext';

interface ReportSlingaProps {
  slinga: SlingaData;
}

export default function ReportSlinga({ slinga }: ReportSlingaProps) {
  const [hours, setHours] = useState<number | string>(0);
  const [mil, setMil] = useState<number | string>(0);
  const [comments, setComments] = useState('');
  const [saving, setSaving] = useState(false);

  const { notify } = useContext(NotificationContext);
  const { close } = useContext(PopupContext);

  function onChangeHours(event: SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;

    if (value && value !== '') {
      setHours(parseInt(value));
    } else {
      setHours('');
    }
  }

  function onChangeMil(event: SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;

    if (value && value !== '') {
      setMil(parseInt(value));
    } else {
      setMil('');
    }
  }

  async function report() {
    setSaving(true);

    const reportResponse = await reportSlinga(slinga.docId, {
      hours: hours as number,
      mil: mil as number,
      comments,
    });

    if (reportResponse.code === 201) {
      notify(`Slinga${slinga.sNumber} rapporterd`, NotificationStatus.SUCCESS);
      setSaving(false);
      close();
    } else {
      notify(`Kunde inte rapportera slinga: ${reportResponse.error}`, NotificationStatus.ERROR);
      setSaving(false);
    }
  }

  return (
    <section id='report-slinga'>
      <h2>Klarrapportera slinga{slinga.sNumber}</h2>
      <ul>
        <li>
          <p className='label'>Timmar</p>
          <input className='input-text' type='number' value={hours} onChange={onChangeHours} />
        </li>
        <li>
          <p className='label'>Mil</p>
          <input className='input-text' type='number' value={mil} onChange={onChangeMil} />
        </li>
        <li>
          <Textarea
            id='comments'
            value={comments}
            setState={setComments}
            placeholder='Övriga kommentarer'
          />
        </li>

        <li>
          <button className='button--green' onClick={report} style={{ width: '100%' }}>
            {saving && <span className='fa fa-circle-o-notch fa-spin' />} Rapportera
          </button>
        </li>
      </ul>
    </section>
  );
}
