import { SlingaData } from '../../utils/types';
import { AddEditSlingaProvider } from './AddEditSlingaContext';
import AddEditSlinga from './AddEditSlinga';

type AddEditSlingaMainProps = {
  slinga?: SlingaData;
  onDelete?: () => void;
};

/**
 * Main component for add or edit of job. The purpose is to wrap AddEditJob/AddEditSlinga in AddEditJobProvider which contains state
 * for the udpated or new job.
 *
 */
export default function AddEditSlingaMain({ slinga, onDelete }: AddEditSlingaMainProps) {
  return (
    <AddEditSlingaProvider>
      <AddEditSlinga slinga={slinga} onDelete={onDelete} />
    </AddEditSlingaProvider>
  );
}
