import { AddEditJobContext } from './AddEditJobContext';
import AddContactClient from '../../components/components_for_add_edit_job_slinga/AddContactClient';
import { useContext, useEffect } from 'react';

/**
 * Component that wraps 'AddContactClient' and passes data state variables from 'AddEditJobContext' to it.
 *
 */
export default function AddContactClientToJob() {
  const { client, contactClient, contactClientTemp, setContactClient, setContactClientTemp } =
    useContext(AddEditJobContext);

  return (
    <AddContactClient
      client={client}
      contactClient={contactClient}
      contactClientTemp={contactClientTemp}
      setContactClient={setContactClient}
      setContactClientTemp={setContactClientTemp}
    />
  );
}
