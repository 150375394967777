import React, { useEffect, useState } from 'react';
import { MobileState } from '../utils/types';

const initialState: MobileState = {
  isMobile: false,
};

export const MobileStateContext = React.createContext(initialState);

interface MobileStateProviderProps {
  children: React.ReactElement;
}

export function MobileStateProvider({ children }: MobileStateProviderProps) {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(setupViewSizeListener, []);
  useEffect(checkMobileView, []);

  function setupViewSizeListener() {
    window.addEventListener('resize', checkMobileView);
  }

  function checkMobileView() {
    if (window.innerWidth < 1050) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  return (
    <MobileStateContext.Provider
      value={{
        isMobile,
      }}
    >
      {children}
    </MobileStateContext.Provider>
  );
}
