import React, { useContext, useEffect, useState } from 'react';
import {
  ClientData,
  ClientDoc,
  DataState,
  Place,
  SubcontractorData,
  SubcontractorDoc,
  User,
  VehicleData,
  VehicleDoc,
} from '../utils/types';

import { collection, getFirestore, query, Unsubscribe } from '@firebase/firestore';
import { getDoc, onSnapshot, where } from 'firebase/firestore';
import { UserType } from '../utils/constants';
import { populateDocs } from '../firebase/firestore_functions/common';
import { AuthContext } from './AuthContext';

const initialState: DataState = {
  vehicles: [],
  clients: [],
  clientsLoading: false,
  drivers: [],
  places: [],
  subcontractors: [],
};

export const DataContext = React.createContext(initialState);

interface DataProviderProps {
  children: React.ReactElement;
}

/**
 * This component provides application with common data. This data is used in the calendar, filter component and the pages
 * where clients, vehicles and drivers are listed. I thought it would be good to fetch this in the same place
 * @param children the app
 * @returns JSX.Element
 */
const db = getFirestore();
export function DataProvider({ children }: DataProviderProps) {
  const { user, isAuthenticated } = useContext(AuthContext);
  const [vehicles, setVehicles] = useState<VehicleData[]>([]);
  const [clients, setClients] = useState<ClientData[]>([]);
  const [clientsLoading, setClientsLoading] = useState<boolean>(false); // used to show loading spinner when fetching clients
  const [drivers, setDrivers] = useState<User[]>([]);
  const [subcontractors, setSubcontractors] = useState<SubcontractorData[]>([]);
  const [places, setPlaces] = useState<Place[]>([]);

  useEffect(() => {
    // only listen to data if it is an admin user, the drivers don't need it.
    if (
      isAuthenticated !== undefined &&
      user &&
      [UserType.ADMIN, UserType.DRIVER_EXTENDED].includes(user.userType)
    ) {
      const unsubVehicles = setVehiclesListener();
      const unsubClients = setClientsListener();
      const unsubDrivers = setDriversListener();
      const unsubSubcontractors = setSubcontractorsListener();
      const unsubPlaces = setPlacesListener();
      return () => {
        unsubClients();
        unsubVehicles();
        unsubSubcontractors();
        unsubDrivers();
        unsubPlaces();
      };
    }
  }, [isAuthenticated, user]);

  function setVehiclesListener() {
    let unsubscribe: Unsubscribe = () => {
      console.log('not defined');
    };
    try {
      const q = query(collection(db, 'vehicles'));

      unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const _vehicles: VehicleData[] = [];

        for (const doc of querySnapshot.docs) {
          const vehicleDoc = doc.data() as VehicleDoc;

          if (vehicleDoc.driver) {
            const driverDoc = await getDoc(vehicleDoc.driver);
            const driver = {
              ...(driverDoc.data() as User),
              docId: driverDoc.id,
            };

            _vehicles.push({
              ...(doc.data() as VehicleData),
              docId: doc.id,
              driver,
            });
          } else {
            _vehicles.push({
              ...(doc.data() as VehicleData),
              docId: doc.id,
              driver: undefined,
            });
          }
        }

        _vehicles.sort((v1: VehicleData, v2: VehicleData) => {
          if (v1.vehicleType < v2.vehicleType) {
            return -1;
          } else if (v2.vehicleType < v1.vehicleType) {
            return 1;
          }

          return 0;
        });

        setVehicles(_vehicles);
      });
    } catch (error) {
      console.log(error);
    }

    return unsubscribe;
  }
  function setClientsListener() {
    let unsubscribe: Unsubscribe = () => {
      console.log('not defined');
    };
    try {
      setClientsLoading(true);
      const q = query(collection(db, 'clients'));

      unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const _clients: ClientData[] = [];

        for (const doc of querySnapshot.docs) {
          const client = doc.data() as ClientData;
          client.docId = doc.id;

          // populate contacts
          if (client.contacts && client.contacts.length > 0) {
            const contactsResponse = await populateDocs((doc.data() as ClientDoc).contacts);

            if (contactsResponse.code === 200 && contactsResponse.data) {
              client.contacts = contactsResponse.data;
            }
          }
          _clients.push(client);
        }
        setClients(_clients);
        setClientsLoading(false);
      });
    } catch (error) {
      console.log(error);
      setClientsLoading(false);
    }
    return unsubscribe;
  }

  function setSubcontractorsListener() {
    let unsubscribe: Unsubscribe = () => {
      console.log('not defined');
    };
    try {
      const q = query(collection(db, 'subcontractors'));

      unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const _subcontractors: SubcontractorData[] = [];

        for (const doc of querySnapshot.docs) {
          const subcontractor = doc.data() as SubcontractorData;
          subcontractor.docId = doc.id;

          // populate contacts
          if (subcontractor.contacts && subcontractor.contacts.length > 0) {
            const contactsResponse = await populateDocs((doc.data() as SubcontractorDoc).contacts);

            if (contactsResponse.code === 200 && contactsResponse.data) {
              subcontractor.contacts = contactsResponse.data;
            }
          }
          _subcontractors.push(subcontractor);
        }

        setSubcontractors(_subcontractors);
      });
    } catch (error) {
      console.log(error);
    }
    return unsubscribe;
  }

  function setDriversListener() {
    let unsubscribe: Unsubscribe = () => {
      console.log('not defined');
    };
    try {
      const q = query(
        collection(db, 'users'),
        where('userType', 'in', [UserType.DRIVER, UserType.DRIVER_EXTENDED, UserType.ADMIN]),
      );

      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const _drivers: User[] = [];

        for (const doc of querySnapshot.docs) {
          _drivers.push({ ...(doc.data() as User), docId: doc.id });
        }

        setDrivers(_drivers);
      });
    } catch (error) {
      console.log(error);
    }
    return unsubscribe;
  }

  function setPlacesListener(): Unsubscribe {
    let unsubscribe: Unsubscribe = () => {
      console.log('not defined');
    };

    try {
      const q = query(collection(db, 'places'));
      unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const _places: Place[] = []; // temp list to fill with doc data
        for (const doc of querySnapshot.docs) {
          const place = doc.data() as Place;
          place.docId = doc.id;
          _places.push(place);
        }
        setPlaces(_places); // Set actual state
      });
    } catch (error) {
      console.log(error);
    }

    return unsubscribe;
  }

  return (
    <DataContext.Provider
      value={{ drivers, clients, clientsLoading, vehicles, subcontractors, places }}
    >
      {children}
    </DataContext.Provider>
  );
}
