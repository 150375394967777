import { LucideIcon } from 'lucide-react';
import React from 'react';

interface Props {
  text: string;
  onClick: () => void;
  icon?: React.ReactElement;
  backgroundColor: string;
}

export default function PopupButton(props: Props) {
  return (
    <div className='popup-button' onClick={props.onClick} style={{ backgroundColor: props.backgroundColor }}>
      {props.icon && <div style={{ marginRight: '10px' }}>{props.icon}</div>}
      {props.text}
    </div>
  );
}
