import { SyntheticEvent, useContext } from 'react';
import { PopupContext } from '../../contexts/PopupContext';
import { stopPropagation } from '../../utils/uiHelpers';
import './Popup.css';
import { X } from 'lucide-react';

interface PopupProps {
  children: React.ReactElement;
  size: 'min' | 'small' | 'medium' | 'big';
}

export default function Popup({ children, size }: PopupProps) {
  const { close } = useContext(PopupContext);

  function onClickOverlay(e: SyntheticEvent) {
    stopPropagation(e);

    // only possible to close the small ones by click outside of it
    if (size === 'small' || size === 'min') {
      close();
    }
  }

  function onClickPopup(e: SyntheticEvent) {
    if (size !== 'big') {
      stopPropagation(e);
    }
  }

  return (
    <div
      className='overlay'
      onClick={onClickOverlay}
      onWheel={(e: SyntheticEvent) => {
        /* Temporary hack. when "Select" is shown inside popups it uses a fixed positioned div that covers the whole screen
           to be able to close the select options list when clicked on it. This div though prevents the div around the popup 
           from beeing able to scroll. The scroll event is just not happening. The wheel event is though so programmatically call
           scrollBy in that event. */
        e.currentTarget.scrollBy(0, (e.nativeEvent as any).deltaY);
      }}
    >
      <div className={`popup popup--${size}`} onClick={onClickPopup}>
        {(size === 'big' || size === 'medium') && (
          <div className='close-button-container'>
            <X strokeWidth={1.5} size={36} onClick={close} className='close-button' />
          </div>
        )}
        <div className='popup__content'>{children}</div>
      </div>
    </div>
  );
}
