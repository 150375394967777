import { useContext } from 'react';
import { ReportRowData, JobData } from '../../utils/types';
import { PopupContext } from '../../contexts/PopupContext';
import { stopPropagation } from '../../utils/uiHelpers';
import './AddRow.css';
import RowForm from '../../forms/RowForm';
import AlertPopup from '../../components/alert_popup/AlertPopup';
import { JobType } from '../../utils/constants';

interface AddRowProps {
  job: JobData;
  onAdd: (row: ReportRowData) => void;
}
export default function AddRow({ onAdd, job }: AddRowProps) {
  const { close, showPopup } = useContext(PopupContext);

  async function handleSubmit(row: ReportRowData) {

    if (job.jobType === JobType.EXCAVATOR_MAN) {
      if (row.from) {
        onAdd(row);
        close();
      }
      else {
        showPopup(<AlertPopup message='Du måste lägga till plats!' />, 'small');
      }
      return;
    }


    if ((row.from && row.to) || (row.fromLocation && row.toLocation)) {
      onAdd(row);
      close();
    } else {
      showPopup(<AlertPopup message='Du måste lägga till plats!' />, 'small');
    }
  }

  return (
    <section id='add-row' onClick={stopPropagation}>
      <header id='add-row__header'>
        <h2>Lägg till en ny rapportrad</h2>
      </header>
      <main>
        <RowForm
          chosenMaterials={{}}
          from={job.from ? job.from.name : undefined}
          to={job.to ? job.to.name : undefined}
          otherMaterials={''}
          jobType={job.jobType}
          handleSubmit={handleSubmit}
        />
      </main>
    </section>
  );
}
