import { doc, getFirestore } from '@firebase/firestore';
import { onSnapshot } from '@firebase/firestore';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import {
  ContactData,
  ContactDoc,
  SubcontractorData,
  SubcontractorDoc,
  UpdateContactParams,
} from '../utils/types';
import ConfirmAction from '../components/confirm_action';
import { NotificationContext } from '../contexts/NotificationContext';
import { NotificationStatus } from '../utils/constants';
import { PopupContext } from '../contexts/PopupContext';
import { stopPropagation } from '../utils/uiHelpers';
import { deleteContact, addContact } from '../firebase/firestore_functions/contacts';
import { deleteSubcontractor } from '../firebase/firestore_functions/subcontractors';
import AddContact from './AddContact';
import { populateDocs } from '../firebase/firestore_functions/common';
import EditContact from './EditContact';
import EditSubcontractor from './EditSubcontractor';

const db = getFirestore();

interface SubcontractorDetailsProps {
  subcontractor: SubcontractorData;
}

export default function SubcontractorDetails({ subcontractor }: SubcontractorDetailsProps) {
  const [subcontractorInfo, setSubcontractorInfo] = useState(subcontractor);
  const { notify } = useContext(NotificationContext);
  const { close, showPopup } = useContext(PopupContext);

  useEffect(setListenerSubcontractor, []);

  function setListenerSubcontractor() {
    try {
      const q = doc(db, 'subcontractors', subcontractor.docId);

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        if (querySnapshot.exists()) {
          const newSubcontractor = querySnapshot.data() as SubcontractorData;
          newSubcontractor.docId = querySnapshot.id;

          // populate contacts
          if (newSubcontractor.contacts && newSubcontractor.contacts.length > 0) {
            const contactsResponse = await populateDocs(
              (querySnapshot.data() as SubcontractorDoc).contacts,
            );

            if (contactsResponse.code === 200 && contactsResponse.data) {
              newSubcontractor.contacts = contactsResponse.data;
            }
          }

          setSubcontractorInfo(newSubcontractor);
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  }

  function confirmDeleteSubcontractor() {
    showPopup(
      <ConfirmAction
        confirm={_deleteSubcontractor}
        heading='Är du säker på det här?'
        message='Underleverantören kommer raderas från systemet och går inte att återställa!'
      />,
      'small',
    );
  }

  function confirmDeleteContact(idx: number) {
    showPopup(
      <ConfirmAction
        confirm={() => _deleteContact(idx)}
        heading='Är du säker på det här?'
        message='Kontakten kommer raderas från systemet och kan inte återställas'
      />,
      'small',
    );
  }

  async function _deleteSubcontractor() {
    const deleteSubcontractorResponse = await deleteSubcontractor(subcontractor.docId);

    if (deleteSubcontractorResponse.code === 201) {
      close();
      notify('Underleverantör raderad', NotificationStatus.SUCCESS);
    } else {
      notify(
        `Kunde inte radera underleverantören: ${deleteSubcontractorResponse.error}`,
        NotificationStatus.ERROR,
      );
    }
  }

  async function _deleteContact(idx: number) {
    const deleteContactResponse = await deleteContact(
      subcontractorInfo.contacts[idx].docId,
      subcontractorInfo,
      'subcontractors',
    );
    if (deleteContactResponse.code === 201) {
      notify('Kontakt raderad', NotificationStatus.SUCCESS);
    } else {
      notify(
        `Kunde inte radera kontakten: ${deleteContactResponse.error}`,
        NotificationStatus.ERROR,
      );
    }
  }

  function openEditSubcontractor(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<EditSubcontractor subcontractor={{ ...subcontractorInfo }} />, 'medium');
  }

  async function onAddContact(contact: ContactDoc) {
    return await addContact(contact, subcontractorInfo, 'subcontractors');
  }

  function onEditContact(changes: UpdateContactParams, docId: string) {
    // to refect updates in contact list
    for (let i = 0; i < subcontractorInfo.contacts.length; ++i) {
      if (subcontractorInfo.contacts[i].docId === docId) {
        subcontractorInfo.contacts[i] = {
          ...subcontractorInfo.contacts[i],
          ...changes,
        };
      }
    }

    setSubcontractorInfo({ ...subcontractorInfo });
  }

  function openAddContact(event: SyntheticEvent) {
    event.preventDefault();
    showPopup(<AddContact onAdd={onAddContact} />, 'medium');
  }

  function openEditContact(event: SyntheticEvent, contact: ContactData) {
    event.preventDefault();
    showPopup(<EditContact contact={contact} onEdit={onEditContact} />, 'medium');
  }

  return (
    <section className='details' onClick={stopPropagation}>
      <header className='details__header'>
        <h2>{subcontractorInfo.name}</h2>
        <section className='details__header__controls'>
          <button className='fa fa-edit button--icon' onClick={openEditSubcontractor}></button>

          <button
            onClick={confirmDeleteSubcontractor}
            className='fa fa-trash button--icon'
          ></button>
        </section>
      </header>
      <main>
        <section className='details__info'>
          <div className='info'>
            <p className='label'>Organisationsnummer</p>
            <p>{subcontractorInfo.orgNum}</p>
          </div>
          <div className='info'>
            <p className='label'>Namn</p>
            <p>{subcontractorInfo.name}</p>
          </div>

          <div className='info'>
            <p className='label'>Email</p>
            <p>{subcontractorInfo.email}</p>
          </div>

          <div className='info'>
            <p className='label'>Tel</p>
            <p>{subcontractorInfo.phone}</p>
          </div>

          <ul className='form__ul form'>
            <li className='form__li'>
              <div className='form__header'>
                <p className='label'>Kontakter</p>
                <button className='button--main--small form__button' onClick={openAddContact}>
                  <span className='fa fa-plus' />
                  Lägg till
                </button>
              </div>
              <ul id='client-details__form__li__contacts'>
                {!subcontractorInfo.contacts ||
                  (subcontractorInfo.contacts.length === 0 && (
                    <li key='no-contacts'>Inga tillagda kontakter</li>
                  ))}
                {subcontractorInfo.contacts &&
                  subcontractorInfo.contacts.map((contact, idx) => {
                    return (
                      <li key={idx} className='form__list__item'>
                        <p>
                          {contact.name} {' - '} {contact.phone}
                        </p>

                        <div>
                          <button
                            onClick={(event: SyntheticEvent) => {
                              openEditContact(event, contact);
                            }}
                            className='button--icon fa fa-edit'
                          ></button>
                          <button
                            onClick={() => {
                              confirmDeleteContact(idx);
                            }}
                            className='fa fa-trash button--icon'
                          ></button>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </li>
          </ul>
        </section>
      </main>
    </section>
  );
}
