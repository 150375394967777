import { isToday, toDayDateString } from '../../../utils/time';
import { JobDataPartial, SlingaData } from '../../../utils/types';
import './WeekdayDesktop.css';
import { WeekDayEntry } from '../TableComponents/WeekDayEntry';

interface WeekDayProps {
  dateMillis: number;
  jobs: Array<JobDataPartial & SlingaData>;
  onClickJob: (docId: string, clickedDate: number) => void;
  onClickSlinga: (slinga: string) => void;
}

function WeekDayDesktop({ dateMillis, jobs, onClickJob, onClickSlinga }: WeekDayProps) {
  return (
    <>
      <tr className={'date-container'}>
        <td colSpan={7} style={{ padding: '0px' }}>
          {toDayDateString(dateMillis)}
        </td>
      </tr>
      {Object.values(jobs).length === 0 && (
        <tr>
          <td id='no-jobs' colSpan={7}>
            Inga jobb
          </td>
        </tr>
      )}
      {jobs.map((job: JobDataPartial & SlingaData) => {
        return (
          <WeekDayEntry
            key={job.docId}
            job={job}
            onClickJob={onClickJob}
            onClickSlinga={onClickSlinga}
            dateMillis={dateMillis}
          />
        );
      })}
    </>
  );
}

export default WeekDayDesktop;
