import { SyntheticEvent, useState } from 'react';
import { Equipment, EquipmentDoc } from '../utils/types';
import Option from '../components/select/Option';
import Select from '../components/select/Select';
import { EquipmentType, equipmentTypeLabel } from '../utils/constants';

interface EquipmentFormProps {
  equipment?: Equipment;
  handleSubmit: (equipment: EquipmentDoc) => Promise<void>;
  saving: boolean;
}

export default function VehicleForm({ equipment, handleSubmit, saving }: EquipmentFormProps) {
  const [id, setId] = useState<string>(equipment ? equipment.id : '');
  const [service, setService] = useState<string>(equipment ? equipment.service : '');
  const [serviceId, setServiceId] = useState<string>(equipment ? equipment.serviceId : '');
  const [equipmentType, setEquipmentType] = useState<EquipmentType>(
    equipment ? equipment.equipmentType : EquipmentType.EXCAVATOR,
  );

  function onChangeId(event: SyntheticEvent) {
    setId((event.target as HTMLInputElement).value);
  }

  function onChangeService(event: SyntheticEvent) {
    setService((event.target as HTMLInputElement).value);
  }

  function onChangeServiceId(event: SyntheticEvent) {
    setServiceId((event.target as HTMLInputElement).value);
  }

  function onChangeEquipmentType(value: string) {
    setEquipmentType(parseInt(value) as EquipmentType);
  }
  async function _handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    handleSubmit({
      id,
      service,
      serviceId,
      equipmentType,
    });
  }

  return (
    <form className='form' onSubmit={_handleSubmit}>
      <ul className='form__ul'>
        <li className='form__li' key='type'>
          <Select
            onChange={onChangeEquipmentType}
            isMultiSelect={false}
            initialOption={{
              value: equipmentType.toString(),
              label: equipmentTypeLabel[equipmentType],
            }}
            type='form'
            iconRightClose='fa fa-angle-down'
            iconRightOpen='fa fa-angle-up'
          >
            <>
              <Option
                value={EquipmentType.EXCAVATOR.toString()}
                label={equipmentTypeLabel[EquipmentType.EXCAVATOR]}
                key={EquipmentType.EXCAVATOR.toString()}
              />
              <Option
                value={EquipmentType.FLATBED.toString()}
                label={equipmentTypeLabel[EquipmentType.FLATBED]}
                key={EquipmentType.FLATBED.toString()}
              />

              <Option
                value={EquipmentType.TRAILER.toString()}
                label={equipmentTypeLabel[EquipmentType.TRAILER]}
                key={EquipmentType.TRAILER.toString()}
              />
              <Option
                value={EquipmentType.OTHER.toString()}
                label={equipmentTypeLabel[EquipmentType.OTHER]}
                key={EquipmentType.OTHER.toString()}
              />
            </>
          </Select>
        </li>
        <li className='form__li' key='id'>
          <input
            className='input-text form__input'
            type='text'
            value={id}
            placeholder='Id'
            onChange={onChangeId}
            required
          />
        </li>
        <li className='form__li' key='service'>
          <input
            className='input-text form__input'
            type='text'
            value={service}
            placeholder='Tjänst'
            onChange={onChangeService}
            required
          />
        </li>
        <li className='form__li' key='serviceId'>
          <input
            className='input-text form__input'
            type='text'
            value={serviceId}
            placeholder='Artikelnummer'
            onChange={onChangeServiceId}
            required
          />
        </li>

        <li className='form__li loading-parent'>
          {saving && <span className='fa fa-circle-o-notch fa-spin loading-animation' />}
          <input className='button--green form__input' type='submit' value='Spara' />
        </li>
      </ul>
    </form>
  );
}
