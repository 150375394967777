import React, { useEffect, useState } from 'react';
import { AuthState, User } from '../utils/types';
import { onAuthStateChanged } from '@firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { getAuth, User as FirebaseUser } from 'firebase/auth';
import { getUser } from '../firebase/firestore_functions/users';

const initialState: AuthState = {
  fetchUser: (_: FirebaseUser) => {
    return;
  },
};

export const AuthContext = React.createContext(initialState);

const auth = getAuth(firebaseApp);

interface AuthProviderProps {
  children: React.ReactElement;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [isAnonymous, setIsAnonymous] = useState<boolean>(); // external drivers are being signed in as anonymous

  useEffect(() => {
    onAuthStateChanged(auth, async function (user) {
      if (!user) {
        setUser(undefined);
        setIsAuthenticated(false);
      } else if (user.isAnonymous) {
        setIsAnonymous(true);
        setIsAuthenticated(true);
      } else if (user.email) {
        try {
          await fetchUser(user);
        } catch (error) {
          setUser(undefined);
        } finally {
          setIsAnonymous(false);
        }
      }
    });
  }, []);

  async function fetchUser(user: FirebaseUser) {
    const userResponse = await getUser(user.uid);

    if (userResponse.code === 200 && userResponse.data) {
      setUser(userResponse.data);
      setIsAuthenticated(true);
    } else {
      setUser(undefined);
      setIsAuthenticated(false);
    }
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isAnonymous, fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
}
