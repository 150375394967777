import { useContext } from 'react';
import { AddEditJobContext } from './AddEditJobContext';
import AddClient from '../../components/components_for_add_edit_job_slinga/AddClient';

/**
 * Component that wraps 'AddClient' and gives it the data state variables from 'AddEditJobContext'
 */
export default function AddClientToJob() {
  const { client, setClient } = useContext(AddEditJobContext);
  return <AddClient client={client} setClient={setClient} />;
}
