import { SyntheticEvent } from 'react';

/**
 * Used sometimes to prevent buttons in a form to submit the form
 * @param event
 */
export function stopPropagation(event: SyntheticEvent) {
  event.stopPropagation();
  event.nativeEvent.stopPropagation();
}

export function preventDefault(event: SyntheticEvent) {
  event.preventDefault();
}
