import { useContext } from 'react';
import { PopupContext } from '../../contexts/PopupContext';
import './AlertPopup.css';

interface AlertPopupProps {
  message: string;
}

export default function AlertPopup({ message }: AlertPopupProps) {
  const { close } = useContext(PopupContext);

  return (
    <section id='alert-popup'>
      <p id='alert-popup__message'>{message}</p>

      <button id='alert-popup__button' className='button--main--small' onClick={close}>
        Ok
      </button>
    </section>
  );
}
